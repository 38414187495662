import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useReprocessInterfaceError } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

const ReprocessInterfaceErrorModal: FC = () => {
  const { setIsReprocessModalOpen, currentInterfaceError } = useOrderMonitor()

  const handleClose = () => {
    setIsReprocessModalOpen(false)
  }
  const { mutate: reprocessInterfaceError } =
    useReprocessInterfaceError(handleClose)

  const handleConfirm = () => {
    reprocessInterfaceError({
      termID: '1002',
      tripNo: Number(currentInterfaceError?.tripNo),
      errorType: currentInterfaceError?.errorType || '',
      LoadErrorId: Number(currentInterfaceError?.loadErrorID),
    })
  }

  return (
    <DeleteModal
      title="Reprocess this error"
      confirmText="Confirm"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>Are you sure to reprocess this error ?</p>
    </DeleteModal>
  )
}
export default ReprocessInterfaceErrorModal
