import { Layout } from '@/components'

import BaseProdMaster from '@/features/master/baseProd'

const BaseProdMasterPage = () => {
  return (
    <Layout>
      <BaseProdMaster />
    </Layout>
  )
}

export default BaseProdMasterPage
