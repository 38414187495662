import { Layout } from '@/components'

import ErrorReportFeature from '@/features/reports/mismatch'

const ErrorReportPage = () => {
  return (
    <Layout>
      <ErrorReportFeature />
    </Layout>
  )
}

export default ErrorReportPage
