import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetCarrierDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface CarrierAutoCompleteField {
  carrierID: number | null
  carrierCode: string
}

interface CarrierAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const CarrierAutoComplete: FC<CarrierAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: carriers, isLoading } = useGetCarrierDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<CarrierAutoCompleteField>()

  const carrierState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.carrierCode && 'em-has-error'

  return (
    <Autocomplete
      state={carrierState}
      note={formState.errors.carrierCode?.message}
      id="carrierID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={carriers}
      label="Carrier"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('carrierCode')}
      maxLength={20}
      value={watch('carrierCode')}
      {...register('carrierCode', {
        required: isRequired ? 'Carrier is required' : false,
        validate: () =>
          watch('carrierID') === 0 ? 'Carrier is not valid' : undefined,
      })}
      onChange={(value) => {
        setValue(
          'carrierID',
          value?.label ? parseNanToZero(value?.value) : null
        )
        setValue('carrierCode', value?.label)
      }}
    />
  )
}

export default CarrierAutoComplete
