import { useQueueRegistrationStore } from '@/stores'

const QueueRegistrationCompleted = () => {
  const { setStep, setResetData } = useQueueRegistrationStore()
  return (
    <div className="flex flex-col items-center w-full h-full justify-center gap-y-10 p-20 z-10 absolute">
      <div className="">
        <img src="/images/ExxonMobilLogoColor2x.png" className="" />
      </div>
      <div className="flex flex-col gap-4 items-center text-4xl text-white font-bold">
        <p className="">You have entered the queue.</p>
        <p className="">Please check your queue on the queuing screens</p>
      </div>
      <button
        className="em-c-btn"
        onClick={() => {
          setResetData()
          setStep('validateIButton')
        }}
      >
        <span className="em-c-btn__text">Go to Home</span>
      </button>
    </div>
  )
}

export default QueueRegistrationCompleted
