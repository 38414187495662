import { FC, MouseEventHandler } from 'react'

import Icon from '@/standard/Icon'

interface ExpanderButtonProps {
  tabIndex?: number
  expanded: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const ExpanderButton: FC<ExpanderButtonProps> = ({
  tabIndex,
  expanded,
  onClick,
}) => {
  return (
    <button
      className="flex items-center justify-center w-6 h-6 bg-blue-500 text-white rounded-full focus:outline-none"
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {expanded ? (
        <Icon name="chevron-down-filled" size="em-c-icon--small" />
      ) : (
        <Icon name="chevron-right-filled" size="em-c-icon--small" />
      )}
    </button>
  )
}

export default ExpanderButton
