import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetTankerForDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface TankerAutoCompleteField {
  tankerID: number | null
  tankerCode: string
}

interface TankerAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const TankerAutoComplete: FC<TankerAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: tankers, isLoading } = useGetTankerForDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<TankerAutoCompleteField>()

  const tankerState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.tankerCode && 'em-has-error'

  return (
    <Autocomplete
      state={tankerState}
      note={formState.errors.tankerCode?.message}
      id="tankerID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={tankers}
      label="Tanker"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('tankerCode')}
      maxLength={20}
      value={watch('tankerCode')}
      {...register('tankerCode', {
        required: isRequired ? 'Tanker is required' : false,
        validate: () =>
          watch('tankerID') === 0 ? 'Tanker is not valid' : undefined,
      })}
      onChange={(value) => {
        setValue('tankerID', value?.label ? parseNanToZero(value?.value) : null)
        setValue('tankerCode', value?.label)
      }}
    />
  )
}

export default TankerAutoComplete
