import { create } from 'zustand'

import { ScreenList } from '@/services/api/roleMatrix'
import { toCamelCase } from '@/utils'

export interface IRoleAccess {
  canRead: boolean
  canEdit: boolean
  canCreate: boolean
  canDelete: boolean
}

type RoleStateFunction = {
  setRoleMatrix: (value: ScreenList[]) => void
}

type RoleStateIndex = {
  [key: string]: IRoleAccess
}

type RoleState = RoleStateFunction & RoleStateIndex

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useRoleMatrixStore = create<RoleState>(set => ({
  setRoleMatrix: (value: ScreenList[]) => {
    set({
      ...value.reduce((acc: { [key: string]: IRoleAccess }, curr) => {
        const { screenName, ...rest } = curr
        acc[toCamelCase(screenName)] = <IRoleAccess>rest
        return acc
      }, {}),
    })
  },
}))
