import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ISearchTanker, useSearchTanker } from '@/services/api/tankerMaster'

import { useTankerMasterStore } from '@/stores'
import { useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

import TankerMasterTable from './table'

const Header: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddTankerModal } = useTankerMasterStore()
  const { tankerMaster } = useRoleMatrixStore()
  const { canCreate } = tankerMaster
  const { handleSubmit, watch, register } = useForm<ISearchTanker>({
    defaultValues: {
      TankerIButton: '',
      TankerCode: '',
      LicensePlate: '',
      CarrierCode: '',
    },
    mode: 'onBlur',
  })

  const {
    refetch,
    isFetching,
    data: tankers,
    isLoading,
  } = useSearchTanker({
    ...filterFalsyProperties(watch()),
    termID: '1002',
    Page: currentPage,
  })

  const handleAdd = () => {
    setToggleAddTankerModal(true)
  }

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Tanker IButton"
          id="TankerIButton"
          maxLength={12}
          {...register('TankerIButton')}
        />
        <TextInput
          label="Tanker Code"
          id="TankerCode"
          maxLength={20}
          {...register('TankerCode')}
        />
        <TextInput
          label="License Plate"
          id="LicensePlate"
          maxLength={50}
          {...register('LicensePlate')}
        />
        <TextInput
          label="Carrier Code"
          id="CarrierCode"
          maxLength={20}
          {...register('CarrierCode')}
        />
        <Select label="Is Top Load" id="IsTopLoad" {...register('IsTopLoad')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select label="Is Weight" id="IsWeight" {...register('IsWeight')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select label="Is Borrow" id="IsBorrow" {...register('IsBorrow')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={handleAdd}
          />
        )}
      </form>
      <TankerMasterTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        tankers={tankers}
        isLoading={isLoading}
      />
    </>
  )
}
export default Header
