import useDisableRightClick from '@/hooks/useDisableRightClick'
import { useDebounce } from 'react-use'

import { qRegistrationService } from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

import ConfirmGoToHomeModal from './confirmGoToHomeModal'
import DriverIButton from './driverIButton'
import PhoneNumberConfirmation from './phoneNumber'
import QueueRegistrationCompleted from './queueCompleted'
import SelectContractComponent from './selectContract'
import ShowQueueStatusComponent from './showQueueStatus'
import SummaryQueueComponent from './summaryQueue'
import TankerIButton from './tankerIButton'
import ValidateDocument from './validateDocument'

const QRegistrationComponent = () => {
  const { step, setResetData, isGoToHome, setIsGoToHome } =
    useQueueRegistrationStore()

  const { data: isEnableQueueRegister } =
    qRegistrationService.useGetEnableQueueRegister({
      termID: '1002',
    })

  useDisableRightClick()

  useDebounce(
    () => {
      if (step !== 'queueCompleted') return
      setResetData()
    },
    5000,
    [step]
  )

  const goToHomeAndClearData = () => {
    setIsGoToHome(false)
    setResetData()
  }

  return (
    <div className="w-full h-full relative">
      <img
        src="/images/Lehmann.jpg"
        className="w-full h-full absolute z-0 brightness-50"
      />
      {isEnableQueueRegister !== false ? (
        <>
          {/* IButton */}
          {step === 'validateIButton' && <DriverIButton />}
          {step === 'validateTanker' && <TankerIButton />}
          {step === 'validateDocument' && <ValidateDocument />}
          {step === 'selectProd' && <SelectContractComponent />}
          {step === 'showSummary' && <SummaryQueueComponent />}
          {step === 'confirmPhoneNumber' && <PhoneNumberConfirmation />}
          {step === 'queueCompleted' && <QueueRegistrationCompleted />}
          {(step === 'acknowledgement' ||
            step === 'waitForBayassignment' ||
            step === 'waitForAcknowledgement' ||
            step === 'waitForCallToBay' ||
            step === 'goToBay') && <ShowQueueStatusComponent />}
          {isGoToHome && (
            <ConfirmGoToHomeModal
              onClose={() => setIsGoToHome(false)}
              onConfirm={() => goToHomeAndClearData()}
            />
          )}
        </>
      ) : (
        <div className="flex items-center w-full h-full justify-center p-20 z-10 absolute mx-auto">
          <div className="flex flex-col items-center justify-center gap-20 w-3/4 h-3/4 bg-white rounded-lg shadow-2xl p-10">
            <p className="text-4xl xl:text-6xl font-extrabold m-0 text-center">
              Good day, please continue your activities as usual.
            </p>
            <p className="text-lg xl:text-2xl m-0 text-center">
              (Queuing System is out of service at the moment.)
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default QRegistrationComponent
