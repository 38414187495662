import { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'

import { BaseProdAutoComplete, BayAutoComplete } from '@/components'

import { IBayProdInformation } from '@/stores'

import Select from '@/standard/Select'
import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

import {
  Required,
  extractOnlyNumericCharacters,
  parseNanToZero,
  parseStringToBoolean,
} from '@/utils'

const BayProdMasterForm = () => {
  const { setValue, register, formState } =
    useFormContext<Required<IBayProdInformation>>()

  const armState = formState.errors.arm && 'em-has-error'

  const handleChange = (
    fieldName: keyof Required<IBayProdInformation>,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value
    setValue(fieldName, extractOnlyNumericCharacters(newValue))
  }

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
        <BaseProdAutoComplete isRequired />
        <TextInput
          id="arm"
          label="Arm"
          required
          maxLength={9}
          state={armState}
          note={formState.errors.arm?.message}
          {...register('arm', {
            required: {
              value: true,
              message: 'Arm is required',
            },
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleChange('arm', event),
            setValueAs: parseNanToZero,
          })}
        />
        <BayAutoComplete isRequired />
        <Select
          id="isActive"
          label="Is Active"
          selectOptionalClass="w-full"
          required
          {...register('isActive', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </div>
      <div className="grid grid-cols-1 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <TextArea
          id="remark"
          label="Remark"
          maxLength={200}
          textareaOptionalClass="!h-[6rem]"
          {...register('remark')}
        />
      </div>
    </>
  )
}

export default BayProdMasterForm
