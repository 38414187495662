import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { ISearchDrawer, useGetDrawer } from '@/services/api/drawerMaster'

import { useDrawerMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import DrawerMasterTable from './table'

const Header: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddDrawerModal } = useDrawerMasterStore()
  const { drawerMaster } = useRoleMatrixStore()
  const { canCreate } = drawerMaster

  const { register, handleSubmit, watch } = useForm<ISearchDrawer>({
    defaultValues: {
      drawerCode: '',
      drawerName: '',
      termID: '1002',
    },
    mode: 'onBlur',
  })

  const { refetch, isFetching, data, isLoading } = useGetDrawer({
    termID: '1002',
    ...(watch('drawerCode') ? { drawerCode: watch('drawerCode') } : {}),
    ...(watch('drawerName') ? { drawerName: watch('drawerName') } : {}),
    page: currentPage,
  })

  const handleSearch: SubmitHandler<ISearchDrawer> = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const openModal = () => {
    setToggleAddDrawerModal(true)
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Drawer Code"
          id="drawerCode"
          maxLength={20}
          {...register('drawerCode')}
        />
        <TextInput
          label="Drawer Name"
          id="drawerName"
          maxLength={50}
          {...register('drawerName')}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={openModal}
          />
        )}
      </form>
      <DrawerMasterTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        drawers={data}
        isLoading={isLoading}
      />
    </>
  )
}
export default Header
