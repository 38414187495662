import { FC } from 'react'

import { Layout } from '@/components'

import OrderMonitorComponent from '@/features/monitor/order'

const OrderMonitorPage: FC = () => {
  return (
    <Layout>
      <OrderMonitorComponent />
    </Layout>
  )
}

export default OrderMonitorPage
