import { Pagination } from '@nextui-org/pagination'

interface ToolbarWithPaginationProps {
  label?: string
  currentPage: number
  numberOfPages: number
  optionalClass?: string
  onChangePage?: (page: number) => void
}

const ToolbarWithPagination = ({
  label,
  currentPage,
  numberOfPages,
  optionalClass,
  onChangePage,
}: ToolbarWithPaginationProps) => {
  return (
    <div
      className={`em-c-toolbar ${optionalClass ? optionalClass : ''}`}
      role="toolbar"
      aria-label="Toolbar with pagination"
      aria-describedby="toolbarItemsQuantity"
    >
      <div className="em-c-toolbar__item" id="toolbarItemsQuantity">
        {label}
      </div>
      <div className="em-c-toolbar__item em-is-aligned-right">
        <Pagination
          isCompact
          showShadow
          size="sm"
          total={numberOfPages}
          page={currentPage}
          onChange={onChangePage}
        />
      </div>
    </div>
  )
}

export default ToolbarWithPagination
