import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import {
  GetGateToGateResponseBody,
  GetGateToGateResponseData,
} from '@/services/api/gateToGate'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<GetGateToGateResponseData>([
  {
    key: 'documentType',
    name: 'Document Type',
    renderCell: ({ row }) => row.documentType || '-',
  },
  {
    key: 'documentNr',
    name: 'Document Number',
    renderCell: ({ row }) => row.documentNr || '-',
  },
  {
    key: 'fullName',
    name: 'Full Name',
    renderCell: ({ row }) => row.fullName || '-',
  },
  {
    key: 'licensePlate',
    name: 'License Plate',
    renderCell: ({ row }) => row.licensePlate || '-',
  },
  {
    key: 'carrierCode',
    name: 'Carrier Code',
    renderCell: ({ row }) => row.carrierCode || '-',
  },
  {
    key: 'drawerCode',
    name: 'Drawer Code',
    renderCell: ({ row }) => row.drawerCode || '-',
  },
  { key: 'bay', name: 'Bay', renderCell: ({ row }) => row.bay || '-' },
  {
    key: 'displayCreateDTM',
    name: 'Create Date Time',
    renderCell: ({ row }) => row.displayCreateDTM || '-',
  },
  {
    key: 'displayPickDTM',
    name: 'Pick DTM',
    renderCell: ({ row }) => row.displayPickDTM || '-',
  },
  {
    key: 'durationWaitForBayAssignment',
    name: 'Time to Pick',
    renderCell: ({ row }) => row.durationWaitForBayAssignment || '-',
  },
  {
    key: 'displayAckownledgeDTM',
    name: 'Ack DTM',
    renderCell: ({ row }) => row.displayAckownledgeDTM || '-',
  },
  {
    key: 'durationAckownledgement',
    name: 'Time to Ack',
    renderCell: ({ row }) => row.durationAckownledgement || '-',
  },
  {
    key: 'displayCallTDM',
    name: 'Call DTM',
    renderCell: ({ row }) => row.displayCallTDM || '-',
  },
  {
    key: 'durationWaitForCallToBay',
    name: 'Time to Call',
    renderCell: ({ row }) => row.durationWaitForCallToBay || '-',
  },
  {
    key: 'displayStartDTM',
    name: 'Load Start DTM',
    renderCell: ({ row }) => row.displayStartDTM || '-',
  },
  {
    key: 'durationWaitForStartLoad',
    name: 'Time to Start Load',
    renderCell: ({ row }) => row.durationWaitForStartLoad || '-',
  },
  {
    key: 'displayEndDTM',
    name: 'Load Complete DTM',
    renderCell: ({ row }) => row.displayEndDTM || '-',
  },
  {
    key: 'durationLoadTime',
    name: 'Time to Load Complete',
    renderCell: ({ row }) => row.durationLoadTime || '-',
  },
  {
    key: 'totalTime',
    name: 'Total Time',
    renderCell: ({ row }) => row.totalTime || '-',
  },
])

interface GateToGateTableProps {
  currentPage: number
  onChangePage: (page: number) => void
  reports?: GetGateToGateResponseBody
  isLoading: boolean
}

const GateToGateTable: FC<GateToGateTableProps> = ({
  currentPage,
  onChangePage,
  reports,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage,
    rowsPerPage,
    total: reports?.total || 0,
  })

  return reports ? (
    <>
      <DataGrid
        className="h-full rdg-light"
        columns={columns}
        rows={reports.result}
        headerRowHeight={30}
        rowHeight={45}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          reports?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((reports?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default GateToGateTable
