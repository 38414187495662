import { Layout } from '@/components'

import CustomerMaster from '@/features/master/customer'

const CustomerMasterPage = () => {
  return (
    <Layout>
      <CustomerMaster />
    </Layout>
  )
}

export default CustomerMasterPage
