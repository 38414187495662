import { useEffect } from 'react'

import msalPublicClient, { authPermissions } from '@/auth/authConfig'

const useAutoReLogin = () => {
  useEffect(() => {
    const interval = setInterval(
      () => {
        msalPublicClient
          .ssoSilent({ ...authPermissions, prompt: 'none' })
          .catch((error) => {
            console.error(error)
          })
      },
      12 * 60 * 60 * 1000
    ) // 12 hours in milliseconds

    return () => {
      clearInterval(interval)
    }
  }, [])
}

export default useAutoReLogin
