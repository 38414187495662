import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import {
  IDrawerProdInformation,
  useAddDrawerProd,
} from '@/services/api/drawerProd'

import { useDrawerProductStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

import DrawerProductForm from '../forms/drawerProduct'
import BomTable from '../tables/bom'

const DrawerProductAddModal = () => {
  const {
    setToggleAddDrawerProdModal,
    clearBomChild,
    bomChild,
    setToggleAddBomChildModal,
  } = useDrawerProductStore()

  const handleClose = () => {
    setToggleAddDrawerProdModal(false)
  }

  const { mutate, isPending } = useAddDrawerProd(handleClose)
  const methods = useForm<IDrawerProdInformation>({
    defaultValues: {
      drawerProdID: 0,
      drawerProdCode: '',
      drawerProdName: '',
      remark: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: IDrawerProdInformation) => {
    mutate({
      body: {
        drawerProdID: data.drawerProdID,
        drawerProdCode: data.drawerProdCode,
        drawerProdName: data.drawerProdName,
        drawerProdGroupID: data.drawerProdGroupID,
        remark: data.remark,
        bomChildList: bomChild.map((bom) => ({
          bomid: bom.bomid,
          baseProdID: bom.baseProdID,
        })),
      },
      params: { termID: '1002' },
    })
  }

  const handleAddBom = () => {
    setToggleAddBomChildModal(true)
  }

  useEffect(() => {
    return () => {
      clearBomChild()
    }
  }, [])

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a drawer product"
        confirmLabel="Add"
        isValid={methods.formState.isValid && bomChild.length > 0}
        onClose={handleClose}
        isLoading={isPending}
        modalWindowOptionalClass="!w-[50rem]"
      >
        <DrawerProductForm />
        <ButtonGroup optionalClass="my-4">
          <Button
            disabled={!methods.formState.isValid}
            type="em-c-btn--primary"
            label="+ Add a Bom Child"
            typeAttribute="button"
            onClick={handleAddBom}
          />
        </ButtonGroup>
        <BomTable rows={bomChild} />
      </FormModal>
    </FormProvider>
  )
}

export default DrawerProductAddModal
