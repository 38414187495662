import { create } from 'zustand'

export interface IDrawerInformation {
  drawerID: number
  termID: string
  drawerCode: string
  drawerName: string
  remark: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayCreatedDate: Date
  displayModifiedDate: Date
  displayRemark: string
}

export interface IDrawerData {
  result: IDrawerInformation[]
  total: number
}

interface DrawerMasterState {
  currentDrawer: IDrawerInformation | undefined
  setCurrentDrawer: (value: IDrawerInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddDrawerModal: boolean
  setToggleAddDrawerModal: (value: boolean) => void
  toggleEditDrawerModal: boolean
  setToggleEditDrawerModal: (value: boolean) => void
}

export const useDrawerMasterStore = create<DrawerMasterState>((set) => ({
  currentDrawer: undefined,
  setCurrentDrawer: (value) => set({ currentDrawer: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddDrawerModal: false,
  setToggleAddDrawerModal: (value) => set({ toggleAddDrawerModal: value }),
  toggleEditDrawerModal: false,
  setToggleEditDrawerModal: (value) => set({ toggleEditDrawerModal: value }),
}))
