import { Layout } from '@/components'

import DrawerProduct from '@/features/master/drawerProduct'

const DrawerProductPage = () => {
  return (
    <Layout>
      <DrawerProduct />
    </Layout>
  )
}

export default DrawerProductPage
