import { DeleteModal } from '@/components'

import { useDeletePriority } from '@/services/api/priorityMaster'

import { usePriorityMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const PriorityDeleteModal = () => {
  const { currentPriority, setCurrentPriority, setToggleConfirmDeleteModal } =
    usePriorityMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentPriority(undefined)
  }

  const { mutate, isPending } = useDeletePriority(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', priorityID: currentPriority!.priorityID })
  }

  return (
    <DeleteModal
      title="Delete a priority"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {currentPriority?.priorityCode && (
          <BulletedListItem>
            Priority Code: {currentPriority?.priorityCode}
          </BulletedListItem>
        )}
        {currentPriority?.priorityName && (
          <BulletedListItem>
            Priority Name: {currentPriority?.priorityName}
          </BulletedListItem>
        )}
        {`${currentPriority?.delayMin}` && (
          <BulletedListItem>
            Delay Min: {currentPriority?.delayMin}
          </BulletedListItem>
        )}
        {`${currentPriority?.dropMax}` && (
          <BulletedListItem>
            Drop Max: {currentPriority?.dropMax}
          </BulletedListItem>
        )}
        {currentPriority?.remark && (
          <BulletedListItem>Remark: {currentPriority?.remark}</BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default PriorityDeleteModal
