import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetDrawerDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface DrawerAutoCompleteField {
  drawerID: number | null
  drawerCode: string
}

interface DrawerAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const DrawerAutoComplete: FC<DrawerAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: drawers, isLoading } = useGetDrawerDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<DrawerAutoCompleteField>()

  const drawerState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.drawerCode && 'em-has-error'

  return (
    <Autocomplete
      state={drawerState}
      note={formState.errors.drawerCode?.message}
      id="drawerID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={drawers}
      label="Drawer"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('drawerCode')}
      maxLength={20}
      value={watch('drawerCode')}
      {...register('drawerCode', {
        required: isRequired ? 'Drawer is required' : false,
        validate: () =>
          watch('drawerID') === 0 ? 'Drawer is not valid' : undefined,
      })}
      onChange={(value) => {
        setValue('drawerID', value?.label ? parseNanToZero(value?.value) : null)
        setValue('drawerCode', value?.label)
      }}
    />
  )
}

export default DrawerAutoComplete
