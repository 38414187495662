import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IShipmentDetail, useShipmentMasterStore } from '@/stores'

import ShipmentChildForm from '../forms/child'

const AddShipmentChildModal: FC = () => {
  const { addShipmentChild, setToggleAddShipmentChildModal } =
    useShipmentMasterStore()
  const methods = useForm<IShipmentDetail>({
    mode: 'onBlur',
    defaultValues: {
      shipmentDetailID: 0,
      shipToCustomerID: null,
      soldToCustomerID: null,
    },
  })

  const handleClose = () => {
    setToggleAddShipmentChildModal(false)
  }

  const onSubmit = (data: IShipmentDetail) => {
    addShipmentChild(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a shipment: Order"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Add"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <ShipmentChildForm />
      </FormModal>
    </FormProvider>
  )
}

export default AddShipmentChildModal
