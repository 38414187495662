import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { ITankerData } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchTanker {
  termID: string
  Page: number
  TankerIButton?: string
  TankerCode?: string
  LicensePlate?: string
  CarrierCode?: string
  IsTopLoad?: string
  IsWeight?: string
  IsBorrow?: string
}

export interface IAddTanker {
  tankerIButton: string
  tankerCode: string
  licensePlate: string
  carrierID: number
  isTopLoad: boolean
  isWeight: boolean
  isBorrow: boolean
  remark: string
}

export interface IEditTanker {
  tankerID: number
  tankerIButton: string
  tankerCode: string
  licensePlate: string
  carrierID: number
  isTopLoad: boolean
  isWeight: boolean
  isBorrow: boolean
  remark: string
}

export function useSearchTanker(params: ISearchTanker) {
  return useQuery({
    queryKey: ['searchTanker', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ITankerData>>(
          `/TankerMaster/SearchTanker`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface IDeleteTankerRequestParams {
  tankerID: number
  termID: string
}

export function useDeleteTanker(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IDeleteTankerRequestParams>({
    mutationFn: async (params) => {
      const { data } = await api.delete('TankerMaster/DeleteTanker', { params })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchTanker'] })
    },
  })
}

type IAddTankerRequestParams = Pick<IDeleteTankerRequestParams, 'termID'>

interface IAddTankerOptions {
  body: IAddTanker
  params: IAddTankerRequestParams
}

export function useAddTanker(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IAddTankerOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post('TankerMaster/AddTanker', body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchTanker'] })
    },
  })
}

interface IEditTankerOptions {
  body: IEditTanker
  params: IAddTankerRequestParams
}

export function useEditTanker(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IEditTankerOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post('TankerMaster/EditTanker', body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchTanker'] })
    },
  })
}
