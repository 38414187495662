export function convertBooleanToYesNo(yesNo: boolean) {
  if (yesNo) return 'Yes'
  return 'No'
}

export function parseStringToBoolean(value: string | boolean) {
  if (typeof value === 'boolean') return value
  return value === 'true'
}

export function parseNanToZero(value: string | number) {
  if (typeof value === 'string') {
    return isNaN(Number(value)) ? 0 : Number(value)
  }
  return isNaN(value) ? 0 : value
}
