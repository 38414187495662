import React from 'react'

import { AccordionItemProps } from './AccordionItem'

interface AccordionProps {
  children: React.ReactNode
  optionalClass?: string
}

const Accordion = ({ children, optionalClass, ...props }: AccordionProps) => {
  return (
    <ul
      className={`em-c-accordion ${optionalClass ? optionalClass : ''}`}
      {...props}
    >
      {React.Children.toArray(children).map((child, index) =>
        React.isValidElement<AccordionItemProps>(child)
          ? React.cloneElement(child, { id: index })
          : child
      )}
    </ul>
  )
}

export default Accordion
