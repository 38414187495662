import { FC } from 'react'

import DeleteModal from '@/components/modals/delete'

import { useDeleteBay } from '@/services/api/bayMaster'

import { useBayMasterStore } from '@/stores'

const DeleteBayModal: FC = () => {
  const { currentBay, setCurrentBay, setToggleConfirmDeleteModal } =
    useBayMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentBay(undefined)
  }
  const { mutate, isPending } = useDeleteBay(handleClose)

  const handleConfirm = async () => {
    if (currentBay) {
      const { bayID, termID } = currentBay
      mutate({ bayID, termID })
    } else {
      handleClose()
    }
  }

  return (
    <DeleteModal
      title="Delete a bay"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>
        Are you sure you want to delete?
        {` (${currentBay?.bay})`}?
      </p>
    </DeleteModal>
  )
}

export default DeleteBayModal
