import { create } from 'zustand'

export interface IPersonnelInformation {
  personnelID: number
  termID: string
  personnelIButton: string
  personnelName: string
  email: string
  termVersion: string
  termDate: Date
  dataPrivacyVersion: string
  dataPrivacyDate: Date
  phoneNo: string
  name: string
  drawerID: number
  drawerCode: string
  isBorrow: boolean
  remark: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayIsBorrow: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
}

export interface IPersonnelData {
  result: IPersonnelInformation[]
  total: number
}

interface PersonnelMasterState {
  currentPersonal: IPersonnelInformation | undefined
  setCurrentPersonal: (value: IPersonnelInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddPersonnelModal: boolean
  setToggleAddPersonnelModal: (value: boolean) => void
  toggleEditPersonnelModal: boolean
  setToggleEditPersonnelModal: (value: boolean) => void
}

export const usePersonnelMasterStore = create<PersonnelMasterState>((set) => ({
  currentPersonal: undefined,
  setCurrentPersonal: (value) => set({ currentPersonal: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddPersonnelModal: false,
  setToggleAddPersonnelModal: (value) =>
    set({ toggleAddPersonnelModal: value }),
  toggleEditPersonnelModal: false,
  setToggleEditPersonnelModal: (value) =>
    set({ toggleEditPersonnelModal: value }),
}))
