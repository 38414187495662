import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteBaseProd } from '@/services/api/baseProdMaster'

import { useBaseProdMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const BaseProductDeleteModal: FC = () => {
  const { currentBaseProd, setCurrentBaseProd, setToggleConfirmDeleteModal } =
    useBaseProdMasterStore()

  const handleClose = () => {
    setCurrentBaseProd(undefined)
    setToggleConfirmDeleteModal(false)
  }

  const { mutate, isPending } = useDeleteBaseProd(handleClose)

  const handleConfirm = () => {
    mutate({
      termID: '1002',
      baseProdID: currentBaseProd!.baseProdID as number,
    })
  }

  return (
    <DeleteModal
      title="Delete a base product"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Base Product Code: {currentBaseProd?.baseProdCode}
        </BulletedListItem>
        <BulletedListItem>
          Base Product Name: {currentBaseProd?.baseProdName}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default BaseProductDeleteModal
