import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import BaseProdForm from '@/features/master/baseProd/form'

import { useAddBaseProd } from '@/services/api/baseProdMaster'

import { IBaseProdInformation, useBaseProdMasterStore } from '@/stores'

const BaseProdAddModal: FC = () => {
  const { setToggleAddBaseProdModal } = useBaseProdMasterStore()

  const handleClose = () => {
    setToggleAddBaseProdModal(false)
  }

  const { mutate, isPending } = useAddBaseProd(handleClose)
  const { handleSubmit, formState, ...formMethods } =
    useForm<IBaseProdInformation>({
      mode: 'onBlur',
    })

  const onSubmit = (data: IBaseProdInformation) => {
    mutate({
      body: {
        baseProdID: 0,
        baseProdCode: data.baseProdCode,
        baseProdName: data.baseProdName,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={formState}
      {...formMethods}
    >
      <FormModal
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title="Add a base product"
        confirmLabel="Add"
        isValid={formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <BaseProdForm />
      </FormModal>
    </FormProvider>
  )
}

export default BaseProdAddModal
