import { Link } from '@tanstack/react-router'
import Icon, { IconTypes } from 'components/Icon'

interface VerticalHeaderSolidNavItemProps {
  label: string
  url?: string
  iconName?: IconTypes
  optionalClass?: string
}

const VerticalHeaderSolidNavItem = ({
  url = '#',
  iconName,
  label,
  optionalClass,
}: VerticalHeaderSolidNavItemProps) => {
  return (
    <li
      className={`em-c-solid-nav__item ${optionalClass ? optionalClass : ''}`}
      role="menuitem"
    >
      <Link to={url} className="em-c-solid-nav__link">
        {iconName && (
          <Icon
            name={iconName}
            size="em-c-icon--small"
            optionalClass="em-c-solid-nav__icon"
          />
        )}
        {label}
      </Link>
    </li>
  )
}

export default VerticalHeaderSolidNavItem
