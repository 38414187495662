import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDrawerProductStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DeleteBomModal: FC = () => {
  const {
    deleteBomChild,
    setToggleConfirmDeleteBomChildModal,
    selectedBomChild,
  } = useDrawerProductStore()

  const handleClose = () => {
    setToggleConfirmDeleteBomChildModal(false)
  }

  const handleDelete = () => {
    deleteBomChild()
    handleClose()
  }

  return (
    <DeleteModal
      title="Delete a Bom child"
      modalWindowOptionalClass="!z-[70]"
      overlayOptionalClass="!z-[60]"
      onDelete={handleDelete}
      onClose={handleClose}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Base Product Code: {selectedBomChild?.baseProdCode}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default DeleteBomModal
