import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import {
  IContractData,
  IContractInformation,
  useContractStore,
  useRoleMatrixStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IContractInformation>([
  {
    key: 'contractNr',
    name: 'Contract Number',
  },
  {
    key: 'contractType',
    name: 'Contract Type',
  },
  {
    key: 'drawerCode',
    name: 'Drawer Code',
  },
  {
    key: 'carrierCode',
    name: 'Carrier Code',
  },
  {
    key: 'shipToCustomerCode',
    name: 'Ship To Customer Code',
  },
  {
    key: 'soldToCustomerCode',
    name: 'Sold To Customer Code',
  },
  {
    key: 'displayValidityStart',
    name: 'Validity Start',
  },
  {
    key: 'displayValidityEnd',
    name: 'Validity End',
  },
  {
    key: 'displayCreatedDate',
    name: 'Created Date',
  },
  {
    key: 'createdBy',
    name: 'Created By',
  },
  {
    key: 'displayModifiedDate',
    name: 'Modified Date',
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
  },
  {
    key: 'action',
    name: 'Action',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { contract } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setCurrentContract,
        setToggleEditContractModal,
      } = useContractStore.getState()
      const { canDelete, canEdit } = contract

      const handleDelete = () => {
        setCurrentContract(row)
        setToggleConfirmDeleteModal(true)
      }

      const handleEdit = () => {
        setCurrentContract(row)
        setToggleEditContractModal(true)
      }

      return (
        <TableButtonActions
          onEdit={handleEdit}
          onDelete={handleDelete}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      )
    },
  },
])

interface ContractTableProps {
  onChangePage: (page: number) => void
  currentPage: number
  contracts?: IContractData
  isLoading: boolean
}

const ContractTable: FC<ContractTableProps> = ({
  currentPage,
  onChangePage,
  contracts,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage,
    rowsPerPage,
    total: contracts?.total || 0,
  })

  return contracts?.result ? (
    <>
      <DataGrid
        columns={columns}
        rows={contracts.result}
        className="h-full rdg-light"
        rowHeight={45}
        headerRowHeight={30}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          contracts?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((contracts?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default ContractTable
