import { DeleteModal } from '@/components'

import { useDeleteContract } from '@/services/api/contract'

import { useContractStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DeleteContractModal = () => {
  const { currentContract, setToggleConfirmDeleteModal, setCurrentContract } =
    useContractStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentContract(undefined)
  }

  const { mutate, isPending } = useDeleteContract(handleClose)

  const handleConfirm = () => {
    mutate({ contractID: currentContract!.contractID, termID: '1002' })
  }

  return (
    <DeleteModal
      title="Delete a contract"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {currentContract?.contractNr && (
          <BulletedListItem>
            Contract Number: {currentContract.contractNr}
          </BulletedListItem>
        )}
        {currentContract?.contractType && (
          <BulletedListItem>
            Contract Type: {currentContract.contractType}
          </BulletedListItem>
        )}
        {currentContract?.drawerCode && (
          <BulletedListItem>
            Drawer Code: {currentContract.drawerCode}
          </BulletedListItem>
        )}
        {currentContract?.carrierCode && (
          <BulletedListItem>
            Carrier Code: {currentContract.carrierCode}
          </BulletedListItem>
        )}
        {currentContract?.shipToCustomerCode && (
          <BulletedListItem>
            Ship To Customer Code: {currentContract.shipToCustomerCode}
          </BulletedListItem>
        )}
        {currentContract?.soldToCustomerCode && (
          <BulletedListItem>
            Sold To Customer Name: {currentContract.soldToCustomerCode}
          </BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default DeleteContractModal
