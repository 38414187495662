import { useMutation, useQuery } from '@tanstack/react-query'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'
import { GenericResponseBody } from '../types'

/*******************************
 GET - /api/BayMaster/SearchBay *
 *******************************/

export interface ISearchBayRequestParams {
  termID: string
  BayID: string
  bay: string
  IsTopLoad: string
  IsActive: string
  IsDedicate: string
  Page: number
}

export type ISearchBayResponseBody = GenericResponseBody<{
  result: ISearchBayResult[]
  total: number
}>

export interface ISearchBayResult {
  bayID: number
  termID: string
  bay: string
  qCnt: number
  bayCapacity: number
  isTopLoad: boolean
  isActive: boolean
  isDedicate: boolean
  remark: string
  createdDate: string
  createdBy: string
  modifiedDate: string
  modifiedBy: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
  displayIsTopLoad: string
  displayIsActive: string
  displayIsDedicate: string
}

/*******************************
 POST - /api/BayMaster/AddBay
 *******************************/

export type IAddBayRequestParams = Required<
  Pick<ISearchBayRequestParams, 'termID'>
>

export interface IAddBayRequestBody {
  bayID?: number
  bay: string
  qCnt: number
  bayCapacity: number
  isTopLoad: boolean
  isActive: boolean
  isDedicate: boolean
  remark: string
  dedicatedBayList: IGetBayResultDetail[]
}

type IAddBayResponseBody = ISearchBayResponseBody

/*******************************
 POST - /api/BayMaster/EditBay
 *******************************/

type IEditBayRequestParams = IAddBayRequestParams

type IEditBayRequestBody = IAddBayRequestBody

type IEditBayResponseBody = ISearchBayResponseBody

/*******************************
 POST - /api/BayMaster/DeleteBay
 *******************************/

interface IDeleteBayRequestParams {
  termID: string
  bayID: number
}

type IDeleteBayResponseBody = ISearchBayResponseBody

/**************************************
 GET - /api/BayMaster/GetBayForActiveFunction
 **************************************/

type IGetBayForActiveFunctionRequestParams = ISearchBayRequestParams

type IGetBayForActiveFunctionResponseBody = GenericResponseBody<
  ISearchBayResult[]
>

/**************************************
 POST - /api/BayMaster/UpdateBayStatus
 **************************************/

type IUpdateBayStatusRequestParams = IAddBayRequestParams

interface IUpdateBayStatusRequestBody {
  bayID: number
  isActive: boolean
}

type IUpdateBayStatusResponseBody = GenericResponseBody<null>

/**************************************
 POST - /api/BayMaster/GetBay
 **************************************/

type IGetBayRequestParams = IDeleteBayRequestParams

export type IGetBayResponseBody = GenericResponseBody<IGetBayResult>

interface IGetBayResult extends ISearchBayResult {
  details: IGetBayResultDetail[]
}

export interface IGetBayResultDetail {
  dedicatedBayID: number
  carrierID?: number
  carrierCode?: string
  drawerID?: number
  drawerCode?: string
  dedicatedStart: string | null
  dedicatedEnd?: string | null
  periodStartString: string
  periodEndString: string
}

export function useSearchBay(params?: Partial<ISearchBayRequestParams>) {
  return useQuery<unknown, unknown, ISearchBayResponseBody['result']>({
    queryKey: ['searchBay', params?.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<ISearchBayResponseBody>(
          '/BayMaster/SearchBay',
          {
            params,
          }
        )
        if (data.message) {
          throw new Error(data.message)
        }
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface IAddBayOptions {
  params: IAddBayRequestParams
  body: IAddBayRequestBody
}

export function useAddBay(cb?: () => void) {
  return useMutation<IAddBayResponseBody, unknown, IAddBayOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post<IAddBayResponseBody>(
        '/BayMaster/AddBay',
        body,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchBay'] })
    },
  })
}

interface IEditBayOptions {
  params: IEditBayRequestParams
  body: IEditBayRequestBody
}

export function useEditBay(cb?: () => void) {
  return useMutation<IEditBayResponseBody, unknown, IEditBayOptions>({
    mutationFn: async ({ params, body }) => {
      const { data } = await api.post<IEditBayResponseBody>(
        '/BayMaster/EditBay',
        body,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchBay'] })
    },
  })
}

export function useDeleteBay(cb?: () => void) {
  return useMutation<IDeleteBayResponseBody, unknown, IDeleteBayRequestParams>({
    mutationFn: async (params) => {
      const { data } = await api.delete('/BayMaster/DeleteBay', {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchBay'] })
    },
  })
}

export function useGetBayForActiveFunction(
  params?: Partial<IGetBayForActiveFunctionRequestParams>
) {
  return useQuery<unknown, unknown, IGetBayForActiveFunctionResponseBody>({
    queryKey: ['getBayForActiveFunction', params],
    queryFn: async () => {
      try {
        const { data } = await api.get<IGetBayForActiveFunctionResponseBody>(
          '/BayMaster/GetBayForActiveFunction',
          {
            params,
          }
        )
        if (data.message) {
          throw new Error(data.message)
        }

        return data
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface IUpdateBayStatusOptions {
  params: IUpdateBayStatusRequestParams
  body: IUpdateBayStatusRequestBody[]
}

export function useUpdateBayStatus(cb?: () => void) {
  return useMutation<
    IUpdateBayStatusResponseBody,
    unknown,
    IUpdateBayStatusOptions
  >({
    mutationFn: async ({ params, body }) => {
      const { data } = await api.post<IUpdateBayStatusResponseBody>(
        '/BayMaster/UpdateBayStatusList',
        body,
        { params }
      )

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({
        queryKey: ['searchBay'],
      })
      queryClient.invalidateQueries({
        queryKey: ['getBayForActiveFunction'],
      })
    },
  })
}

export function useGetBay(params: IGetBayRequestParams) {
  return useQuery<unknown, unknown, IGetBayResult>({
    queryKey: ['getBay', params],
    queryFn: async () => {
      try {
        const { data } = await api.get<IGetBayResponseBody>(
          '/BayMaster/GetBay',
          {
            params,
          }
        )
        if (data.message) {
          throw new Error(data.message)
        }
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}
