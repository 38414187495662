import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { useAddTanker } from '@/services/api/tankerMaster'

import { ITankerInformation, useTankerMasterStore } from '@/stores'

import { removeKeyFromObject } from '@/utils'

import TankerMasterForm from './form'

const TankerMasterAddModal: FC = () => {
  const { setToggleAddTankerModal } = useTankerMasterStore()

  const handleClose = () => {
    setToggleAddTankerModal(false)
  }

  const { mutate, isPending } = useAddTanker(handleClose)
  const methods = useForm<ITankerInformation>({
    defaultValues: {
      tankerIButton: '',
      tankerCode: '',
      licensePlate: '',
      isTopLoad: false,
      isWeight: false,
      isBorrow: false,
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: ITankerInformation) => {
    mutate({
      body: removeKeyFromObject(data, ['carrierCode']),
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a tanker"
        confirmLabel="Add"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <TankerMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default TankerMasterAddModal
