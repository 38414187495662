export function createDownloadLink(
  data: Blob,
  filename: string,
  extension: string
) {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${filename}.${extension}`)
  document.body.appendChild(link)
  link.click()
}
