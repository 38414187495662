import { useEffect } from 'react'

// Hook to disable right-click and touch events that trigger the right click
const useDisableRightClick = () => {
  const handleContextMenu = (event: Event) => {
    event.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])
}

export default useDisableRightClick
