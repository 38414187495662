import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { useAddContract } from '@/services/api/contract'

import { IContractInformation, useContractStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

import { removeKeyFromObject } from '@/utils'

import ContractForm from '../forms/contract'
import ContractDetailTable from '../tables/detail'

const ContractAddModal = () => {
  const {
    setToggleAddContractModal,
    clearContractDetail,
    contractDetail,
    setToggleAddContractDetailModal,
  } = useContractStore()

  const handleClose = () => {
    setToggleAddContractModal(false)
  }

  const { mutate, isPending } = useAddContract(handleClose)
  const methods = useForm<IContractInformation>({
    defaultValues: {
      contractID: 0,
      contractNr: '',
      contractType: '',
      soldToCustomerID: null,
      shipToCustomerID: null,
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: IContractInformation) => {
    mutate({
      body: {
        contractID: data.contractID,
        contractNr: data.contractNr,
        contractType: data.contractType,
        validityStart: data.validityStart,
        validityEnd: data.validityEnd,
        soldToCustomerID: data.soldToCustomerID,
        shipToCustomerID: data.shipToCustomerID,
        carrierID: data.carrierID,
        drawerID: data.drawerID,
        details:
          contractDetail?.map((detail) =>
            removeKeyFromObject(detail, ['drawerProdCode'])
          ) || [],
      },
      params: { termID: '1002' },
    })
  }

  const handleAddContractDetail = () => {
    setToggleAddContractDetailModal(true)
  }

  useEffect(() => {
    return () => {
      clearContractDetail()
    }
  }, [])

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a contract"
        confirmLabel="Add"
        isValid={methods.formState.isValid && contractDetail.length > 0}
        onClose={handleClose}
        isLoading={isPending}
        modalWindowOptionalClass="!w-[50rem]"
      >
        <ContractForm />
        <ButtonGroup optionalClass="my-4">
          <Button
            disabled={!methods.formState.isValid}
            type="em-c-btn--primary"
            label="+ Add a contract detail"
            typeAttribute="button"
            onClick={handleAddContractDetail}
          />
        </ButtonGroup>
        <ContractDetailTable detail={contractDetail} />
      </FormModal>
    </FormProvider>
  )
}

export default ContractAddModal
