import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { ISearchBaseProd, useGetBaseProd } from '@/services/api/baseProdMaster'

import { useBaseProdMasterStore } from '@/stores'
import { useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

import BaseProdMasterTable from './table'

const BaseProdHeader: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddBaseProdModal } = useBaseProdMasterStore()
  const { baseProductMaster } = useRoleMatrixStore()
  const { canCreate } = baseProductMaster

  const { register, handleSubmit, watch } = useForm<ISearchBaseProd>({
    defaultValues: {
      BaseProdCode: '',
      BaseProdName: '',
      Page: currentPage,
      termID: '1002',
    },
    mode: 'onBlur',
  })

  const { refetch, isFetching, data } = useGetBaseProd({
    ...filterFalsyProperties(watch()),
    termID: '1002',
    Page: currentPage,
  })

  const handleSearch: SubmitHandler<ISearchBaseProd> = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const openModal = () => {
    setToggleAddBaseProdModal(true)
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Base Product Code"
          id="BaseProdCode"
          {...register('BaseProdCode')}
        />
        <TextInput
          label="Base Product Name"
          id="BaseProdName"
          {...register('BaseProdName')}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={openModal}
          />
        )}
      </form>
      <BaseProdMasterTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        baseProds={data}
        isLoading={isFetching}
      />
    </>
  )
}
export default BaseProdHeader
