import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import ShipmentForm from '@/features/master/shipment/forms/shipment'
import ShipmentChildTable from '@/features/master/shipment/tables/child'

import { useAddShipment } from '@/services/api/shipmentMaster'

import { IShipmentInformation, useShipmentMasterStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

const AddShipmentModal = () => {
  const { setToggleAddShipmentChildModal, shipmentChildren, clearState } =
    useShipmentMasterStore()
  const methods = useForm<IShipmentInformation>({
    mode: 'onBlur',
  })

  const { mutate, isPending } = useAddShipment(clearState)

  const handleClose = () => {
    clearState()
  }

  const handleAddChild = () => {
    setToggleAddShipmentChildModal(true)
  }

  const onSubmit = (data: IShipmentInformation) => {
    mutate({
      body: {
        shipmentID: data.shipmentID,
        shipmentNr: data.shipmentNr,
        carrierID: data.carrierID,
        drawerID: data.drawerID,
        tankerID: data.tankerID,
        tripPriority: data.tripPriority,
        schedDate: data.schedDate,
        expDate: data.expDate,
        remark: data.remark,
        type: data.type,
        details: shipmentChildren.map(
          ({
            drawerProdID,
            shipmentDetailID,
            shipToCustomerID,
            soldToCustomerID,
          }) => ({
            drawerProdID,
            shipmentDetailID,
            shipToCustomerID,
            soldToCustomerID,
          })
        ),
      },
      params: {
        termID: '1002',
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a shipment"
        confirmLabel="Add"
        isValid={methods.formState.isValid && shipmentChildren.length > 0}
        onClose={handleClose}
        modalWindowOptionalClass="!w-[50rem]"
        isLoading={isPending}
      >
        <ShipmentForm />
        <ButtonGroup optionalClass="my-4">
          <Button
            disabled={!methods.formState.isValid}
            type="em-c-btn--primary"
            label="+ Add child"
            typeAttribute="button"
            onClick={handleAddChild}
          />
        </ButtonGroup>
        <ShipmentChildTable shipmentChildren={shipmentChildren} />
      </FormModal>
    </FormProvider>
  )
}

export default AddShipmentModal
