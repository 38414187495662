import { create } from 'zustand'

export interface ITankerInformation {
  tankerID: number
  termID: string
  tankerIButton: string
  tankerCode: string
  licensePlate: string
  carrierID: number
  carrierCode: string
  isTopLoad: boolean
  isWeight: boolean
  isBorrow: boolean
  remark: string
  createdDate: string
  createdBy: string
  modifiedDate: string
  modifiedBy: string
  displayIsTopLoad: string
  displayIsWeight: string
  displayIsBorrow: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
}

export interface ITankerData {
  result: ITankerInformation[]
  total: number
}

interface TankerMasterState {
  currentTanker: ITankerInformation | undefined
  setCurrentTanker: (value: ITankerInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddTankerModal: boolean
  setToggleAddTankerModal: (value: boolean) => void
  toggleEditTankerModal: boolean
  setToggleEditTankerModal: (value: boolean) => void
}

export const useTankerMasterStore = create<TankerMasterState>((set) => ({
  currentTanker: undefined,
  setCurrentTanker: (value) => set({ currentTanker: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddTankerModal: false,
  setToggleAddTankerModal: (value) => set({ toggleAddTankerModal: value }),
  toggleEditTankerModal: false,
  setToggleEditTankerModal: (value) => set({ toggleEditTankerModal: value }),
}))
