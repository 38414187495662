import { ChangeEvent, FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useReassignQueue } from '@/services/api/monitor/bay'
import {
  IDetailByBayList,
  IDetailByDrawerProdList,
  IViewQueueAlreadyAssignBayResponseBody,
  useViewQueueAlreadyAssignBay,
} from '@/services/api/monitor/order'

import { useBayMonitor } from '@/stores/monitor/bay'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'
import TextInput from '@/standard/TextInput'

import { extractOnlyNumericCharacters, parseNanToZero } from '@/utils'

const ReassignModal: FC = () => {
  const { setIsReassignModalOpen, currentQueueInBay } = useBayMonitor()
  const { mutate: reassignQueue } = useReassignQueue()

  const { data } = useViewQueueAlreadyAssignBay({
    termID: '1002',
    tankerID: Number(currentQueueInBay?.tankerID),
    documentID: Number(currentQueueInBay?.documentID),
    documentType: currentQueueInBay?.documentType || '',
  })

  const { register, handleSubmit, formState, setValue } =
    useForm<IViewQueueAlreadyAssignBayResponseBody>({
      values: data,
      mode: 'onBlur',
    })

  const onSubmit: SubmitHandler<IViewQueueAlreadyAssignBayResponseBody> = (
    form
  ) => {
    const noDuplicateDocIds = new Map()

    form.result.detailByTankerAndDocList.forEach((doc) => {
      noDuplicateDocIds.set(`${doc.documentID}`, {
        documentID: doc.documentID,
        documentType: doc.documentType,
      })
    })

    const docList = Array.from(noDuplicateDocIds.values()).map((doc) => {
      return {
        documentID: doc.documentID,
        documentType: doc.documentType,
        detailList: form.result.detailByDrawerProdList
          .map((prod: IDetailByDrawerProdList) => {
            if (prod.documentID === doc.documentID) {
              return {
                drawerProdID: prod.drawerProdID,
                compartmentCnt: prod.compartmentCnt,
              }
            }
          })
          .filter((item) => item),
      }
    })
    reassignQueue({
      params: { termID: '1002', tankerID: Number(currentQueueInBay?.tankerID) },
      body: docList,
    })
    handleClose()
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: any
  ) => {
    const newValue = event.target.value
    setValue(fieldName, extractOnlyNumericCharacters(newValue))
  }

  const handleClose = () => {
    setIsReassignModalOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Modal modalWindowOptionalClass="!w-full">
        <ModalHeader
          title="Reassign Queue"
          onClose={handleClose}
          optionalClass="!mb-[0]"
        />
        <span className="text-main-darkRed">
          This allows you to reassign bay for the queue. You can specify no. of
          compartments required and click{' '}
          <span className=" font-bold">"Confirm"</span> button. System will
          re-calculate and provide the new assigned bay.
        </span>
        <div className="overflow-y-auto">
          <ModalBody>
            <div className="pt-6">
              <h5>Order Summary</h5>
              <div className="grid grid-cols-4 gap-2 font-bold border-b-2 border-black px-2 pt-2">
                <div>Order No.</div>
                <div>Drawer Product</div>
                <div>Assigned Bay</div>
                <div>Compartment</div>
              </div>
              {data?.result.detailByBayList.map((bay: IDetailByBayList) => {
                return (
                  <div
                    className="grid grid-cols-4 gap-2 p-2 bg-slate-200"
                    key={`${bay.drawerProdCode}-${bay.bayID}`}
                  >
                    <div>{`${bay.documentType} - ${bay.documentNr}`}</div>
                    <div>{`${bay.drawerProdCode} - ${bay.drawerProdName}`}</div>
                    <div>{bay.bay}</div>
                    <div>{bay.compartmentCnt}</div>
                  </div>
                )
              })}
            </div>
            <div className="pt-10">
              <h5>Please specify no. of compartments required</h5>
              <div className="grid grid-cols-4 gap-2 font-bold border-b-2 border-black px-2 pt-2">
                <div>Order No.</div>
                <div>Drawer Product</div>
                <div>Max Compartment</div>
                <div>Compartment</div>
              </div>
              <div className=" bg-slate-200 pt-1">
                {data?.result.detailByDrawerProdList.map(
                  (prod: IDetailByDrawerProdList, index: number) => {
                    return (
                      <div
                        className="grid grid-cols-4 place-items-baseline h-[72px] gap-2 px-2"
                        key={`${prod.documentID}-${prod.drawerProdID}`}
                      >
                        <div>{`${prod.documentType} - ${prod.documentNr}`}</div>
                        <div>{`${prod.drawerProdCode} - ${prod.drawerProdName}`}</div>
                        <div>{prod.compartmentCnt}</div>
                        <div>
                          <TextInput
                            id={`prod${prod.drawerProdName}`}
                            state={
                              formState?.errors?.result
                                ?.detailByDrawerProdList?.[index]
                                ?.compartmentCnt && 'em-has-error'
                            }
                            optionalClass="!mb-[0]"
                            bodyOptionalClass="!mb-[0] !w-[180px]"
                            required
                            note={
                              formState?.errors?.result
                                ?.detailByDrawerProdList?.[index]
                                ?.compartmentCnt?.message
                            }
                            {...register(
                              `result.detailByDrawerProdList.${index}.compartmentCnt`,
                              {
                                required: {
                                  value: true,
                                  message: 'Compartment is required',
                                },
                                min: {
                                  value: 0,
                                  message: 'This field should not less than 0',
                                },
                                max: {
                                  value: prod.compartmentCnt,
                                  message: `This field should not more than ${prod.compartmentCnt}`,
                                },
                                setValueAs: parseNanToZero,
                                onChange: (e) =>
                                  handleChange(
                                    e,
                                    `result.detailByDrawerProdList.${index}.compartmentCnt`
                                  ),
                              }
                            )}
                          />
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter optionalClass="pt-4">
            <ButtonGroup>
              <Button
                type="em-c-btn--primary"
                label="Confirm"
                typeAttribute="submit"
                disabled={!formState.isValid}
              />
              <Button label="Cancel" onClick={handleClose} />
            </ButtonGroup>
          </ModalFooter>
        </div>
      </Modal>
    </form>
  )
}
export default ReassignModal
