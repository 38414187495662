import { Layout } from '@/components'

import CarrierMaster from '@/features/master/carrier'

const CarrierMasterPage = () => {
  return (
    <Layout>
      <CarrierMaster />
    </Layout>
  )
}

export default CarrierMasterPage
