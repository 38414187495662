import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  useId,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { TextInputState } from '@/standard/TextInput/TextInput'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string
  error?: string
  bodyOptionalClass?: string
  note?: string
  state?: TextInputState
}

const DatePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { bodyOptionalClass, state, note, id, ...rest } = props
  const randomId = useId()

  return (
    <div className={twMerge('em-c-field !mb-[0]', state)}>
      <label htmlFor={id} className="em-c-field__label">
        {props.required && (
          <span aria-hidden="true" className="em-c-field__required">
            *
          </span>
        )}
        {props.label}
      </label>
      <div className={twMerge('em-c-field__body !mb-[0]', bodyOptionalClass)}>
        <input
          type="datetime-local"
          id={id}
          className="em-c-input em-js-input"
          disabled={state === 'em-is-disabled'}
          readOnly={state === 'em-is-readonly'}
          ref={ref}
          {...rest}
        />
      </div>
      {note && (
        <div className="em-c-field__note" id={`textinput-note-${randomId}`}>
          {note}
        </div>
      )}
    </div>
  )
})
export default DatePicker
