import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeletePersonnel } from '@/services/api/personnelMaster'

import { usePersonnelMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const PersonnelMasterDeleteModal: FC = () => {
  const { currentPersonal, setCurrentPersonal, setToggleConfirmDeleteModal } =
    usePersonnelMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentPersonal(undefined)
  }

  const { mutate, isPending } = useDeletePersonnel(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', personnelID: currentPersonal!.personnelID })
  }

  return (
    <DeleteModal
      title="Delete a personnel"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Personnel iButton: {currentPersonal?.personnelIButton}
        </BulletedListItem>
        <BulletedListItem>Name: {currentPersonal?.name}</BulletedListItem>
        <BulletedListItem>Email: {currentPersonal?.email}</BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default PersonnelMasterDeleteModal
