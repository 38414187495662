import {
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

interface ClickAwayListenerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  onClickAway: () => void
}

const ClickAwayListener = forwardRef<HTMLDivElement, ClickAwayListenerProps>(
  ({ children, onClickAway, ...rest }, outerRef) => {
    const innerRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(outerRef, () => innerRef.current!, [])

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          innerRef.current &&
          !innerRef?.current.contains(event.target as Node)
        ) {
          onClickAway()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [onClickAway])

    return (
      <div ref={innerRef} {...rest}>
        {children}
      </div>
    )
  }
)

export default ClickAwayListener
