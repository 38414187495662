import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import CustomerMasterForm from '@/features/master/customer/form'

import { useEditCustomer } from '@/services/api/customerMaster'

import { ICustomerInformation, useCustomerMasterStore } from '@/stores'

const CustomerEditModal = () => {
  const { currentCustomer, setToggleEditCustomerModal, setCurrentCustomer } =
    useCustomerMasterStore()

  const handleClose = () => {
    setToggleEditCustomerModal(false)
    setCurrentCustomer(undefined)
  }

  const { mutate, isPending } = useEditCustomer(handleClose)
  const methods = useForm<ICustomerInformation>({
    values: currentCustomer,
    mode: 'onBlur',
  })

  const onSubmit = (data: ICustomerInformation) => {
    mutate({
      body: {
        customerID: data.customerID,
        customerCode: data.customerCode,
        customerName: data.customerName,
        priorityID: data.priorityID,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a customer"
        confirmLabel="Edit"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <CustomerMasterForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default CustomerEditModal
