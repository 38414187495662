import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IPersonnelData } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchPersonnel {
  termID: string
  Page: number
  PersonnelIButton?: string
  Name?: string
  DrawerCode?: string
  IsBorrow?: string
}

export interface IAddPersonnel {
  personnelIButton: string
  email: string
  phoneNo: string
  name: string
  drawerID: number
  isBorrow: boolean
  remark: string
}

export interface IEditPersonnel {
  personnelID: number
  personnelIButton: string
  email: string
  phoneNo: string
  name: string
  drawerID: number
  isBorrow: boolean
  remark: string
}

type IAddAndEditPersonnelRequestParams = Pick<ISearchPersonnel, 'termID'>

interface IAddAndEditPersonnelOptions<T> {
  body: T
  params: IAddAndEditPersonnelRequestParams
}

interface IDeletePersonnelRequestParams {
  personnelID: number
  termID: string
}

type IResponsePersonnel = GenericResponseBody<IPersonnelData>

export function useGetPersonnel(params: ISearchPersonnel) {
  return useQuery({
    queryKey: ['searchPersonnel', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponsePersonnel>(
          `/PersonnelMaster/SearchPersonnel`,
          { params }
        )

        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useAddPersonnel(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    IAddAndEditPersonnelOptions<IAddPersonnel>
  >({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/PersonnelMaster/AddPersonnel`, body, {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchPersonnel'] })
    },
  })
}

export function useDeletePersonnel(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    IDeletePersonnelRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete('/PersonnelMaster/DeletePersonnel', {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchPersonnel'] })
    },
  })
}

export function useEditPersonnel(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    IAddAndEditPersonnelOptions<IEditPersonnel>
  >({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/PersonnelMaster/EditPersonnel`, body, {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchPersonnel'] })
    },
  })
}
