import config from '@/config'
import axios from 'axios'

import msalPublicClient, { authPermissions } from '@/auth/authConfig'

const baseURL = config.baseApiAddressDev

export const api = axios.create({
  baseURL,
})

api.interceptors.request.use(
  async (config) => {
    const token = await retrieveToken()
    config.headers.Authorization = `Bearer ${token}`
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Headers'] = '*'
    config.headers['Content-Type'] = 'application/json'

    return config
  },
  (error) => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

const retrieveToken = async () => {
  try {
    const { accessToken } =
      await msalPublicClient.acquireTokenSilent(authPermissions)
    return accessToken
  } catch (error) {
    console.info(error)
    await msalPublicClient.acquireTokenRedirect(authPermissions)
  }
}
