import { Children, DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface ToggleProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /** Label that sits above the toggle */
  label?: string
  /** Validation status, one of: */
  state?: 'em-is-valid' | 'em-has-error' | 'em-is-disabled'
  /** A note about the validation status */
  note?: string
  optionalClass?: string
  children?: ReactNode
  containerOptionalClass?: string
}

const Toggle = ({
  label,
  state,
  note,
  optionalClass,
  containerOptionalClass,
  children,
  ...props
}: ToggleProps) => {
  return (
    <div
      className={`em-c-field em-c-field--toggle !mb-0 ${state ? state : ''}`}
      role="radiogroup"
      aria-labelledby="toggleLabel"
      aria-describedby="toggleDesc"
      tabIndex={0}
      {...props}
    >
      {label && (
        <label className="em-c-field__label" id="toggleLabel">
          {label}
        </label>
      )}
      <div className="em-c-field__body !mb-0">
        <div className={twMerge('em-c-toggle', optionalClass)}>
          {Children.toArray(children).map((child, index) => {
            return (
              <div
                className={twMerge(
                  'em-c-toggle__container',
                  containerOptionalClass
                )}
                key={index}
              >
                {child}
              </div>
            )
          })}
        </div>
      </div>
      {note && (
        <div className="em-c-field__note" id="toggleDesc">
          {note}
        </div>
      )}
    </div>
  )
}

export default Toggle
