import { useMutation, useQuery } from '@tanstack/react-query'

import { queryClient } from '@/services/queryClient'
import { GenericResponseBody } from '@/services/types'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'

export interface IAcknowledge {
  documentNr: string | null
  documentType: string
  tankerID: number
  licensePlate: string
  driverName: string
  bayList: string[]
  isAck: boolean
  maxOvertimeToLoad: number
  overtimeCnt: number
  pickDTM: string
  ackDTM: string
}

interface IResponseGetQueue {
  message: string | null
  code: number
  gotoBayListFirst: IAcknowledge[] | null
  gotoBayListSecond: IAcknowledge[] | null
  waitAcknowledgeListFirst: IAcknowledge[] | null
  waitAcknowledgeListSecond: IAcknowledge[] | null
}

export const acknowledgementService = {
  useGetQueueAcknowledge: (termID: string) => {
    const getQueue = async () => {
      const { data } = await api.get<IResponseGetQueue>(
        `QueueAcknowledgement/GetQueueAcknowledge`,
        {
          params: {
            termID,
          },
        }
      )

      return data
    }
    return useQuery({
      queryKey: ['acknowledgement'],
      queryFn: getQueue,
      // 5 seconds
      refetchInterval: 5000,
    })
  },
}

export function useGetAnnouncement(termID: string) {
  return useQuery<unknown, unknown, string>({
    queryKey: ['GetAnnouncement'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<string>>(
          `QueueAcknowledgement/GetAnnouncement`,
          { params: { termID } }
        )
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface UseSetAnnouncementParams {
  announcement: string
  termID: string
}

export function useSetAnnouncement() {
  return useMutation<
    GenericResponseBody<string>,
    unknown,
    UseSetAnnouncementParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.post<GenericResponseBody<string>>(
        `QueueAcknowledgement/SetAnnouncement`,
        null,
        { params }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['GetAnnouncement'] })
    },
  })
}
