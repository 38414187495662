import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import {
  IBomChildList,
  IDrawerProdInformation,
} from '@/services/api/drawerProd'

interface IDrawerProdStore {
  currentDrawerProd: IDrawerProdInformation | undefined
  setCurrentDrawerProd: (value: IDrawerProdInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddDrawerProdModal: boolean
  setToggleAddDrawerProdModal: (value: boolean) => void
  toggleEditDrawerProdModal: boolean
  setToggleEditDrawerProdModal: (value: boolean) => void
  // Bom Child
  toggleConfirmDeleteBomChildModal: boolean
  setToggleConfirmDeleteBomChildModal: (value: boolean) => void
  toggleAddBomChildModal: boolean
  setToggleAddBomChildModal: (value: boolean) => void
  toggleEditBomChildModal: boolean
  setToggleEditBomChildModal: (value: boolean) => void
  bomChild: IBomChildList[]
  setBomChild: (value: IBomChildList[]) => void
  addBomChild: (value: IBomChildList) => void
  editBomChild: (value: IBomChildList) => void
  deleteBomChild: () => void
  selectedBomChild: IBomChildList | undefined
  setSelectedBomChild: (value: IBomChildList) => void
  bomChildIndex: number
  setBomChildIndex: (value: number) => void
  clearBomChild: () => void
}

export const useDrawerProductStore = create<IDrawerProdStore>()(
  immer((set) => ({
    currentDrawerProd: undefined,
    setCurrentDrawerProd: (value) => {
      set((state) => {
        state.currentDrawerProd = value
      })
    },
    toggleConfirmDeleteModal: false,
    setToggleConfirmDeleteModal: (value) => {
      set((state) => {
        state.toggleConfirmDeleteModal = value
      })
    },
    toggleAddDrawerProdModal: false,
    setToggleAddDrawerProdModal: (value) => {
      set((state) => {
        state.toggleAddDrawerProdModal = value
      })
    },
    toggleEditDrawerProdModal: false,
    setToggleEditDrawerProdModal: (value) => {
      set((state) => {
        state.toggleEditDrawerProdModal = value
      })
    },
    bomChild: [],
    setBomChild: (value) => {
      set((state) => {
        state.bomChild = value
      })
    },
    addBomChild: (value) => {
      set((state) => {
        state.bomChild.push(value)
      })
    },
    editBomChild: (value) => {
      set((state) => {
        state.bomChild[state.bomChildIndex] = value
      })
    },
    deleteBomChild: () => {
      set((state) => {
        state.bomChild.splice(state.bomChildIndex, 1)
      })
    },
    selectedBomChild: undefined,
    setSelectedBomChild: (value) => {
      set((state) => {
        state.selectedBomChild = value
      })
    },
    bomChildIndex: 0,
    setBomChildIndex: (value) => {
      set((state) => {
        state.bomChildIndex = value
      })
    },
    clearBomChild: () => {
      set((state) => {
        state.bomChild = []
        state.bomChildIndex = 0
        state.selectedBomChild = undefined
      })
    },
    toggleConfirmDeleteBomChildModal: false,
    setToggleConfirmDeleteBomChildModal: (value) => {
      set((state) => {
        state.toggleConfirmDeleteBomChildModal = value
      })
    },
    toggleAddBomChildModal: false,
    setToggleAddBomChildModal: (value) => {
      set((state) => {
        state.toggleAddBomChildModal = value
      })
    },
    toggleEditBomChildModal: false,
    setToggleEditBomChildModal: (value) => {
      set((state) => {
        state.toggleEditBomChildModal = value
      })
    },
  }))
)
