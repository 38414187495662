export function extractOnlyNumericCharacters(value: string) {
  return value.replace(/[^0-9]/g, '')
}

export function extractOnlyAlphanumericCharacters(value: string) {
  return value.replace(/[^a-zA-Z0-9]/g, '')
}

export function toCamelCase(str: string) {
  return str
    .replace(/[-\s]\w/g, (word) => word[1].toUpperCase())
    .replace(/^[A-Z]/, (word) => word.toLowerCase())
}

export function formatDriverName(name = ''): string {
  const parts = name.split(/[\s]+/).filter(Boolean)

  switch (parts.length) {
    case 1:
      return parts[0]
    case 2: {
      if (parts[0] === parts[0].toUpperCase()) {
        return `${parts[1]} ${parts[0][0]}.`
      } else {
        return `${parts[1]} ${parts[0][0]}.`
      }
    }
    default: {
      const lastName = parts[parts.length - 1]
      const firstInitial = parts[0][0]
      return `${lastName} ${firstInitial}.`
    }
  }
}
