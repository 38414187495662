import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { GetAuditReportResponseData } from '@/services/api/auditData'

interface RowHeight {
  auditHeaderId: number
  height: number
}

interface State {
  rowHeight: Record<number, number>
  setRowHeight: (rowHeight: RowHeight) => void
  rows: GetAuditReportResponseData[]
  setRows: (rows: GetAuditReportResponseData[]) => void
}

const useAuditReportStore = create<State>()(
  immer((set) => ({
    rowHeight: {},
    setRowHeight(rowHeight) {
      set((state) => {
        state.rowHeight[rowHeight.auditHeaderId] = rowHeight.height
      })
    },
    rows: [],
    setRows(rows) {
      set((state) => {
        state.rows = rows
      })
    },
  }))
)

export default useAuditReportStore
