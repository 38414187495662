import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { useEditContract, useGetContractByID } from '@/services/api/contract'

import { IContractInformation, useContractStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

import { formatDateToLocalDateTime, removeKeyFromObject } from '@/utils'

import ContractForm from '../forms/contract'
import ContractDetailTable from '../tables/detail'

const EditContractModal: FC = () => {
  const {
    setToggleEditContractModal,
    clearContractDetail,
    contractDetail,
    setToggleAddContractDetailModal,
    currentContract,
    setContractDetail,
  } = useContractStore()

  const handleClose = () => {
    setToggleEditContractModal(false)
  }

  const { mutate, isPending } = useEditContract(handleClose)
  const { data, isLoading } = useGetContractByID({
    contractID: currentContract!.contractID,
    termID: '1002',
  })

  const methods = useForm<IContractInformation>({
    values: {
      ...data,
      validityStart: formatDateToLocalDateTime(data?.validityStart),
      validityEnd: formatDateToLocalDateTime(data?.validityEnd),
      soldToCustomerID: data?.soldToCustomerID || null,
      shipToCustomerID: data?.shipToCustomerID || null,
    } as IContractInformation,
    mode: 'onBlur',
  })

  const onSubmit = (data: IContractInformation) => {
    mutate({
      body: {
        contractID: data.contractID,
        contractNr: data.contractNr,
        contractType: data.contractType,
        validityStart: data.validityStart,
        validityEnd: data.validityEnd,
        soldToCustomerID: data.soldToCustomerID,
        shipToCustomerID: data.shipToCustomerID,
        carrierID: data.carrierID,
        drawerID: data.drawerID,
        details:
          contractDetail?.map((detail) =>
            removeKeyFromObject(detail, ['drawerProdCode'])
          ) || [],
      },
      params: { termID: '1002' },
    })
  }

  const handleAddContractDetail = () => {
    setToggleAddContractDetailModal(true)
  }

  useEffect(() => {
    return () => {
      clearContractDetail()
    }
  }, [])

  useEffect(() => {
    if (data) {
      setContractDetail(data.details)
    }
  }, [data?.details])

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a contract"
        confirmLabel="Edit"
        isValid={methods.formState.isValid && contractDetail.length > 0}
        onClose={handleClose}
        isLoading={isPending}
        modalWindowOptionalClass="!w-[50rem]"
      >
        <ContractForm isLoading={isLoading} isEdit />
        <ButtonGroup optionalClass="my-4">
          <Button
            disabled={!methods.formState.isValid}
            type="em-c-btn--primary"
            label="+ Add a contract detail"
            typeAttribute="button"
            onClick={handleAddContractDetail}
          />
        </ButtonGroup>
        <ContractDetailTable detail={contractDetail} isLoading={isLoading} />
      </FormModal>
    </FormProvider>
  )
}

export default EditContractModal
