import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useViewRegistrationError } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

const HasSeenModal: FC = () => {
  const { setIsShowHasSeenModalOpen, currentError } = useOrderMonitor()

  const handleClose = () => {
    setIsShowHasSeenModalOpen(false)
  }
  const { mutate: alreadyViewError } = useViewRegistrationError(handleClose)

  const handleConfirm = () => {
    alreadyViewError({ termID: '1002', errorID: Number(currentError?.errorID) })
  }

  return (
    <DeleteModal
      title="Set is seen this error"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>Are you sure you want to change status to seen?</p>
    </DeleteModal>
  )
}
export default HasSeenModal
