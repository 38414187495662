import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import BayProdMasterForm from '@/features/master/bayProd/form'

import { useEditBayProd } from '@/services/api/bayProdMaster'

import { IBayProdInformation, useBayProdMasterStore } from '@/stores'

import { Required } from '@/utils'

const BayProdMasterEditModal: FC = () => {
  const { setToggleEditBayProdModal, currentBayProd } = useBayProdMasterStore()

  const handleClose = () => {
    setToggleEditBayProdModal(false)
  }

  const { mutate, isPending } = useEditBayProd(handleClose)
  const { register, handleSubmit, formState, watch, ...formMethods } = useForm<
    Required<IBayProdInformation>
  >({
    mode: 'onBlur',
    values: {
      ...currentBayProd,
      bayCode: currentBayProd?.bay,
      baseProdCode: `${currentBayProd?.baseProdCode} - ${currentBayProd?.baseProdName}`,
    } as Required<IBayProdInformation>,
  })

  const onSubmit = (data: Required<IBayProdInformation>) => {
    mutate({
      body: {
        bayProdID: data.bayProdID,
        bayID: data.bayID,
        arm: data.arm,
        baseProdID: data.baseProdID,
        isActive: data.isActive,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider
      watch={watch}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      {...formMethods}
    >
      <FormModal
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title="Edit a bay product"
        confirmLabel="Edit"
        isValid={formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <BayProdMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default BayProdMasterEditModal
