export function findDiffObjects<T = unknown>(oldArray: T[], newArray: T[]) {
  return newArray.filter(
    (a) => !oldArray.some((b) => JSON.stringify(a) === JSON.stringify(b))
  )
}

export function paginate<T>(
  array: T[] | undefined,
  currentPage: number,
  rowsPerPage: number
): T[] {
  if (!array) return []
  return array.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
}

export function calculateStartRange(currentPage: number, rowsPerPage: number) {
  return (currentPage - 1) * rowsPerPage + 1
}

export function calculateEndRange(
  currentPage: number,
  rowsPerPage: number,
  total: number
) {
  return Math.min(currentPage * rowsPerPage, total)
}
