import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import {
  ISearchBayResult,
  useEditBay,
  useGetBay,
} from '@/services/api/bayMaster'

import { useBayMasterStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

import { formatISODate } from '@/utils'

import BayForm from '../forms/bay'
import DedicatedBayTable from '../tables/child'

const EditBayModal: FC = () => {
  const {
    setToggleEditBayModal,
    clearState,
    setBayChildren,
    setToggleAddBayChildModal,
    bayChildren,
    currentBay,
  } = useBayMasterStore()

  const { mutate: editBay, isPending } = useEditBay(clearState)
  const { data: bay, isFetching } = useGetBay({
    termID: '1002',
    bayID: currentBay?.bayID ?? -1,
  })

  const { register, handleSubmit, setValue, formState, watch } =
    useForm<ISearchBayResult>({
      values: bay,
      mode: 'onBlur',
    })

  const handleClose = () => {
    setToggleEditBayModal(false)
  }

  const handleAddChild = () => {
    setToggleAddBayChildModal(true)
  }

  useEffect(() => {
    setBayChildren(bay?.details || [])
    return () => {
      setBayChildren([])
    }
  }, [bay?.details])

  const onSubmit: SubmitHandler<ISearchBayResult> = (form) => {
    editBay({
      params: { termID: '1002' },
      body: {
        bayID: form.bayID,
        bay: form.bay,
        qCnt: form.qCnt,
        bayCapacity: form.bayCapacity,
        isTopLoad: form.isTopLoad,
        isActive: form.isActive,
        isDedicate: (bayChildren ?? []).length > 0,
        remark: form.remark,
        dedicatedBayList:
          bayChildren.map((detail) => ({
            dedicatedBayID: detail.dedicatedBayID,
            carrierID: detail.carrierID,
            drawerID: detail.drawerID,
            dedicatedStart: detail.dedicatedStart
              ? formatISODate(detail.dedicatedStart)
              : null,
            dedicatedEnd: detail.dedicatedEnd
              ? formatISODate(detail.dedicatedEnd)
              : null,
            periodStartString: detail.periodStartString,
            periodEndString: detail.periodEndString,
          })) ?? [],
      },
    })
  }

  return (
    <FormModal
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title="Edit a bay"
      onClose={handleClose}
      isValid={formState.isValid}
      confirmLabel="Edit"
      modalWindowOptionalClass="!w-[50rem]"
      isLoading={isPending}
    >
      <BayForm
        isEdit
        formState={formState}
        watch={watch}
        setValue={setValue}
        register={register}
      />
      <ButtonGroup optionalClass="mt-3">
        <Button
          type="em-c-btn--primary"
          label="+ Add a dedicated bay"
          typeAttribute="button"
          onClick={handleAddChild}
        />
      </ButtonGroup>
      <DedicatedBayTable dedicatedBays={bayChildren} isLoading={isFetching} />
    </FormModal>
  )
}

export default EditBayModal
