import React, { MouseEventHandler } from 'react'

import VerticalHeaderMenu from './VerticalHeaderMenu'

interface VerticalHeaderProps {
  /** Application name. */
  title: string
  children: React.ReactNode
  withSearch?: boolean
  optionalClass?: string
  onLogout?: MouseEventHandler<HTMLButtonElement>
}

const VerticalHeader = ({
  title,
  children,
  withSearch,
  optionalClass,
  onLogout,
  ...props
}: VerticalHeaderProps) => {
  return (
    <header
      className={`em-c-header em-c-header--vertical ${
        optionalClass ? optionalClass : ''
      }`}
      role="banner"
      {...props}
    >
      <div className="em-c-header__inner">
        <a className="skip-to-content" href="#main">
          Skip to main content
        </a>
        <a className="skip-to-footer" href="#footer">
          Skip to footer
        </a>
        <VerticalHeaderMenu
          title={title}
          withSearch={withSearch}
          onLogout={onLogout}
        />
      </div>
      <div className="em-c-header__nav-container em-js-nav-panel">
        {children}
      </div>
    </header>
  )
}

export default VerticalHeader
