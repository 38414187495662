import { useFormContext } from 'react-hook-form'

import { DatePicker, DrawerProdAutoComplete } from '@/components'

import { IContractDetail } from '@/stores'

import { formatISODate } from '@/utils'

const ContractDetailForm = () => {
  const { register, formState } = useFormContext<IContractDetail>()
  const validityStartState = formState.errors.itemValStart && 'em-has-error'
  const validityEndState = formState.errors.itemValEnd && 'em-has-error'

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <DrawerProdAutoComplete isRequired />
      <DatePicker
        required
        label="Validity Start"
        id="itemValStart"
        state={validityStartState}
        note={formState.errors.itemValStart?.message}
        {...register('itemValStart', {
          setValueAs: formatISODate,
          required: {
            value: true,
            message: 'Validity Start is required',
          },
        })}
      />
      <DatePicker
        required
        label="Validity End"
        id="itemValEnd"
        state={validityEndState}
        note={formState.errors.itemValEnd?.message}
        {...register('itemValEnd', {
          setValueAs: formatISODate,
          required: {
            value: true,
            message: 'Validity End is required',
          },
        })}
      />
    </div>
  )
}

export default ContractDetailForm
