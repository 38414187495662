import {
  IContactList,
  IRegistrationQueue,
  ISelectedProdDetails,
  ISelectedShipmentList,
  ISummaryQRegistrationData,
  IValidateDocument,
  qRegistrationService,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

const ConfirmEditContractModal = ({
  toggleModal = false,
  closeModal,
  contractDetail,
}: {
  toggleModal: boolean
  closeModal: () => void
  contractDetail: ISelectedShipmentList
}): React.ReactElement => {
  const {
    personnelIButton,
    tankerIButton,
    queueData,
    setDocument,
    setSummaryData,
    setStep,
    setQueueData,
  } = useQueueRegistrationStore()

  const { mutateAsync: mutateValidateDocument } =
    qRegistrationService.useValidateDocument()

  const onConfirmEdit = async () => {
    const selectedContractID = contractDetail.details.map(
      (prod: ISelectedProdDetails) => {
        return prod.drawerProdID
      }
    )
    setDocument(contractDetail.documentNr)
    const documentPayload: IValidateDocument = {
      termID: '1002',
      personnelIButton,
      tankerIButton: tankerIButton,
      docNr: contractDetail.documentNr,
      isUpdateContract: true,
      details: {
        contractList: queueData?.contractList?.length
          ? queueData?.contractList
          : [],
        shipmentList: queueData?.shipmentList?.length
          ? queueData?.shipmentList
          : [],
      },
    }
    const data = await mutateValidateDocument(documentPayload)

    const productQuantityList = data.result.dataForSelectProd.details
    const newList = productQuantityList.filter((prod: ISelectedProdDetails) => {
      return !selectedContractID.includes(prod.drawerProdID)
    })

    const newDetails = [...newList, ...contractDetail.details].sort(
      (a, b) => a.drawerProdID - b.drawerProdID
    )

    const dataForEditContract: ISummaryQRegistrationData = {
      dataForSelectProd: {
        details: newDetails,
        documentID: contractDetail.documentID,
        documentNr: contractDetail.documentNr,
        type: contractDetail.type,
      },
      selectedContractList: data.result.selectedContractList,
      selectedShipmentList: data.result.selectedShipmentList,
      step: data.result.step,
    }
    setSummaryData(dataForEditContract)
    setStep(data.result.step)

    const newContractList = queueData?.contractList?.filter(
      (value: IContactList) => value.documentID !== contractDetail.documentID
    )
    const newShipmentList = queueData?.shipmentList?.filter(
      (value: IContactList) => value.documentID !== contractDetail.documentID
    )
    const manageQueueData: IRegistrationQueue = {
      termID: '1002',
      personnelIButton,
      tankerIButton,
      shipmentList: newShipmentList,
      contractList: newContractList,
    }
    setQueueData(manageQueueData)
  }

  return (
    <div
      className="em-c-modal "
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
    >
      <section
        className={`em-c-modal__window ${toggleModal ? '' : 'em-is-closed'}`}
      >
        <div className="em-c-modal__header">
          <div className="">
            <div className="em-c-modal__title" id="modalTitle">
              {`Confirmed to Edit ${
                contractDetail.type === 0 ? 'Contract' : 'Shipment'
              }: ${contractDetail.documentNr}`}
            </div>
          </div>
          <button
            className="em-c-btn em-c-btn--bare em-c-modal__close-btn"
            title="Close modal"
            onClick={() => {
              closeModal()
            }}
          >
            <div className="em-c-btn__inner">
              <svg className="em-c-icon em-c-icon--small em-c-btn__icon">
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="/images/16/em-icons.svg#x-filled"
                ></use>
              </svg>
            </div>
          </button>
        </div>

        <div className="pt-10">
          <div className="flex gap-8 justify-center">
            <button
              className="em-c-btn"
              onClick={() => {
                closeModal()
              }}
            >
              <span className="em-c-btn__text">Cancel</span>
            </button>
            <button
              className="em-c-btn em-c-btn--primary"
              onClick={() => {
                onConfirmEdit()
              }}
            >
              <span className="em-c-btn__text">Confirm</span>
            </button>
          </div>
        </div>
      </section>
      <div
        className={`em-c-modal__overlay ${toggleModal ? '' : 'em-is-closed'}`}
      ></div>
    </div>
  )
}

export default ConfirmEditContractModal
