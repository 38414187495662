import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IBaseProdInformation } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchBaseProd {
  termID: string
  Page: number
  BaseProdCode?: string
  BaseProdName?: string
}

export interface IAddBaseProd {
  baseProdID: number
  baseProdCode: string
  baseProdName: string
  remark: string
}

export interface IEditBaseProd {
  baseProdID: number
  baseProdCode: string
  baseProdName: string
  remark: string
}

export interface IBaseProdData {
  result: IBaseProdInformation[]
  total: number
}

interface IResponseBaseProd {
  message: string
  code: number
  result: IBaseProdData
}

export function useGetBaseProd(params: ISearchBaseProd) {
  return useQuery({
    queryKey: ['searchBaseProd', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseBaseProd>(
          `/BaseProdMaster/SearchBaseProd`,
          { params }
        )
        if (data.message) {
          throw new Error(data.message)
        }
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface UseAddBaseProd {
  body: IAddBaseProd
  params: Pick<ISearchBaseProd, 'termID'>
}

export function useAddBaseProd(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseAddBaseProd>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/BaseProdMaster/AddBaseProd`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchBaseProd'] })
    },
  })
}

interface UseEditBaseProd {
  body: IEditBaseProd
  params: Pick<ISearchBaseProd, 'termID'>
}

export function useEditBaseProd(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseEditBaseProd>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/BaseProdMaster/EditBaseProd`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchBaseProd'] })
    },
  })
}

interface UseDeleteBaseProd {
  baseProdID: number
  termID: string
}

export function useDeleteBaseProd(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseDeleteBaseProd>({
    mutationFn: async (params) => {
      const { data } = await api.delete(`/BaseProdMaster/DeleteBaseProd`, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchBaseProd'] })
    },
  })
}
