import { FC } from 'react'

import { usePriorityMasterStore } from '@/stores'

import PriorityAddModal from './addModal'
import PriorityDeleteModal from './deleteModal'
import PriorityEditModal from './editModal'
import PriorityMasterHeader from './header'

const PriorityMasterComponent: FC = () => {
  const {
    toggleAddPriorityModal,
    toggleEditPriorityModal,
    toggleConfirmDeleteModal,
  } = usePriorityMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddPriorityModal && <PriorityAddModal />}
      {toggleEditPriorityModal && <PriorityEditModal />}
      {toggleConfirmDeleteModal && <PriorityDeleteModal />}
      <PriorityMasterHeader />
    </div>
  )
}
export default PriorityMasterComponent
