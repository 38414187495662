import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import {
  IPersonnelData,
  IPersonnelInformation,
  usePersonnelMasterStore,
  useRoleMatrixStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IPersonnelInformation>([
  {
    key: 'personnelIButton',
    name: 'Personnel IButton',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'termVersion',
    name: 'Term Version',
  },
  {
    key: 'displayTermDate',
    name: 'Term Date',
  },
  {
    key: 'dataPrivacyVersion',
    name: 'Data Privacy Version',
  },
  {
    key: 'displayDataPrivacyDate',
    name: 'Data Privacy Date',
  },
  {
    key: 'phoneNo',
    name: 'Phone Number',
  },
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'drawerCode',
    name: 'Drawer Code',
  },
  {
    key: 'displayIsBorrow',
    name: 'Is Borrow',
  },
  {
    key: 'displayCreatedDate',
    name: 'Created Date',
  },
  {
    key: 'createdBy',
    name: 'Created By',
  },
  {
    key: 'displayModifiedDate',
    name: 'Modified Date',
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
  },
  {
    key: 'action',
    name: 'Action',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { personnelMaster } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setCurrentPersonal,
        setToggleEditPersonnelModal,
      } = usePersonnelMasterStore.getState()
      const { canDelete, canEdit } = personnelMaster

      const handleDelete = () => {
        setCurrentPersonal(row)
        setToggleConfirmDeleteModal(true)
      }

      const handleEdit = () => {
        setCurrentPersonal(row)
        setToggleEditPersonnelModal(true)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface PersonnelMasterTableProps {
  onChangePage: (page: number) => void
  currentPage: number
  personals?: IPersonnelData
  isLoading: boolean
}

const PersonnelMasterTable: FC<PersonnelMasterTableProps> = ({
  onChangePage,
  currentPage,
  personals,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage: currentPage,
    rowsPerPage,
    total: personals?.total || 0,
  })

  return personals?.result ? (
    <>
      <DataGrid
        columns={columns}
        rows={personals.result}
        className="h-full rdg-light"
        rowHeight={45}
        headerRowHeight={30}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          personals?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((personals?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default PersonnelMasterTable
