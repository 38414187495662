import React from 'react'

interface ModalFooterProps {
  children?: React.ReactNode
  optionalClass?: string
}

const ModalFooter = ({ children, optionalClass }: ModalFooterProps) => {
  return (
    <div className={`em-c-modal__footer ${optionalClass ? optionalClass : ''}`}>
      {children}
    </div>
  )
}

export default ModalFooter
