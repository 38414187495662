import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  IDetailByBayList,
  IViewQueueAlreadyAssignBayResponseBody,
  useViewQueueAlreadyAssignBay,
} from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

const QueueDetailModal: FC = () => {
  const {
    currentWaitingQueue,
    setCurrentWaitingQueue,
    setIsQueueDetailModalOpen,
  } = useOrderMonitor()

  const tankerID = Number(currentWaitingQueue?.tankerID)
  const documentID = Number(currentWaitingQueue?.documentID)
  const documentType = currentWaitingQueue?.documentType || ''

  const { data } = useViewQueueAlreadyAssignBay({
    termID: '1002',
    tankerID,
    documentID,
    documentType,
  })

  const { handleSubmit } = useForm<IViewQueueAlreadyAssignBayResponseBody>({
    values: data,
    mode: 'onBlur',
  })

  const onSubmit: SubmitHandler<
    IViewQueueAlreadyAssignBayResponseBody
  > = () => {
    handleClose()
  }

  const handleClose = () => {
    setIsQueueDetailModalOpen(false)
    setCurrentWaitingQueue(undefined)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Modal
      // modalWindowOptionalClass="!w-4/5"
      >
        <ModalHeader
          title="Queue Detail"
          onClose={handleClose}
          optionalClass="!mb-[0]"
        />
        <div className="overflow-y-auto">
          <ModalBody>
            <div className="pt-6">
              <h5>Order Summary</h5>
              <div className="grid grid-cols-3 gap-2 font-bold border-b-2 border-black px-2 pt-2">
                <div>Order No.</div>
                <div>Drawer Product</div>
                <div>Compartment</div>
              </div>
              {data?.result.detailByBayList.map((bay: IDetailByBayList) => {
                return (
                  <div
                    className="grid grid-cols-3 gap-2 p-2 bg-slate-200"
                    key={`${bay.drawerProdCode}-${bay.bayID}`}
                  >
                    <div>{`${bay.documentType} - ${bay.documentNr}`}</div>
                    <div>{`${bay.drawerProdCode} - ${bay.drawerProdName}`}</div>
                    <div>{bay.compartmentCnt}</div>
                  </div>
                )
              })}
            </div>
          </ModalBody>
          <ModalFooter optionalClass="pt-4">
            <ButtonGroup>
              <Button label="Close" onClick={handleClose} />
            </ButtonGroup>
          </ModalFooter>
        </div>
      </Modal>
    </form>
  )
}
export default QueueDetailModal
