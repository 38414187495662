import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import CarrierMasterForm from '@/features/master/carrier/form'

import { useAddCarrier } from '@/services/api/carrierMaster'

import { ICarrierInformation, useCarrierMasterStore } from '@/stores'

const CarrierMasterAddModal: FC = () => {
  const { setToggleAddCarrierModal } = useCarrierMasterStore()

  const handleClose = () => {
    setToggleAddCarrierModal(false)
  }

  const { mutate, isPending } = useAddCarrier(handleClose)
  const methods = useForm<ICarrierInformation>({
    defaultValues: {
      carrierCode: '',
      carrierName: '',
      remark: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: ICarrierInformation) => {
    mutate({
      body: {
        carrierID: 0,
        carrierCode: data.carrierCode,
        carrierName: data.carrierName,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a carrier"
        confirmLabel="Add"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <CarrierMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default CarrierMasterAddModal
