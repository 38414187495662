import { useQueueRegistrationStore } from '@/stores'

const ShowQueueStatusComponent = (): React.ReactElement => {
  const { step, setStep } = useQueueRegistrationStore()

  const currentStatus =
    step === 'waitForBayassignment'
      ? 'the next step is to wait for bay assignment'
      : step === 'waitForAcknowledgement'
        ? 'the next step is to wait for acknowledgement'
        : step === 'waitForCallToBay'
          ? 'the next step is to wait for call to bay'
          : step === 'goToBay'
            ? 'the next step is go to bay'
            : step === 'acknowledgement'
              ? 'Your queue is successfully acknowledged. Please check your queue on the screen and wait for the call to the assigned BAY.'
              : ''

  return (
    <div className="flex flex-col w-full h-full gap-4 py-10 justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-4 max-w-lg h-fit bg-white rounded-md shadow-xl p-10 z-10">
        <h2>Your queue status</h2>
        <li className="text-xl font-bold">{currentStatus}</li>
      </div>
      <button className="em-c-btn" onClick={() => {}}>
        <span
          className="em-c-btn__text"
          onClick={() => {
            setStep('validateIButton')
          }}
        >
          BACK
        </span>
      </button>
    </div>
  )
}

export default ShowQueueStatusComponent
