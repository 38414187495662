import { useMutation, useQuery } from '@tanstack/react-query'

import { IDrawerData } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'
import { GenericResponseBody } from '../types'

export interface ISearchDrawer {
  drawerCode?: string
  drawerName?: string
  termID: string
  page: number
}

export interface IAddDrawer {
  drawerCode: string
  drawerName: string
  termID: string
  remark: string
}

export interface IEditDrawer {
  drawerID: number
  drawerCode: string
  drawerName: string
  remark: string
}

interface IResponseDrawer {
  message: string
  code: number
  result: IDrawerData
}

interface IAddAndEditDrawerRequestParams {
  termID: string
}

interface IAddAndEditDrawerOptions {
  body: IAddDrawer | IEditDrawer
  params: IAddAndEditDrawerRequestParams
}

interface IDeleteDrawerRequestParams {
  drawerID: number
  termID: string
}

export function useGetDrawer(params: ISearchDrawer) {
  return useQuery({
    queryKey: ['searchDrawer', params.page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseDrawer>(
          `/DrawerMaster/SearchDrawer`,
          { params }
        )
        if (data.message) {
          throw new Error(data.message)
        }
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useAddDrawer(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IAddAndEditDrawerOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post('/DrawerMaster/AddDrawer', body, {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawer'] })
    },
  })
}

export function useEditDrawer(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IAddAndEditDrawerOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post('/DrawerMaster/EditDrawer', body, {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawer'] })
    },
  })
}

export function useDeleteDrawer(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IDeleteDrawerRequestParams>({
    mutationFn: async (params) => {
      const { data } = await api.delete(`DrawerMaster/DeleteDrawer`, { params })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawer'] })
    },
  })
}
