import { FC } from 'react'

import Icon from '@/standard/Icon'

interface ErrorPageProps {
  message?: string
}

const ErrorPage: FC<ErrorPageProps> = ({
  message = 'We are unable to fetch the role at the moment. Please try again later.',
}) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Icon
            size="em-c-icon--large"
            name="circle-exclamation-mark"
            aria-hidden
            optionalClass="mx-auto h-12 w-auto text-red-600"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            An error occurred
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">{message}</p>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
