import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetDrawerProdGroupForDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface DrawerProdGroupAutoCompleteField {
  drawerProdGroupID: number | null
  drawerProdGroupCode: string
}

interface DrawerProdGroupAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const DrawerProdGroupAutoComplete: FC<DrawerProdGroupAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: drawerProdGroups, isLoading } =
    useGetDrawerProdGroupForDropdown({
      termID: '1002',
    })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<DrawerProdGroupAutoCompleteField>()

  const drawerProdGroupState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.drawerProdGroupCode && 'em-has-error'

  return (
    <Autocomplete
      state={drawerProdGroupState}
      note={formState.errors.drawerProdGroupCode?.message}
      id="drawerProdGroupID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={drawerProdGroups}
      label="Drawer Prod Group"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('drawerProdGroupCode')}
      maxLength={20}
      value={watch('drawerProdGroupCode')}
      {...register('drawerProdGroupCode', {
        required: isRequired ? 'Drawer Product Group is required' : false,
        validate: () =>
          watch('drawerProdGroupID') === 0
            ? 'Drawer Product Group is not valid'
            : undefined,
      })}
      onChange={(value) => {
        setValue(
          'drawerProdGroupID',
          value?.label ? parseNanToZero(value?.value) : null
        )
        setValue('drawerProdGroupCode', value?.label)
      }}
    />
  )
}

export default DrawerProdGroupAutoComplete
