import ConditionalWrapper from 'components/Shared/ConditionalWrapper'
import React, { MouseEventHandler, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Icon, { IconTypes } from '../Icon'
import { IconProps } from '../Icon/Icon'

interface ButtonProps {
  id?: string
  /** Edit button text here */
  label?: string
  /** Select button type */
  type?:
    | 'em-c-btn--primary'
    | 'em-c-btn--secondary'
    | 'em-c-btn--tertiary'
    | 'em-c-btn--bare'
    | 'em-c-btn--inverted'
    | 'em-c-btn--inverted-red'
  /** The type attribute specifies the type of button tag. For most browsers the default type of button is submit. */
  typeAttribute?: 'button' | 'submit' | 'reset'
  /** The title attribute on a `<button>` tag adds a tooltip with title text to the button */
  title?: string
  /** Select button size, by default medium */
  size?: 'em-c-btn--small' | 'em-c-btn--medium' | 'em-c-btn--large'
  /** Is the button selectable */
  selectable?: boolean
  /** The attribute `disabled` must also be added to the button */
  disabled?: boolean
  /** Add an icon to the button */
  isIconButton?: boolean
  /** Show only the icon without text */
  isIconOnlyButton?: boolean
  /** Link value indicates the redirect destination */
  link?: string
  target?: string
  iconName?: IconTypes
  /** Optional click handler*/
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  children?: React.ReactNode
  optionalClass?: string
  iconSize?: IconProps['size']
  isLoading?: boolean
}

const Button = ({
  id,
  label,
  type,
  typeAttribute,
  link,
  target = '_blank',
  title,
  size = 'em-c-btn--medium',
  selectable = false,
  disabled = false,
  isIconButton,
  isIconOnlyButton,
  iconName = 'settings',
  children,
  optionalClass,
  iconSize,
  isLoading,
  ...props
}: ButtonProps) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <ConditionalWrapper
      condition
      wrapper={(children) => (
        <>
          {link ? (
            <a
              id={id}
              href={link}
              target={target}
              rel="noopener noreferrer"
              className={twMerge(
                'em-c-btn',
                type,
                size,
                optionalClass,
                selectable && 'em-js-btn-selectable',
                disabled && 'em-c-btn--disabled',
                isActive && 'em-is-active'
              )}
              title={title}
              {...props}
              onClick={(e) => {
                if (props.onClick) props.onClick(e)
                if (selectable) setIsActive(!isActive)
              }}
              aria-label={
                isIconButton || isIconOnlyButton
                  ? label
                    ? label
                    : 'Button name'
                  : undefined
              }
            >
              {children}
            </a>
          ) : (
            <button
              id={id}
              className={twMerge(
                'em-c-btn',
                type,
                size,
                optionalClass,
                selectable && 'em-js-btn-selectable',
                disabled && 'em-c-btn--disabled',
                isActive && 'em-is-active'
              )}
              disabled={disabled}
              title={title}
              type={typeAttribute}
              {...props}
              onClick={(e) => {
                if (props.onClick) props.onClick(e)
                if (selectable) setIsActive(!isActive)
              }}
              aria-label={
                isIconButton || isIconOnlyButton
                  ? label
                    ? label
                    : 'Button name'
                  : undefined
              }
            >
              {children}
            </button>
          )}
        </>
      )}
    >
      {children ? (
        <div className="em-c-btn__inner">{children}</div>
      ) : isIconButton || isIconOnlyButton ? (
        <div className="em-c-btn__inner">
          <Icon
            size={iconSize}
            name={iconName}
            optionalClass={twMerge(
              'em-c-btn__icon',
              isLoading && 'animate-spin-slow'
            )}
            aria-hidden={isIconOnlyButton ? undefined : true}
          />
          {!isIconOnlyButton ? (
            <span className="em-c-btn__text">{label}</span>
          ) : (
            ''
          )}
        </div>
      ) : (
        <span className="em-c-btn__text">{label}</span>
      )}
    </ConditionalWrapper>
  )
}

export default Button
