import { FC } from 'react'

import {
  IGetRegistrationErrorResult,
  useGetRegistrationError,
} from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

import Button from '@/standard/Button'
import Table, {
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '@/standard/Tables/Table'

import { formatDateAndTime } from '@/utils'

const ProcessErrorTab: FC = () => {
  const { setIsShowHasSeenModalOpen, setCurrentError } = useOrderMonitor()

  const { data: registrationErrorData } = useGetRegistrationError({
    termID: '1002',
  })

  const handleHasSeenModal = () => {
    setIsShowHasSeenModalOpen(true)
  }

  return (
    <>
      <div className="flex items-center h-11">
        <h4>Process Error Report</h4>
      </div>
      <Table
        optionalClass="pt-4"
        bodyInnerOptionalClass="bg-neutral-lightGray"
        type="em-c-table--striped"
      >
        <TableHeader optionalClass="text-white">
          <TableHeaderItem optionalClass="!py-[6px]">Result</TableHeaderItem>
          <TableHeaderItem optionalClass="w-[150px] !py-[6px]">
            Create Date
          </TableHeaderItem>
          <TableHeaderItem optionalClass="w-[100px] !py-[6px] text-center">
            Action
          </TableHeaderItem>
        </TableHeader>
        <TableBody>
          {registrationErrorData?.result.result.map(
            (queue: IGetRegistrationErrorResult) => {
              return (
                <TableRow
                  optionalClass="align-middle text-black bg-white !h-[50px]"
                  type="em-c-table--condensed"
                  key={queue.errorID}
                >
                  <div>{queue.errorMsg}</div>
                  <div>{formatDateAndTime(queue.createdDate)}</div>
                  <div className="flex gap-2 items-center justify-center">
                    <Button
                      isIconOnlyButton
                      typeAttribute="button"
                      label="Clear Error"
                      title="Clear Error"
                      iconName="x-filled"
                      size="em-c-btn--small"
                      iconSize="em-c-icon--small"
                      onClick={() => {
                        handleHasSeenModal()
                        setCurrentError(queue)
                      }}
                    />
                  </div>
                </TableRow>
              )
            }
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default ProcessErrorTab
