import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteCustomer } from '@/services/api/customerMaster'

import { useCustomerMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const CustomerMasterDeleteModal: FC = () => {
  const { currentCustomer, setCurrentCustomer, setToggleConfirmDeleteModal } =
    useCustomerMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentCustomer(undefined)
  }

  const { mutate, isPending } = useDeleteCustomer(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', customerID: currentCustomer!.customerID })
  }

  return (
    <DeleteModal
      title="Delete a customer"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Customer Code: {currentCustomer?.customerCode}
        </BulletedListItem>
        <BulletedListItem>
          Customer Name: {currentCustomer?.customerName}
        </BulletedListItem>
        <BulletedListItem>
          Priority: {currentCustomer?.priorityCode}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default CustomerMasterDeleteModal
