import { useEffect, useRef, useState } from 'react'
import { useMeasure, useScroll } from 'react-use'
import { twMerge } from 'tailwind-merge'

import {
  IAgreePrivacy,
  qRegistrationService,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

import Button from '@/standard/Button'

const TermAndConditionModal = ({
  toggleModal = false,
  closeModal,
  declinedModal,
}: {
  toggleModal: boolean
  closeModal: () => void
  declinedModal: () => void
}): React.ReactElement => {
  const { personnelIButton, setStep } = useQueueRegistrationStore()
  const [disabledAcceptTerm, setDisabledAcceptTerm] = useState(true)

  const scrollRef = useRef<HTMLDivElement>(null)
  const { y } = useScroll(scrollRef)
  const [ref, { height }] = useMeasure<any>()

  const { data: dataPrivacy } = qRegistrationService.useGetDataPrivacyVersion({
    termID: '1002',
  })
  const { mutateAsync: mutateAgreeData, isPending } =
    qRegistrationService.useAgreeDateAndPrivacy()

  const onAgreeDataAndPrivacy = async () => {
    const payload: IAgreePrivacy = {
      termID: '1002',
      personnelIButton,
      dataPrivacyVersion: dataPrivacy || '',
    }
    const data = await mutateAgreeData(payload)
    if (data.code === 200 && !data.message) {
      setStep(data.result.step)
      closeModal()
    }
  }

  useEffect(() => {
    if (!toggleModal) return
    if (height === 0) return
    if (
      Number(y) + Number(scrollRef.current?.offsetHeight) >= height ||
      height <= Number(scrollRef.current?.offsetHeight)
    ) {
      setDisabledAcceptTerm(false)
    }
  }, [y, height, toggleModal])

  return (
    <div
      className="em-c-modal "
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
    >
      <section
        className={`em-c-modal__window em-c-modal--medium !max-h-[70vh] ${
          toggleModal ? '' : 'em-is-closed'
        }`}
      >
        <div className="em-c-modal__header">
          <div className="">
            <div className="em-c-modal__title" id="modalTitle">
              {`Terms and Conditions`}
            </div>
          </div>
        </div>

        <div
          className="overflow-auto border-2"
          id="modalDescription"
          ref={scrollRef}
        >
          <div ref={ref}>
            <p className="text-pretty px-4">
              By using this queuing system kiosk, you agree to ExxonMobil and
              its Affiliates using information you provide, including Personal
              Information, to provide you with Terminal services, and contact
              you about your activity on its facilities. You can contact
              Terminal officers on how to use, request or correct your Personal
              Information, make a complaint, as well as revoke your consent. For
              more information about the Exxon Mobil Privacy Policy, please
              visit
              https://www.exxonmobil.com.au/en-au/legal-pages/privacy-policy.
            </p>
          </div>
        </div>

        <div className="pt-10">
          <div className="flex gap-8 justify-center">
            <button
              className="em-c-btn"
              onClick={() => {
                setStep('validateIButton')
                declinedModal()
              }}
            >
              <span className="em-c-btn__text">Decline</span>
            </button>

            <Button
              label="Accept"
              onClick={onAgreeDataAndPrivacy}
              optionalClass={twMerge(
                'em-c-btn em-c-btn--primary w-[100px]',
                disabledAcceptTerm ? 'em-c-btn--disabled' : 'em-c-btn--primary'
              )}
              {...(isPending && {
                disabled: true,
                isIconOnlyButton: true,
                iconName: 'refresh',
                isLoading: true,
              })}
            />
          </div>
        </div>
      </section>
      <div
        className={`em-c-modal__overlay ${toggleModal ? '' : 'em-is-closed'}`}
      ></div>
    </div>
  )
}

export default TermAndConditionModal
