import { useQuery } from '@tanstack/react-query'

import { api } from '@/services/apiClient'
import { GenericResponseBody } from '@/services/types'

import { handleQueryError } from '@/utils'

interface ISelectData {
  label: string
  value: string
}

interface IDropdownRequestParams {
  termID: string
}

export function useGetBaseProdForDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['baseProdDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/BaseProdMaster/GetBaseProdForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetBayForDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['bayDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/BayMaster/GetBayForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetCarrierDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['carrierDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/CarrierMaster/GetCarrierForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetCustomerForDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['customerDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/CustomerMaster/GetCustomerForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetDrawerDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['drawerDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/DrawerMaster/GetDrawerForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetDrawerProdGroupForDropdown(
  params: IDropdownRequestParams
) {
  return useQuery({
    queryKey: ['drawerProdGroupDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/DrawerProdGroupMaster/GetDrawerProdGroupForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetDrawerProdForDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['drawerProdDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/DrawerProdMaster/GetDrawerProdForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetPriorityForDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['priorityDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/PriorityMaster/GetPriorityForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetTankerForDropdown(params: IDropdownRequestParams) {
  return useQuery({
    queryKey: ['tankerDropdown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<ISelectData[]>>(
          `/TankerMaster/GetTankerForDropdown`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}
