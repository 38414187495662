import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IContractDetail, useContractStore } from '@/stores'

import { formatDateToLocalDateTime } from '@/utils'

import ContractDetailForm from '../forms/detail'

const EditContractDetailModal: FC = () => {
  const {
    editContractDetail,
    selectedContractDetail,
    setToggleEditContractDetailModal,
  } = useContractStore()

  const methods = useForm<IContractDetail>({
    mode: 'onBlur',
    values: {
      ...selectedContractDetail,
      itemValStart: formatDateToLocalDateTime(
        selectedContractDetail?.itemValStart
      ),
      itemValEnd: formatDateToLocalDateTime(selectedContractDetail?.itemValEnd),
    } as IContractDetail,
  })

  const handleClose = () => {
    setToggleEditContractDetailModal(false)
  }

  const onSubmit = (data: IContractDetail) => {
    editContractDetail(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a Contract Detail"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Edit"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <ContractDetailForm />
      </FormModal>
    </FormProvider>
  )
}

export default EditContractDetailModal
