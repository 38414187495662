import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteQueue } from '@/services/api/monitor/order'

import { useBayMonitor, useOrderMonitor } from '@/stores'

const DeleteQueueByTankerModal: FC = () => {
  const {
    setIsDeleteQueueModalOpen,
    currentWaitingQueue,
    setCurrentWaitingQueue,
    setIsDeleteQueueByTankerModalOpen,
  } = useOrderMonitor()
  const {
    currentQueueInAlreadyAssigned,
    currentQueueInBay,
    setCurrentQueueInAlreadyAssigned,
    setCurrentQueueInBay,
  } = useBayMonitor()

  const handleCloseWhenDeleteSuccess = () => {
    setIsDeleteQueueByTankerModalOpen(false)
    setIsDeleteQueueModalOpen(false)
    setCurrentWaitingQueue(undefined)
    setCurrentQueueInAlreadyAssigned(undefined)
    setCurrentQueueInBay(undefined)
  }
  const { mutate: deleteQueue } = useDeleteQueue(handleCloseWhenDeleteSuccess)

  const handleConfirm = () => {
    const tankerID =
      Number(currentWaitingQueue?.tankerID) ||
      Number(currentQueueInAlreadyAssigned?.tankerID) ||
      Number(currentQueueInBay?.tankerID)

    deleteQueue({
      termID: '1002',
      tankerID,
    })
  }

  return (
    <DeleteModal
      modalWindowOptionalClass="!z-[70]"
      overlayOptionalClass="!z-[60]"
      title="Delete all queue"
      confirmText="Confirm"
      onClose={() => {
        setIsDeleteQueueByTankerModalOpen(false)
      }}
      onDelete={handleConfirm}
    >
      <p>
        Confirm to delete all queue in truck license plate:
        <span className="font-bold pl-1">
          {currentWaitingQueue?.licensePlate ||
            currentQueueInBay?.licensePlate ||
            ''}
        </span>
        ?
      </p>
    </DeleteModal>
  )
}
export default DeleteQueueByTankerModal
