import { ChangeEventHandler, InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import Icon, { IconTypes } from '../Icon'

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  /** Field/validation status, one of: */
  state?: 'em-is-valid' | 'em-has-error' | 'em-is-readonly' | 'em-is-disabled'
  /** The `id` attribute is needed to associate the text area with a label */
  id?: string
  /** Label that sits above the text area */
  label?: string
  /** Specifies a short hint that describes the expected value of a text area */
  placeholder?: string
  /** Specifies a name for a text area */
  name?: string
  /** The value that the text area contains */
  value?: string
  /** Adds an asterisk (*) to mark the field as required */
  required?: boolean
  onChange: ChangeEventHandler<HTMLTextAreaElement>
  /** Specifies the visible number of lines in a text area */
  rows?: number
  /** A note about the field/validation status */
  note?: string
  optionalClass?: string
  textareaOptionalClass?: string
}

const inputIcon = {
  'em-is-valid': 'circle-check' as IconTypes,
  'em-has-error': 'warning' as IconTypes,
  'em-is-readonly': 'lock' as IconTypes,
  'em-is-disabled': 'ban' as IconTypes,
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      state,
      id = 'input',
      label,
      placeholder,
      name,
      value,
      required,
      onChange,
      rows = 5,
      note,
      optionalClass,
      textareaOptionalClass,
      ...props
    },
    ref
  ) => {
    return (
      <div className={twMerge('em-c-field !mb-[0]', state, optionalClass)}>
        {label && (
          <label htmlFor={id} className="em-c-field__label">
            {required && (
              <span aria-hidden="true" className="em-c-field__required">
                *
              </span>
            )}
            {label}
          </label>
        )}
        <div className="em-c-field__body">
          <textarea
            className={twMerge('em-c-textarea', textareaOptionalClass)}
            id={id}
            name={name}
            placeholder={placeholder}
            defaultValue={value}
            disabled={state === 'em-is-disabled'}
            readOnly={state === 'em-is-readonly'}
            onChange={onChange}
            rows={rows}
            aria-describedby="Textarea-note"
            aria-required={required}
            required={required}
            ref={ref}
            {...props}
          />
          {state && (
            <div role="alert" aria-describedby="Textarea-note">
              <Icon
                name={inputIcon[state as keyof typeof inputIcon]}
                optionalClass="em-c-field__icon"
              />
            </div>
          )}
        </div>
        {note && (
          <div className="em-c-field__note" id="Textarea-note">
            {note}
          </div>
        )}
      </div>
    )
  }
)

export default TextArea
