import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ISelectContract,
  ISelectedProdDetails,
  qRegistrationService,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

import Button from '@/standard/Button'

import CustomSelectQuantity from './selectQuantity'

const quantityList = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
]
export interface IDicForChooseProduct {
  [key: string]: ISelectedProdDetails
}

const SelectContractComponent = () => {
  const {
    setStep,
    summaryData,
    queueData,
    setSummaryData,
    editContract,
    setEditContract,
    setIsGoToHome,
  } = useQueueRegistrationStore()

  const [productList, setProductList] = useState<ISelectedProdDetails[]>()
  const [compartmentQty, setCompartmentQty] = useState<{
    [key: string]: { quantity: number }
  }>({})

  const [chooseProduct, setChooseProduct] = useState<IDicForChooseProduct>({})
  const [chooseProductCode, setChooseProductCode] = useState<string[]>([])

  const onSelectedProduct = (value: ISelectedProdDetails, checked: boolean) => {
    const selectProdList: IDicForChooseProduct = { ...chooseProduct }
    if (checked) {
      selectProdList[value.drawerProdCode] = value
    } else {
      const tempQty = { ...compartmentQty }
      tempQty[value.drawerProdCode].quantity = 0
      setCompartmentQty(tempQty)
      selectProdList[value.drawerProdCode].compartmentCnt = 0
      delete selectProdList[value.drawerProdCode]
    }
    setChooseProduct(selectProdList)
    setChooseProductCode(Object.keys(selectProdList))
  }

  const onUpdateQuantity = (value: ISelectedProdDetails, qty: string) => {
    const temp = chooseProduct
    temp[value.drawerProdCode].compartmentCnt = Number(qty)
    setChooseProduct(temp)

    const tempQty = compartmentQty
    tempQty[value.drawerProdCode].quantity = Number(qty)
    setCompartmentQty(tempQty)
    setChooseProductCode(Object.keys(temp)) // this line for rerender
  }

  const { mutateAsync: mutateSelectContract, isPending } =
    qRegistrationService.useSelectContract()

  const onSelectContractComplete = async () => {
    const product = Object.values(chooseProduct).map(
      (prod: ISelectedProdDetails) => {
        return {
          drawerProdID: prod.drawerProdID,
          drawerProdName: prod.drawerProdName,
          drawerProdCode: prod.drawerProdCode,
          compartmentCnt: prod.compartmentCnt,
        }
      }
    )

    const contractList = queueData?.contractList?.length
      ? queueData?.contractList
      : []
    const shipmentList = queueData?.shipmentList?.length
      ? queueData?.shipmentList
      : []

    if (summaryData?.dataForSelectProd.type === 0) {
      contractList.push({
        documentID: Number(summaryData?.dataForSelectProd.documentID),
        documentNr: summaryData?.dataForSelectProd?.documentNr || ' ',
        type: 0,
        details: product,
      })
    } else {
      shipmentList.push({
        documentID: Number(summaryData?.dataForSelectProd.documentID),
        documentNr: summaryData?.dataForSelectProd?.documentNr || ' ',
        type: summaryData?.dataForSelectProd.type === 2 ? 2 : 3,
        details: product,
      })
    }

    const queueDatas: ISelectContract = {
      termID: '1002',
      shipmentList,
      contractList,
    }

    const data = await mutateSelectContract(queueDatas)
    setStep(data.result.step)
    setSummaryData(data.result)
    setEditContract(undefined)
  }

  const nextWithSched =
    Object.keys(chooseProduct).length &&
    Object.values(chooseProduct).every((prod: ISelectedProdDetails) => {
      return prod.compartmentCnt !== 0
    })
  const nextWithPre =
    Object.keys(compartmentQty).length &&
    Object.values(compartmentQty).every((comp) => {
      return comp.quantity !== 0
    })
  const nextWithCT =
    Object.keys(chooseProduct).length &&
    Object.values(chooseProduct).every((prod: ISelectedProdDetails) => {
      return prod.compartmentCnt !== 0
    })

  const checkCanNext =
    summaryData?.dataForSelectProd.type === 0
      ? nextWithCT
      : summaryData?.dataForSelectProd.type === 3
        ? nextWithPre
        : nextWithSched

  useEffect(() => {
    if (productList?.length) return
    const productListFromAPI = summaryData?.dataForSelectProd?.details
    setProductList(productListFromAPI)

    if (summaryData?.dataForSelectProd.type !== 0) {
      const selectProdList: IDicForChooseProduct = {}
      summaryData?.dataForSelectProd.details?.forEach(
        (prod: ISelectedProdDetails) => {
          return (selectProdList[prod.drawerProdCode] = prod)
        }
      )
      setChooseProduct(selectProdList)
      setChooseProductCode(Object.keys(selectProdList))
    }

    if (editContract?.details?.length) {
      const selectProdList: IDicForChooseProduct = {}
      editContract.details?.forEach((prod: ISelectedProdDetails) => {
        return (selectProdList[prod.drawerProdCode] = prod)
      })
      setChooseProduct(selectProdList)
      setChooseProductCode(Object.keys(selectProdList))
    }

    const quantityList: { [key: string]: { quantity: number } } = {}
    productListFromAPI?.forEach((prod: ISelectedProdDetails) => {
      if (summaryData?.dataForSelectProd.type === 3) {
        return (quantityList[prod.drawerProdCode] = {
          quantity: editContract?.documentID ? prod.compartmentCnt : 0,
        })
      } else {
        return (quantityList[prod.drawerProdCode] = {
          quantity: prod.compartmentCnt,
        })
      }
    })
    setCompartmentQty(quantityList)
  }, [summaryData])

  return (
    <div className="flex max-w-screen-xl h-full mx-auto py-10 bg-transparent">
      <div className="flex flex-col gap-4 w-full h-full bg-white rounded-md shadow-xl p-10 z-10">
        <h2 className="">{`${
          summaryData?.dataForSelectProd.type === 0 ? 'Contract' : 'Shipment'
        } Number : ${summaryData?.dataForSelectProd?.documentNr}`}</h2>
        <h3 className="">Please select your product and quantity</h3>
        <div className="overflow-auto min-h-0 flex-1">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {!!productList?.length &&
              productList.map((prod: ISelectedProdDetails) => {
                return (
                  <div
                    key={prod.drawerProdCode}
                    className="flex items-center justify-between px-4 gap-4 border h-16"
                  >
                    <label
                      htmlFor={prod.drawerProdID.toString()}
                      className="flex items-center gap-4"
                    >
                      {summaryData?.dataForSelectProd.type === 0 && (
                        <input
                          type="checkbox"
                          name={prod.drawerProdName}
                          id={prod.drawerProdID.toString()}
                          className="!w-8 h-8 !m-0"
                          onChange={(event) => {
                            onSelectedProduct(prod, event.target.checked)
                          }}
                          defaultChecked={prod.compartmentCnt !== 0}
                        />
                      )}
                      <h3 className="!m-0">{`${prod.drawerProdName} - ${prod.drawerProdCode}`}</h3>
                    </label>
                    {summaryData?.dataForSelectProd.type === 2 ? (
                      <h3>{`${prod.compartmentCnt} compartments`}</h3>
                    ) : (
                      <CustomSelectQuantity
                        data={quantityList}
                        id={`qtt-${prod.drawerProdID}`}
                        name={`qtt-${prod.drawerProdCode}`}
                        className="w-[200px] h-12"
                        value={compartmentQty[prod.drawerProdCode]?.quantity}
                        disabled={
                          !chooseProductCode.includes(prod.drawerProdCode)
                        }
                        onChange={(event) => {
                          onUpdateQuantity(prod, event.target.value)
                        }}
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <div className="flex gap-4 justify-center items-center">
          <button
            className="em-c-btn !px-12 !py-10 text-2xl"
            onClick={() => {
              setIsGoToHome(true)
            }}
          >
            Go to Home
          </button>

          <Button
            label="Next"
            onClick={onSelectContractComplete}
            optionalClass={twMerge(
              'em-c-btn em-c-btn--primary !px-12 !py-10 w-[145px] text-2xl',
              checkCanNext ? 'em-c-btn--primary' : 'em-c-btn--disabled'
            )}
            {...(isPending
              ? {
                  disabled: true,
                  isIconOnlyButton: true,
                  iconName: 'refresh',
                  isLoading: true,
                }
              : { disabled: !checkCanNext })}
          />
        </div>
      </div>
    </div>
  )
}

export default SelectContractComponent
