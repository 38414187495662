import { FC, useState } from 'react'

import { useBayMasterStore } from '@/stores'

import BayMasterHeader from './header'
import AddBayModal from './modals/addBay'
import AddDedicatedBayModal from './modals/addDedicatedBay'
import DeleteBayModal from './modals/deleteBay'
import DeleteDedicatedBayModal from './modals/deleteDedicatedBay'
import EditBayModal from './modals/editBay'
import EditDedicatedBayModal from './modals/editDedicatedBay'
import ManageMultipleBaysModal from './modals/manageMultipleBay'

const BayMaster: FC = () => {
  const {
    toggleAddBayModal,
    toggleAddBayChildModal,
    toggleEditBayChildModal,
    toggleEditBayModal,
    toggleConfirmDeleteModal,
    toggleConfirmDeleteChildModal,
  } = useBayMasterStore()

  const [isOpenManageMultipleBaysModal, setIsOpenManageMultipleBaysModal] =
    useState<boolean>(false)

  const handleOnManageMultipleBay = () => {
    setIsOpenManageMultipleBaysModal(true)
  }

  const handleOnCloseManageMultipleBaysModal = () => {
    setIsOpenManageMultipleBaysModal(false)
  }

  return (
    <div className="flex flex-col h-full">
      {isOpenManageMultipleBaysModal && (
        <ManageMultipleBaysModal
          onClose={handleOnCloseManageMultipleBaysModal}
        />
      )}
      {toggleConfirmDeleteModal && <DeleteBayModal />}
      {toggleAddBayModal && <AddBayModal />}
      {toggleAddBayChildModal && <AddDedicatedBayModal />}
      {toggleEditBayChildModal && <EditDedicatedBayModal />}
      {toggleConfirmDeleteChildModal && <DeleteDedicatedBayModal />}
      {toggleEditBayModal && <EditBayModal />}
      <BayMasterHeader onManageMultipleBay={handleOnManageMultipleBay} />
    </div>
  )
}

export default BayMaster
