import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import {
  IShipmentData,
  IShipmentInformation,
  useRoleMatrixStore,
  useShipmentMasterStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IShipmentInformation>([
  {
    key: 'shipmentNr',
    name: 'Shipment Number',
  },
  {
    key: 'carrierCode',
    name: 'Carrier Code',
  },
  {
    key: 'drawerCode',
    name: 'Drawer Code',
  },
  {
    key: 'tankerCode',
    name: 'Tanker Code',
  },
  {
    key: 'displayLoadDate',
    name: 'Load Date',
  },
  {
    key: 'displaySchedDate',
    name: 'Scheduled Date',
  },
  {
    key: 'displayExpDate',
    name: 'Expected Date',
  },
  {
    key: 'displayCreatedDate',
    name: 'Created Date',
  },
  {
    key: 'createdBy',
    name: 'Created By',
  },
  {
    key: 'displayModifiedDate',
    name: 'Modified Date',
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
  },
  {
    key: 'actions',
    name: 'Actions',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { shipment } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setCurrentShipment,
        setToggleEditShipmentModal,
      } = useShipmentMasterStore.getState()
      const { canEdit, canDelete } = shipment

      const handleDelete = () => {
        setCurrentShipment(row)
        setToggleConfirmDeleteModal(true)
      }

      const handleEdit = () => {
        setCurrentShipment(row)
        setToggleEditShipmentModal(true)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface ShipmentTableProps {
  onChangePage: (page: number) => void
  currentPage: number
  shipments?: IShipmentData
  isLoading: boolean
}

const ShipmentTable: FC<ShipmentTableProps> = ({
  onChangePage,
  currentPage,
  shipments,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage: currentPage,
    rowsPerPage,
    total: shipments?.total || 0,
  })

  return shipments?.result ? (
    <>
      <DataGrid
        columns={columns}
        rows={shipments.result}
        className="h-full rdg-light"
        rowHeight={45}
        headerRowHeight={30}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          shipments?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((shipments?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default ShipmentTable
