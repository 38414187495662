import { Children, FC, ReactNode, cloneElement, isValidElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface TableHeaderProps {
  optionalClass?: string
  headerRowOptionalClass?: string
  children?: ReactNode
}

const TableHeader: FC<TableHeaderProps> = ({
  optionalClass,
  children,
  headerRowOptionalClass,
}) => {
  return (
    <thead
      className={twMerge('em-c-table__header sticky top-0 z-10', optionalClass)}
    >
      <tr className={twMerge('em-c-table__header-row', headerRowOptionalClass)}>
        {Children.toArray(children).map((child) =>
          isValidElement(child) ? cloneElement(child) : child
        )}
      </tr>
    </thead>
  )
}

export default TableHeader
