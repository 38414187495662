import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { toastParams } from '@/services/api/monitor/toast'
import { api } from '@/services/apiClient'
import { queryClient } from '@/services/queryClient'

type IDocumentType = 'SH' | 'CT'

/*******************************
 GET - /api/BayMonitor/GetBayMonitorData *
 *******************************/

export interface IGetRegistrationErrorRequestParams {
  termID: string
}
export interface IGetRegistrationErrorResponseBody {
  message: string | null
  code: number
  result: {
    result: IGetRegistrationErrorResult[]
    total: number
  }
}

export interface IGetRegistrationErrorResult {
  createdDate: string
  displayCreatedDate: string
  errorCode: number
  errorID: number
  errorMsg: string
}

/*******************************
 GET - /api/QueueMonitor/GetDocumentInterfaceError *
 *******************************/

export interface IGetDocumentInterfaceErrorResponseBody {
  message: string | null
  code: number
  result: IDocumentInterfaceErrorResult[]
}

export interface IDocumentInterfaceErrorResult {
  licensePlate: string
  errorDescription: string
  errorType: string
  tripNo: number
  loadErrorID: number
  loadId: number
}

/*******************************
 GET - /api/QueueMonitor/GetQueueWaitForBayAssignment
 *******************************/

export type IGetQueueWaitForBayAssignmentRequestParams =
  IGetRegistrationErrorRequestParams

export interface IGetQueueWaitForBayAssignmentResponseBody {
  message: string | null
  code: number
  result: IGetQueueWaitForBayAssignmentResult[]
}

export interface IGetQueueWaitForBayAssignmentResult {
  tankerID: number
  licensePlate: string
  documentType: string
  documentID: number
  documentNr: number
  sortDTM: string
  bayList: string[]
  ackDTM: string
  callDTM: string
  isRemove: boolean
  isDisable: boolean
  overtimeCnt: number
  displaySortDTM: string
  displayAckDTM: string
  displayCallDTM: string
  waitDuration: number
}

/*******************************
 GET - /api/QueueMonitor/GetQueueAlreadyAssignBay
 *******************************/

export type IGetQueueAlreadyAssignBayRequestParams =
  IGetRegistrationErrorRequestParams

export interface IGetQueueAlreadyAssignBayResponseBody {
  message: string | null
  code: number
  result: IGetQueueAlreadyAssignBayResult[]
}

export interface IGetQueueAlreadyAssignBayResult {
  tankerID: number
  licensePlate: string
  documentType: string
  documentID: number
  documentNr: number
  sortDTM: string
  bayList: string[]
  ackDTM: string
  callDTM: string
  pickDTM: string
  isRemove: boolean
  isDisable: boolean
  overtimeCnt: number
  displaySortDTM: string
  displayAckDTM: string
  displayCallDTM: string
  overtimeDuration: 24
  waitDuration: 0
}

export const useGetRegistrationError = (
  params: IGetRegistrationErrorRequestParams
) => {
  return useQuery<unknown, unknown, IGetRegistrationErrorResponseBody>({
    queryKey: ['getRegistrationErrorData'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetRegistrationErrorResponseBody>(
          '/QueueMonitor/GetRegistrationError',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

export const useGetDocumentInterfaceError = (
  params: IGetRegistrationErrorRequestParams
) => {
  return useQuery<unknown, unknown, IGetDocumentInterfaceErrorResponseBody>({
    queryKey: ['getDocumentInterfaceError'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetDocumentInterfaceErrorResponseBody>(
          '/QueueMonitor/GetDocumentInterfaceError',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

export const useGetQueueWaitForBayAssignment = (
  params: IGetQueueWaitForBayAssignmentRequestParams
) => {
  return useQuery<unknown, unknown, IGetQueueWaitForBayAssignmentResponseBody>({
    queryKey: ['getQueueWaitForBayAssignment'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetQueueWaitForBayAssignmentResponseBody>(
          '/QueueMonitor/GetQueueWaitForBayAssignment',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

export const useGetQueueAlreadyAssignBay = (
  params: IGetQueueAlreadyAssignBayRequestParams
) => {
  return useQuery<unknown, unknown, IGetQueueAlreadyAssignBayResponseBody>({
    queryKey: ['getQueueAlreadyAssignBay'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetQueueAlreadyAssignBayResponseBody>(
          '/QueueMonitor/GetQueueAlreadyAssignBay',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

/******************
 View Queue Detail
 ******************/

/*******************************
 GET - /api/QueueMonitor/ViewQueueAlreadyAssignBay
 *******************************/

export interface IViewQueueAlreadyAssignBayParams {
  termID: string
  tankerID: number
  documentType: string
  documentID: number
}

export interface IDetailByDrawerProdList {
  documentType: IDocumentType
  documentID: number
  documentNr: string
  drawerProdID: number
  drawerProdCode: string
  drawerProdName: string
  compartmentCnt: number
}
export interface IDetailByBayList {
  bayID: number
  bay: string
  documentType: IDocumentType
  documentID: number
  documentNr: string
  drawerProdID: number
  drawerProdCode: string
  drawerProdName: string
  compartmentCnt: number
}

export interface IDrawerProdList {
  drawerProdID: number
  termID: string
  drawerProdCode: string
  drawerProdName: string
  drawerProdGroupCode: string
  remark: string
}

export interface IDetailByTankerAndDocList {
  bay: string
  bayID: number
  callDTM: string
  documentID: number
  documentNr: string
  documentType: IDocumentType
  endDTM: string
  qListID: number
  startDTM: string
}

export interface IViewQueueAlreadyAssignBayResult {
  detailByDrawerProdList: IDetailByDrawerProdList[]
  detailByBayList: IDetailByBayList[]
  drawerProdList: IDrawerProdList[]
  detailByTankerAndDocList: IDetailByTankerAndDocList[]
}

export interface IViewQueueAlreadyAssignBayResponseBody {
  message: string | null
  code: number
  result: IViewQueueAlreadyAssignBayResult
}

export const useViewQueueAlreadyAssignBay = (
  params: IViewQueueAlreadyAssignBayParams
) => {
  return useQuery<unknown, unknown, IViewQueueAlreadyAssignBayResponseBody>({
    queryKey: ['viewQueueAlreadyAssignBay', params],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IViewQueueAlreadyAssignBayResponseBody>(
          '/QueueMonitor/ViewQueueAlreadyAssignBay',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

/******************
 Update Queue Detail
 ******************/

/*******************************
 POST - /api/BayMonitor/MoveToUnassignBay *
 *******************************/

export interface IRemoveAssignedQueueRequestParams
  extends IGetRegistrationErrorRequestParams {
  tankerID: number
}

export interface IRemoveAssignedQueueDetail {
  drawerProdID: number
  compartmentCnt: number
}
export interface IRemoveAssignedQueueRequestBody {
  documentID: number
  documentType: string
  detailList: IRemoveAssignedQueueDetail[]
}

export interface IRemoveAssignedQueueResponseBody {
  message: string | null
  code: number
  result: null
}

interface IRemoveAssignedQueueOptions {
  params: IRemoveAssignedQueueRequestParams
  body: IRemoveAssignedQueueRequestBody[] | any
}

export const useRemoveAssignedQueue = (cb?: () => void) => {
  return useMutation<
    IRemoveAssignedQueueResponseBody,
    unknown,
    IRemoveAssignedQueueOptions
  >({
    mutationFn: async ({ params, body }) => {
      const { data } = await api.post<IRemoveAssignedQueueResponseBody>(
        '/QueueMonitor/RemoveAssignedQueue',
        body,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['getBayMonitor'] })
      queryClient.invalidateQueries({
        queryKey: ['getQueueWaitForBayAssignment'],
      })
      queryClient.invalidateQueries({ queryKey: ['getQueueAlreadyAssignBay'] })
      cb?.()
    },
  })
}

/*******************************
 POST - /api/BayMonitor/MoveToUnassignBay *
 *******************************/

export interface IViewRegistrationErrorRequestParams
  extends IGetRegistrationErrorRequestParams {
  errorID: number
}

export interface IViewRegistrationErrorResponseBody {
  message: string | null
  code: number
  result: null
}

export const useViewRegistrationError = (cb?: () => void) => {
  return useMutation<
    IViewRegistrationErrorResponseBody,
    unknown,
    IViewRegistrationErrorRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.post<IViewRegistrationErrorResponseBody>(
        '/QueueMonitor/ViewRegistrationError',
        {},
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['getRegistrationErrorData'] })
      cb?.()
    },
  })
}

/*******************************
 POST - /api/BayMonitor/EnableQueue *
 *******************************/

export type IEnableQueueRequestParams = IRemoveAssignedQueueRequestParams

export interface IEnableQueueResponseBody {
  message: string | null
  code: number
  result: null
}

export const useEnableQueue = (cb?: () => void) => {
  return useMutation<
    IEnableQueueResponseBody,
    unknown,
    IEnableQueueRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.post<IEnableQueueResponseBody>(
        '/QueueMonitor/EnableQueue',
        null,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getQueueWaitForBayAssignment'],
      })
      cb?.()
    },
  })
}

/*******************************
 POST - /api/BayMonitor/DisableQueue *
 *******************************/

export type IDisableQueueRequestParams = IRemoveAssignedQueueRequestParams

export interface IDisableQueueResponseBody {
  message: string | null
  code: number
  result: null
}

export const useDisableQueue = (cb?: () => void) => {
  return useMutation<
    IDisableQueueResponseBody,
    unknown,
    IDisableQueueRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.post<IDisableQueueResponseBody>(
        '/QueueMonitor/DisableQueue',
        null,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getQueueWaitForBayAssignment'],
      })
      cb?.()
    },
  })
}

/*******************************
 DELETE - /api/BayMonitor/DeleteQueue *
 *******************************/

export type IDeleteQueueRequestParams = IRemoveAssignedQueueRequestParams

export interface IDeleteQueueResponseBody {
  message: string | null
  code: number
  result: null
}

export const useDeleteQueue = (cb?: () => void) => {
  return useMutation<
    IDeleteQueueResponseBody,
    unknown,
    IDeleteQueueRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete<IDeleteQueueResponseBody>(
        '/QueueMonitor/DeleteQueue',
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['getBayMonitor'] })
      queryClient.invalidateQueries({
        queryKey: ['getQueueWaitForBayAssignment'],
      })
      queryClient.invalidateQueries({ queryKey: ['getQueueAlreadyAssignBay'] })
      cb?.()
    },
  })
}

/*******************************
 DELETE - /api/QueueMonitor/DeleteQueueByQListID *
 *******************************/

interface IDeleteQueueByQListRequestParams
  extends IGetRegistrationErrorRequestParams {
  qListID: number
}

export const useDeleteQueueByQList = (cb?: () => void) => {
  return useMutation<
    IDeleteQueueResponseBody,
    unknown,
    IDeleteQueueByQListRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete<IDeleteQueueResponseBody>(
        '/QueueMonitor/DeleteQueueByQListID',
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['getBayMonitor'] })
      queryClient.invalidateQueries({
        queryKey: ['getQueueWaitForBayAssignment'],
      })
      queryClient.invalidateQueries({ queryKey: ['getQueueAlreadyAssignBay'] })
      cb?.()
    },
  })
}

/*******************************
 POST - /api/QueueMonitor/UpdateSortDTM *
 *******************************/

export type IUpdateDTMRequestParams = IRemoveAssignedQueueRequestParams

interface IUpdateDTMRequestOptions {
  params: IUpdateDTMRequestParams
  body: string
}

export interface IUpdateDTMResponseBody {
  message: string | null
  code: number
  result: null
}

export const useUpdateSortDTM = (cb?: () => void) => {
  return useMutation<IUpdateDTMResponseBody, unknown, IUpdateDTMRequestOptions>(
    {
      mutationFn: async ({ params, body }) => {
        const { data } = await api.post<IUpdateDTMResponseBody>(
          '/QueueMonitor/UpdateSortDTM',
          body,
          {
            params,
          }
        )
        return data
      },
      onSuccess: (data) => {
        if (data.message) {
          throw new Error(data.message)
        }
        queryClient.invalidateQueries({
          queryKey: ['getQueueWaitForBayAssignment'],
        })
        cb?.()
      },
    }
  )
}

/*******************************
 POST - /api/QueueMonitor/useUpdateStartDTM *
 *******************************/
export const useUpdateStartDTM = (cb?: () => void) => {
  return useMutation<IUpdateDTMResponseBody, unknown, IUpdateDTMRequestParams>({
    mutationFn: async (params) => {
      const { data } = await api.post<IUpdateDTMResponseBody>(
        '/QueueMonitor/UpdateStartDTM',
        null,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getBayMonitor'],
      })
      cb?.()
    },
  })
}

/*******************************
 POST - /api/QueueMonitor/useUpdateEndDTM *
 *******************************/
export const useUpdateEndDTM = (cb?: () => void) => {
  return useMutation<IUpdateDTMResponseBody, unknown, IUpdateDTMRequestParams>({
    mutationFn: async (params) => {
      const { data } = await api.post<IUpdateDTMResponseBody>(
        '/QueueMonitor/UpdateEndDTM',
        null,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getBayMonitor'],
      })
      cb?.()
    },
  })
}

/*******************************
 DELETE - /api/QueueMonitor/DeleteAllQueueWaitForBayAssignment *
 *******************************/

export type IDeleteAllQueueWaitForBayAssignmentRequestParams =
  IGetRegistrationErrorRequestParams

export interface IDeleteAllQueueWaitForBayAssignmentResponseBody {
  message: string | null
  code: number
  result: null
}

export const useDeleteAllQueueWaitForBayAssignment = (cb?: () => void) => {
  return useMutation<
    IDeleteAllQueueWaitForBayAssignmentResponseBody,
    unknown,
    IDeleteAllQueueWaitForBayAssignmentRequestParams
  >({
    mutationFn: async (params) => {
      const { data } =
        await api.delete<IDeleteAllQueueWaitForBayAssignmentResponseBody>(
          '/QueueMonitor/DeleteAllQueueWaitForBayAssignment',
          {
            params,
          }
        )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getQueueWaitForBayAssignment'],
      })
      cb?.()
    },
  })
}

/*******************************
 DELETE - /api/QueueMonitor/DeleteAllQueueAlreadyAssignBay *
 *******************************/

export type IDeleteAllQueueAlreadyAssignBayRequestParams =
  IGetRegistrationErrorRequestParams

export interface IDeleteAllQueueAlreadyAssignBayResponseBody {
  message: string | null
  code: number
  result: null
}

export const useDeleteAllQueueAlreadyAssignBay = (cb?: () => void) => {
  return useMutation<
    IDeleteAllQueueAlreadyAssignBayResponseBody,
    unknown,
    IDeleteAllQueueAlreadyAssignBayRequestParams
  >({
    mutationFn: async (params) => {
      const { data } =
        await api.delete<IDeleteAllQueueAlreadyAssignBayResponseBody>(
          '/QueueMonitor/DeleteAllQueueAlreadyAssignBay',
          {
            params,
          }
        )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getQueueAlreadyAssignBay'],
      })
      cb?.()
    },
  })
}

/*******************************
 POST - /api/QueueMonitor/ReprocessInterfaceError *
 *******************************/

export interface IReprocessRequestParams
  extends IGetRegistrationErrorRequestParams {
  tripNo: number
  errorType: string
  LoadErrorId: number
}

export interface IReprocessResponseBody {
  message: string | null
  code: number
  result: any
}

export const useReprocessInterfaceError = (cb?: () => void) => {
  return useMutation<IReprocessResponseBody, unknown, IReprocessRequestParams>({
    mutationFn: async (params) => {
      const { data } = await api.post<IReprocessResponseBody>(
        '/QueueMonitor/ReprocessInterfaceError',
        null,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({
        queryKey: ['getDocumentInterfaceError'],
      })
      cb?.()
    },
  })
}
