import { produce } from 'immer'

export function removeKeyFromObject<T>(obj: T, keys: (keyof T)[]) {
  return produce(obj, (draft: never) => {
    keys.forEach((key) => delete draft[key])
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterFalsyProperties<T extends { [key: string]: any }>(
  obj: T
): T {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !!value)
  ) as T
}
