import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteBayProd } from '@/services/api/bayProdMaster'

import { useBayProdMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const BayProductMasterDeleteModal: FC = () => {
  const { currentBayProd, setCurrentBayProd, setToggleConfirmDeleteModal } =
    useBayProdMasterStore()

  const handleClose = () => {
    setCurrentBayProd(undefined)
    setToggleConfirmDeleteModal(false)
  }

  const { mutate, isPending } = useDeleteBayProd(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', bayProdID: currentBayProd!.bayProdID as number })
  }

  return (
    <DeleteModal
      title="Delete a bay product"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>Bay: {currentBayProd?.bay}</BulletedListItem>
        <BulletedListItem>Arm: {currentBayProd?.arm}</BulletedListItem>
        <BulletedListItem>
          Base Product Code: {currentBayProd?.baseProdCode}
        </BulletedListItem>
        <BulletedListItem>
          Base Product Name: {currentBayProd?.baseProdName}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default BayProductMasterDeleteModal
