import { ChangeEvent, FC, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import BayAutoComplete from '@/components/forms/autocompletes/bay'

import BayProdMasterTable from '@/features/master/bayProd/table'

import { ISearchBayProduct, useGetBayProd } from '@/services/api/bayProdMaster'

import { useBayProdMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { extractOnlyNumericCharacters } from '@/utils'

interface Form extends Omit<ISearchBayProduct, 'BayID'> {
  bayID: number
}

const Header: FC = () => {
  const [currentPage, onChangePage] = useState(1)
  const { setToggleAddBayProdModal, setToggleMultipleEditBayProdModal } =
    useBayProdMasterStore()
  const { bayProductMaster } = useRoleMatrixStore()
  const { canCreate } = bayProductMaster

  const { handleSubmit, register, setValue, watch, ...methods } = useForm<Form>(
    {
      mode: 'onBlur',
    }
  )

  const {
    refetch,
    isFetching,
    isLoading,
    data: bayProds,
  } = useGetBayProd({
    termID: '1002',
    Page: currentPage,
    ...(watch('Arm')
      ? {
          Arm: watch('Arm'),
        }
      : {}),
    ...(watch('BaseProdCode') ? { BaseProdCode: watch('BaseProdCode') } : {}),
    ...(watch('bayID') ? { BayID: watch('bayID') } : {}),
    ...(watch('IsActive') ? { IsActive: watch('IsActive') } : {}),
  })

  const handleSearch: SubmitHandler<ISearchBayProduct> = () => {
    refetch().finally(() => onChangePage(1))
  }

  const handleChangeArm = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numericCharacters = extractOnlyNumericCharacters(value)
    setValue('Arm', numericCharacters)
  }

  const handleClickManageMultipleBayProduct = () => {
    setToggleMultipleEditBayProdModal(true)
  }

  const openModal = () => {
    setToggleAddBayProdModal(true)
  }

  return (
    <FormProvider
      register={register}
      handleSubmit={handleSubmit}
      setValue={setValue}
      watch={watch}
      {...methods}
    >
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <BayAutoComplete isAbsolute />
        <TextInput
          label="Arm"
          id="arm"
          maxLength={9}
          {...register('Arm', {
            onChange: handleChangeArm,
          })}
        />
        <TextInput
          label="Base Product Code"
          id="BaseProdCode"
          maxLength={20}
          {...register('BaseProdCode')}
        />
        <Select
          id="isActive"
          label="Avaiable"
          selectOptionalClass="w-full"
          {...register('IsActive')}
        >
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={openModal}
          />
        )}
        <Button
          optionalClass="!p-[1.5em] self-end ml-auto"
          type="em-c-btn--primary"
          typeAttribute="button"
          label="Manage Multiple Bay Products"
          onClick={handleClickManageMultipleBayProduct}
        />
      </form>
      <BayProdMasterTable
        isLoading={isLoading}
        bayProds={bayProds}
        currentPage={currentPage}
        onChangePage={onChangePage}
      />
    </FormProvider>
  )
}

export default Header
