import React from 'react'

type ConditionalWrapperProps = {
  children: React.ReactElement
  condition?: string | boolean
  wrapper: (children: React.ReactElement) => JSX.Element
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => {
  return condition ? wrapper(children) : children
}

export default ConditionalWrapper
