import { AccountInfo, InteractionStatus } from '@azure/msal-browser'
import { IMsalContext } from '@azure/msal-react'
import { create } from 'zustand'

interface Login {
  instance: IMsalContext['instance']
  inProgress: IMsalContext['inProgress']
  isAuthenticated: boolean
}

type AuthState = {
  account: AccountInfo | null
  login: (params: Login) => Promise<void>
}

const useAuthStore = create<AuthState>((set) => {
  const login: AuthState['login'] = async ({
    isAuthenticated,
    inProgress,
    instance,
  }) => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      const response = await instance.loginPopup()
      set({ account: response.account })
    }
  }

  return {
    account: null,
    login,
  }
})

export default useAuthStore
