import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '@/standard/Button'

interface PhoneNumberPadProps {
  typingPhoneNumber: (digit: string) => void
  onBackSpace: () => void
  onConfirm: () => void
  setIsGoToHome: (value: boolean) => void
  onSkipPhoneNumber?: () => void
  canSkip?: boolean
  isPending?: boolean
  isConfirmDisabled?: boolean
  isBackDisabled?: boolean
}

const PhoneNumberPad: FC<PhoneNumberPadProps> = ({
  typingPhoneNumber,
  onBackSpace,
  onConfirm,
  setIsGoToHome,
  onSkipPhoneNumber,
  canSkip,
  isPending,
  isConfirmDisabled,
  isBackDisabled,
}) => {
  return (
    <div className="z-10">
      <div className="grid grid-rows-3 grid-cols-3 gap-2 text-4xl font-bold">
        {[...Array(9)].map((_, index) => (
          <div
            key={index}
            className="p-2 bg-gray-200 text-center rounded-lg border-4 border-standard-cyan active:bg-standard-cyan select-none"
            onClick={() => typingPhoneNumber((index + 1).toString())}
          >
            {index + 1}
          </div>
        ))}
        <button
          className={twMerge(
            'em-c-btn em-c-btn--primary !h-full !p-2 !px-8',
            isBackDisabled ? 'em-c-btn--disabled' : 'em-c-btn--primary'
          )}
          onClick={onBackSpace}
        >
          Back
        </button>
        <div
          className="p-2 bg-gray-200 text-center rounded-lg border-4 border-standard-cyan active:bg-standard-cyan select-none"
          onClick={() => typingPhoneNumber('0')}
        >
          0
        </div>
        <Button
          label="Next"
          onClick={onConfirm}
          optionalClass={twMerge(
            'em-c-btn em-c-btn--primary !h-full !p-2',
            isConfirmDisabled ? 'em-c-btn--disabled' : 'em-c-btn--primary'
          )}
          {...(isPending
            ? {
                disabled: true,
                isIconOnlyButton: true,
                iconName: 'refresh',
                isLoading: true,
              }
            : { disabled: isConfirmDisabled })}
        />
      </div>
      <div className="flex gap-2 mt-2">
        <button
          className={twMerge('em-c-btn w-full', canSkip && 'w-1/2')}
          onClick={() => setIsGoToHome(true)}
        >
          Go to Home
        </button>
        {canSkip && (
          <Button
            label="Skip"
            onClick={onSkipPhoneNumber}
            type="em-c-btn--inverted"
            optionalClass="w-1/2"
            {...(isPending && {
              disabled: true,
              isIconOnlyButton: true,
              iconName: 'refresh',
              isLoading: true,
            })}
          />
        )}
      </div>
    </div>
  )
}

export default PhoneNumberPad
