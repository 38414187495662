import { useState } from 'react'
import { useForm } from 'react-hook-form'

import DrawerProdGroupMasterTable from '@/features/master/drawerProdGroup/table'

import {
  ISearchDrawerProdGroup,
  useSearchDrawerProdGroup,
} from '@/services/api/drawerProdGroupMaster'

import { useDrawerProdGroupMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

const DrawerProdGroupHeader = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddDrawerProdGroupModal } = useDrawerProdGroupMasterStore()
  const { drawerProductGroupMaster } = useRoleMatrixStore()
  const { canCreate } = drawerProductGroupMaster
  const { handleSubmit, watch, register } = useForm<ISearchDrawerProdGroup>({
    defaultValues: {
      DrawerProdGroupName: '',
      DrawerProdGroupCode: '',
    },
    mode: 'onBlur',
  })

  const {
    refetch,
    isFetching,
    isLoading,
    data: drawerProdGroups,
  } = useSearchDrawerProdGroup({
    ...filterFalsyProperties(watch()),
    Page: currentPage,
    termID: '1002',
  })

  const handleAdd = () => {
    setToggleAddDrawerProdGroupModal(true)
  }

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Drawer Product Group Code"
          id="DrawerProdGroupCode"
          maxLength={50}
          {...register('DrawerProdGroupCode')}
        />
        <TextInput
          label="Drawer Product Group Name"
          id="DrawerProdGroupName"
          maxLength={50}
          {...register('DrawerProdGroupName')}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={handleAdd}
          />
        )}
      </form>
      <DrawerProdGroupMasterTable
        isLoading={isLoading}
        drawerProdGroups={drawerProdGroups}
        currentPage={currentPage}
        onChangePage={setCurrentPage}
      />
    </>
  )
}

export default DrawerProdGroupHeader
