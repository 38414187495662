import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { useEditTanker } from '@/services/api/tankerMaster'

import { ITankerInformation, useTankerMasterStore } from '@/stores'

import TankerMasterForm from './form'

const TankerMasterEditModal = () => {
  const { currentTanker, setToggleEditTankerModal, setCurrentTanker } =
    useTankerMasterStore()

  const handleClose = () => {
    setToggleEditTankerModal(false)
    setCurrentTanker(undefined)
  }

  const { mutate, isPending } = useEditTanker(handleClose)
  const methods = useForm<ITankerInformation>({
    values: currentTanker,
    mode: 'onBlur',
  })

  const onSubmit = (data: ITankerInformation) => {
    mutate({
      body: {
        tankerID: data.tankerID,
        tankerIButton: data.tankerIButton,
        tankerCode: data.tankerCode,
        licensePlate: data.licensePlate,
        carrierID: data.carrierID,
        isTopLoad: data.isTopLoad,
        isWeight: data.isWeight,
        isBorrow: data.isBorrow,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a tanker"
        confirmLabel="Edit"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <TankerMasterForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default TankerMasterEditModal
