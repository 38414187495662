import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import CustomButtonDropdown from '@/components/dropdown/bayMonitor'

import CascadeBay from '@/features/queue/acknowledgement/cascadeBay'

import {
  IGetBayMonitorResult,
  IGetReassignmentResult,
  useGetBayMonitor,
  useGetReassignmentData,
} from '@/services/api/monitor/bay'

import { useOrderMonitor } from '@/stores'

import { useBayMonitor } from '@/stores/monitor/bay'

import Table, {
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '@/standard/Tables/Table'

import { formatDateAndTime } from '@/utils'

import DeleteQueueByQListModal from '../order/deleteByQListModal'
import DeleteQueueByTankerModal from '../order/deleteByTankerModal'
import DeleteQueueModal from '../order/deleteQueueModal'
import ReassignModal from './reassignModal'
import RemoveAssignedModal from './removeAssignedModal'
import ShowProductByBayModal from './showProductModal'

const BayMonitorComponent: FC = () => {
  const {
    isShowProdModalOpen,
    setIsShowProdModalOpen,
    setCurrentBay,
    isReassignModalOpen,
    isRemoveAssignedModalOpen,
  } = useBayMonitor()
  const {
    isDeleteQueueModalOpen,
    isDeleteQueueByQListModalOpen,
    isDeleteQueueByTankerModalOpen,
  } = useOrderMonitor()

  const { data: bayData } = useGetBayMonitor({ termID: '1002' })
  const { data: reassignmentData } = useGetReassignmentData({ termID: '1002' })

  const handleShowProdModal = () => {
    setIsShowProdModalOpen(true)
  }

  return (
    <div>
      <div className="flex-1 min-h-0 relative z-20 bg-white">
        <div className="grid gap-4 grid-cols-1 xl:grid-cols-2 4xl:grid-cols-3 place-items-center mx-auto pt-6">
          {bayData?.result?.map((bay: IGetBayMonitorResult) => {
            return (
              <div className="bg-white border border-black" key={bay.bayID}>
                <div className="flex w-full h-[30px] bg-black justify-center items-center gap-4 relative">
                  <div
                    className={twMerge(
                      'w-5 h-5 rounded-full bg-red-500',
                      bay.bayStatus === 1 && 'bg-green-500',
                      bay.bayStatus === 2 && 'bg-yellow-500'
                    )}
                  />

                  <span className="text-white font-bold uppercase">
                    {bay.bay}
                  </span>
                  <button
                    className="flex w-4 h-4 rounded-full ring-1 ring-white text-white items-center justify-center absolute right-1"
                    onClick={() => {
                      setCurrentBay(bay.bayID)
                      handleShowProdModal()
                    }}
                  >
                    i
                  </button>
                </div>
                <div className=" min-w-full"></div>
                <Table
                  tableOptionalClass="!min-w-[450px] xl:!min-w-[480px] 2xl:!min-w-[510px]"
                  optionalClass="!m-0"
                  type="em-c-table--striped"
                  bodyInnerOptionalClass="!overflow-visible"
                >
                  <TableHeader optionalClass="text-white">
                    <TableHeaderItem optionalClass="!w-[100px]">
                      Status
                    </TableHeaderItem>
                    <TableHeaderItem optionalClass="!w-[80px]">
                      Title
                    </TableHeaderItem>
                    <TableHeaderItem optionalClass="!w-[150px]">
                      Details
                    </TableHeaderItem>
                    <TableHeaderItem optionalClass="!w-[120px]">
                      Action
                    </TableHeaderItem>
                  </TableHeader>
                  <TableBody>
                    <TableRow
                      optionalClass="align-middle text-black bg-white h-[100px]"
                      tdOptionalClass="!py-0 !px-1"
                      type="em-c-table--condensed"
                    >
                      <div>
                        <span className="text-base font-bold">Loading</span>
                      </div>
                      {bay.loadTanker?.tankerCode ? (
                        <div className="flex flex-col space-y-2">
                          <span>Tanker code</span>
                          <span>
                            {bay.loadTanker?.documentType || 'Doc No.'}
                          </span>
                          <span>Start DTM</span>
                          <span>Call DTM</span>
                        </div>
                      ) : (
                        <>-</>
                      )}
                      {bay.loadTanker?.tankerCode ? (
                        <div className="flex flex-col space-y-2">
                          <span>{bay.loadTanker?.tankerCode}</span>
                          <span>{bay.loadTanker?.documentNr}</span>
                          <span>
                            {formatDateAndTime(bay.loadTanker.startDTM) || '-'}
                          </span>
                          <span>
                            {formatDateAndTime(bay.loadTanker.callDTM) || '-'}
                          </span>
                        </div>
                      ) : (
                        <>-</>
                      )}
                      {bay.loadTanker?.tankerCode ? (
                        <div className="flex justify-center">
                          <CustomButtonDropdown data={bay.loadTanker} />
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </TableRow>
                    <TableRow
                      optionalClass="align-middle text-black bg-white h-[100px]"
                      tdOptionalClass="!py-0 !px-1"
                      type="em-c-table--condensed"
                    >
                      <div>
                        <span className="text-base font-bold">Next Queue</span>
                      </div>
                      {bay.otherTankerList[0]?.tankerCode ? (
                        <div className="flex flex-col space-y-2">
                          <span>Tanker code</span>
                          <span>
                            {bay.otherTankerList[0]?.documentType || 'Doc No.'}
                          </span>
                          <span>Call DTM</span>
                        </div>
                      ) : (
                        <>-</>
                      )}
                      {bay.otherTankerList[0]?.tankerCode ? (
                        <div className="flex flex-col space-y-2">
                          <span>{bay.otherTankerList[0].tankerCode}</span>
                          <span>{bay.otherTankerList[0].documentNr}</span>
                          <span>
                            {formatDateAndTime(
                              bay.otherTankerList[0].callDTM
                            ) || '-'}
                          </span>
                        </div>
                      ) : (
                        <>-</>
                      )}
                      {bay.otherTankerList[0]?.tankerCode ? (
                        <div className="flex justify-center">
                          <CustomButtonDropdown data={bay.otherTankerList[0]} />
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </TableRow>
                    <TableRow
                      optionalClass="align-middle text-black bg-white h-[100px]"
                      tdOptionalClass="!py-0 !px-1"
                      type="em-c-table--condensed"
                    >
                      <div>
                        <span className="text-base font-bold">Next Queue</span>
                      </div>
                      {bay.otherTankerList[1]?.tankerCode ? (
                        <div className="flex flex-col space-y-2">
                          <span>Tanker code</span>
                          <span>
                            {bay.otherTankerList[1]?.documentType || 'Doc No.'}
                          </span>
                          <span>Call DTM</span>
                        </div>
                      ) : (
                        <>-</>
                      )}
                      {bay.otherTankerList[1]?.tankerCode ? (
                        <div className="flex flex-col space-y-2">
                          <span>{bay.otherTankerList[1].tankerCode}</span>
                          <span>{bay.otherTankerList[1].documentNr}</span>
                          <span>
                            {formatDateAndTime(
                              bay.otherTankerList[1].callDTM
                            ) || '-'}
                          </span>
                        </div>
                      ) : (
                        <>-</>
                      )}
                      {bay.otherTankerList[1]?.tankerCode ? (
                        <div className="flex justify-center">
                          <CustomButtonDropdown data={bay.otherTankerList[1]} />
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex-1 min-h-0 py-10">
        <h3>Reassignment List</h3>
        <div className="pt-6">
          <Table
            optionalClass="min-w-5xl"
            type="em-c-table--striped"
            bodyInnerOptionalClass="bg-neutral-lightGray border border-gray-300 !min-h-[300px]"
          >
            <TableHeader optionalClass="text-white">
              <TableHeaderItem optionalClass="w-[200px]">
                Tanker Code
              </TableHeaderItem>
              <TableHeaderItem optionalClass="w-[400px]">
                Order No
              </TableHeaderItem>
              <TableHeaderItem optionalClass="w-[230px]">
                New bay
              </TableHeaderItem>
              <TableHeaderItem optionalClass="w-[230px]">
                Old bay
              </TableHeaderItem>
            </TableHeader>
            <TableBody>
              {reassignmentData?.result.map(
                (tanker: IGetReassignmentResult) => {
                  return (
                    <TableRow
                      optionalClass="align-middle text-black bg-white"
                      key={`${tanker.tankerCode}-${tanker.documentID}`}
                    >
                      <div>{tanker.tankerCode}</div>
                      <div>{`${tanker.documentType}: ${tanker.documentNr}`}</div>
                      <div>
                        <CascadeBay bays={tanker.newBayList} />
                      </div>
                      <div>
                        <CascadeBay bays={tanker.oldBayList} />
                      </div>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      {isShowProdModalOpen && <ShowProductByBayModal />}
      {isReassignModalOpen && <ReassignModal />}
      {isRemoveAssignedModalOpen && <RemoveAssignedModal />}
      {isDeleteQueueModalOpen && <DeleteQueueModal />}
      {isDeleteQueueByQListModalOpen && <DeleteQueueByQListModal />}
      {isDeleteQueueByTankerModalOpen && <DeleteQueueByTankerModal />}
    </div>
  )
}

export default BayMonitorComponent
