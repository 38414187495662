import { FC, useEffect, useRef, useState } from 'react'

import { qRegistrationService } from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

import { extractOnlyAlphanumericCharacters } from '@/utils'

import TermAndConditionModal from './termAndCon'

const DriverIButton: FC = () => {
  const { setStep, setPersonnelIButton } = useQueueRegistrationStore()

  const { mutateAsync: mutateValidateDriver } =
    qRegistrationService.useValidateDriver()

  const IButtonRef = useRef<HTMLInputElement>(null)
  const [dataIButton, setDataIButton] = useState('')
  const [messageError, setMessageError] = useState('')
  const [toggleTermModal, setToggleTermModal] = useState(false)

  const resetDriverIButton = () => {
    setDataIButton('')
    setPersonnelIButton('')
    setMessageError('')
  }

  const openModal = () => {
    setToggleTermModal(true)
  }
  const declinedModal = () => {
    setToggleTermModal(false)
    resetDriverIButton()
  }
  const closeModal = () => {
    setToggleTermModal(false)
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      IButtonRef.current &&
      !IButtonRef.current.contains(event.target as Node)
    ) {
      IButtonRef.current.focus()
    }
  }

  useEffect(() => {
    // function validate personnel
    if (dataIButton.length !== 16) return
    setPersonnelIButton(dataIButton)
    setMessageError('')
    const validateDriver = async () => {
      const data = await mutateValidateDriver({
        termID: '1002',
        personnelIButton: dataIButton,
      })
      if (data.message) {
        setMessageError(data.message)
      } else {
        if (data?.result?.step !== 'dataPrivacy') {
          setStep(data.result.step)
        } else {
          openModal()
        }
      }
      setDataIButton('')
    }
    validateDriver()
  }, [dataIButton])

  useEffect(() => {
    if (IButtonRef.current) {
      IButtonRef.current.focus()
    }
  }, [IButtonRef.current])

  useEffect(() => {
    document.addEventListener('contextmenu', handleClickOutside)
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('contextmenu', handleClickOutside)
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <>
      {toggleTermModal && (
        <TermAndConditionModal
          toggleModal={toggleTermModal}
          closeModal={closeModal}
          declinedModal={declinedModal}
        />
      )}
      <div className="flex flex-col items-center w-full h-full justify-center gap-y-10 p-20 z-10 absolute">
        <img src="/images/ExxonMobilLogoColor2x.png" />
        <p className="text-4xl text-white m-0">
          Please scan
          <span className="font-bold text-standard-cyan mx-2">
            Driver iButton
          </span>
          OR type in iButton ID
        </p>
        <p className="text-xl text-red-500 h-7 m-0">
          {messageError && messageError}
        </p>
        <input
          ref={IButtonRef}
          type="text"
          className="max-w-md h-[80px] text-4xl px-4 ring-4 ring-blue-400 focus:bg-blue-100"
          placeholder="Driver IButton"
          maxLength={16}
          onChange={(event) => {
            setDataIButton(
              extractOnlyAlphanumericCharacters(event.target.value)
            )
          }}
          value={dataIButton}
        />
        <div className="flex items-center space-x-2">
          <button
            className="em-c-btn"
            onClick={() => {
              resetDriverIButton()
            }}
          >
            <span className="em-c-btn__text">Reset</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default DriverIButton
