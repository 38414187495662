import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import PersonnelMasterTable from '@/features/master/personnel/table'

import {
  ISearchPersonnel,
  useGetPersonnel,
} from '@/services/api/personnelMaster'

import { usePersonnelMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

const Header: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddPersonnelModal } = usePersonnelMasterStore()
  const { personnelMaster } = useRoleMatrixStore()
  const { canCreate } = personnelMaster
  const { handleSubmit, watch, register } = useForm<ISearchPersonnel>({
    defaultValues: {
      PersonnelIButton: '',
      Name: '',
      DrawerCode: '',
      IsBorrow: '',
    },
    mode: 'onBlur',
  })

  const {
    refetch,
    isFetching,
    isLoading,
    data: personals,
  } = useGetPersonnel({
    ...filterFalsyProperties(watch()),
    termID: '1002',
    Page: currentPage,
  })

  const handleAdd = () => {
    setToggleAddPersonnelModal(true)
  }

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Personnel IButton"
          id="PersonnelIButton"
          maxLength={12}
          {...register('PersonnelIButton')}
        />
        <TextInput
          label="Name"
          id="Name"
          maxLength={50}
          {...register('Name')}
        />
        <TextInput
          label="Drawer Code"
          id="DrawerCode"
          maxLength={20}
          {...register('DrawerCode')}
        />
        <Select label="Is Borrow" id="IsBorrow" {...register('IsBorrow')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={handleAdd}
          />
        )}
      </form>
      <PersonnelMasterTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        personals={personals}
        isLoading={isLoading}
      />
    </>
  )
}
export default Header
