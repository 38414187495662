import Button from 'components/Button'
import Icon from 'components/Icon'
import { MouseEventHandler } from 'react'

import HeaderTitle from '../HeaderTitle'

interface VerticalHeaderMenuProps {
  title: string
  btnLabel?: string
  withSearch?: boolean
  optionalClass?: string
  onLogout?: MouseEventHandler<HTMLButtonElement>
}

const VerticalHeaderMenu = ({
  title,
  // btnLabel = 'Menu',
  withSearch,
  optionalClass,
  onLogout,
}: VerticalHeaderMenuProps) => {
  return (
    <div className={`em-c-header__band ${optionalClass ? optionalClass : ''}`}>
      <div className="em-c-header__body">
        <HeaderTitle title={title} logotype />
        {onLogout && (
          <Button
            isIconButton
            iconName="link-external"
            type="em-c-btn--bare"
            onClick={onLogout}
          />
        )}

        {/* <Button
          type="em-c-btn--inverted"
          size="em-c-btn--small"
          optionalClass="em-c-header__nav-btn em-js-nav-trigger"
          aria-haspopup="true"
          aria-controls="verticalMenu"
        >
          <Icon
            name="hamburger"
            size="em-c-icon--small"
            optionalClass="em-c-btn__icon em-js-btn-icon"
          />
          <Icon
            name="x-filled"
            size="em-c-icon--small"
            optionalClass="em-c-btn__icon em-u-is-hidden em-js-btn-swap-icon"
          />
          <span className="em-c-btn__text em-js-btn-label">{btnLabel}</span>
        </Button> */}
        <Button
          type="em-c-btn--bare"
          size="em-c-btn--small"
          optionalClass={`em-js-header-search-trigger  ${
            withSearch ? '' : 'em-u-is-hidden'
          }`}
          aria-label="Search"
        >
          <Icon
            name="search"
            size="em-c-icon--small"
            optionalClass="em-c-btn__icon em-js-btn-icon"
          />
          <Icon
            name="x-filled"
            size="em-c-icon--small"
            optionalClass="em-c-btn__icon em-u-is-hidden em-js-btn-swap-icon"
          />
        </Button>
      </div>
    </div>
  )
}

export default VerticalHeaderMenu
