import { ChangeEvent, ChangeEventHandler, FC } from 'react'

import { ScreenList, useEditRole } from '@/services/api/roleMatrix'

import { useRoleMatrixStore } from '@/stores'

import Toggle, { ToggleItem } from '@/standard/Toggle'

import { parseStringToBoolean } from '@/utils'

interface RoleMatrixToggleProps {
  screenList: ScreenList
  activeKey: 'canRead' | 'canEdit' | 'canCreate' | 'canDelete'
  role?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const RoleMatrixToggle: FC<RoleMatrixToggleProps> = ({
  screenList,
  activeKey,
  role,
  onChange,
}) => {
  const { roleMatrix } = useRoleMatrixStore()
  const { canEdit } = roleMatrix
  const disabled =
    !canEdit || (screenList.screenName === 'Role Matrix' && role === 'Admin')
  const { mutate } = useEditRole()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (role) {
      mutate({
        body: {
          role,
          screenList: [
            {
              ...screenList,
              [activeKey]: parseStringToBoolean(event.currentTarget.value),
            },
          ],
        },
        params: { termID: '1002' },
      })
    }
  }

  return typeof screenList[activeKey] === 'boolean' ? (
    <Toggle
      optionalClass="justify-center"
      containerOptionalClass="flex items-center"
      state={disabled ? 'em-is-disabled' : undefined}
    >
      <ToggleItem
        labelOptionalClass="!py-[0.1rem] !px-[1rem] !h-[70%] !flex !items-center"
        name={`isActive-${screenList.mapID}-${role}-${activeKey}`}
        value="true"
        checked={screenList[activeKey] as boolean}
        onChange={onChange ?? handleChange}
        disabled={disabled}
      >
        Yes
      </ToggleItem>
      <ToggleItem
        name={`isInActive-${screenList.mapID}-${role}-${activeKey}`}
        labelOptionalClass="!py-[1px] !px-[1rem] !h-[70%] !flex !items-center"
        value="false"
        checked={!screenList[activeKey]}
        onChange={onChange ?? handleChange}
        disabled={disabled}
      >
        No
      </ToggleItem>
    </Toggle>
  ) : (
    'N/A'
  )
}

export default RoleMatrixToggle
