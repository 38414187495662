import { FC } from 'react'

import LoadingIndicator from '@/standard/LoadingIndicator'

interface LoadingPageProps {
  label?: string
}

const LoadingPage: FC<LoadingPageProps> = ({
  label = 'Loading, please wait',
}) => {
  return <LoadingIndicator textOnly isScreen label={label} />
}

export default LoadingPage
