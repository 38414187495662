import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IAddPersonnel, useAddPersonnel } from '@/services/api/personnelMaster'

import { IPersonnelInformation, usePersonnelMasterStore } from '@/stores'

import { removeKeyFromObject } from '@/utils'

import PersonnelMasterForm from './form'

interface OnSubmit extends IAddPersonnel {
  drawerCode: string
}

const PersonnelMasterAddModal = () => {
  const { setToggleAddPersonnelModal } = usePersonnelMasterStore()

  const handleClose = () => {
    setToggleAddPersonnelModal(false)
  }

  const { mutate, isPending } = useAddPersonnel(handleClose)
  const methods = useForm<IPersonnelInformation>({
    defaultValues: {
      personnelIButton: '',
      email: '',
      name: '',
      isBorrow: false,
      phoneNo: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: OnSubmit) => {
    mutate({
      body: removeKeyFromObject(data, ['drawerCode']),
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a personnel"
        confirmLabel="Add"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <PersonnelMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default PersonnelMasterAddModal
