import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import {
  ITankerData,
  ITankerInformation,
  useRoleMatrixStore,
  useTankerMasterStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<ITankerInformation>([
  {
    key: 'tankerIButton',
    name: 'Tanker iButton',
  },
  {
    key: 'tankerCode',
    name: 'Tanker Code',
  },
  {
    key: 'licensePlate',
    name: 'License Plate',
  },
  {
    key: 'carrierCode',
    name: 'Carrier Code',
  },
  {
    key: 'displayIsTopLoad',
    name: 'Is Top Load',
  },
  {
    key: 'displayIsWeight',
    name: 'Is Weight',
  },
  {
    key: 'displayIsBorrow',
    name: 'Is Borrow',
  },
  {
    key: 'displayCreatedDate',
    name: 'Created Date',
  },
  {
    key: 'createdBy',
    name: 'Created By',
  },
  {
    key: 'displayModifiedDate',
    name: 'Modified Date',
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
  },
  {
    key: 'actions',
    name: 'Action',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { tankerMaster } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setToggleEditTankerModal,
        setCurrentTanker,
      } = useTankerMasterStore.getState()
      const { canEdit, canDelete } = tankerMaster

      const handleDelete = () => {
        setCurrentTanker(row)
        setToggleConfirmDeleteModal(true)
      }

      const handleEdit = () => {
        setCurrentTanker(row)
        setToggleEditTankerModal(true)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface TankerMasterTableProps {
  onChangePage: (page: number) => void
  currentPage: number
  tankers?: ITankerData
  isLoading: boolean
}

const TankerMasterTable: FC<TankerMasterTableProps> = ({
  onChangePage,
  currentPage,
  tankers,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading: isLoading,
    currentPage: currentPage,
    rowsPerPage,
    total: tankers?.total || 0,
  })

  return tankers?.result ? (
    <>
      <DataGrid
        columns={columns}
        rows={tankers.result}
        className="h-full rdg-light"
        rowHeight={45}
        headerRowHeight={30}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          tankers?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((tankers?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default TankerMasterTable
