import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useContractStore } from '@/stores'

import BulletedList from '@/standard/BulletedList'
import BulletedListItem from '@/standard/BulletedList/BulletedListItem'

import { formatDate } from '@/utils'

const DeleteContractDetailModal: FC = () => {
  const {
    deleteContractDetail,
    setToggleConfirmDeleteDetailModal,
    selectedContractDetail,
  } = useContractStore()

  const handleClose = () => {
    setToggleConfirmDeleteDetailModal(false)
  }

  const handleDelete = () => {
    deleteContractDetail()
    handleClose()
  }

  return (
    <DeleteModal
      title="Delete a contract detail"
      modalWindowOptionalClass="!z-[70]"
      overlayOptionalClass="!z-[60]"
      onDelete={handleDelete}
      onClose={handleClose}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {selectedContractDetail?.drawerProdCode && (
          <BulletedListItem>
            Drawer Product Code: {selectedContractDetail?.drawerProdCode}
          </BulletedListItem>
        )}
        {selectedContractDetail?.itemValStart && (
          <BulletedListItem>
            Validity Start: {formatDate(selectedContractDetail?.itemValStart)}
          </BulletedListItem>
        )}
        {selectedContractDetail?.itemValEnd && (
          <BulletedListItem>
            Validity End: {formatDate(selectedContractDetail?.itemValEnd)}
          </BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default DeleteContractDetailModal
