import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { Outlet } from '@tanstack/react-router'
import { Bounce, ToastContainer } from 'react-toastify'

import { ErrorModal } from '@/components'

import LoginFeature from '@/features/auth/login'

import { useErrorModalStore } from '@/stores'

function App() {
  const { setIsOpen, message, isOpen } = useErrorModalStore()

  return (
    <>
      <UnauthenticatedTemplate>
        <LoginFeature />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <ToastContainer
          position="top-center"
          limit={1}
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Bounce}
        />
        {isOpen && (
          <ErrorModal onClose={() => setIsOpen(false)} message={message} />
        )}
        <Outlet />
      </AuthenticatedTemplate>
    </>
  )
}

export default App
