import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetPriorityForDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface PriorityAutoCompleteField {
  priorityID: number | null
  priorityCode: number
}

interface PriorityAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const PriorityAutoComplete: FC<PriorityAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: priorities, isLoading } = useGetPriorityForDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<PriorityAutoCompleteField>()

  const priorityState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.priorityCode && 'em-has-error'

  return (
    <Autocomplete
      state={priorityState}
      note={formState.errors.priorityCode?.message}
      id="priorityID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={priorities}
      label="Priority"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('priorityCode')}
      maxLength={20}
      value={watch('priorityCode')}
      {...register('priorityCode', {
        required: isRequired ? 'Priority is required' : false,
        validate: () =>
          watch('priorityID') === 0 ? 'Priority is not valid' : undefined,
      })}
      onChange={(value) => {
        setValue(
          'priorityID',
          value?.label ? parseNanToZero(value?.value) : null
        )
        setValue('priorityCode', parseNanToZero(value?.label))
      }}
    />
  )
}

export default PriorityAutoComplete
