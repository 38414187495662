import { FC } from 'react'

import { Layout } from '@/components'

import BayMaster from '@/features/master/bay'

const BayMasterPage: FC = () => {
  return (
    <Layout>
      <BayMaster />
    </Layout>
  )
}

export default BayMasterPage
