import { create } from 'zustand'

import {
  IRegistrationQueue,
  ISelectedShipmentList,
  ISummaryQRegistrationData,
} from '@/services/api/qRegistration'

export type TRegistrationStep =
  | 'validateIButton'
  | 'dataPrivacy'
  | 'validateTanker'
  | 'validateDocument'
  | 'selectProd'
  | 'showSummary'
  | 'confirmPhoneNumber'
  | 'queueCompleted'
  | 'waitForBayassignment'
  | 'waitForAcknowledgement'
  | 'waitForCallToBay'
  | 'goToBay'
  | 'acknowledgement'

interface RegistrationState {
  personnelIButton: string
  tankerIButton: string
  document: string
  step: TRegistrationStep
  summaryData: ISummaryQRegistrationData | undefined
  queueData: IRegistrationQueue | undefined
  phoneData: string
  editContract: ISelectedShipmentList | undefined
  setPersonnelIButton: (value: string) => void
  setTankerIButton: (value: string) => void
  setDocument: (value: string) => void
  setStep: (value: TRegistrationStep) => void
  setSummaryData: (value: ISummaryQRegistrationData) => void
  setQueueData: (value: IRegistrationQueue) => void
  setPhoneData: (value: string) => void
  setEditContract: (value: ISelectedShipmentList | undefined) => void
  setResetData: () => void
  removeOrder: ISelectedShipmentList | undefined
  setRemoveOrder: (value: ISelectedShipmentList | undefined) => void
  orderList: string[]
  setOrderList: (value: string[]) => void
  isGoToHome: boolean
  setIsGoToHome: (value: boolean) => void
}

export const useQueueRegistrationStore = create<RegistrationState>((set) => ({
  personnelIButton: '',
  tankerIButton: '',
  document: '',
  step: 'validateIButton',
  summaryData: undefined,
  queueData: undefined,
  phoneData: '',
  editContract: undefined,
  setPersonnelIButton: (value) => set({ personnelIButton: value }),
  setTankerIButton: (value) => set({ tankerIButton: value }),
  setDocument: (value) => set({ document: value }),
  setStep: (value) => set({ step: value }),
  setSummaryData: (value) => set({ summaryData: value }),
  setQueueData: (value) => set({ queueData: value }),
  setPhoneData: (value) => set({ phoneData: value }),
  setEditContract: (value) => set({ editContract: value }),
  removeOrder: undefined,
  setRemoveOrder: (value) => set({ removeOrder: value }),
  orderList: [],
  setOrderList: (value) => set({ orderList: value }),
  setResetData: () =>
    set({
      personnelIButton: '',
      tankerIButton: '',
      document: '',
      step: 'validateIButton',
      summaryData: undefined,
      queueData: undefined,
      phoneData: '',
      editContract: undefined,
      orderList: [],
    }),
  isGoToHome: false,
  setIsGoToHome: (value) => set({ isGoToHome: value }),
}))
