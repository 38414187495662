import { FC, useState } from 'react'

import { IGetQueueAlreadyAssignBayResult } from '@/services/api/monitor/order'

import { useBayMonitor, useOrderMonitor } from '@/stores'

import ClickAwayListener from '@/standard/ClickAwayListener'

import Button from '../../standard/Button/Button'

interface Props {
  data: IGetQueueAlreadyAssignBayResult
}

const QueueAlreadyAssignedActionsDropdown: FC<Props> = ({ data }) => {
  const { setIsRemoveAssignedModalOpen, setCurrentQueueInAlreadyAssigned } =
    useBayMonitor()

  const { setIsDeleteQueueModalOpen } = useOrderMonitor()

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    if (!data.tankerID) return
    setCurrentQueueInAlreadyAssigned(data)
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleRemoveAssignedModal = () => {
    setIsRemoveAssignedModalOpen(true)
  }
  const handleDeleteQueueModal = () => {
    setIsDeleteQueueModalOpen(true)
  }

  return (
    <div className="relative flex flex-col w-[40px] h-6">
      <Button
        isIconOnlyButton
        typeAttribute="button"
        label="View Queue"
        title="View Queue"
        iconName="settings"
        size="em-c-btn--small"
        iconSize="em-c-icon--small"
        onClick={() => {
          handleOpen()
        }}
      />

      <ClickAwayListener onClickAway={() => handleClose()}>
        {isOpen && (
          <div className="w-[150px] bg-white shadow-md rounded-sm absolute top-8 right-0 z-50 space-y-1">
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleRemoveAssignedModal()
                handleClose()
              }}
              disabled={data.isRemove}
            >
              <span>Remove assigned bay</span>
            </button>
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleDeleteQueueModal()
                handleClose()
              }}
              disabled={!data.isRemove}
            >
              <span>Delete Queue</span>
            </button>
          </div>
        )}
      </ClickAwayListener>
    </div>
  )
}

export default QueueAlreadyAssignedActionsDropdown
