import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { IGetBayResultDetail, ISearchBayResult } from '@/services/api/bayMaster'

interface BayMasterState {
  currentBay: ISearchBayResult | undefined
  setCurrentBay: (value: ISearchBayResult | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddBayModal: boolean
  setToggleAddBayModal: (value: boolean) => void
  toggleEditBayModal: boolean
  setToggleEditBayModal: (value: boolean) => void
  // Children
  toggleConfirmDeleteChildModal: boolean
  setToggleConfirmDeleteChildModal: (value: boolean) => void
  toggleAddBayChildModal: boolean
  setToggleAddBayChildModal: (value: boolean) => void
  toggleEditBayChildModal: boolean
  setToggleEditBayChildModal: (value: boolean) => void
  bayChildren: IGetBayResultDetail[]
  setBayChildren: (value: IGetBayResultDetail[]) => void
  addBayChild: (value: IGetBayResultDetail) => void
  editBayChild: (value: IGetBayResultDetail) => void
  deleteBayChild: () => void
  selectedBayChild: IGetBayResultDetail | undefined
  setSelectedBayChild: (value: IGetBayResultDetail | undefined) => void
  selectedBayChildIndex: number
  setSelectedBayChildIndex: (value: number) => void
  clearState: () => void
}

export const useBayMasterStore = create<BayMasterState>()(
  immer((set) => ({
    currentBay: undefined,
    setCurrentBay: (value) => {
      set((state) => {
        state.currentBay = value
      })
    },
    toggleConfirmDeleteModal: false,
    setToggleConfirmDeleteModal: (value) => {
      set((state) => {
        state.toggleConfirmDeleteModal = value
      })
    },
    toggleAddBayModal: false,
    setToggleAddBayModal: (value) => {
      set((state) => {
        state.toggleAddBayModal = value
      })
    },
    toggleEditBayModal: false,
    setToggleEditBayModal: (value) => {
      set((state) => {
        state.toggleEditBayModal = value
      })
    },
    // Children
    toggleConfirmDeleteChildModal: false,
    setToggleConfirmDeleteChildModal: (value) => {
      set((state) => {
        state.toggleConfirmDeleteChildModal = value
      })
    },
    toggleAddBayChildModal: false,
    setToggleAddBayChildModal: (value) => {
      set((state) => {
        state.toggleAddBayChildModal = value
      })
    },
    toggleEditBayChildModal: false,
    setToggleEditBayChildModal: (value) => {
      set((state) => {
        state.toggleEditBayChildModal = value
      })
    },
    bayChildren: [],
    setBayChildren: (value) => {
      set((state) => {
        state.bayChildren = value
      })
    },
    addBayChild: (value) => {
      set((state) => {
        state.bayChildren.push(value)
      })
    },
    editBayChild: (value) => {
      set((state) => {
        state.bayChildren[state.selectedBayChildIndex] = value
      })
    },
    deleteBayChild: () => {
      set((state) => {
        state.bayChildren.splice(state.selectedBayChildIndex, 1)
      })
    },
    selectedBayChild: undefined,
    setSelectedBayChild: (value) => {
      set((state) => {
        state.selectedBayChild = value
      })
    },
    selectedBayChildIndex: 0,
    setSelectedBayChildIndex: (value) => {
      set((state) => {
        state.selectedBayChildIndex = value
      })
    },
    clearState: () => {
      set((state) => {
        state.currentBay = undefined
        state.toggleConfirmDeleteModal = false
        state.toggleAddBayModal = false
        state.toggleEditBayModal = false
        state.toggleConfirmDeleteChildModal = false
        state.toggleAddBayChildModal = false
        state.toggleEditBayChildModal = false
        state.bayChildren = []
        state.selectedBayChild = undefined
        state.selectedBayChildIndex = 0
      })
    },
  }))
)
