import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import ContractDetailForm from '@/features/master/contract/forms/detail'

import { IContractDetail, useContractStore } from '@/stores'

const AddContractDetailModal = () => {
  const { addContractDetail, setToggleAddContractDetailModal } =
    useContractStore()
  const methods = useForm<IContractDetail>({
    mode: 'onBlur',
    defaultValues: {
      contractDetailID: 0,
    },
  })

  const handleClose = () => {
    setToggleAddContractDetailModal(false)
  }

  const onSubmit = (data: IContractDetail) => {
    addContractDetail(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a Contract Detail"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Add"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <ContractDetailForm />
      </FormModal>
    </FormProvider>
  )
}

export default AddContractDetailModal
