import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import DrawerProdGroupMasterForm from '@/features/master/drawerProdGroup/form'

import {
  IEditDrawerProdGroup,
  useEditDrawerProdGroup,
} from '@/services/api/drawerProdGroupMaster'

import { useDrawerProdGroupMasterStore } from '@/stores'

const DrawerProdGroupEditModal: FC = () => {
  const {
    currentDrawerProdGroup,
    setToggleEditDrawerProdGroupModal,
    setCurrentDrawerProdGroup,
  } = useDrawerProdGroupMasterStore()

  const handleClose = () => {
    setToggleEditDrawerProdGroupModal(false)
    setCurrentDrawerProdGroup(undefined)
  }

  const { mutate, isPending } = useEditDrawerProdGroup(handleClose)
  const methods = useForm<IEditDrawerProdGroup>({
    values: currentDrawerProdGroup,
    mode: 'onBlur',
  })

  const onSubmit = (data: IEditDrawerProdGroup) => {
    mutate({
      body: {
        drawerProdGroupID: data.drawerProdGroupID,
        drawerProdGroupCode: data.drawerProdGroupCode,
        drawerProdGroupName: data.drawerProdGroupName,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a drawer product group"
        confirmLabel="Edit"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <DrawerProdGroupMasterForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default DrawerProdGroupEditModal
