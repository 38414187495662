import { FC } from 'react'

import BayProdMasterAddModal from '@/features/master/bayProd/addModal'
import BayProductMasterDeleteModal from '@/features/master/bayProd/deleteModal'

import { useBayProdMasterStore } from '@/stores'

import BayProdMasterEditModal from './editModal'
import Header from './header'
import ManageMultipleBayProductModal from './manageMultipleBayProduct'

const BayProdMasterComponent: FC = () => {
  const {
    toggleAddBayProdModal,
    toggleEditBayProdModal,
    toggleConfirmDeleteModal,
    toggleMultipleEditBayProdModal,
  } = useBayProdMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddBayProdModal && <BayProdMasterAddModal />}
      {toggleEditBayProdModal && <BayProdMasterEditModal />}
      {toggleConfirmDeleteModal && <BayProductMasterDeleteModal />}
      {toggleMultipleEditBayProdModal && <ManageMultipleBayProductModal />}
      <Header />
    </div>
  )
}
export default BayProdMasterComponent
