import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetBaseProdForDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface BaseProdAutoCompleteField {
  baseProdID: number | null
  baseProdCode: string
}

interface BaseProdAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const BaseProdAutoComplete: FC<BaseProdAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: baseProds, isLoading } = useGetBaseProdForDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<BaseProdAutoCompleteField>()

  const baseProdState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.baseProdCode && 'em-has-error'

  return (
    <Autocomplete
      state={baseProdState}
      note={formState.errors.baseProdCode?.message}
      id="baseProdID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={baseProds}
      label="Base Product"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('baseProdCode')}
      maxLength={20}
      value={watch('baseProdCode')}
      {...register('baseProdCode', {
        required: isRequired ? 'Base Product is required' : false,
        validate: () =>
          watch('baseProdID') === 0 ? 'Base Product is not valid' : undefined,
      })}
      onChange={(value) => {
        setValue(
          'baseProdID',
          value?.label ? parseNanToZero(value?.value) : null
        )
        setValue('baseProdCode', value?.label)
      }}
    />
  )
}

export default BaseProdAutoComplete
