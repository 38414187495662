import { FC } from 'react'

import ErrorReportHeader from './header'

const ErrorReportFeature: FC = () => {
  return (
    <div className="flex flex-col h-full">
      <ErrorReportHeader />
    </div>
  )
}

export default ErrorReportFeature
