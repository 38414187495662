import { FC } from 'react'

import { useBaseProdMasterStore } from '@/stores'

import BaseProdAddModal from './addModal'
import BaseProductDeleteModal from './deleteModal'
import BaseProdEditModal from './editModal'
import BaseProdHeader from './header'

const BaseProdMasterComponent: FC = () => {
  const {
    toggleAddBaseProdModal,
    toggleEditBaseProdModal,
    toggleConfirmDeleteModal,
  } = useBaseProdMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddBaseProdModal && <BaseProdAddModal />}
      {toggleEditBaseProdModal && <BaseProdEditModal />}
      {toggleConfirmDeleteModal && <BaseProductDeleteModal />}
      <BaseProdHeader />
    </div>
  )
}
export default BaseProdMasterComponent
