import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import CarrierMasterTable from '@/features/master/carrier/table'

import { ISearchCarrier, useSearchCarrier } from '@/services/api/carrierMaster'

import { useCarrierMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

const Header: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddCarrierModal } = useCarrierMasterStore()
  const { carrierMaster } = useRoleMatrixStore()
  const { canCreate } = carrierMaster

  const { register, handleSubmit, watch } = useForm<ISearchCarrier>({
    defaultValues: {
      CarrierCode: '',
      CarrierName: '',
    },
    mode: 'onBlur',
  })

  const { refetch, isFetching, data, isLoading } = useSearchCarrier({
    ...watch(),
    termID: '1002',
    Page: currentPage,
  })

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const openModal = () => {
    setToggleAddCarrierModal(true)
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Carrier Code"
          id="CarrierCode"
          maxLength={20}
          {...register('CarrierCode')}
        />
        <TextInput
          label="Carrier Name"
          id="CarrierName"
          maxLength={50}
          {...register('CarrierName')}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={openModal}
          />
        )}
      </form>
      <CarrierMasterTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        carriers={data}
        isLoading={isLoading}
      />
    </>
  )
}
export default Header
