import { create } from 'zustand'

export interface ICarrierInformation {
  carrierID: number
  termID: string
  carrierCode: string
  carrierName: string
  remark: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayCreatedDate: Date
  displayModifiedDate: Date
  displayRemark: string
}

export interface ICarrierData {
  result: ICarrierInformation[]
  total: number
}

interface CarrierMasterState {
  currentCarrier: ICarrierInformation | undefined
  setCurrentCarrier: (value: ICarrierInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddCarrierModal: boolean
  setToggleAddCarrierModal: (value: boolean) => void
  toggleEditCarrierModal: boolean
  setToggleEditCarrierModal: (value: boolean) => void
}

export const useCarrierMasterStore = create<CarrierMasterState>((set) => ({
  currentCarrier: undefined,
  setCurrentCarrier: (value) => set({ currentCarrier: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddCarrierModal: false,
  setToggleAddCarrierModal: (value) => set({ toggleAddCarrierModal: value }),
  toggleEditCarrierModal: false,
  setToggleEditCarrierModal: (value) => set({ toggleEditCarrierModal: value }),
}))
