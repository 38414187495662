import { FC, useEffect, useRef, useState } from 'react'

import {
  IValidateTankerData,
  qRegistrationService,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

import { extractOnlyAlphanumericCharacters } from '@/utils'

const TankerIButton: FC = () => {
  const { personnelIButton, setStep, setTankerIButton, setIsGoToHome } =
    useQueueRegistrationStore()

  const { mutateAsync: mutateValidateTanker } =
    qRegistrationService.useValidateTanker()

  const tankerIButtonRef = useRef<HTMLInputElement>(null)
  const [tankerDataIButton, setTankerDataIButton] = useState('')
  const [messageTankerError, setMessageTankerError] = useState('')

  const resetTankerIButton = () => {
    setTankerDataIButton('')
    setTankerIButton('')
    setMessageTankerError('')
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      tankerIButtonRef.current &&
      !tankerIButtonRef.current.contains(event.target as Node)
    ) {
      tankerIButtonRef.current.focus()
    }
  }

  useEffect(() => {
    // function validate tanker
    if (tankerDataIButton.length !== 16) return
    setTankerIButton(tankerDataIButton)
    setMessageTankerError('')
    const validateTanker = async () => {
      const payloadTanker: IValidateTankerData = {
        termID: '1002',
        personnelIButton,
        tankerIButton: tankerDataIButton,
      }
      const data = await mutateValidateTanker(payloadTanker)
      if (data.message) {
        setMessageTankerError(data?.message)
      } else {
        setStep(data.result.step)
      }
      setTankerDataIButton('')
    }
    validateTanker()
  }, [tankerDataIButton])

  useEffect(() => {
    if (tankerIButtonRef.current) {
      tankerIButtonRef.current.focus()
    }
  }, [tankerIButtonRef.current])

  useEffect(() => {
    document.addEventListener('contextmenu', handleClickOutside)
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('contextmenu', handleClickOutside)
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <div className="flex flex-col items-center w-full h-full justify-center gap-y-10 p-20 z-10 absolute">
      <img src="/images/ExxonMobilLogoColor2x.png" />
      <p className="text-4xl text-white m-0">
        Please scan
        <span className="font-bold text-standard-cyan mx-2">Truck iButton</span>
        OR type in iButton ID
      </p>
      <p className="text-xl text-red-500 m-0 h-7">{messageTankerError}</p>
      <input
        ref={tankerIButtonRef}
        type="text"
        className="max-w-md h-[80px] text-4xl px-4 ring-4 ring-blue-400 focus:bg-blue-100"
        placeholder="Truck IButton"
        maxLength={16}
        onChange={(event) => {
          setTankerDataIButton(
            extractOnlyAlphanumericCharacters(event.target.value)
          )
        }}
        value={tankerDataIButton}
      />
      <div className="flex items-center space-x-2">
        <button
          className="em-c-btn"
          onClick={() => {
            setIsGoToHome(true)
          }}
        >
          Go to Home
        </button>
        <button
          className="em-c-btn"
          onClick={() => {
            resetTankerIButton()
          }}
        >
          <span className="em-c-btn__text">Reset</span>
        </button>
      </div>
    </div>
  )
}

export default TankerIButton
