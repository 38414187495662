import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import msalPublicClient from '@/auth/authConfig'

import { api } from '@/services/apiClient'
import { queryClient } from '@/services/queryClient'
import { GenericResponseBody } from '@/services/types'

import { useRoleMatrixStore } from '@/stores'

import { handleQueryError } from '@/utils'

interface GetRoleScreenMappingRequestParams {
  role: string
  termID: string
}

export async function refetchRoleScreenMapping(isUpdated = false) {
  const account = msalPublicClient.getActiveAccount()

  if (!account?.idTokenClaims?.roles?.length) {
    throw new Error('User does not have any roles')
  }

  const refetch = async () => {
    const data = await queryClient.fetchQuery<unknown, unknown, ScreenList[]>(
      getRoleScreenMappingOptions({
        role: account?.idTokenClaims?.roles?.[0] || '',
        // role: 'Admin',
        termID: '1002',
      })
    )

    const { setRoleMatrix } = useRoleMatrixStore.getState()
    setRoleMatrix(data)
    return data
  }

  if (isUpdated) {
    return await refetch()
  }

  if (Object.keys(useRoleMatrixStore.getState()).length <= 1) {
    return await refetch()
  }
}

export function getRoleScreenMappingOptions(
  params: GetRoleScreenMappingRequestParams
) {
  return queryOptions<unknown, unknown, ScreenList[]>({
    queryKey: ['roleScreenMapping'],
    queryFn: async () => {
      const { data } = await api.get<GenericResponseBody<ScreenList[]>>(
        `/RoleScreenMapping/GetRoleScreenMapping`,
        { params }
      )
      if (data?.message) {
        throw new Error(data.message)
      }
      return data?.result
    },
  })
}

interface GetAllRoleScreenMappingResponseBody {
  role: string
  screenList: ScreenList[]
}

export function useGetAllRoleScreenMapping(
  params: Pick<GetRoleScreenMappingRequestParams, 'termID'>
) {
  return useQuery<unknown, unknown, GetAllRoleScreenMappingResponseBody[]>({
    queryKey: ['allRoleScreenMapping'],
    queryFn: async () => {
      try {
        const { data } = await api.get<
          GenericResponseBody<GetAllRoleScreenMappingResponseBody[]>
        >(`/RoleScreenMapping/GetAllRoleScreenMapping`, { params })
        if (data?.message) {
          throw new Error(data.message)
        }
        return data?.result
      } catch (e) {
        handleQueryError(e)
      }
    },
  })
}

interface AddRoleRequestBody {
  role: string
  screenList: ScreenList[]
}

export interface ScreenList {
  mapID: number
  screenName: string
  canRead: boolean | null
  canEdit: boolean | null
  canCreate: boolean | null
  canDelete: boolean | null
}

interface UseAddRoleOptions {
  body: AddRoleRequestBody
  params: Pick<GetRoleScreenMappingRequestParams, 'termID'>
}

export function useAddRole(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseAddRoleOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post<GenericResponseBody>(
        `/RoleScreenMapping/AddRole`,
        body,
        { params }
      )

      return data
    },
    onSuccess: async (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      await queryClient.invalidateQueries({
        queryKey: ['allRoleScreenMapping'],
      })
      await refetchRoleScreenMapping(true)
    },
  })
}

interface EditRoleRequestBody {
  role: string
  screenList: ScreenList[]
}

interface UseEditRoleOptions {
  body: EditRoleRequestBody
  params: Pick<GetRoleScreenMappingRequestParams, 'termID'>
}

export function useEditRole(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseEditRoleOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post<GenericResponseBody>(
        `/RoleScreenMapping/EditRole`,
        body,
        { params }
      )
      return data
    },
    onSuccess: async (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      await queryClient.invalidateQueries({
        queryKey: ['allRoleScreenMapping'],
      })
      await refetchRoleScreenMapping(true)
    },
  })
}

export function useDeleteRole(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    GetRoleScreenMappingRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete<GenericResponseBody>(
        `/RoleScreenMapping/DeleteRole`,
        { params }
      )
      return data
    },
    onSuccess: async (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      await queryClient.invalidateQueries({
        queryKey: ['allRoleScreenMapping'],
      })
      await refetchRoleScreenMapping(true)
    },
  })
}
