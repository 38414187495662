import { Layout } from '@/components'

import TankerMaster from '@/features/master/tanker'

const TankerMasterPage = () => {
  return (
    <Layout>
      <TankerMaster />
    </Layout>
  )
}

export default TankerMasterPage
