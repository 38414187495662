import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IShipmentDetail, useShipmentMasterStore } from '@/stores'

import ShipmentChildForm from '../forms/child'

const EditShipmentChildModal: FC = () => {
  const {
    editShipmentChild,
    selectedShipmentChild,
    setToggleEditShipmentChildModal,
  } = useShipmentMasterStore()
  const methods = useForm<IShipmentDetail>({
    mode: 'onBlur',
    values: {
      ...selectedShipmentChild,
      shipToCustomerID: null,
      soldToCustomerID: null,
    } as IShipmentDetail,
  })

  const handleClose = () => {
    setToggleEditShipmentChildModal(false)
  }

  const onSubmit = (data: IShipmentDetail) => {
    editShipmentChild(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a shipment: Order"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Edit"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <ShipmentChildForm />
      </FormModal>
    </FormProvider>
  )
}

export default EditShipmentChildModal
