import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { CarrierAutoComplete } from '@/components'

import { ITankerInformation } from '@/stores'

import Select from '@/standard/Select'
import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

import {
  extractOnlyAlphanumericCharacters,
  parseStringToBoolean,
} from '@/utils'

interface TankerMasterFormProps {
  isEdit?: boolean
}

const TankerMasterForm: FC<TankerMasterFormProps> = ({ isEdit }) => {
  const { formState, setValue, register } = useFormContext<ITankerInformation>()

  const tankerIButtonState = isEdit
    ? 'em-is-disabled'
    : formState.errors.tankerIButton && 'em-has-error'
  const tankerCodeState = formState.errors.tankerCode && 'em-has-error'
  const licensePlateState = formState.errors.licensePlate && 'em-has-error'
  const isTopLoadState = formState.errors.isTopLoad && 'em-has-error'
  const isWeightState = formState.errors.isWeight && 'em-has-error'
  const isBorrowState = formState.errors.isBorrow && 'em-has-error'

  const handleIButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(
      'tankerIButton',
      extractOnlyAlphanumericCharacters(newValue).slice(0, 16)
    )
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-2 lg:gap-4">
        <TextInput
          id="tankerIButton"
          label="Tanker iButton"
          state={tankerIButtonState}
          required
          maxLength={16}
          note={formState.errors.tankerIButton?.message}
          {...register('tankerIButton', {
            required: {
              value: true,
              message: 'Tanker iButton is required',
            },
            ...(isEdit
              ? {}
              : {
                  pattern: {
                    value: /^[a-zA-Z0-9]{16}$/,
                    message: 'Tanker iButton must be 16 characters',
                  },
                }),
            onChange: handleIButtonChange,
          })}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <TextInput
          id="tankerCode"
          label="Tanker Code"
          state={tankerCodeState}
          required
          maxLength={20}
          note={formState.errors.tankerCode?.message}
          {...register('tankerCode', {
            required: {
              value: true,
              message: 'Tanker Code is required',
            },
          })}
        />
        <TextInput
          id="licensePlate"
          label="License Plate"
          state={licensePlateState}
          required
          maxLength={50}
          note={formState.errors.licensePlate?.message}
          {...register('licensePlate', {
            required: {
              value: true,
              message: 'License Plate is required',
            },
          })}
        />
      </div>
      <div className="grid grid-cols-1 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <CarrierAutoComplete isRequired />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <Select
          label="Is Top Load"
          id="isTopLoad"
          selectOptionalClass="w-full"
          state={isTopLoadState}
          note={formState.errors.isTopLoad?.message}
          required
          {...register('isTopLoad', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select
          label="Is Weight"
          id="isWeight"
          selectOptionalClass="w-full"
          state={isWeightState}
          note={formState.errors.isWeight?.message}
          required
          {...register('isWeight', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select
          label="Is Borrow"
          id="isBorrow"
          selectOptionalClass="w-full"
          state={isBorrowState}
          note={formState.errors.isBorrow?.message}
          required
          {...register('isBorrow', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </div>
      <div className="grid grid-cols-1 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <TextArea
          id="remark"
          label="Remark"
          textareaOptionalClass="!h-[6rem]"
          maxLength={200}
          {...register('remark')}
        />
      </div>
    </>
  )
}

export default TankerMasterForm
