import ConditionalWrapper from 'components/Shared/ConditionalWrapper'
import {
  Children,
  ReactNode,
  cloneElement,
  isValidElement,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

interface TableRowProps {
  type?: 'em-c-table--condensed' | 'em-c-table--striped'
  isSelectable?: boolean
  children?: ReactNode
  optionalClass?: string
  tdOptionalClass?: string
}

const TableRow = ({
  type,
  isSelectable,
  children,
  optionalClass,
  tdOptionalClass,
}: TableRowProps) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <tr
      className={twMerge(
        'em-c-table__row',
        optionalClass,
        type,
        isSelectable && 'em-js-table-row-selectable',
        isActive && 'em-is-active'
      )}
      onClick={() => {
        if (isSelectable) setIsActive(!isActive)
      }}
    >
      {Children.toArray(children).map((child, index) => {
        return (
          <ConditionalWrapper
            key={index}
            condition
            wrapper={(children) => (
              <td
                className={twMerge(
                  'em-c-table__cell em-js-cell em-js-cell-editable',
                  tdOptionalClass
                )}
              >
                {children}
              </td>
            )}
          >
            <>{isValidElement(child) ? cloneElement(child) : child}</>
          </ConditionalWrapper>
        )
      })}
    </tr>
  )
}

export default TableRow
