import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { DrawerProdGroupAutoComplete } from '@/components'

import { IDrawerProdInformation } from '@/services/api/drawerProd'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

interface DrawerProductFormProps {
  isLoading?: boolean
  isEdit?: boolean
}

const DrawerProductForm: FC<DrawerProductFormProps> = ({
  isLoading,
  isEdit,
}) => {
  const { formState, register } = useFormContext<IDrawerProdInformation>()

  const drawerProdCodeState =
    isLoading || isEdit
      ? 'em-is-disabled'
      : formState.errors.drawerProdCode && 'em-has-error'
  const drawerProdNameState = isLoading
    ? 'em-is-disabled'
    : formState.errors.drawerProdName && 'em-has-error'
  const remarkState = isLoading ? 'em-is-disabled' : undefined

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4">
        <TextInput
          id="drawerProdCode"
          label="Drawer Product Code"
          state={drawerProdCodeState}
          maxLength={20}
          required
          note={formState.errors.drawerProdCode?.message}
          {...register('drawerProdCode', {
            required: {
              value: true,
              message: 'Drawer Product Code is required',
            },
          })}
        />
        <TextInput
          id="drawerProdName"
          label="Drawer Product Name"
          state={drawerProdNameState}
          maxLength={50}
          required
          note={formState.errors.drawerProdName?.message}
          {...register('drawerProdName', {
            required: {
              value: true,
              message: 'Drawer Product Name is required',
            },
          })}
        />
        <DrawerProdGroupAutoComplete isDisabled={isLoading} isRequired />
      </div>
      <div className="grid grid-cols-1 gap-2 lg:gap-4 mt-2 lg:mt-4">
        <TextArea
          id="remark"
          label="Remark"
          state={remarkState}
          textareaOptionalClass="!h-[6rem]"
          maxLength={200}
          {...register('remark')}
        />
      </div>
    </>
  )
}

export default DrawerProductForm
