import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteRole } from '@/services/api/roleMatrix'

import { useRoleModalStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const RoleMatrixDeleteModal: FC = () => {
  const { setCurrentRole, setToggleDeleteModal, currentRole } =
    useRoleModalStore()

  const handleClose = () => {
    setCurrentRole('')
    setToggleDeleteModal(false)
  }

  const { mutate, isPending } = useDeleteRole(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', role: currentRole })
  }

  return (
    <DeleteModal
      title="Delete a role"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>Role: {currentRole}</BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default RoleMatrixDeleteModal
