import { Layout } from '@/components'

import PersonnelMaster from '@/features/master/personnel'

const PersonnelMasterPage = () => {
  return (
    <Layout>
      <PersonnelMaster />
    </Layout>
  )
}

export default PersonnelMasterPage
