import {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import {
  ISearchBayResult,
  useGetBayForActiveFunction,
  useUpdateBayStatus,
} from '@/services/api/bayMaster'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'
import Select from '@/standard/Select'

interface ManageMultipleBaysModalProps {
  onClose?: () => void
}

const ManageMultipleBaysModal: FC<ManageMultipleBaysModalProps> = ({
  onClose,
}) => {
  const { data, isLoading } = useGetBayForActiveFunction({ termID: '1002' })
  const { mutate, isPending } = useUpdateBayStatus(onClose)
  const [selectedItemsBox1, setSelectedItemsBox1] = useState<string[]>([])
  const [selectedItemsBox2, setSelectedItemsBox2] = useState<string[]>([])

  const [activeItems, setActiveItems] = useState<ISearchBayResult[]>([]) // replace this with your actual items
  const [inactiveItems, setInactiveItems] = useState<ISearchBayResult[]>([])

  const handleSelectChange = (
    e: ChangeEvent<HTMLSelectElement>,
    setSelectedItems: Dispatch<SetStateAction<string[]>>
  ) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    )
    setSelectedItems(selectedOptions)
  }

  const moveFromLeftToRight = () => {
    const newFromItems = activeItems.filter(
      (item) => !selectedItemsBox1.includes(`${item.bayID}`)
    )
    const selectedItems = activeItems.filter((item) =>
      selectedItemsBox1.includes(`${item.bayID}`)
    )

    const newToItems = [...inactiveItems, ...selectedItems]
    setActiveItems(newFromItems)
    setInactiveItems(newToItems)
    setSelectedItemsBox1([])
  }

  const moveFromRightToLeft = () => {
    const newFromItems = inactiveItems.filter(
      (item) => !selectedItemsBox2.includes(`${item.bayID}`)
    )
    const selectedItems = inactiveItems.filter((item) =>
      selectedItemsBox2.includes(`${item.bayID}`)
    )
    const newToItems = [...activeItems, ...selectedItems]
    setActiveItems(newToItems)
    setInactiveItems(newFromItems)
    setSelectedItemsBox2([])
  }

  const moveAllItems = (
    fromItems: ISearchBayResult[],
    toItems: ISearchBayResult[],
    setFromItems: Dispatch<SetStateAction<ISearchBayResult[]>>,
    setToItems: Dispatch<SetStateAction<ISearchBayResult[]>>
  ) => {
    const newFromItems: ISearchBayResult[] = []
    const newToItems = [...fromItems, ...toItems]
    setFromItems(newFromItems)
    setToItems(newToItems)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const activeBays = activeItems.map((item) => ({
      bayID: item.bayID,
      isActive: true,
    }))
    const inactiveBays = inactiveItems.map((item) => ({
      bayID: item.bayID,
      isActive: false,
    }))
    mutate({
      params: { termID: '1002' },
      body: [...activeBays, ...inactiveBays],
    })
  }

  useEffect(() => {
    if (!data) return
    setActiveItems(data.result?.filter((bay) => bay.isActive))
    setInactiveItems(data.result?.filter((bay) => !bay.isActive))
  }, [data?.result])

  return (
    <form autoComplete="off" onReset={onClose} onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader
          title="Manage multiple bays"
          onClose={onClose}
          optionalClass="!mb-[0]"
        />
        <div className="overflow-y-auto">
          <ModalBody>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-4">
              <h3>Active</h3>
              <h3>Inactive</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-4 mt-4">
              <Select
                multiple
                value={selectedItemsBox1}
                state={isLoading ? 'em-is-disabled' : undefined}
                bodyOptionalClass="h-full"
                selectOptionalClass="!py-[0] w-full h-full lg:!h-[20rem]"
                onChange={(e) => handleSelectChange(e, setSelectedItemsBox1)}
                onFocus={() => setSelectedItemsBox2([])}
              >
                {activeItems.map((item) => (
                  <option
                    className="!px-[1rem]"
                    key={item.bayID}
                    value={item.bayID}
                  >
                    {item.bay}
                  </option>
                ))}
              </Select>
              <Select
                multiple
                value={selectedItemsBox2}
                state={isLoading ? 'em-is-disabled' : undefined}
                bodyOptionalClass="h-full"
                selectOptionalClass="!py-[0] w-full h-full lg:!h-[20rem]"
                onChange={(e) => handleSelectChange(e, setSelectedItemsBox2)}
                onFocus={() => setSelectedItemsBox1([])}
              >
                {inactiveItems.map((item) => (
                  <option
                    className="!px-[1rem]"
                    key={item.bayID}
                    value={item.bayID}
                  >
                    {item.bay}
                  </option>
                ))}
              </Select>
            </div>
            <ButtonGroup optionalClass="!justify-center mt-6">
              <Button
                label="<<"
                disabled={inactiveItems.length === 0}
                title="Move all left"
                typeAttribute="button"
                onClick={() =>
                  moveAllItems(
                    inactiveItems,
                    activeItems,
                    setInactiveItems,
                    setActiveItems
                  )
                }
              />
              <Button
                label="<"
                disabled={selectedItemsBox2.length === 0}
                title="Move right to left"
                typeAttribute="button"
                onClick={moveFromRightToLeft}
              />
              <Button
                label=">"
                disabled={selectedItemsBox1.length === 0}
                title="Move left to right"
                typeAttribute="button"
                onClick={moveFromLeftToRight}
              />
              <Button
                label=">>"
                disabled={activeItems.length === 0}
                title="Move all right"
                typeAttribute="button"
                onClick={() =>
                  moveAllItems(
                    activeItems,
                    inactiveItems,
                    setActiveItems,
                    setInactiveItems
                  )
                }
              />
            </ButtonGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                label="Submit"
                type="em-c-btn--primary"
                typeAttribute="submit"
                {...(isPending
                  ? {
                      isIconOnlyButton: true,
                      iconName: 'refresh',
                      isLoading: true,
                      disabled: true,
                    }
                  : {
                      label: 'Submit',
                    })}
              />
              <Button label="Cancel" typeAttribute="reset" />
            </ButtonGroup>
          </ModalFooter>
        </div>
      </Modal>
    </form>
  )
}

export default ManageMultipleBaysModal
