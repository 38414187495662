import usePaginationLabel from '@/hooks/usePaginationLabel'
import ToolbarWithPagination from 'components/Toolbars/ToolbarWithPagination'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import LoadingIndicator from '@/standard/LoadingIndicator'

interface TableProps {
  type?: 'em-c-table--condensed' | 'em-c-table--striped'
  hasPagination?: boolean
  optionalClass?: string
  bodyInnerOptionalClass?: string
  tableOptionalClass?: string
  children?: React.ReactNode
  onChangePage?: (page: number) => void
  currentPage?: number
  numberOfPages?: number
  rowsPerPage?: number
  isLoading?: boolean
  total?: number
}

const Table = ({
  type,
  children,
  optionalClass,
  bodyInnerOptionalClass,
  tableOptionalClass,
  hasPagination,
  onChangePage,
  currentPage = 0,
  numberOfPages = 0,
  rowsPerPage = 0,
  total = 0,
  isLoading,
  ...props
}: TableProps) => {
  const paginationLabel = usePaginationLabel({
    isLoading: isLoading,
    currentPage: currentPage,
    rowsPerPage: rowsPerPage,
    total: total,
  })

  return (
    <div className={twMerge('em-c-table-object', optionalClass)} {...props}>
      <div className={twMerge('em-c-table-object__body flex-1 min-h-0')}>
        <div
          className={twMerge(
            'em-c-table-object__body-inner h-full !overflow-auto',
            bodyInnerOptionalClass && bodyInnerOptionalClass
          )}
        >
          <table
            className={twMerge(
              'em-c-table relative',
              tableOptionalClass,
              type && type
            )}
            role="table"
          >
            {isLoading ? (
              <caption>
                <LoadingIndicator isFixed />
              </caption>
            ) : (
              children
            )}
          </table>
        </div>
      </div>
      {hasPagination === true && (
        <div className="em-c-table-object__footer">
          <ToolbarWithPagination
            label={paginationLabel}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onChangePage={onChangePage}
          />
        </div>
      )}
    </div>
  )
}

export default Table
