import { useEffect, useRef, useState } from 'react'

import { PhoneNumberPad } from '@/components'

import {
  IValidateDocument,
  qRegistrationService,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

const ValidateDocument = () => {
  const {
    personnelIButton,
    tankerIButton,
    setStep,
    setDocument,
    setSummaryData,
    queueData,
    orderList,
    setOrderList,
    setIsGoToHome,
  } = useQueueRegistrationStore()

  // Document
  const docInputRef = useRef<HTMLInputElement>(null)
  const [documentData, setDocumentData] = useState('')
  const [messageDocError, setMessageDocError] = useState('')

  const { mutateAsync: mutateValidateDocument, isPending } =
    qRegistrationService.useValidateDocument()

  // function validate document
  const validateDocument = async () => {
    setDocument(documentData)
    setMessageDocError('')

    if (orderList.length && orderList.includes(documentData)) {
      setMessageDocError(`Order: ${documentData} is duplicated`)
      return
    }

    const documentPayload: IValidateDocument = {
      termID: '1002',
      personnelIButton,
      tankerIButton: tankerIButton,
      docNr: documentData,
      details: {
        contractList: queueData?.contractList?.length
          ? queueData?.contractList
          : [],
        shipmentList: queueData?.shipmentList?.length
          ? queueData?.shipmentList
          : [],
      },
      isUpdateContract: true, //disable duplicate order checking
    }
    const data = await mutateValidateDocument(documentPayload)

    if (data.message) {
      setMessageDocError(data?.message)
    } else {
      setOrderList([...orderList, documentData])
      setSummaryData(data?.result)
      setStep(data.result.step)
    }
  }

  const typingDocumentNumber = (value: string) => {
    if (documentData.length >= 10) return
    setDocumentData(documentData.concat(value))
  }

  const onBackSpace = () => {
    if (!documentData.length) return
    setDocumentData(documentData.slice(0, -1))
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      docInputRef.current &&
      !docInputRef.current.contains(event.target as Node)
    ) {
      docInputRef.current.focus()
    }
  }

  useEffect(() => {
    if (docInputRef.current) {
      docInputRef.current.focus()
    }
  }, [docInputRef.current])

  useEffect(() => {
    document.addEventListener('contextmenu', handleClickOutside)
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('contextmenu', handleClickOutside)
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  return (
    <div className="flex flex-col items-center w-full h-full justify-center gap-y-3 p-20 z-10 absolute">
      <div className="">
        <img src="/images/ExxonMobilLogoColor2x.png" className="" />
      </div>
      <p className="text-4xl text-white m-0 text-center">
        Please type in your document <br />
        (If you have more than 6 digits, put last 6 digits)
      </p>
      <p className="text-xl text-red-500 m-0 h-7">
        {messageDocError && messageDocError}
      </p>
      <input
        ref={docInputRef}
        type="tel"
        inputMode="none"
        className="max-w-md h-[80px] text-4xl px-4 ring-4 ring-blue-400 focus:bg-blue-100"
        placeholder="Shipment/Contract"
        maxLength={10}
        onChange={(event) => {
          setDocumentData(event.target.value)
        }}
        value={documentData}
      />
      <PhoneNumberPad
        onBackSpace={onBackSpace}
        onConfirm={validateDocument}
        typingPhoneNumber={typingDocumentNumber}
        setIsGoToHome={setIsGoToHome}
        isConfirmDisabled={!documentData}
        isPending={isPending}
      />
    </div>
  )
}

export default ValidateDocument
