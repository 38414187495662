export interface ISelectData {
  label: string
  value: string
  [key: string]: any
}

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  id: string
  name: string
  data: ISelectData[]
}

const CustomSelectQuantity = (props: Props) => {
  return (
    <div
      className={`!m-0 ${props.disabled ? 'em-is-disabled !bg-gray-200' : ''}`}
    >
      <div>
        <select {...props}>
          <option value="0">No. of compartments</option>
          {props.data.map((option: ISelectData, index: number) => {
            return (
              <option key={`${index}${option.label}`} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}
export default CustomSelectQuantity
