import { FC } from 'react'
import DataGrid from 'react-data-grid'

import { TableButtonActions } from '@/components'

import { IGetBayResultDetail } from '@/services/api/bayMaster'

import { useBayMasterStore, useRoleMatrixStore } from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'

import { formatDate, makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IGetBayResultDetail>([
  {
    key: 'carrierCode',
    name: 'Carrier Code',
  },
  {
    key: 'drawerCode',
    name: 'Drawer Code',
  },
  {
    key: 'dedicatedStart',
    name: 'Dedicated Start',
    renderCell({ row }) {
      return row.dedicatedStart ? formatDate(row.dedicatedStart) : '-'
    },
  },
  {
    key: 'dedicatedEnd',
    name: 'Dedicated End',
    renderCell({ row }) {
      return row.dedicatedEnd ? formatDate(row.dedicatedEnd) : '-'
    },
  },
  {
    key: 'periodStartString',
    name: 'Period Start',
  },
  {
    key: 'periodEndString',
    name: 'Period End',
  },
  {
    key: 'actions',
    name: 'Actions',
    renderCell: ({ row, rowIdx }) => {
      const { bayMaster } = useRoleMatrixStore.getState()
      const {
        setToggleEditBayChildModal,
        setSelectedBayChild,
        setSelectedBayChildIndex,
        setToggleConfirmDeleteChildModal,
      } = useBayMasterStore.getState()
      const { canEdit, canDelete } = bayMaster

      const handleDelete = () => {
        setToggleConfirmDeleteChildModal(true)
        setSelectedBayChild(row)
        setSelectedBayChildIndex(rowIdx)
      }

      const handleEdit = () => {
        setToggleEditBayChildModal(true)
        setSelectedBayChild(row)
        setSelectedBayChildIndex(rowIdx)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface DedicatedBayTableProps {
  dedicatedBays: IGetBayResultDetail[]
  isLoading?: boolean
}

const DedicatedBayTable: FC<DedicatedBayTableProps> = ({
  dedicatedBays,
  isLoading,
}) => {
  return !isLoading && dedicatedBays ? (
    <DataGrid
      className="rdg-light h-auto"
      columns={columns}
      rows={dedicatedBays}
      rowHeight={45}
      headerRowHeight={30}
    />
  ) : (
    <LoadingIndicator textOnly />
  )
}

export default DedicatedBayTable
