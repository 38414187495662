import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import {
  ISearchBayResponseBody,
  ISearchBayResult,
} from '@/services/api/bayMaster'

import { useBayMasterStore, useRoleMatrixStore } from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<ISearchBayResult>([
  {
    key: 'bay',
    name: 'Bay',
  },
  {
    key: 'qCnt',
    name: 'Q Count',
  },
  {
    key: 'bayCapacity',
    name: 'Bay Capacity',
  },
  {
    key: 'displayIsTopLoad',
    name: 'Is Top Load',
  },
  {
    key: 'displayIsActive',
    name: 'Is Active',
  },
  {
    key: 'displayIsDedicate',
    name: 'Is Dedicate',
  },
  {
    key: 'displayCreatedDate',
    name: 'Created Date',
  },
  {
    key: 'createdBy',
    name: 'Created By',
  },
  {
    key: 'displayModifiedDate',
    name: 'Modified Date',
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
  },
  {
    key: 'actions',
    name: 'Actions',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { bayMaster } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setCurrentBay,
        setToggleEditBayModal,
      } = useBayMasterStore.getState()
      const { canEdit, canDelete } = bayMaster

      const handleDelete = () => {
        setCurrentBay(row)
        setToggleConfirmDeleteModal(true)
      }

      const handleEdit = () => {
        setCurrentBay(row)
        setToggleEditBayModal(true)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface BayMasterTableProps {
  currentPage: number
  onChangePage: (page: number) => void
  bays?: ISearchBayResponseBody['result']
  isLoading: boolean
}

const BayMasterTable: FC<BayMasterTableProps> = ({
  currentPage = 0,
  onChangePage,
  bays,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage: currentPage,
    rowsPerPage,
    total: bays?.total || 0,
  })

  return (
    <>
      {bays?.result ? (
        <>
          <DataGrid
            columns={columns}
            rows={bays.result}
            className="h-full rdg-light"
            rowHeight={45}
            headerRowHeight={30}
          />
          <ToolbarWithPagination
            optionalClass={twMerge(
              'text-[0.8rem] !px-[0.8rem]',
              bays?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
            )}
            label={label}
            currentPage={currentPage}
            numberOfPages={Math.ceil((bays?.total || 0) / rowsPerPage)}
            onChangePage={onChangePage}
          />
        </>
      ) : (
        <LoadingIndicator isFixed />
      )}
    </>
  )
}

export default BayMasterTable
