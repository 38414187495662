import { FC } from 'react'
import DataGrid from 'react-data-grid'

import { TableButtonActions } from '@/components'

import { IBomChildList } from '@/services/api/drawerProd'

import { useDrawerProductStore, useRoleMatrixStore } from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IBomChildList>([
  {
    key: 'baseProdCode',
    name: 'Base Product Code',
  },
  {
    key: 'actions',
    name: 'Actions',
    renderCell: ({ rowIdx, row }) => {
      const { drawerProductMaster } = useRoleMatrixStore.getState()
      const {
        setToggleEditBomChildModal,
        setToggleConfirmDeleteBomChildModal,
        setBomChildIndex,
        setSelectedBomChild,
      } = useDrawerProductStore.getState()
      const { canEdit, canDelete } = drawerProductMaster

      const handleDelete = () => {
        setToggleConfirmDeleteBomChildModal(true)
        setBomChildIndex(rowIdx)
        setSelectedBomChild(row)
      }

      const handleEdit = () => {
        setToggleEditBomChildModal(true)
        setBomChildIndex(rowIdx)
        setSelectedBomChild(row)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface BomTableProps {
  rows: IBomChildList[]
  isLoading?: boolean
}

const BomTable: FC<BomTableProps> = ({ rows, isLoading }) => {
  return !isLoading && rows ? (
    <DataGrid
      className="rdg-light h-auto"
      columns={columns}
      rows={rows}
      rowHeight={45}
      headerRowHeight={30}
    />
  ) : (
    <LoadingIndicator textOnly />
  )
}

export default BomTable
