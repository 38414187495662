import { FC } from 'react'

import { usePersonnelMasterStore } from '@/stores'

import PersonnelMasterAddModal from './addModal'
import PersonnelMasterDeleteModal from './deleteModal'
import PersonnelEditModal from './editModal'
import Header from './header'

const PersonnelMasterComponent: FC = () => {
  const {
    toggleAddPersonnelModal,
    toggleConfirmDeleteModal,
    toggleEditPersonnelModal,
  } = usePersonnelMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddPersonnelModal && <PersonnelMasterAddModal />}
      {toggleConfirmDeleteModal && <PersonnelMasterDeleteModal />}
      {toggleEditPersonnelModal && <PersonnelEditModal />}
      <Header />
    </div>
  )
}
export default PersonnelMasterComponent
