import { useQuery } from '@tanstack/react-query'

import { api } from '@/services/apiClient'
import { GenericResponseBody } from '@/services/types'

import { createDownloadLink, formatISODate, handleQueryError } from '@/utils'

export interface SearchErrorReportRequestParams {
  termId: string
  LoadId?: string
  DocumentNr?: string
  LicensePlate?: string
  startdate?: string
  enddate?: string
  page: number
}

export interface ErrorReportResponseBody {
  total: number
  result: GetErrorReportResponseData[]
}

export interface GetErrorReportResponseData {
  loadId: number
  documentNr: number
  licensePlate: string
  errorDescription: string
  errorType: string
  createdDate: string
  displayCreatedDate: string
}

export function useErrorReport(params: SearchErrorReportRequestParams) {
  return useQuery<unknown, unknown, ErrorReportResponseBody>({
    queryKey: ['mismatchError', params.page],
    queryFn: async () => {
      try {
        const { data } = await api.get<
          GenericResponseBody<ErrorReportResponseBody>
        >('/MismatchBayReport/Report', { params })
        if (data?.message) {
          throw new Error(data.message)
        }
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useErrorReportExcel(params: SearchErrorReportRequestParams) {
  return useQuery<unknown, unknown, void>({
    queryKey: ['mismatchErrorExcel'],
    enabled: false,
    queryFn: async () => {
      try {
        const { data } = await api.get<Blob>('/MismatchBayReport/Export', {
          responseType: 'blob',
          params,
        })
        createDownloadLink(
          data,
          `error_report_${formatISODate(new Date())}`,
          'xlsx'
        )
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}
