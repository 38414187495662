import { FC } from 'react'

import { useShipmentMasterStore } from '@/stores'

import Header from './header'
import AddShipmentChildModal from './modals/addChildModal'
import AddShipmentModal from './modals/addModal'
import DeleteShipmentChildModal from './modals/deleteChildModal'
import DeleteShipmentModal from './modals/deleteModal'
import EditShipmentChildModal from './modals/editChildModal'
import EditShipmentModal from './modals/editModal'

const ShipmentFeature: FC = () => {
  const {
    toggleAddShipmentModal,
    toggleAddShipmentChildModal,
    toggleEditShipmentChildModal,
    toggleEditShipmentModal,
    toggleConfirmDeleteModal,
    toggleConfirmDeleteChildModal,
  } = useShipmentMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddShipmentModal && <AddShipmentModal />}
      {toggleEditShipmentModal && <EditShipmentModal />}
      {toggleConfirmDeleteModal && <DeleteShipmentModal />}
      {toggleAddShipmentChildModal && <AddShipmentChildModal />}
      {toggleEditShipmentChildModal && <EditShipmentChildModal />}
      {toggleConfirmDeleteChildModal && <DeleteShipmentChildModal />}
      <Header />
    </div>
  )
}
export default ShipmentFeature
