import { AxiosError } from 'axios'

import { GenericErrorResponse } from '@/services/queryClient'

import { useErrorModalStore } from '@/stores'

export function handleQueryError(
  error: Error | AxiosError<GenericErrorResponse>
) {
  const { setMessage, setIsOpen } = useErrorModalStore.getState()
  if (error instanceof AxiosError) {
    if (error.isAxiosError) console.info(error.toJSON())
    setMessage(error.response?.data.title || error.message)
    setIsOpen(true)
  } else {
    setMessage(error.message)
    setIsOpen(true)
  }
}
