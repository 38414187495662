import { create } from 'zustand'

export interface IDrawerProdGroupInformation {
  drawerProdGroupID: number
  termID: string
  drawerProdGroupCode: string
  drawerProdGroupName: string
  remark: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayCreatedDate: Date
  displayModifiedDate: Date
  displayRemark: string
}

interface DrawerProdGroupMasterState {
  currentDrawerProdGroup: IDrawerProdGroupInformation | undefined
  setCurrentDrawerProdGroup: (
    value: IDrawerProdGroupInformation | undefined
  ) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddDrawerProdGroupModal: boolean
  setToggleAddDrawerProdGroupModal: (value: boolean) => void
  toggleEditDrawerProdGroupModal: boolean
  setToggleEditDrawerProdGroupModal: (value: boolean) => void
}

export const useDrawerProdGroupMasterStore = create<DrawerProdGroupMasterState>(
  (set) => ({
    currentDrawerProdGroup: undefined,
    setCurrentDrawerProdGroup: (value) =>
      set({ currentDrawerProdGroup: value }),
    toggleConfirmDeleteModal: false,
    setToggleConfirmDeleteModal: (value) =>
      set({ toggleConfirmDeleteModal: value }),
    toggleAddDrawerProdGroupModal: false,
    setToggleAddDrawerProdGroupModal: (value) =>
      set({ toggleAddDrawerProdGroupModal: value }),
    toggleEditDrawerProdGroupModal: false,
    setToggleEditDrawerProdGroupModal: (value) =>
      set({ toggleEditDrawerProdGroupModal: value }),
  })
)
