import { FC } from 'react'

import Button from '@/standard/Button'

interface TableButtonActionsProps {
  canEdit: boolean
  canDelete: boolean
  onEdit: () => void
  onDelete: () => void
}

const TableButtonActions: FC<TableButtonActionsProps> = ({
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="flex justify-center items-center h-full gap-4">
      {canEdit && (
        <Button
          isIconOnlyButton
          typeAttribute="button"
          label="Edit"
          iconName="pencil"
          size="em-c-btn--small"
          iconSize="em-c-icon--small"
          onClick={onEdit}
        />
      )}
      {canDelete && (
        <Button
          isIconOnlyButton
          typeAttribute="button"
          label="Delete"
          iconName="trashcan"
          size="em-c-btn--small"
          iconSize="em-c-icon--small"
          onClick={onDelete}
        />
      )}
    </div>
  )
}

export default TableButtonActions
