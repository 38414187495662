import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import BomForm from '@/features/master/drawerProduct/forms/bom'

import { IBomChildList } from '@/services/api/drawerProd'

import { useDrawerProductStore } from '@/stores'

const EditBomModal: FC = () => {
  const { editBomChild, selectedBomChild, setToggleEditBomChildModal } =
    useDrawerProductStore()

  const methods = useForm<IBomChildList>({
    mode: 'onBlur',
    values: selectedBomChild,
  })

  const handleClose = () => {
    setToggleEditBomChildModal(false)
  }

  const onSubmit = (data: IBomChildList) => {
    editBomChild(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a Bom"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Edit"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <BomForm />
      </FormModal>
    </FormProvider>
  )
}

export default EditBomModal
