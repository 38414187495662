import Downshift from 'downshift'
import {
  InputHTMLAttributes,
  MouseEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { FieldValues } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import ClickAwayListener from '@/standard/ClickAwayListener'
import Icon, { IconTypes } from '@/standard/Icon'
import { TextInputState } from '@/standard/TextInput/TextInput'

interface AutoCompleteProps<T extends FieldValues>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  items?: T[]
  itemToString: (item: T | null) => string
  label: string
  filterKey: keyof T
  renderedKey: keyof T
  onChange: (value?: T) => void
  note?: string
  state?: TextInputState
  onClickItem?: MouseEventHandler<HTMLLIElement>
  isAbsolute?: boolean
}

const inputIcon = {
  'em-is-valid': 'circle-check' as IconTypes,
  'em-has-error': 'warning' as IconTypes,
  'em-is-readonly': 'lock' as IconTypes,
  'em-is-disabled': 'ban' as IconTypes,
}

const AutoComplete = forwardRef<
  HTMLInputElement,
  AutoCompleteProps<FieldValues>
>(
  (
    {
      items,
      itemToString,
      label,
      filterKey,
      onChange,
      renderedKey,
      note,
      state,
      required,
      id,
      onClickItem,
      isAbsolute,
      ...rest
    },
    outerRef
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [inputWidth, setInputWidth] = useState(0)
    const randomId = useId()

    const handleSelect = useCallback(
      (selection: FieldValues | null) => {
        if (onChange && selection) {
          onChange(selection)
        }
      },
      [onChange]
    )

    const handleChange = useCallback(
      (inputValue: string) => {
        if (!inputValue) {
          onChange()
        }
        if (inputValue) {
          const item = items?.find((item) => item[renderedKey] === inputValue)
          onChange({ [renderedKey]: inputValue, ...item })
        }
      },
      [items, onChange, renderedKey]
    )

    useImperativeHandle(outerRef, () => inputRef.current!, [])

    useEffect(() => {
      if (inputRef.current) {
        setInputWidth(inputRef.current.offsetWidth)
      }
    }, [])

    return (
      <Downshift itemToString={itemToString} onSelect={handleSelect}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getLabelProps,
          getRootProps,
          inputValue,
          highlightedIndex,
          selectedItem,
          isOpen,
          closeMenu,
          openMenu,
        }) => {
          return (
            <ClickAwayListener
              {...getRootProps(
                { onClickAway: closeMenu },
                { suppressRefError: true }
              )}
              className="relative"
            >
              <div className={twMerge('em-c-field !mb-[0]', state)}>
                <label
                  className="em-c-field__label"
                  {...getLabelProps({ htmlFor: id })}
                >
                  {required && (
                    <span aria-hidden="true" className="em-c-field__required">
                      *
                    </span>
                  )}
                  {label}
                </label>
                <div className="em-c-field__body !mb-[0]">
                  <input
                    {...getInputProps({
                      ...rest,
                      onChange: (e) => {
                        const { value } = e.currentTarget
                        handleChange(value)
                      },
                      onClick: () => openMenu(),
                      ref: inputRef,
                    })}
                    id={id}
                    placeholder="Type or click to see options"
                    list="browsers"
                    aria-describedby={`textinput-note-${randomId}`}
                    className="em-c-input em-js-input"
                    disabled={state === 'em-is-disabled'}
                    readOnly={state === 'em-is-readonly'}
                    aria-required={required}
                    required={required}
                  />
                  <Icon
                    name={inputIcon[state as keyof typeof inputIcon]}
                    size="em-c-icon--medium"
                    optionalClass="em-c-field__icon"
                  />
                </div>
                {note && (
                  <div
                    className={twMerge(
                      'em-c-field__note',
                      isAbsolute && 'absolute'
                    )}
                    id={`textinput-note-${randomId}`}
                  >
                    {note}
                  </div>
                )}
              </div>
              <ul
                className={twMerge(
                  `fixed bg-white mt-1 shadow-md max-h-[25vh] overflow-scroll p-0 z-[100]`,
                  !(isOpen && items?.length) && 'hidden'
                )}
                style={{ width: `${inputWidth}px` }}
                {...getMenuProps()}
              >
                {isOpen &&
                  items
                    ?.filter((item) => {
                      return !inputValue
                        ? items
                        : item[filterKey]
                            .toLowerCase()
                            .includes(inputValue?.toLowerCase() as string)
                    })
                    ?.map((item, index) => (
                      <li
                        key={randomId + index}
                        className={twMerge(
                          highlightedIndex === index && 'bg-blue-300',
                          selectedItem === item && 'font-bold',
                          'py-2 px-3 shadow-sm flex flex-col'
                        )}
                        {...getItemProps({
                          item,
                          index,
                          onClick: onClickItem,
                        })}
                      >
                        {itemToString(item)}
                      </li>
                    ))}
              </ul>
            </ClickAwayListener>
          )
        }}
      </Downshift>
    )
  }
)

export default AutoComplete
