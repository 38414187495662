import { FC, Fragment } from 'react'

interface ICascadeBay {
  bays: string[]
}

const CascadeBay: FC<ICascadeBay> = ({ bays }) => {
  return bays.map((bay: string, index: number) => (
    <Fragment key={index}>
      {!!index && '►'}
      {bay}{' '}
    </Fragment>
  ))
}
export default CascadeBay
