import { FC } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'

import { useGetCustomerForDropdown } from '@/services/api/dropdown'

import AutoComplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface CustomerAutoCompleteProps {
  isRequired?: boolean
  customFormValue?: string
  label?: string
  isDisabled?: boolean
}

const CustomerAutoComplete: FC<CustomerAutoCompleteProps> = ({
  customFormValue,
  label = 'Customer',
  isRequired,
  isDisabled,
}) => {
  const { data: customers, isLoading } = useGetCustomerForDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<FieldValues>()

  const customerID = customFormValue ? `${customFormValue}ID` : 'customerID'
  const customerCode = customFormValue
    ? `${customFormValue}Code`
    : 'customerCode'

  const customerState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors[customerCode] && 'em-has-error'

  return (
    <AutoComplete
      state={customerState}
      note={formState.errors?.[customerCode]?.message as string}
      id={customerID}
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={customers}
      label={label}
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors(customerCode)}
      maxLength={20}
      value={watch(customerCode)}
      {...register(customerCode, {
        required: isRequired ? `${label} is required` : false,
        validate: () =>
          watch(customerID) === 0 ? `${label} is not valid` : undefined,
      })}
      onChange={(value) => {
        setValue(customerID, value?.label ? parseNanToZero(value?.value) : null)
        setValue(customerCode, value?.label)
      }}
    />
  )
}

export default CustomerAutoComplete
