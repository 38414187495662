import { useForm } from 'react-hook-form'

import FormModal from '@/components/modals/form'

import DrawerMasterForm from '@/features/master/drawer/form'

import { useAddDrawer } from '@/services/api/drawerMaster'

import { IDrawerInformation, useDrawerMasterStore } from '@/stores'

const DrawerMasterAddModal = () => {
  const { setToggleAddDrawerModal } = useDrawerMasterStore()

  const handleClose = () => {
    setToggleAddDrawerModal(false)
  }

  const { mutate, isPending } = useAddDrawer(handleClose)
  const { register, handleSubmit, formState } = useForm<IDrawerInformation>({
    defaultValues: {
      drawerCode: '',
      drawerName: '',
      remark: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: IDrawerInformation) => {
    mutate({ body: data, params: { termID: '1002' } })
  }

  return (
    <FormModal
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title="Add a drawer"
      confirmLabel="Add"
      isValid={formState.isValid}
      onClose={handleClose}
      isLoading={isPending}
    >
      <DrawerMasterForm formState={formState} register={register} />
    </FormModal>
  )
}

export default DrawerMasterAddModal
