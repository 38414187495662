import React from 'react'
import { twMerge } from 'tailwind-merge'

interface TableHeaderItemProps {
  optionalClass?: string
  children?: React.ReactNode
}

const TableHeaderItem = ({ optionalClass, children }: TableHeaderItemProps) => {
  return (
    <th
      scope="col"
      className={twMerge('em-c-table__header-cell', optionalClass)}
    >
      {children}
    </th>
  )
}

export default TableHeaderItem
