import { format, formatISO } from 'date-fns'

export function formatDate(date?: Date | string) {
  if (!date) return ''
  return format(new Date(date), 'dd/MM/yyyy')
}

export function formatDateForInput(date?: Date | string) {
  if (!date) return ''
  if (typeof date === 'string') date = new Date(date)
  return date.toISOString().split('T')[0]
}

export function formatDateAndTime(date?: Date | string) {
  if (!date) return ''
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

export function formatISODate(date?: Date | string) {
  if (!date) return ''
  if (typeof date === 'string') date = new Date(date)
  return formatISO(date)
}

export function formatDateToLocalDateTime(date?: Date | string) {
  if (!date) return ''
  if (typeof date === 'string') date = new Date(date)
  return format(date, "yyyy-MM-dd'T'kk:mm")
}
