import { FC, ReactNode } from 'react'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

interface RemoveModalProps {
  children: ReactNode
  onClose: () => void
  onDelete: () => void
}

const ConfirmRemoveModal: FC<RemoveModalProps> = ({
  children,
  onClose,
  onDelete,
}): React.ReactElement => {
  return (
    <Modal>
      <ModalHeader
        title="Are you want to remove order ?"
        onClose={onClose}
        isError
      />
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button type="em-c-btn--primary" label="Delete" onClick={onDelete} />
          <Button
            label="Cancel"
            onClick={() => {
              onClose()
            }}
          />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmRemoveModal
