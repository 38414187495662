import React from 'react'

interface BulletedListItemProps {
  children?: React.ReactNode
  optionalClass?: string
}

const BulletedListItem = ({
  children,
  optionalClass,
  ...props
}: BulletedListItemProps) => {
  return (
    <li
      className={`em-c-bulleted-list__item ${
        optionalClass ? optionalClass : ''
      }`}
      {...props}
    >
      {children}
    </li>
  )
}

export default BulletedListItem
