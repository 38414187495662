import { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-use'
import { twMerge } from 'tailwind-merge'

import SideBar from './Sidebar'

interface Props {
  hideSidebar?: boolean
  children?: ReactNode
}

const Layout: FC<Props> = ({ hideSidebar, children }) => {
  const convertString = (str: string) => {
    const words = str.replace(/([A-Z])/g, ' $1').split(' ')
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    )
    return capitalizedWords.join(' ')
  }
  const { pathname } = useLocation()

  const [pageName] = useState(() => {
    if (pathname === '/acknowledgement') return ''
    return pathname ? convertString(pathname.split('/')[1]) : ''
  })

  useEffect(() => {
    if (!pageName) return
    document.title = `QS - ${pageName}`
    return () => {
      document.title = 'Queueing System'
    }
  }, [])

  return (
    <main className="flex h-full">
      {!hideSidebar && (
        <section className="max-w-fit overflow-y-scroll">
          <SideBar />
        </section>
      )}
      <section className="flex-1 h-full flex flex-col relative overflow-auto">
        <div
          className={twMerge(
            'px-8 sticky top-0 shrink-0',
            pathname !== '/acknowledgement' && 'pt-5'
          )}
        >
          <h2>{pageName}</h2>
        </div>
        <div
          className={twMerge(
            pathname === '/acknowledgement'
              ? 'flex-1 min-h-0'
              : 'flex-1 min-h-0 px-8 py-5'
          )}
        >
          {children}
        </div>
      </section>
    </main>
  )
}

export default Layout
