import { Column } from 'react-data-grid'

export function makeDataGridColumns<T>(columns: Column<T>[]): Column<T>[] {
  return columns.map((column) => {
    return {
      resizable: true,
      headerCellClass:
        'em-c-table__header em-c-table__header-row text-center em-c-table--condensed em-c-table__header-cell border-r-[1px] border-white border-solid',
      cellClass: 'em-c-table__body text-center',
      ...column,
    }
  })
}
