import { Layout } from '@/components'

import Shipment from '@/features/master/shipment'

const ShipmentPage = () => {
  return (
    <Layout>
      <Shipment />
    </Layout>
  )
}

export default ShipmentPage
