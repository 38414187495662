import { FC } from 'react'
import DataGrid from 'react-data-grid'

import { TableButtonActions } from '@/components'

import { IContractDetail, useContractStore, useRoleMatrixStore } from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'

import { formatDate, makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IContractDetail>([
  {
    key: 'drawerProdCode',
    name: 'Drawer Product Code',
  },
  {
    key: 'itemValStart',
    name: 'Validity Start',
    renderCell: ({ row }) => formatDate(row.itemValStart),
  },
  {
    key: 'itemValEnd',
    name: 'Validity End',
    renderCell: ({ row }) => formatDate(row.itemValEnd),
  },
  {
    key: 'actions',
    name: 'Actions',
    renderCell: ({ row, rowIdx }) => {
      const { contract } = useRoleMatrixStore.getState()
      const { canDelete, canEdit } = contract
      const {
        setToggleEditContractDetailModal,
        setSelectedContractDetail,
        setSelectedContractDetailIndex,
        setToggleConfirmDeleteDetailModal,
      } = useContractStore.getState()

      const handleDelete = () => {
        setToggleConfirmDeleteDetailModal(true)
        setSelectedContractDetail(row)
        setSelectedContractDetailIndex(rowIdx)
      }

      const handleEdit = () => {
        setToggleEditContractDetailModal(true)
        setSelectedContractDetail(row)
        setSelectedContractDetailIndex(rowIdx)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface ContractDetailTableProps {
  detail?: IContractDetail[]
  isLoading?: boolean
}

const ContractDetailTable: FC<ContractDetailTableProps> = ({
  detail,
  isLoading,
}) => {
  return !isLoading && detail ? (
    <DataGrid
      className="rdg-light h-auto"
      columns={columns}
      rows={detail}
      rowHeight={45}
      headerRowHeight={30}
    />
  ) : (
    <LoadingIndicator textOnly />
  )
}

export default ContractDetailTable
