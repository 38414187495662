import React from 'react'

export interface AccordionBodyProps {
  id: number
  children?: React.ReactNode
  optionalClass?: string
}

const AccordionBody = ({ id, children, optionalClass }: AccordionBodyProps) => {
  return (
    <div
      className={`em-c-accordion__body ${optionalClass ? optionalClass : ''}`}
      id={`accordionContent-${id + 1}`}
      role="region"
      aria-label={children?.toString()}
    >
      {children}
    </div>
  )
}

export default AccordionBody
