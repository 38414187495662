import useAutoReLogin from '@/hooks/useAutoReLogin'
import useFullscreenStatus from '@/hooks/useFullScreen'

import { Layout } from '@/components'

import Acknowledgement from '@/features/queue/acknowledgement'

const AcknowledgementPage = () => {
  const isFullScreen = useFullscreenStatus()
  useAutoReLogin()

  return (
    <Layout hideSidebar={isFullScreen}>
      <Acknowledgement isFullScreen={isFullScreen} />
    </Layout>
  )
}

export default AcknowledgementPage
