import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import FormModal from '@/components/modals/form'

import { ISearchBayResult, useAddBay } from '@/services/api/bayMaster'

import { useBayMasterStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

import { formatISODate } from '@/utils'

import BayForm from '../forms/bay'
import DedicatedBayTable from '../tables/child'

const AddBayModal: FC = () => {
  const { setToggleAddBayChildModal, bayChildren, clearState } =
    useBayMasterStore()
  const { mutate: addBay, isPending } = useAddBay(clearState)

  const { register, handleSubmit, setValue, formState, watch } =
    useForm<ISearchBayResult>({
      defaultValues: {
        bay: '',
        remark: '',
        isTopLoad: false,
        isActive: false,
        isDedicate: false,
      },
      mode: 'onBlur',
    })

  const handleClose = () => {
    clearState()
  }

  const handleAddChild = () => {
    setToggleAddBayChildModal(true)
  }

  const onSubmit: SubmitHandler<ISearchBayResult> = (body) => {
    addBay({
      params: { termID: '1002' },
      body: {
        ...body,
        isDedicate: bayChildren.length > 0,
        dedicatedBayList: bayChildren.map((item) => ({
          dedicatedBayID: item.dedicatedBayID,
          carrierID: item.carrierID as number,
          drawerID: item.drawerID as number,
          dedicatedStart: item.dedicatedStart
            ? formatISODate(item.dedicatedStart)
            : null,
          dedicatedEnd: item.dedicatedEnd
            ? formatISODate(item.dedicatedEnd)
            : null,
          periodStartString: item.periodStartString,
          periodEndString: item.periodEndString,
        })),
      },
    })
  }

  return (
    <FormModal
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title="Add a bay"
      onClose={handleClose}
      isValid={formState.isValid}
      confirmLabel="Add"
      modalWindowOptionalClass="!w-[50rem]"
      isLoading={isPending}
    >
      <BayForm
        formState={formState}
        watch={watch}
        setValue={setValue}
        register={register}
      />
      <ButtonGroup optionalClass="mt-3">
        <Button
          type="em-c-btn--primary"
          label="+ Add a dedicated bay"
          typeAttribute="button"
          onClick={handleAddChild}
        />
      </ButtonGroup>
      <DedicatedBayTable dedicatedBays={bayChildren} />
    </FormModal>
  )
}

export default AddBayModal
