import config from '@/config'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'

export const authPermissions: { scopes: string[] } = {
  scopes: [config.authConfig.appScope],
}

export const msalConfig: Configuration = {
  auth: {
    clientId: config.authConfig.clientId,
    authority: config.authConfig.authority,
    redirectUri: config.authConfig.redirectUri,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
}

const msalPublicClient = new PublicClientApplication(msalConfig)

export default msalPublicClient
