import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  CarrierAutoComplete,
  CustomerAutoComplete,
  DatePicker,
  DrawerAutoComplete,
} from '@/components'

import { IContractInformation } from '@/stores'

import TextInput from '@/standard/TextInput'

import { extractOnlyNumericCharacters, formatISODate } from '@/utils'

interface IContractFormProps {
  isEdit?: boolean
  isLoading?: boolean
}

const ContractForm: FC<IContractFormProps> = ({ isLoading, isEdit }) => {
  const { formState, register, setValue } =
    useFormContext<IContractInformation>()

  const contractNrState =
    isLoading || isEdit
      ? 'em-is-disabled'
      : formState.errors.contractNr && 'em-has-error'
  const contractTypeState = isLoading
    ? 'em-is-disabled'
    : formState.errors.contractType && 'em-has-error'
  const validityStartState = isLoading
    ? 'em-is-disabled'
    : formState.errors.validityStart && 'em-has-error'
  const validityEndState = isLoading
    ? 'em-is-disabled'
    : formState.errors.validityEnd && 'em-has-error'

  const handleContractNrChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('contractNr', extractOnlyNumericCharacters(newValue))
  }

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
        <TextInput
          id="contractNr"
          label="Contract Number"
          state={contractNrState}
          maxLength={10}
          required
          note={formState.errors.contractNr?.message}
          {...register('contractNr', {
            required: {
              value: true,
              message: 'Contract Number is required',
            },
            onChange: handleContractNrChange,
          })}
        />
        <TextInput
          id="contractType"
          label="Contract Type"
          state={contractTypeState}
          maxLength={10}
          required
          note={formState.errors.contractType?.message}
          {...register('contractType', {
            required: {
              value: true,
              message: 'Contract Type is required',
            },
          })}
        />
        <DrawerAutoComplete isRequired />
        <CarrierAutoComplete isRequired />
        <DatePicker
          required
          label="Validity Start"
          id="validityStart"
          state={validityStartState}
          note={formState.errors.validityStart?.message}
          {...register('validityStart', {
            setValueAs: formatISODate,
            required: {
              value: true,
              message: 'Validity Start is required',
            },
          })}
        />
        <DatePicker
          required
          label="Validity End"
          id="validityEnd"
          state={validityEndState}
          note={formState.errors.validityEnd?.message}
          {...register('validityEnd', {
            setValueAs: formatISODate,
            required: {
              value: true,
              message: 'Validity End is required',
            },
          })}
        />
        <CustomerAutoComplete
          label="Sold to Customer"
          customFormValue="soldToCustomer"
        />
        <CustomerAutoComplete
          label="Ship to Customer"
          customFormValue="shipToCustomer"
        />
      </div>
    </>
  )
}

export default ContractForm
