import { BaseProdAutoComplete } from '@/components'

const BomForm = () => {
  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <BaseProdAutoComplete isRequired />
    </div>
  )
}

export default BomForm
