import { useMsal } from '@azure/msal-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-use'

import { useRoleMatrixStore, useSideBarStore } from '@/stores'

import VerticalHeader, {
  VerticalHeaderSolidNav,
  VerticalHeaderSolidNavItem,
} from '@/standard/Headers/VerticalHeader'
import TreeNavigation, {
  TreeNavigationDropdown,
  TreeNavigationItem,
} from '@/standard/TreeNavigation'

const SideBar: FC = () => {
  const { pathname } = useLocation()
  const { instance } = useMsal()
  const {
    isMasterMenuOpen,
    isReportMenuOpen,
    setMasterMenuOpen,
    setReportMenuOpen,
  } = useSideBarStore()
  const [queueMonitor, setQueueMonitor] = useState<string>('/queueMonitor')
  const {
    queueRegisteration,
    queueAcknowledgementDisplay,
    queueBayMonitor,
    queueOrderMonitor,
    generalSetting,
    baseProductMaster,
    bayMaster,
    bayProductMaster,
    carrierMaster,
    contract,
    customerMaster,
    drawerMaster,
    drawerProductMaster,
    personnelMaster,
    priorityMaster,
    drawerProductGroupMaster,
    shipment,
    tankerMaster,
    roleMatrix,
    auditReport,
    gateToGateReport,
    errorReport,
  } = useRoleMatrixStore()

  const hideMasterDataMenu = useMemo(
    () =>
      [
        bayMaster?.canRead,
        bayProductMaster?.canRead,
        drawerProductMaster?.canRead,
        drawerProductGroupMaster?.canRead,
        baseProductMaster?.canRead,
        shipment?.canRead,
        contract?.canRead,
        personnelMaster?.canRead,
        tankerMaster?.canRead,
        carrierMaster?.canRead,
        drawerMaster?.canRead,
        priorityMaster?.canRead,
        customerMaster?.canRead,
      ].includes(true),
    [
      bayMaster?.canRead,
      bayProductMaster?.canRead,
      drawerProductMaster?.canRead,
      drawerProductGroupMaster?.canRead,
      baseProductMaster?.canRead,
      shipment?.canRead,
      contract?.canRead,
      personnelMaster?.canRead,
      tankerMaster?.canRead,
      carrierMaster?.canRead,
      drawerMaster?.canRead,
      priorityMaster?.canRead,
      customerMaster?.canRead,
    ]
  )

  const hideReportMenu = useMemo(
    () =>
      [
        gateToGateReport?.canRead,
        errorReport?.canRead,
        auditReport?.canRead,
      ].includes(true),
    [gateToGateReport?.canRead, errorReport?.canRead, auditReport?.canRead]
  )

  const handleLogout = () => {
    instance.logout({
      account: instance.getActiveAccount(),
    })
  }

  useEffect(() => {
    if (pathname === '/') {
      setQueueMonitor(pathname)
    }
    if (pathname === '/queueMonitor') {
      setQueueMonitor(pathname)
    }
  }, [])

  return (
    <VerticalHeader title="QS | Yaraville" onLogout={handleLogout}>
      <VerticalHeaderSolidNav>
        {queueRegisteration?.canRead && (
          <VerticalHeaderSolidNavItem
            url="/registration"
            label="QUEUE REGISTRATION"
            iconName="pencil"
          />
        )}
        {queueAcknowledgementDisplay?.canRead && (
          <VerticalHeaderSolidNavItem
            url="/acknowledgement"
            label="ACKNOWLEDGEMENT"
            iconName="circle-check"
          />
        )}
      </VerticalHeaderSolidNav>
      <TreeNavigation>
        {queueOrderMonitor?.canRead && (
          <TreeNavigationItem label="Queue Monitor" to={queueMonitor} />
        )}
        {queueBayMonitor?.canRead && (
          <TreeNavigationItem label="Bay Monitor" to="/bayMonitor" />
        )}
        {hideMasterDataMenu && (
          <TreeNavigationDropdown
            label="Master Data"
            expanded={isMasterMenuOpen}
            onClick={setMasterMenuOpen}
          >
            {bayMaster?.canRead && (
              <TreeNavigationItem label="Bay" to="/bayMaster" />
            )}
            {bayProductMaster?.canRead && (
              <TreeNavigationItem label="Bay Product" to="/bayProductMaster" />
            )}
            {drawerProductMaster?.canRead && (
              <TreeNavigationItem label="Drawer Product" to="/drawerProduct" />
            )}
            {drawerProductGroupMaster?.canRead && (
              <TreeNavigationItem
                label="Drawer Product Group"
                to="/drawerProductGroupMaster"
              />
            )}
            {baseProductMaster?.canRead && (
              <TreeNavigationItem
                label="Base Product"
                to="/baseProductMaster"
              />
            )}
            {shipment?.canRead && (
              <TreeNavigationItem label="Shipment" to="/shipment" />
            )}
            {contract?.canRead && (
              <TreeNavigationItem label="Contract" to="/contract" />
            )}
            {personnelMaster?.canRead && (
              <TreeNavigationItem label="Personnel" to="/personnelMaster" />
            )}
            {tankerMaster?.canRead && (
              <TreeNavigationItem label="Tanker" to="/tankerMaster" />
            )}
            {carrierMaster?.canRead && (
              <TreeNavigationItem label="Carrier" to="/carrierMaster" />
            )}
            {drawerMaster?.canRead && (
              <TreeNavigationItem label="Drawer" to="/drawerMaster" />
            )}
            {priorityMaster?.canRead && (
              <TreeNavigationItem label="Priority" to="/priorityMaster" />
            )}
            {customerMaster?.canRead && (
              <TreeNavigationItem label="Customer" to="/customerMaster" />
            )}
          </TreeNavigationDropdown>
        )}
        {hideReportMenu && (
          <TreeNavigationDropdown
            label="Report"
            expanded={isReportMenuOpen}
            onClick={setReportMenuOpen}
          >
            {gateToGateReport?.canRead && (
              <TreeNavigationItem label="Gate To Gate" to="/gateToGate" />
            )}
            {errorReport?.canRead && (
              <TreeNavigationItem label="Error" to="/errorReport" />
            )}
            {auditReport?.canRead && (
              <TreeNavigationItem label="Audit" to="/auditReport" />
            )}
          </TreeNavigationDropdown>
        )}
        {roleMatrix?.canRead && (
          <TreeNavigationItem label="Role Matrix" to="/roleMatrix" />
        )}
        {generalSetting?.canRead && (
          <TreeNavigationItem label="General Settings" to="/generalSettings" />
        )}
      </TreeNavigation>
    </VerticalHeader>
  )
}
export default SideBar
