import { FC, useEffect } from 'react'
import DataGrid from 'react-data-grid'

import RoleMatrixToggle from '@/features/roleMatrix/toggle'

import {
  ScreenList,
  useGetAllRoleScreenMapping,
} from '@/services/api/roleMatrix'

import { useRoleMatrixStore, useRoleModalStore } from '@/stores'

import Accordion, { AccordionBody, AccordionItem } from '@/standard/Accordion'
import LoadingIndicator from '@/standard/LoadingIndicator'

import { makeDataGridColumns } from '@/utils'

interface Column extends ScreenList {
  role: string
}

const columns = makeDataGridColumns<Column>([
  {
    key: 'screenName',
    name: 'Screen Name',
  },
  {
    key: 'canRead',
    name: 'Can Read',
    renderCell: ({ row }) => {
      const { role, ...screenList } = row
      return (
        <RoleMatrixToggle
          role={role}
          screenList={screenList}
          activeKey="canRead"
        />
      )
    },
  },
  {
    key: 'canCreate',
    name: 'Can Create',
    renderCell: ({ row }) => {
      const { role, ...screenList } = row
      return (
        <RoleMatrixToggle
          role={role}
          screenList={screenList}
          activeKey="canCreate"
        />
      )
    },
  },
  {
    key: 'canEdit',
    name: 'Can Edit',
    renderCell: ({ row }) => {
      const { role, ...screenList } = row
      return (
        <RoleMatrixToggle
          role={role}
          screenList={screenList}
          activeKey="canEdit"
        />
      )
    },
  },
  {
    key: 'canDelete',
    name: 'Can Delete',
    renderCell: ({ row }) => {
      const { role, ...screenList } = row
      return (
        <RoleMatrixToggle
          role={role}
          screenList={screenList}
          activeKey="canDelete"
        />
      )
    },
  },
])

const RoleMatrixTable: FC = () => {
  const defaultRoles = [
    'Admin',
    'Driver',
    'MonitorKiosk',
    'Operator',
    'Supervisor',
    'Support',
  ]
  const { setToggleDeleteModal, setCurrentRole, setScreenList } =
    useRoleModalStore()
  const { data } = useGetAllRoleScreenMapping({ termID: '1002' })
  const { roleMatrix } = useRoleMatrixStore()
  const { canDelete } = roleMatrix

  const handleClickButton = (role: string) => {
    setToggleDeleteModal(true)
    setCurrentRole(role)
  }

  useEffect(() => {
    if (data) {
      setScreenList(
        data[0].screenList.map((screen) => ({
          ...screen,
          canRead: typeof screen.canRead === 'boolean' ? false : null,
          canEdit: typeof screen.canEdit === 'boolean' ? false : null,
          canCreate: typeof screen.canCreate === 'boolean' ? false : null,
          canDelete: typeof screen.canDelete === 'boolean' ? false : null,
        }))
      )
    }
    return () => {
      setScreenList([])
    }
  }, [data])

  return data ? (
    <Accordion optionalClass="overflow-y-scroll">
      {data.map((role, index) => {
        return (
          <AccordionItem
            key={role.role}
            id={index}
            title={role.role}
            showIconButton={canDelete && !defaultRoles.includes(role.role)}
            iconName="trashcan"
            onClickButton={(e) => {
              e.stopPropagation()
              handleClickButton(role.role)
            }}
          >
            <AccordionBody id={index}>
              <DataGrid
                className="rdg-light"
                columns={columns}
                rows={role.screenList.map((screen) => ({
                  ...screen,
                  role: role.role,
                }))}
              />
            </AccordionBody>
          </AccordionItem>
        )
      })}
    </Accordion>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default RoleMatrixTable
