import { FC, useEffect } from 'react'

import { useAlertInModalStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

interface GoToHomeModalModalProps {
  onClose: () => void
  onConfirm: () => void
}

const ConfirmGoToHomeModal: FC<GoToHomeModalModalProps> = ({
  onClose,
  onConfirm,
}): React.ReactElement => {
  const { setIsAlertInModalOpen } = useAlertInModalStore()

  const handleClose = () => {
    setIsAlertInModalOpen(false)
    onClose()
  }

  useEffect(() => {
    return () => {
      setIsAlertInModalOpen(false)
    }
  }, [])

  return (
    <Modal>
      <ModalHeader title="Go to home page?" onClose={handleClose} isError />
      <ModalBody>
        <p>If you confirm your changes will not be saved.</p>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            type="em-c-btn--primary"
            label="Confirm"
            onClick={onConfirm}
          />
          <Button label="Cancel" onClick={handleClose} />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmGoToHomeModal
