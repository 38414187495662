import { FC } from 'react'

import { Layout } from '@/components'

import BayMonitorComponent from '@/features/monitor/bay'

const BayMonitorPage: FC = () => {
  return (
    <Layout>
      <BayMonitorComponent />
    </Layout>
  )
}

export default BayMonitorPage
