import { create } from 'zustand'

import {
  IDetailByTankerAndDocList,
  IDocumentInterfaceErrorResult,
  IGetQueueWaitForBayAssignmentResult,
  IGetRegistrationErrorResult,
} from '@/services/api/monitor/order'

interface IOrderMonitor {
  isShowHasSeenModalOpen: boolean
  setIsShowHasSeenModalOpen: (value: boolean) => void
  isEnableQueueModalOpen: boolean
  setIsEnableQueueModalOpen: (value: boolean) => void
  isDisableQueueModalOpen: boolean
  setIsDisableQueueModalOpen: (value: boolean) => void
  isDeleteQueueByQListModalOpen: boolean
  setIsDeleteQueueByQListModalOpen: (value: boolean) => void
  isDeleteQueueByTankerModalOpen: boolean
  setIsDeleteQueueByTankerModalOpen: (value: boolean) => void
  isDeleteQueueModalOpen: boolean
  setIsDeleteQueueModalOpen: (value: boolean) => void
  isDeleteAllQueueWaitForBayAssignmentModalOpen: boolean
  setIsDeleteAllQueueWaitForBayAssignmentModalOpen: (value: boolean) => void
  isDeleteAllQueueAlreadyAssignBayModalOpen: boolean
  setIsDeleteAllQueueAlreadyAssignBayModalOpen: (value: boolean) => void
  isUpdateSortDTMModalOpen: boolean
  setIsUpdateSortDTMModalOpen: (value: boolean) => void
  isUpdateDTMModalOpen: boolean
  setIsUpdateDTMModalOpen: (value: boolean) => void
  isQueueDetailModalOpen: boolean
  setIsQueueDetailModalOpen: (value: boolean) => void
  isReprocessModalOpen: boolean
  setIsReprocessModalOpen: (value: boolean) => void
  currentError: IGetRegistrationErrorResult | undefined
  setCurrentError: (value: IGetRegistrationErrorResult) => void
  currentWaitingQueue: IGetQueueWaitForBayAssignmentResult | undefined
  setCurrentWaitingQueue: (value?: IGetQueueWaitForBayAssignmentResult) => void
  currentDeleteQueueByQList: IDetailByTankerAndDocList | undefined
  setCurrentDeleteQueueByQList: (value?: IDetailByTankerAndDocList) => void
  currentInterfaceError: IDocumentInterfaceErrorResult | undefined
  setCurrentInterfaceError: (value: IDocumentInterfaceErrorResult) => void
}
export const useOrderMonitor = create<IOrderMonitor>((set) => ({
  isShowHasSeenModalOpen: false,
  setIsShowHasSeenModalOpen: (value) => set({ isShowHasSeenModalOpen: value }),
  isEnableQueueModalOpen: false,
  setIsEnableQueueModalOpen: (value) => set({ isEnableQueueModalOpen: value }),
  isDisableQueueModalOpen: false,
  setIsDisableQueueModalOpen: (value) =>
    set({ isDisableQueueModalOpen: value }),
  isDeleteQueueModalOpen: false,
  setIsDeleteQueueModalOpen: (value) => set({ isDeleteQueueModalOpen: value }),
  isDeleteQueueByQListModalOpen: false,
  setIsDeleteQueueByQListModalOpen: (value) =>
    set({ isDeleteQueueByQListModalOpen: value }),
  isDeleteQueueByTankerModalOpen: false,
  setIsDeleteQueueByTankerModalOpen: (value) =>
    set({ isDeleteQueueByTankerModalOpen: value }),
  isDeleteAllQueueWaitForBayAssignmentModalOpen: false,
  setIsDeleteAllQueueWaitForBayAssignmentModalOpen: (value) =>
    set({ isDeleteAllQueueWaitForBayAssignmentModalOpen: value }),
  isDeleteAllQueueAlreadyAssignBayModalOpen: false,
  setIsDeleteAllQueueAlreadyAssignBayModalOpen: (value) =>
    set({ isDeleteAllQueueAlreadyAssignBayModalOpen: value }),
  isUpdateSortDTMModalOpen: false,
  setIsUpdateSortDTMModalOpen: (value) =>
    set({ isUpdateSortDTMModalOpen: value }),
  isUpdateDTMModalOpen: false,
  setIsUpdateDTMModalOpen: (value) => set({ isUpdateDTMModalOpen: value }),
  isQueueDetailModalOpen: false,
  setIsQueueDetailModalOpen: (value) => set({ isQueueDetailModalOpen: value }),
  isReprocessModalOpen: false,
  setIsReprocessModalOpen: (value) => set({ isReprocessModalOpen: value }),
  currentError: undefined,
  setCurrentError: (value) => set({ currentError: value }),
  currentWaitingQueue: undefined,
  setCurrentWaitingQueue: (value) => set({ currentWaitingQueue: value }),
  currentDeleteQueueByQList: undefined,
  setCurrentDeleteQueueByQList: (value) =>
    set({ currentDeleteQueueByQList: value }),
  currentInterfaceError: undefined,
  setCurrentInterfaceError: (value) => set({ currentInterfaceError: value }),
}))
