import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import PersonnelMasterForm from '@/features/master/personnel/form'

import { useEditPersonnel } from '@/services/api/personnelMaster'

import { IPersonnelInformation, usePersonnelMasterStore } from '@/stores'

const PersonnelEditModal = () => {
  const { currentPersonal, setToggleEditPersonnelModal, setCurrentPersonal } =
    usePersonnelMasterStore()

  const handleClose = () => {
    setToggleEditPersonnelModal(false)
    setCurrentPersonal(undefined)
  }

  const { mutate, isPending } = useEditPersonnel(handleClose)
  const methods = useForm<IPersonnelInformation>({
    values: currentPersonal,
    mode: 'onBlur',
  })

  const onSubmit = (data: IPersonnelInformation) => {
    mutate({
      body: {
        personnelID: data.personnelID,
        personnelIButton: data.personnelIButton,
        name: data.name,
        email: data.email,
        phoneNo: data.phoneNo,
        drawerID: data.drawerID,
        isBorrow: data.isBorrow,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a personnel"
        confirmLabel="Edit"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <PersonnelMasterForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default PersonnelEditModal
