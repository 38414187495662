import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useBayMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DeleteDedicatedBayModal: FC = () => {
  const {
    selectedBayChild,
    setToggleConfirmDeleteChildModal,
    deleteBayChild,
    setSelectedBayChild,
    setSelectedBayChildIndex,
  } = useBayMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteChildModal(false)
    setSelectedBayChild(undefined)
    setSelectedBayChildIndex(-1)
  }

  const handleConfirm = () => {
    deleteBayChild()
    handleClose()
  }

  return (
    <DeleteModal
      title="Delete a drawer"
      modalWindowOptionalClass="!z-[70]"
      overlayOptionalClass="!z-[60]"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {selectedBayChild?.dedicatedBayID && (
          <BulletedListItem>
            Dedicated Bay ID: {`(#${selectedBayChild.dedicatedBayID})`}
          </BulletedListItem>
        )}
        {selectedBayChild?.drawerCode && (
          <BulletedListItem>
            Drawer Code: {`${selectedBayChild.drawerCode}`}
          </BulletedListItem>
        )}
        {selectedBayChild?.carrierCode && (
          <BulletedListItem>
            Carrier Code: {`${selectedBayChild.carrierCode}`}
          </BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default DeleteDedicatedBayModal
