import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import {
  ICarrierData,
  ICarrierInformation,
  useCarrierMasterStore,
  useRoleMatrixStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<ICarrierInformation>([
  {
    key: 'carrierCode',
    name: 'Carrier Code',
  },
  { key: 'carrierName', name: 'Carrier Name' },
  { key: 'displayCreatedDate', name: 'Created Date' },
  { key: 'createdBy', name: 'Created By' },
  { key: 'displayModifiedDate', name: 'Modified Date' },
  { key: 'modifiedBy', name: 'Modified By' },
  {
    key: 'action',
    name: 'Action',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { carrierMaster } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setCurrentCarrier,
        setToggleEditCarrierModal,
      } = useCarrierMasterStore.getState()
      const { canEdit, canDelete } = carrierMaster
      const handleDelete = () => {
        setCurrentCarrier(row)
        setToggleConfirmDeleteModal(true)
      }
      const handleEdit = () => {
        setCurrentCarrier(row)
        setToggleEditCarrierModal(true)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface ICarrierMasterTable {
  currentPage: number
  onChangePage: (page: number) => void
  carriers?: ICarrierData
  isLoading: boolean
}

const CarrierMasterTable: FC<ICarrierMasterTable> = ({
  onChangePage,
  currentPage,
  carriers,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage,
    rowsPerPage,
    total: carriers?.total || 0,
  })

  return carriers?.result ? (
    <>
      <DataGrid
        columns={columns}
        rows={carriers.result}
        className="h-full rdg-light"
        rowHeight={45}
        headerRowHeight={30}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          carriers?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((carriers?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default CarrierMasterTable
