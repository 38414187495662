import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { useEditCarrier } from '@/services/api/carrierMaster'

import { ICarrierInformation, useCarrierMasterStore } from '@/stores'

import CarrierMasterForm from './form'

const CarrierEditModal: FC = () => {
  const { setToggleEditCarrierModal, currentCarrier, setCurrentCarrier } =
    useCarrierMasterStore()

  const handleClose = () => {
    setToggleEditCarrierModal(false)
    setCurrentCarrier(undefined)
  }

  const { mutate, isPending } = useEditCarrier(handleClose)
  const methods = useForm<ICarrierInformation>({
    defaultValues: {
      carrierID: currentCarrier?.carrierID,
      carrierCode: currentCarrier?.carrierCode,
      carrierName: currentCarrier?.carrierName,
      remark: currentCarrier?.remark,
    },
    mode: 'onBlur',
  })

  const onSubmit = (body: ICarrierInformation) => {
    mutate({
      body,
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a carrier"
        confirmLabel="Edit"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <CarrierMasterForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default CarrierEditModal
