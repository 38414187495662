import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import {
  ErrorReportResponseBody,
  GetErrorReportResponseData,
} from '@/services/api/errorReport'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<GetErrorReportResponseData>([
  { key: 'loadId', name: 'Load ID' },
  { key: 'documentNr', name: 'Document Number' },
  { key: 'licensePlate', name: 'License Plate' },
  { key: 'errorDescription', name: 'Error Description' },
  { key: 'errorType', name: 'Error Type' },
  { key: 'displayCreatedDate', name: 'Created Date' },
])

interface ErrorReportTableProps {
  currentPage: number
  onChangePage: (page: number) => void
  reports?: ErrorReportResponseBody
  isLoading: boolean
}

const ErrorReportTable: FC<ErrorReportTableProps> = ({
  currentPage,
  onChangePage,
  reports,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage,
    rowsPerPage,
    total: reports?.total || 0,
  })

  return reports ? (
    <>
      <DataGrid
        className="h-full rdg-light"
        columns={columns}
        rows={reports.result}
        headerRowHeight={30}
        rowHeight={45}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          reports?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((reports?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default ErrorReportTable
