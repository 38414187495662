import { Layout } from '@/components'

import BayProdMaster from '@/features/master/bayProd'

const BayProdMasterPage = () => {
  return (
    <Layout>
      <BayProdMaster />
    </Layout>
  )
}
export default BayProdMasterPage
