import { create } from 'zustand'

export interface IBaseProdInformation {
  baseProdID: number
  termID: string
  baseProdCode: string
  baseProdName: string
  remark: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayRemark: string
  displayCreatedDate: Date
  displayModifiedDate: Date
}

interface BaseProdMasterState {
  currentBaseProd: IBaseProdInformation | undefined
  setCurrentBaseProd: (value: IBaseProdInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddBaseProdModal: boolean
  setToggleAddBaseProdModal: (value: boolean) => void
  toggleEditBaseProdModal: boolean
  setToggleEditBaseProdModal: (value: boolean) => void
}

export const useBaseProdMasterStore = create<BaseProdMasterState>((set) => ({
  currentBaseProd: undefined,
  setCurrentBaseProd: (value) => set({ currentBaseProd: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddBaseProdModal: false,
  setToggleAddBaseProdModal: (value) => set({ toggleAddBaseProdModal: value }),
  toggleEditBaseProdModal: false,
  setToggleEditBaseProdModal: (value) =>
    set({ toggleEditBaseProdModal: value }),
}))
