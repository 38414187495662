import { FC } from 'react'

import { useContractStore } from '@/stores'

import ContractHeader from './header'
import AddContractDetailModal from './modals/addDetailModal'
import ContractAddModal from './modals/addModal'
import DeleteContractDetailModal from './modals/deleteDetailModal'
import DeleteContractModal from './modals/deleteModal'
import EditContractDetailModal from './modals/editDetailModal'
import EditContractModal from './modals/editModal'

const ContractFeature: FC = () => {
  const {
    toggleAddContractModal,
    toggleAddContractDetailModal,
    toggleEditContractDetailModal,
    toggleConfirmDeleteDetailModal,
    toggleEditContractModal,
    toggleConfirmDeleteModal,
  } = useContractStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddContractModal && <ContractAddModal />}
      {toggleAddContractDetailModal && <AddContractDetailModal />}
      {toggleEditContractDetailModal && <EditContractDetailModal />}
      {toggleConfirmDeleteDetailModal && <DeleteContractDetailModal />}
      {toggleEditContractModal && <EditContractModal />}
      {toggleConfirmDeleteModal && <DeleteContractModal />}
      <ContractHeader />
    </div>
  )
}
export default ContractFeature
