import RoleMatrixTable from '@/features/roleMatrix/table'

import { useRoleMatrixStore, useRoleModalStore } from '@/stores'

import Button from '@/standard/Button'

import RoleMatrixAddModal from './addModal'
import RoleMatrixDeleteModal from './deleteModal'

const RoleMatrixComponent = () => {
  const { toggleDeleteModal, setToggleAddModal, toggleAddModal } =
    useRoleModalStore()
  const { roleMatrix } = useRoleMatrixStore()
  const { canCreate } = roleMatrix

  const handleAdd = () => {
    setToggleAddModal(true)
  }

  return (
    <div className="flex flex-col h-full">
      {toggleDeleteModal && <RoleMatrixDeleteModal />}
      {toggleAddModal && <RoleMatrixAddModal />}
      {canCreate && (
        <Button
          typeAttribute="button"
          optionalClass="!p-[1.5em] self-end mb-4"
          label="+ Add"
          onClick={handleAdd}
        />
      )}
      <RoleMatrixTable />
    </div>
  )
}

export default RoleMatrixComponent
