import { useMsal } from '@azure/msal-react'

const LoginFeature = () => {
  const { instance } = useMsal()

  const handleLogin = () => {
    instance.loginRedirect()
  }

  return (
    <>
      <iframe
        className="fixed inset-0 w-full h-full object-cover z-0"
        src="https://www.youtube.com/embed/jxnY1qYx_mk?controls=0&showinfo=0&rel=0&autoplay=1&&mute=1&loop=1&playlist=jxnY1qYx_mk"
        title="Background video"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
      <div className="absolute inset-0 bg-black bg-opacity-40 z-10" />
      <div className="relative flex items-center justify-center h-screen z-20">
        <div className="flex flex-col items-center justify-center p-8 bg-white bg-opacity-10 rounded-lg backdrop-blur-lg border border-white border-opacity-20 mt-[30%]">
          <img
            src="/images/Exxon_Mobil_Logo.svg"
            alt="QS Yaraville"
            className="mb-6"
          />
          <h2 className="text-2xl text-white">Welcome | QS Yaraville</h2>

          <button
            onClick={handleLogin}
            className="mt-8 px-4 py-2 bg-black bg-opacity-30 text-white rounded-md cursor-pointer duration-300 hover:bg-opacity-20 hover:scale-105 transform transition-all"
          >
            Login with Single Sign-On
          </button>
        </div>
      </div>
    </>
  )
}

export default LoginFeature
