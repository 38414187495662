import { FC } from 'react'

import { useDrawerProductStore } from '@/stores'

import Header from './header'
import AddBomModal from './modals/addBomModal'
import DrawerProductAddModal from './modals/addModal'
import DeleteBomModal from './modals/deleteBomModal'
import DrawerProductDeleteModal from './modals/deleteModal'
import EditBomModal from './modals/editBomModal'
import DrawerProductEditModal from './modals/editModal'

const DrawerProductComponent: FC = () => {
  const {
    toggleAddDrawerProdModal,
    toggleAddBomChildModal,
    toggleEditBomChildModal,
    toggleConfirmDeleteBomChildModal,
    toggleEditDrawerProdModal,
    toggleConfirmDeleteModal,
  } = useDrawerProductStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddDrawerProdModal && <DrawerProductAddModal />}
      {toggleEditDrawerProdModal && <DrawerProductEditModal />}
      {toggleConfirmDeleteModal && <DrawerProductDeleteModal />}
      {toggleAddBomChildModal && <AddBomModal />}
      {toggleEditBomChildModal && <EditBomModal />}
      {toggleConfirmDeleteBomChildModal && <DeleteBomModal />}
      <Header />
    </div>
  )
}
export default DrawerProductComponent
