import { useEffect, useRef, useState } from 'react'
import OTPInput from 'react-otp-input'

import { PhoneNumberPad } from '@/components'

import {
  IRegistrationQueue,
  qRegistrationService,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

const PhoneNumberConfirmation = () => {
  const {
    step,
    personnelIButton,
    tankerIButton,
    queueData,
    setPhoneData,
    setStep,
    setIsGoToHome,
  } = useQueueRegistrationStore()
  // Phone number
  const phoneNumberRef = useRef<HTMLInputElement>(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [messageError, setMessagePhoneError] = useState('')

  const { data: phoneNo } = qRegistrationService.useGetPhoneNo(
    step === 'confirmPhoneNumber',
    { termID: '1002', personnelIButton }
  )

  const { mutateAsync: mutateCreateQueue, isPending } =
    qRegistrationService.useCreateQueueRegistration()

  useEffect(() => {
    if (phoneNo) {
      setPhoneNumber(phoneNo)
      setPhoneData(phoneNo)
    } else {
      setPhoneNumber('')
      setPhoneData('')
    }
  }, [phoneNo])

  useEffect(() => {
    setPhoneData(phoneNumber)
  }, [phoneNumber])

  const onCreateQueue = async () => {
    const createBody: IRegistrationQueue = {
      termID: '1002',
      personnelIButton,
      tankerIButton,
      phoneNo: phoneNumber,
      shipmentList: queueData?.shipmentList?.length
        ? queueData?.shipmentList
        : [],
      contractList: queueData?.contractList?.length
        ? queueData?.contractList
        : [],
    }
    const data = await mutateCreateQueue(createBody)
    if (data.message) {
      setMessagePhoneError(data.message)
    } else {
      setStep('queueCompleted')
    }
  }

  const typingPhoneNumber = (value: string) => {
    if (phoneNumber.length >= 10) return
    setPhoneNumber(phoneNumber.concat(value))
  }
  const onBackSpace = () => {
    if (!phoneNumber.length) return
    setPhoneNumber(phoneNumber.slice(0, -1))
  }

  const onSkipPhoneNumber = async () => {
    const createBody: IRegistrationQueue = {
      termID: '1002',
      personnelIButton,
      tankerIButton,
      phoneNo: '9999999999',
      shipmentList: queueData?.shipmentList?.length
        ? queueData?.shipmentList
        : [],
      contractList: queueData?.contractList?.length
        ? queueData?.contractList
        : [],
    }
    const data = await mutateCreateQueue(createBody)
    if (data.message) {
      setMessagePhoneError(data.message)
    } else {
      setStep('queueCompleted')
    }
  }

  return (
    <div className="flex flex-col items-center max-w-screen-xl mx-auto h-full justify-center gap-y-5">
      <div className="z-10">
        <img src="/images/ExxonMobilLogoColor2x.png" className="" />
      </div>
      <p className="text-4xl text-white m-0 z-10">
        Please Confirm your
        <span className="text-main-curiosBlue font-bold ml-2">
          Phone Number
        </span>
      </p>
      <p className="text-xl text-red-500 m-0 h-7">{messageError}</p>

      <OTPInput
        value={phoneNumber}
        onChange={() => {}}
        numInputs={10}
        renderInput={(props, index: number) => (
          <input
            {...props}
            inputMode="numeric"
            ref={index === 0 ? phoneNumberRef : props.ref}
            disabled
          />
        )}
        inputStyle="!w-20 h-20 mx-2 text-5xl font-bold bg-white z-10"
        shouldAutoFocus
        inputType="tel"
      />
      <PhoneNumberPad
        onBackSpace={onBackSpace}
        onConfirm={onCreateQueue}
        typingPhoneNumber={typingPhoneNumber}
        setIsGoToHome={setIsGoToHome}
        onSkipPhoneNumber={onSkipPhoneNumber}
        canSkip
        isConfirmDisabled={phoneNumber.length !== 10}
        isPending={isPending}
      />
    </div>
  )
}

export default PhoneNumberConfirmation
