import { FC } from 'react'

import QueueAlreadyAssignedActionsDropdown from '@/components/dropdown/queueAlreadyAssigned'
import QueueWaitingActionsDropdown from '@/components/dropdown/queueWaiting'

import CascadeBay from '@/features/queue/acknowledgement/cascadeBay'

import {
  IGetQueueAlreadyAssignBayResult,
  IGetQueueWaitForBayAssignmentResult,
  useGetDocumentInterfaceError,
  useGetQueueAlreadyAssignBay,
  useGetQueueWaitForBayAssignment,
  useGetRegistrationError,
} from '@/services/api/monitor/order'

import { useBayMonitor, useOrderMonitor } from '@/stores'

import Button from '@/standard/Button'
import Tab, { TabItem } from '@/standard/Tab'
import Table, {
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '@/standard/Tables/Table'

import { convertBooleanToYesNo, formatDateAndTime } from '@/utils'

import RemoveAssignedModal from '../bay/removeAssignedModal'
import DeleteAllQueueAlreadyAssignBayModal from './deleteAllQueueAlreadyAssignBayModal'
import DeleteAllQueueWaitForBayAssignmentModal from './deleteAllQueueWaitForBayAssignmentModal'
import DeleteQueueByQListModal from './deleteByQListModal'
import DeleteQueueByTankerModal from './deleteByTankerModal'
import DeleteQueueModal from './deleteQueueModal'
import DisableQueueModal from './disableModal'
import EnableQueueModal from './enableModal'
import HasSeenModal from './hasSeen'
import ProcessErrorTab from './processErrorTab'
import QueueDetailModal from './queueDetailModal'
import SystemErrorTab from './systemErrorTab'
import UpdateSortDTMModal from './updateSortDTMModal'

const OrderMonitorComponent: FC = () => {
  const {
    isShowHasSeenModalOpen,
    isEnableQueueModalOpen,
    isDisableQueueModalOpen,
    isUpdateSortDTMModalOpen,
    isQueueDetailModalOpen,
    isDeleteAllQueueWaitForBayAssignmentModalOpen,
    isDeleteAllQueueAlreadyAssignBayModalOpen,
    isDeleteQueueModalOpen,
    isDeleteQueueByQListModalOpen,
    isDeleteQueueByTankerModalOpen,
    setIsDeleteAllQueueWaitForBayAssignmentModalOpen,
    setIsDeleteAllQueueAlreadyAssignBayModalOpen,
  } = useOrderMonitor()

  const { isRemoveAssignedModalOpen } = useBayMonitor()

  const { data: queueWaitForAssignBay } = useGetQueueWaitForBayAssignment({
    termID: '1002',
  })
  const { data: queueAlreadyAssignBay } = useGetQueueAlreadyAssignBay({
    termID: '1002',
  })
  const { data: registrationErrorData } = useGetRegistrationError({
    termID: '1002',
  })
  const { data: documentInterfaceError } = useGetDocumentInterfaceError({
    termID: '1002',
  })

  const handleDeleteAllQueueWaitForBayAssignmentModal = () => {
    setIsDeleteAllQueueWaitForBayAssignmentModalOpen(true)
  }
  const handleDeleteAllQueueAlreadyAssignBayModal = () => {
    setIsDeleteAllQueueAlreadyAssignBayModalOpen(true)
  }

  return (
    <div className="overflow-hidden h-full">
      <div className="overflow-hidden h-full">
        <Tab
          type="em-c-tabs--underline"
          optionalClass="overflow-hidden h-full"
          tabBodyOptionalClass="overflow-auto h-full !pt-0"
        >
          <TabItem title="Overview">
            <div className="flex justify-between items-center">
              <h4>Queue wait for assign</h4>
              <Button
                typeAttribute="button"
                label="Delete All"
                onClick={() => handleDeleteAllQueueWaitForBayAssignmentModal()}
              />
            </div>
            <Table
              optionalClass="py-4"
              type="em-c-table--striped"
              bodyInnerOptionalClass="bg-neutral-lightGray border border-gray-300 h-[530px]"
            >
              <TableHeader optionalClass="text-white">
                <TableHeaderItem optionalClass="w-[200px] !py-[6px]">
                  Plate No
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[200px] !py-[6px]">
                  Document No
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[200px] !py-[6px]">
                  Sort DTM
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[100px] !py-[6px]">
                  Duration(m)
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[100px] !py-[6px]">
                  Is Disabled
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[100px] !py-[6px] text-center">
                  Actions
                </TableHeaderItem>
              </TableHeader>
              <TableBody>
                {queueWaitForAssignBay?.result.map(
                  (queue: IGetQueueWaitForBayAssignmentResult) => {
                    return (
                      <TableRow
                        optionalClass="align-middle text-black bg-white !h-[50px]"
                        type="em-c-table--condensed"
                        key={`${queue.tankerID}-${queue.documentID}`}
                      >
                        <div>{queue.licensePlate}</div>
                        <div>{`${queue.documentType}: ${
                          queue.documentNr || 'Doc. name'
                        }`}</div>
                        <div>{formatDateAndTime(queue.sortDTM)}</div>
                        <div>{queue.waitDuration || '-'}</div>
                        <div>
                          <span>{convertBooleanToYesNo(queue.isDisable)}</span>
                        </div>
                        <div className="flex gap-2 items-center justify-center">
                          <QueueWaitingActionsDropdown data={queue} />
                        </div>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
            <div className="flex justify-between items-center pt-10">
              <h4>Queue already assigned</h4>
              <Button
                typeAttribute="button"
                label="Delete All"
                onClick={() => handleDeleteAllQueueAlreadyAssignBayModal()}
              />
            </div>
            <Table
              optionalClass="py-4"
              type="em-c-table--striped"
              bodyInnerOptionalClass="bg-neutral-lightGray border border-gray-300 h-[530px]"
            >
              <TableHeader optionalClass="text-white">
                <TableHeaderItem optionalClass="w-[200px] !py-[6px]">
                  Plate No
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[200px] !py-[6px]">
                  Document No
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[230px] !py-[6px]">
                  Sort DTM
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[230px] !py-[6px]">
                  Pick DTM
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[400px] !py-[6px]">
                  Bay List
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[120px] !py-[6px]">
                  Is Removed
                </TableHeaderItem>
                <TableHeaderItem optionalClass="w-[100px] !py-[6px] text-center">
                  Actions
                </TableHeaderItem>
              </TableHeader>
              <TableBody>
                {queueAlreadyAssignBay?.result.map(
                  (queue: IGetQueueAlreadyAssignBayResult) => {
                    return (
                      <TableRow
                        optionalClass="align-middle text-black bg-white !h-[50px]"
                        type="em-c-table--condensed"
                        key={`${queue.tankerID}-${queue.documentID}`}
                      >
                        <div>{queue.licensePlate}</div>
                        <div>{`${queue.documentType}: ${
                          queue.documentNr || 'Doc. name'
                        }`}</div>
                        <div>
                          <span>{formatDateAndTime(queue.sortDTM)}</span>
                        </div>
                        <div>
                          <span>{formatDateAndTime(queue.pickDTM)}</span>
                        </div>
                        <div>
                          <CascadeBay bays={queue.bayList} />
                        </div>
                        <div>
                          <span>{convertBooleanToYesNo(queue.isRemove)}</span>
                        </div>
                        <div className="flex gap-2 items-center justify-center">
                          <QueueAlreadyAssignedActionsDropdown data={queue} />
                        </div>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TabItem>
          <TabItem
            title={`Process Error (${registrationErrorData?.result.total})`}
          >
            <ProcessErrorTab />
          </TabItem>
          <TabItem
            title={`System Error (${documentInterfaceError?.result.length})`}
          >
            <SystemErrorTab />
          </TabItem>
        </Tab>
      </div>
      {isShowHasSeenModalOpen && <HasSeenModal />}
      {isEnableQueueModalOpen && <EnableQueueModal />}
      {isDisableQueueModalOpen && <DisableQueueModal />}
      {isUpdateSortDTMModalOpen && <UpdateSortDTMModal />}
      {isDeleteAllQueueWaitForBayAssignmentModalOpen && (
        <DeleteAllQueueWaitForBayAssignmentModal />
      )}
      {isDeleteAllQueueAlreadyAssignBayModalOpen && (
        <DeleteAllQueueAlreadyAssignBayModal />
      )}
      {isRemoveAssignedModalOpen && <RemoveAssignedModal />}
      {isQueueDetailModalOpen && <QueueDetailModal />}
      {isDeleteQueueModalOpen && <DeleteQueueModal />}
      {isDeleteQueueByQListModalOpen && <DeleteQueueByQListModal />}
      {isDeleteQueueByTankerModalOpen && <DeleteQueueByTankerModal />}
    </div>
  )
}

export default OrderMonitorComponent
