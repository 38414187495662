import { Layout } from '@/components'

import PriorityMaster from '@/features/master/priority'

const PriorityMasterPage = () => {
  return (
    <Layout>
      <PriorityMaster />
    </Layout>
  )
}

export default PriorityMasterPage
