import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteAllQueueWaitForBayAssignment } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

const DeleteAllQueueWaitForBayAssignmentModal: FC = () => {
  const { setIsDeleteAllQueueWaitForBayAssignmentModalOpen } = useOrderMonitor()

  const handleClose = () => {
    setIsDeleteAllQueueWaitForBayAssignmentModalOpen(false)
  }

  const { mutate: deleteAllWaitQueue } =
    useDeleteAllQueueWaitForBayAssignment(handleClose)

  const handleConfirm = () => {
    deleteAllWaitQueue({
      termID: '1002',
    })
  }

  return (
    <DeleteModal
      title="Delete all queue in wait for assign ?"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>{`Confirm to DELETE all queue.`}</p>
    </DeleteModal>
  )
}
export default DeleteAllQueueWaitForBayAssignmentModal
