import { DeleteModal } from '@/components'

import { useDeleteShipment } from '@/services/api/shipmentMaster'

import { useShipmentMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DeleteShipmentModal = () => {
  const { currentShipment, setCurrentShipment, setToggleConfirmDeleteModal } =
    useShipmentMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentShipment(undefined)
  }

  const { mutate, isPending } = useDeleteShipment(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', shipmentID: currentShipment!.shipmentID })
  }

  return (
    <DeleteModal
      title="Delete a shipment"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {currentShipment?.shipmentID && (
          <BulletedListItem>
            Shipment Number: {currentShipment?.shipmentNr}
          </BulletedListItem>
        )}
        {currentShipment?.carrierCode && (
          <BulletedListItem>
            Carrier Code: {currentShipment?.carrierCode}
          </BulletedListItem>
        )}
        {currentShipment?.drawerCode && (
          <BulletedListItem>
            Drawer Code: {currentShipment?.drawerCode}
          </BulletedListItem>
        )}
        {currentShipment?.tankerCode && (
          <BulletedListItem>
            Tanker Code: {currentShipment?.tankerCode}
          </BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default DeleteShipmentModal
