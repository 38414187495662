import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { DrawerAutoComplete } from '@/components'

import { IPersonnelInformation } from '@/stores'

import Select from '@/standard/Select'
import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

import {
  extractOnlyAlphanumericCharacters,
  extractOnlyNumericCharacters,
  parseStringToBoolean,
} from '@/utils'

interface PersonnelMasterFormProps {
  isEdit?: boolean
}

const PersonnelMasterForm: FC<PersonnelMasterFormProps> = ({ isEdit }) => {
  const { formState, register, setValue } =
    useFormContext<IPersonnelInformation>()

  const personnelIButtonState = isEdit
    ? 'em-is-disabled'
    : formState.errors.personnelIButton && 'em-has-error'
  const emailState = formState.errors.email && 'em-has-error'
  const phoneNoState = formState.errors.phoneNo && 'em-has-error'
  const nameState = formState.errors.name && 'em-has-error'
  const isBorrowState = formState.errors.isBorrow && 'em-has-error'

  const handleIButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('personnelIButton', extractOnlyAlphanumericCharacters(newValue))
  }

  const handlePhoneNoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('phoneNo', extractOnlyNumericCharacters(newValue))
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-2 lg:gap-4">
        <TextInput
          id="personnelIButton"
          label="Personnel iButton"
          state={personnelIButtonState}
          maxLength={16}
          required
          note={formState.errors.personnelIButton?.message}
          {...register('personnelIButton', {
            required: {
              value: true,
              message: 'Personnel iButton is required',
            },
            ...(isEdit
              ? {}
              : {
                  pattern: {
                    value: /^[a-zA-Z0-9]{16}$/,
                    message: 'Personnel iButton must be 16 characters',
                  },
                }),
            onChange: handleIButtonChange,
          })}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <TextInput
          id="email"
          label="Email"
          state={emailState}
          required
          maxLength={50}
          note={formState.errors.email?.message}
          {...register('email', {
            required: {
              value: true,
              message: 'Email is required',
            },
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid email address',
            },
          })}
        />
        <TextInput
          id="phoneNo"
          label="Phone No"
          state={phoneNoState}
          required
          maxLength={10}
          note={formState.errors.phoneNo?.message}
          {...register('phoneNo', {
            required: {
              value: true,
              message: 'Phone No is required',
            },
            onChange: handlePhoneNoChange,
          })}
        />
      </div>
      <div className="grid grid-cols-1 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <TextInput
          id="name"
          label="Name"
          state={nameState}
          required
          maxLength={50}
          note={formState.errors.name?.message}
          {...register('name', {
            required: {
              value: true,
              message: 'Name is required',
            },
          })}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <DrawerAutoComplete isRequired />
        <Select
          label="Is Borrow"
          id="isBorrow"
          selectOptionalClass="w-full"
          state={isBorrowState}
          note={formState.errors.isBorrow?.message}
          required
          {...register('isBorrow', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </div>
      <div className="grid grid-cols-1 mt-2 lg:mt-4 gap-2 lg:gap-4">
        <TextArea
          id="remark"
          label="Remark"
          textareaOptionalClass="!h-[6rem]"
          maxLength={200}
          {...register('remark')}
        />
      </div>
    </>
  )
}

export default PersonnelMasterForm
