import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IPriorityInformation } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface IPriorityData {
  result: IPriorityInformation[]
  total: number
}

export interface ISearchPriority {
  termID: string
  Page: number
  PriorityName?: string
}

export interface IAddPriority {
  priorityID: number
  priorityCode: number
  priorityName: string
  delayMin: number
  dropMax: number
  remark?: string
}

interface IEditPriority {
  priorityID: number
  priorityCode: number
  priorityName: string
  delayMin: number
  dropMax: number
  remark?: string
}

export function useGetPriority(params: ISearchPriority) {
  return useQuery<unknown, unknown, IPriorityData>({
    queryKey: ['searchPriority', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<IPriorityData>>(
          `/PriorityMaster/SearchPriority`,
          { params }
        )

        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface AddPriorityOptions {
  body: IAddPriority
  params: Pick<ISearchPriority, 'termID'>
}

export function useAddPriority(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, AddPriorityOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/PriorityMaster/AddPriority`, body, {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchPriority'] })
    },
  })
}

interface EditPriorityOptions {
  body: IEditPriority
  params: Pick<ISearchPriority, 'termID'>
}

export function useEditPriority(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, EditPriorityOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/PriorityMaster/EditPriority`, body, {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchPriority'] })
    },
  })
}

interface DeletePriorityOptions {
  termID: string
  priorityID: number
}

export function useDeletePriority(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, DeletePriorityOptions>({
    mutationFn: async (params) => {
      const { data } = await api.delete('/PriorityMaster/DeletePriority', {
        params,
      })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchPriority'] })
    },
  })
}
