import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IDrawerProdGroupInformation } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchDrawerProdGroup {
  termID: string
  Page: number
  DrawerProdGroupCode?: string
  DrawerProdGroupName?: string
}

export interface IAddDrawerProdGroup {
  drawerProdGroupID: number
  drawerProdGroupCode: string
  drawerProdGroupName: string
  remark: string
}

export interface IEditDrawerProdGroup {
  drawerProdGroupID: number
  drawerProdGroupCode: string
  drawerProdGroupName: string
  remark: string
}

interface IResponseDrawerProdGroup {
  message: string
  code: number
  result: IDrawerProdGroupData
}

export interface IDrawerProdGroupData {
  result: IDrawerProdGroupInformation[]
  total: number
}

export function useSearchDrawerProdGroup(params: ISearchDrawerProdGroup) {
  return useQuery({
    queryKey: ['searchDrawer', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseDrawerProdGroup>(
          `/DrawerProdGroupMaster/SearchDrawerProdGroup`,
          { params }
        )

        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface IAddDrawerProdGroupOptions {
  body: IAddDrawerProdGroup
  params: Pick<ISearchDrawerProdGroup, 'termID'>
}

export function useAddDrawerProdGroup(cb?: () => void) {
  return useMutation({
    mutationFn: async ({ body, params }: IAddDrawerProdGroupOptions) => {
      const { data } = await api.post(
        `/DrawerProdGroupMaster/AddDrawerProdGroup`,
        body,
        {
          params,
        }
      )

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawer'] })
    },
  })
}

interface IEditDrawerProdGroupOptions {
  body: IEditDrawerProdGroup
  params: Pick<ISearchDrawerProdGroup, 'termID'>
}

export function useEditDrawerProdGroup(cb?: () => void) {
  return useMutation({
    mutationFn: async ({ body, params }: IEditDrawerProdGroupOptions) => {
      const { data } = await api.post(
        `/DrawerProdGroupMaster/EditDrawerProdGroup`,
        body,
        {
          params,
        }
      )

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawer'] })
    },
  })
}

interface IDeleteDrawerProdGroupRequestParams {
  termID: string
  drawerProdGroupID: number
}

export function useDeleteDrawerProdGroup(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    IDeleteDrawerProdGroupRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete(
        '/DrawerProdGroupMaster/DeleteDrawerProdGroup',
        { params }
      )

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawer'] })
    },
  })
}
