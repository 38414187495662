import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { ICustomerData } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchCustomer {
  CustomerCode?: string
  CustomerName?: string
  termID: string
  Page: number
}

export interface IAddCustomer {
  customerID: number
  customerCode: string
  customerName: string
  priorityID: number
  remark: string
}

export interface IEditCustomer {
  customerID: number
  customerCode: string
  customerName: string
  priorityID: number
  remark: string
}

interface IResponseCustomer {
  message: string
  code: number
  result: ICustomerData
}

export function useSearchCustomer(params: ISearchCustomer) {
  return useQuery<unknown, unknown, ICustomerData>({
    queryKey: ['searchCustomer', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseCustomer>(
          `/CustomerMaster/SearchCustomer`,
          { params }
        )
        if (data.message) {
          throw new Error(data.message)
        }
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface UseAddCustomerOptions {
  body: IAddCustomer
  params: Pick<ISearchCustomer, 'termID'>
}

export function useAddCustomer(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseAddCustomerOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/CustomerMaster/AddCustomer`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      queryClient.invalidateQueries({ queryKey: ['searchCustomer'] })
    },
  })
}

interface UseEditCustomerOptions {
  body: IEditCustomer
  params: Pick<ISearchCustomer, 'termID'>
}

export function useEditCustomer(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseEditCustomerOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/CustomerMaster/EditCustomer`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      queryClient.invalidateQueries({ queryKey: ['searchCustomer'] })
    },
  })
}

interface UseDeleteCustomerParams extends Pick<ISearchCustomer, 'termID'> {
  customerID: number
}

export function useDeleteCustomer(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseDeleteCustomerParams>({
    mutationFn: async (params) => {
      const { data } = await api.delete(`/CustomerMaster/DeleteCustomer`, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      queryClient.invalidateQueries({ queryKey: ['searchCustomer'] })
    },
  })
}
