import { useQuery } from '@tanstack/react-query'

import { api } from '@/services/apiClient'
import { GenericResponseBody } from '@/services/types'

import { createDownloadLink, formatISODate, handleQueryError } from '@/utils'

export interface SearchAuditReportRequestParams {
  termId: string
  changeby?: string
  tablename?: string
  startdate?: string
  enddate?: string
  key1?: string
  actionauditid?: number
  note?: string
  page: number
}

export interface GetAuditReportResponseBody {
  total: number
  result: GetAuditReportResponseData[]
}

export interface GetAuditReportResponseData {
  auditHeaderId: number
  auditActionId: number
  auditActionName: string
  tableName: string
  primaryKey: number
  key1: string | null
  key1Name: string | null
  key2: string | null
  key2Name: string | null
  key3: string | null
  key3Name: string | null
  key4: string | null
  key4Name: string | null
  key5: string | null
  key5Name: string | null
  auditDate: string
  displayAuditDate: string
  changeBy: string
  type: 'MASTER' | 'DETAIL'
  expanded: boolean
  note: string | null
}

export function useAuditReport(params: SearchAuditReportRequestParams) {
  return useQuery<unknown, unknown, GetAuditReportResponseBody>({
    queryKey: ['auditReport', params.page],
    queryFn: async () => {
      try {
        const { data } = await api.get<
          GenericResponseBody<GetAuditReportResponseBody>
        >('/AuditData/AuditReport', { params })
        if (data?.message) {
          throw new Error(data.message)
        }
        return {
          result: data.result.result.map((report) => ({
            ...report,
            type: 'MASTER',
            expended: false,
          })),
          total: data.result.total,
        }
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface GetAuditReportDetailRequestParams {
  AuditHeaderId: number
  termId: string
}

export interface AuditChanges {
  columnName: string
  oldValue: string
  newValue: string
}

export function useAuditReportDetail(
  params: GetAuditReportDetailRequestParams
) {
  return useQuery<unknown, unknown, AuditChanges[]>({
    queryKey: ['auditReportDetail', params.AuditHeaderId],
    queryFn: async () => {
      try {
        const { data } = await api.get<GenericResponseBody<AuditChanges[]>>(
          `/AuditData/AuditReportDetail/`,
          { params }
        )
        if (data?.message) {
          throw new Error(data.message)
        }
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useAuditReportExcel(params: SearchAuditReportRequestParams) {
  return useQuery<unknown, unknown, void>({
    enabled: false,
    queryKey: ['auditReportExcel'],
    queryFn: async () => {
      try {
        const { data } = await api.get<Blob>('/AuditData/AuditReportExcel', {
          responseType: 'blob',
          params,
        })
        createDownloadLink(data, `audit_${formatISODate(new Date())}`, 'xlsx')
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface GetAuditActionForDropdownResponseBody {
  auditActionId: number
  actionName: string
  auditTrails: null
}

export function useAuditActionForDropdown() {
  return useQuery<unknown, unknown, GetAuditActionForDropdownResponseBody[]>({
    queryKey: ['GetAuditActionForDropDown'],
    queryFn: async () => {
      try {
        const { data } = await api.get<
          GenericResponseBody<GetAuditActionForDropdownResponseBody[]>
        >('/AuditData/GetAuditActionForDropDown')
        if (data?.message) {
          throw new Error(data.message)
        }
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}
