import useAutoReLogin from '@/hooks/useAutoReLogin'

import QRegistration from '@/features/queue/registration'

const QRegistrationPage = () => {
  useAutoReLogin()
  return <QRegistration />
}

export default QRegistrationPage
