import { ChangeEvent, FC } from 'react'
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import { ISearchBayResult } from '@/services/api/bayMaster'

import Select from '@/standard/Select'
import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

import {
  extractOnlyNumericCharacters,
  parseNanToZero,
  parseStringToBoolean,
} from '@/utils'

interface BayFormProps {
  formState: FormState<ISearchBayResult>
  setValue: UseFormSetValue<ISearchBayResult>
  register: UseFormRegister<ISearchBayResult>
  watch: UseFormWatch<ISearchBayResult>
  isEdit?: boolean
}

const BayForm: FC<BayFormProps> = ({
  formState,
  setValue,
  register,
  watch,
  isEdit,
}) => {
  const bayState = isEdit
    ? 'em-is-disabled'
    : formState.errors.bay && 'em-has-error'
  const qCntHasError = formState.errors.qCnt && 'em-has-error'
  const bayCapacityHasError = formState.errors.bayCapacity && 'em-has-error'

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: keyof ISearchBayResult
  ) => {
    const newValue = event.target.value
    setValue(fieldName, extractOnlyNumericCharacters(newValue))
  }

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4">
        <TextInput
          id="bay"
          label="Bay"
          state={bayState}
          required
          maxLength={20}
          note={formState.errors.bay?.message}
          {...register('bay', {
            required: {
              value: true,
              message: 'Bay is required',
            },
          })}
        />
        <TextInput
          id="qCnt"
          label="Q Count"
          state={qCntHasError}
          required
          note={formState.errors.qCnt?.message}
          {...register('qCnt', {
            onChange: (e) => handleChange(e, 'qCnt'),
            setValueAs: parseNanToZero,
            required: {
              value: true,
              message: 'Q Count is required',
            },
            min: {
              value: watch('bayCapacity'),
              message: 'Q Count must be equal to or greater than Bay Capacity',
            },
          })}
        />
        <TextInput
          id="bayCapacity"
          label="Bay Capacity"
          state={bayCapacityHasError}
          required
          note={formState.errors.bayCapacity?.message}
          {...register('bayCapacity', {
            onChange: (e) => handleChange(e, 'bayCapacity'),
            setValueAs: parseNanToZero,
            required: {
              value: true,
              message: 'Bay Capacity is required',
            },
            max: {
              value: watch('qCnt'),
              message: 'Bay Capacity must be less than or equal to Q Count',
            },
          })}
        />
        <Select
          id="addBayIsTopLoad"
          label="Is Top Load"
          selectOptionalClass="w-full"
          required
          {...register('isTopLoad', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select
          id="isActive"
          label="Is Active"
          selectOptionalClass="w-full"
          required
          {...register('isActive', {
            setValueAs: parseStringToBoolean,
          })}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </div>
      <div className="grid grid-cols-1 gap-2 lg:gap-4 mt-6">
        <TextArea
          id="remark"
          label="Remark"
          textareaOptionalClass="!h-[6rem]"
          maxLength={200}
          {...register('remark')}
        />
      </div>
    </>
  )
}

export default BayForm
