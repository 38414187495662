import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { IBaseProdInformation } from '@/stores'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

interface BaseProdFormProps {
  isEdit?: boolean
}

const BaseProdForm: FC<BaseProdFormProps> = ({ isEdit }) => {
  const { formState, register } = useFormContext<IBaseProdInformation>()

  const baseProdCodeState = isEdit
    ? 'em-is-disabled'
    : formState.errors.baseProdCode && 'em-has-error'
  const baseProdNameState = formState.errors.baseProdName && 'em-has-error'

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <TextInput
        id="baseProdCode"
        label="Base Product Code"
        state={baseProdCodeState}
        required
        maxLength={20}
        note={formState.errors.baseProdCode?.message}
        {...register('baseProdCode', {
          required: {
            value: true,
            message: 'Base Product Code is required',
          },
        })}
      />
      <TextInput
        id="baseProdName"
        label="Base Product Name"
        state={baseProdNameState}
        required
        maxLength={50}
        note={formState.errors.baseProdName?.message}
        {...register('baseProdName', {
          required: {
            value: true,
            message: 'Base Product Name is required',
          },
        })}
      />
      <TextArea id="remark" label="Remark" {...register('remark')} />
    </div>
  )
}

export default BaseProdForm
