import { AuthenticationResult, EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from '@tanstack/react-router'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import msalPublicClient from './auth/authConfig'
import { queryClient } from './services/queryClient'
import { router } from './routes'
import './index.css'
import '../public/css/em-standard-2.0.3.min.css'
import 'react-data-grid/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { refetchRoleScreenMapping } from '@/services/api/roleMatrix'

msalPublicClient
  .initialize()
  .then(() => {
    const account = msalPublicClient.getActiveAccount()
    msalPublicClient.setActiveAccount(account)

    msalPublicClient.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult
        const { account } = payload
        msalPublicClient.setActiveAccount(account)
        refetchRoleScreenMapping(true)
      }
    })

    ReactDOM.createRoot(document.getElementById('root')!).render(
      <StrictMode>
        <MsalProvider instance={msalPublicClient}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <RouterProvider router={router} />
          </QueryClientProvider>
        </MsalProvider>
      </StrictMode>
    )
  })
  .catch(console.error)
