import { ChangeEvent, FC } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { BayAutoComplete } from '@/components'

import {
  IGetBayProdForActiveFunction,
  ISearchBayProduct,
  useGetBayProdForActiveFunction,
} from '@/services/api/bayProdMaster'
import { queryClient } from '@/services/queryClient'

import { IBayProdInformation } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { extractOnlyNumericCharacters } from '@/utils'

interface Form extends Omit<IGetBayProdForActiveFunction, 'BayID'> {
  bayID: number
}

interface ManageMultipleBayProductHeaderProps {
  currentPage: number
}

const ManageMultipleBayProductHeader: FC<
  ManageMultipleBayProductHeaderProps
> = ({ currentPage }) => {
  const { handleSubmit, register, setValue, watch, ...formMethods } =
    useForm<Form>({
      mode: 'onBlur',
    })

  const { refetch, isFetching } = useGetBayProdForActiveFunction({
    termID: '1002',
    Page: currentPage,
    ...(watch('bayID') ? { BayID: watch('bayID') } : {}),
    ...(watch('Arm') ? { Arm: watch('Arm') } : {}),
    ...(watch('BaseProdCode') ? { BaseProdCode: watch('BaseProdCode') } : {}),
    ...(watch('IsActive') ? { IsActive: watch('IsActive') } : {}),
  })

  const handleSearch: SubmitHandler<ISearchBayProduct> = () => {
    refetch()
  }

  const handleChangeArm = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('Arm', extractOnlyNumericCharacters(value))
  }

  const handleAllActive = () => {
    queryClient.setQueriesData<IBayProdInformation[]>(
      { queryKey: ['SearchBayProdForActiveFunction'] },
      (oldData) => {
        if (!oldData) {
          return oldData
        }
        return oldData.map((row) => {
          return { ...row, isActive: true, displayIsActive: 'Yes' }
        })
      }
    )
  }

  const handleAllInactive = () => {
    queryClient.setQueriesData<IBayProdInformation[]>(
      { queryKey: ['SearchBayProdForActiveFunction'] },
      (oldData) => {
        if (!oldData) {
          return oldData
        }
        return oldData.map((row) => {
          return { ...row, isActive: false, displayIsActive: 'No' }
        })
      }
    )
  }

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      register={register}
      setValue={setValue}
      watch={watch}
      {...formMethods}
    >
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Arm"
          id="arm"
          maxLength={9}
          {...register('Arm', {
            onChange: handleChangeArm,
          })}
        />
        <TextInput
          label="Base Product Code"
          id="BaseProdCode"
          maxLength={20}
          {...register('BaseProdCode')}
        />
        <BayAutoComplete isAbsolute />
        <Select
          id="isActive"
          label="Avaiable"
          selectOptionalClass="w-full"
          {...register('IsActive')}
        >
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? {
                iconName: 'refresh',
                isLoading: true,
                disabled: true,
              }
            : { iconName: 'search' })}
        />
        <Button
          typeAttribute="button"
          optionalClass="!p-[1.5em] self-end"
          label="All Active"
          onClick={handleAllActive}
        />
        <Button
          typeAttribute="button"
          optionalClass="!p-[1.5em] self-end"
          label="All Inactive"
          onClick={handleAllInactive}
        />
      </form>
    </FormProvider>
  )
}

export default ManageMultipleBayProductHeader
