import React from 'react'
import { twMerge } from 'tailwind-merge'

interface ButtonGroupProps {
  /** Select button group type */
  type?: 'em-c-btn-group--responsive'
  optionalClass?: string
  children?: React.ReactNode
}

const ButtonGroup = ({
  type,
  optionalClass,
  children,
  ...props
}: ButtonGroupProps) => {
  return (
    <div className={twMerge('em-c-btn-group', type, optionalClass)} {...props}>
      {children}
    </div>
  )
}

export default ButtonGroup
