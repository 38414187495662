import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetDrawerProdForDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface DrawerProdAutoCompleteField {
  drawerProdID: number | null
  drawerProdCode: string
}

interface DrawerProdAutoCompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
}

const DrawerProdAutoComplete: FC<DrawerProdAutoCompleteProps> = ({
  isRequired,
  isDisabled,
}) => {
  const { data: drawerProds, isLoading } = useGetDrawerProdForDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<DrawerProdAutoCompleteField>()

  const drawerProdState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.drawerProdCode && 'em-has-error'

  return (
    <Autocomplete
      state={drawerProdState}
      note={formState.errors.drawerProdCode?.message}
      id="drawerProdID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={drawerProds}
      label="Drawer Product"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('drawerProdCode')}
      maxLength={20}
      value={watch('drawerProdCode')}
      {...register('drawerProdCode', {
        required: isRequired ? 'Drawer is required' : false,
        validate: () =>
          watch('drawerProdID') === 0 ? 'Drawer is not valid' : undefined,
      })}
      onChange={(value) => {
        setValue(
          'drawerProdID',
          value?.label ? parseNanToZero(value?.value) : null
        )
        setValue('drawerProdCode', value?.label)
      }}
    />
  )
}

export default DrawerProdAutoComplete
