import { create } from 'zustand'

export interface IPriorityInformation {
  priorityID: number
  termID: string
  priorityCode: number
  priorityName: string
  delayMin: number
  dropMax: number
  remark: string
  createdDate: string
  createdBy: string
  modifiedDate: string
  modifiedBy: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
}

interface PriorityMasterState {
  currentPriority: IPriorityInformation | undefined
  setCurrentPriority: (value: IPriorityInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddPriorityModal: boolean
  setToggleAddPriorityModal: (value: boolean) => void
  toggleEditPriorityModal: boolean
  setToggleEditPriorityModal: (value: boolean) => void
}

export const usePriorityMasterStore = create<PriorityMasterState>((set) => ({
  currentPriority: undefined,
  setCurrentPriority: (value) => set({ currentPriority: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddPriorityModal: false,
  setToggleAddPriorityModal: (value) => set({ toggleAddPriorityModal: value }),
  toggleEditPriorityModal: false,
  setToggleEditPriorityModal: (value) =>
    set({ toggleEditPriorityModal: value }),
}))
