import App from '@/App'
import {
  createRootRoute,
  createRoute,
  createRouter,
  notFound,
} from '@tanstack/react-router'
import { AxiosError } from 'axios'

import msalPublicClient from '@/auth/authConfig'

import ErrorPage from '@/pages/errors'
import LoadingPage from '@/pages/loading'
import BaseProdMasterPage from '@/pages/master/baseProd'
import BayMasterPage from '@/pages/master/bay'
import BayProdMasterPage from '@/pages/master/bayProd'
import CarrierMasterPage from '@/pages/master/carrier'
import ContractPage from '@/pages/master/contract'
import CustomerMasterPage from '@/pages/master/customer'
import DrawerMasterPage from '@/pages/master/drawer'
import DrawerProdGroupMasterPage from '@/pages/master/drawerProdGroup'
import DrawerProductPage from '@/pages/master/drawerProduct'
import PersonnelMasterPage from '@/pages/master/personnel'
import PriorityMasterPage from '@/pages/master/priority'
import ShipmentPage from '@/pages/master/shipment'
import TankerMasterPage from '@/pages/master/tanker'
import BayMonitorPage from '@/pages/monitor/bay'
import OrderMonitorPage from '@/pages/monitor/order'
import NotFoundPage from '@/pages/notFound'
import AcknowledgementPage from '@/pages/queue/acknowledgement'
import QRegistrationPage from '@/pages/queue/registration'
import AuditReportPage from '@/pages/reports/audit'
import GateToGatePage from '@/pages/reports/gateToGate'
import ErrorReportPage from '@/pages/reports/mismatch'
import RoleMatrixPage from '@/pages/roleMatrix'
import GeneralSettingsPage from '@/pages/settings'

import { refetchRoleScreenMapping } from '@/services/api/roleMatrix'

import { useRoleMatrixStore } from '@/stores'

const rootRoute = createRootRoute({
  component: App,
  beforeLoad: async () => {
    const account = msalPublicClient.getActiveAccount()
    if (account) {
      return await refetchRoleScreenMapping(true)
    }
  },
})

const homePageRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: OrderMonitorPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { queueOrderMonitor } = useRoleMatrixStore.getState()
    if (!queueOrderMonitor?.canRead) {
      throw notFound()
    }
  },
})

const qRegistrationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/registration',
  component: QRegistrationPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { queueRegisteration } = useRoleMatrixStore.getState()
    if (!queueRegisteration?.canRead) {
      throw notFound()
    }
  },
})

const acknowledgementRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/acknowledgement',
  component: AcknowledgementPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { queueAcknowledgementDisplay } = useRoleMatrixStore.getState()
    if (!queueAcknowledgementDisplay?.canRead) {
      throw notFound()
    }
  },
})

const bayMonitorRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/bayMonitor',
  component: BayMonitorPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { queueBayMonitor } = useRoleMatrixStore.getState()
    if (!queueBayMonitor?.canRead) {
      throw notFound()
    }
  },
})

const orderMonitorRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/queueMonitor',
  component: OrderMonitorPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { queueOrderMonitor } = useRoleMatrixStore.getState()
    if (!queueOrderMonitor?.canRead) {
      throw notFound()
    }
  },
})

const roleMatrixRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/roleMatrix',
  component: RoleMatrixPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { roleMatrix } = useRoleMatrixStore.getState()
    if (!roleMatrix?.canRead) {
      throw notFound()
    }
  },
})

const drawerMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/drawerMaster',
  component: DrawerMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { drawerMaster } = useRoleMatrixStore.getState()
    if (!drawerMaster?.canRead) {
      throw notFound()
    }
  },
})

const shipmentRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/shipment',
  component: ShipmentPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { shipment } = useRoleMatrixStore.getState()
    if (!shipment?.canRead) {
      throw notFound()
    }
  },
})

const contractRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/contract',
  component: ContractPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { contract } = useRoleMatrixStore.getState()
    if (!contract?.canRead) {
      throw notFound()
    }
  },
})

const drawerProductRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/drawerProduct',
  component: DrawerProductPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { drawerProductMaster } = useRoleMatrixStore.getState()
    if (!drawerProductMaster?.canRead) {
      throw notFound()
    }
  },
})

const baseProdMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/baseProductMaster',
  component: BaseProdMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { baseProductMaster } = useRoleMatrixStore.getState()
    if (!baseProductMaster?.canRead) {
      throw notFound()
    }
  },
})

const priorityMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/priorityMaster',
  component: PriorityMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { priorityMaster } = useRoleMatrixStore.getState()
    if (!priorityMaster?.canRead) {
      throw notFound()
    }
  },
})

const personnelMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/personnelMaster',
  component: PersonnelMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { personnelMaster } = useRoleMatrixStore.getState()
    if (!personnelMaster?.canRead) {
      throw notFound()
    }
  },
})

const carrierMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/carrierMaster',
  component: CarrierMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { carrierMaster } = useRoleMatrixStore.getState()
    if (!carrierMaster?.canRead) {
      throw notFound()
    }
  },
})

const tankerMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/tankerMaster',
  component: TankerMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { tankerMaster } = useRoleMatrixStore.getState()
    if (!tankerMaster?.canRead) {
      throw notFound()
    }
  },
})

const customerMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/customerMaster',
  component: CustomerMasterPage,
  beforeLoad: () => {
    const { customerMaster } = useRoleMatrixStore.getState()
    if (!customerMaster?.canRead) {
      throw notFound()
    }
  },
})

const drawerProdGroupMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/drawerProductGroupMaster',
  component: DrawerProdGroupMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { drawerProductGroupMaster } = useRoleMatrixStore.getState()
    if (!drawerProductGroupMaster?.canRead) {
      throw notFound()
    }
  },
})

const bayProdMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/bayProductMaster',
  component: BayProdMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { bayProductMaster } = useRoleMatrixStore.getState()
    if (!bayProductMaster?.canRead) {
      throw notFound()
    }
  },
})

const bayMasterRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/bayMaster',
  component: BayMasterPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { bayMaster } = useRoleMatrixStore.getState()
    if (!bayMaster?.canRead) {
      throw notFound()
    }
  },
})

const generateSettingsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/generalSettings',
  component: GeneralSettingsPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { generalSetting } = useRoleMatrixStore.getState()
    if (!generalSetting?.canRead) {
      throw notFound()
    }
  },
})

const auditReportRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/auditReport',
  component: AuditReportPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { auditReport } = useRoleMatrixStore.getState()
    if (!auditReport?.canRead) {
      throw notFound()
    }
  },
})

const gateToGateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/gateToGate',
  component: GateToGatePage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { gateToGateReport } = useRoleMatrixStore.getState()
    if (!gateToGateReport?.canRead) {
      throw notFound()
    }
  },
})

const errorReportRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/errorReport',
  component: ErrorReportPage,
  beforeLoad: () => {
    const account = msalPublicClient.getActiveAccount()
    if (!account) {
      return
    }
    const { errorReport } = useRoleMatrixStore.getState()
    if (!errorReport?.canRead) {
      throw notFound()
    }
  },
})

const routeTree = rootRoute.addChildren([
  homePageRoute,
  qRegistrationRoute,
  acknowledgementRoute,
  bayMonitorRoute,
  orderMonitorRoute,
  roleMatrixRoute,
  baseProdMasterRoute,
  bayProdMasterRoute,
  carrierMasterRoute,
  customerMasterRoute,
  drawerMasterRoute,
  drawerProductRoute,
  drawerProdGroupMasterRoute,
  personnelMasterRoute,
  priorityMasterRoute,
  shipmentRoute,
  contractRoute,
  tankerMasterRoute,
  bayMasterRoute,
  generateSettingsRoute,
  auditReportRoute,
  gateToGateRoute,
  errorReportRoute,
])

export const router = createRouter({
  routeTree,
  defaultErrorComponent: ({ error }: { error: AxiosError | Error }) => {
    if (error instanceof AxiosError) {
      const { response } = error
      if (response?.data) {
        const { data, headers } = response
        if (
          typeof data === 'string' &&
          headers['content-type'].includes('text/html')
        ) {
          const title = data.match(/<title>(.*?)<\/title>/)?.[1]
          document.title = title || 'Error'
          return (
            <iframe
              style={{ width: '100%', height: '100%', border: 'none' }}
              srcDoc={data}
            />
          )
        }
      }
    }
    return msalPublicClient.getActiveAccount() ? (
      <ErrorPage message={error?.message} />
    ) : (
      <LoadingPage label="Just a moment, we're getting your account ready..." />
    )
  },
  defaultNotFoundComponent: NotFoundPage,
  defaultPendingComponent: LoadingPage,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
