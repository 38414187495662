import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IBomChildList } from '@/services/api/drawerProd'

import { useDrawerProductStore } from '@/stores'

import BomForm from '../forms/bom'

const AddBomModal: FC = () => {
  const { addBomChild, setToggleAddBomChildModal } = useDrawerProductStore()
  const methods = useForm<IBomChildList>({
    mode: 'onBlur',
    defaultValues: {
      bomid: 0,
    },
  })

  const handleClose = () => {
    setToggleAddBomChildModal(false)
  }

  const onSubmit = (data: IBomChildList) => {
    addBomChild(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a Bom"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Add"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <BomForm />
      </FormModal>
    </FormProvider>
  )
}

export default AddBomModal
