interface TreeNavigationProps {
  optionalClass?: string
  children: React.ReactNode
}

const TreeNavigation = ({
  children,
  optionalClass,
  ...props
}: TreeNavigationProps) => {
  return (
    <nav
      className={`em-c-tree ${optionalClass ? optionalClass : ''}`}
      aria-label="Tree navigation"
      {...props}
    >
      <ul
        className="em-c-tree__list em-c-tree__list--flush"
        role="menubar"
        aria-orientation="vertical"
      >
        {children}
      </ul>
    </nav>
  )
}

export default TreeNavigation
