import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'

import {
  IGetConfigResponseResult,
  useGetConfig,
  useUpdateConfig,
} from '@/services/api/generalSettings'

import { useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { extractOnlyNumericCharacters } from '@/utils'

const GeneralSetting = () => {
  const { data, isLoading } = useGetConfig({ termID: '1002' })
  const { generalSetting } = useRoleMatrixStore()
  const { canEdit } = generalSetting
  const { mutate, isPending } = useUpdateConfig()

  const { register, setValue, formState, handleSubmit } =
    useForm<IGetConfigResponseResult>({
      mode: 'onBlur',
      values: {
        ...data,
      } as IGetConfigResponseResult,
    })

  const maxOvertimeToAckState = isLoading
    ? 'em-is-disabled'
    : formState.errors.maxOvertimeToAck && 'em-has-error'
  const overtimeIntervalToAckMinState = isLoading
    ? 'em-is-disabled'
    : formState.errors.overtimeIntervalToAckMin && 'em-has-error'
  const maxOvertimeToLoadState = isLoading
    ? 'em-is-disabled'
    : formState.errors.maxOvertimeToLoad && 'em-has-error'
  const overtimeIntervalToLoadMinState = isLoading
    ? 'em-is-disabled'
    : formState.errors.overtimeIntervalToLoadMin && 'em-has-error'

  const handleChange = (
    fieldName: keyof IGetConfigResponseResult,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value
    setValue(fieldName, extractOnlyNumericCharacters(newValue))
  }

  const onSubmit = (body: IGetConfigResponseResult) => {
    mutate({
      termID: '1002',
      ...body,
    })
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4">
        <Select
          state={isLoading ? 'em-is-disabled' : undefined}
          label="Enable Auto Acknowledgement"
          id="enableAutoAck"
          {...register('enableAutoAck')}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select
          state={isLoading ? 'em-is-disabled' : undefined}
          label="Enable Bay Assignment"
          id="enableBayAssignment"
          {...register('enableBayAssignment')}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select
          state={isLoading ? 'em-is-disabled' : undefined}
          label="Enable Call To Bay"
          id="enableCallToBay"
          {...register('enableCallToBay')}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select
          state={isLoading ? 'em-is-disabled' : undefined}
          label="Enable Queue Registration"
          id="enableQueueRegister"
          {...register('enableQueueRegister')}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <TextInput
          id="maxOvertimeToAck"
          label="Max Overtime To Acknowledge"
          type="text"
          note={formState.errors.maxOvertimeToAck?.message}
          state={maxOvertimeToAckState}
          maxLength={9}
          {...register('maxOvertimeToAck', {
            onChange: (value) => handleChange('maxOvertimeToAck', value),
            validate: (value) => {
              if (+value <= 0) {
                return 'Value must be greater than 0'
              }
            },
          })}
        />
        <TextInput
          id="overtimeIntervalToAckMin"
          label="Overtime Interval To Acknowledge (min)"
          type="text"
          note={formState.errors.overtimeIntervalToAckMin?.message}
          state={overtimeIntervalToAckMinState}
          maxLength={9}
          {...register('overtimeIntervalToAckMin', {
            onChange: (value) =>
              handleChange('overtimeIntervalToAckMin', value),
            validate: (value) => {
              if (+value <= 0) {
                return 'Value must be greater than 0'
              }
            },
          })}
        />
        <TextInput
          id="maxOvertimeToLoad"
          label="Max Overtime To Load"
          type="text"
          note={formState.errors.maxOvertimeToLoad?.message}
          state={maxOvertimeToLoadState}
          maxLength={9}
          {...register('maxOvertimeToLoad', {
            onChange: (value) => handleChange('maxOvertimeToLoad', value),
            validate: (value) => {
              if (+value <= 0) {
                return 'Value must be greater than 0'
              }
            },
          })}
        />
        <TextInput
          id="overtimeIntervalToLoadMin"
          label="Overtime Interval To Load (min)"
          type="text"
          note={formState.errors.overtimeIntervalToLoadMin?.message}
          state={overtimeIntervalToLoadMinState}
          maxLength={9}
          {...register('overtimeIntervalToLoadMin', {
            onChange: (value) =>
              handleChange('overtimeIntervalToLoadMin', value),
            validate: (value) => {
              if (+value <= 0) {
                return 'Value must be greater than 0'
              }
            },
          })}
        />
      </div>
      {canEdit && (
        <div className="mt-6">
          <Button
            type="em-c-btn--primary"
            typeAttribute="submit"
            {...(isPending
              ? {
                  isIconOnlyButton: true,
                  iconName: 'refresh',
                  isLoading: true,
                  disabled: true,
                }
              : {
                  label: 'Save',
                  disabled: !formState.isValid,
                })}
          />
        </div>
      )}
    </form>
  )
}

export default GeneralSetting
