import { FC } from 'react'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

interface ErrorModalProps {
  onClose: () => void
  message: string
}

const ErrorModal: FC<ErrorModalProps> = ({ onClose, message }) => {
  return (
    <Modal modalWindowOptionalClass="!z-[90]" overlayOptionalClass="!z-[80]">
      <ModalHeader
        icon="circle-exclamation-mark-filled"
        title="Something went wrong"
        onClose={onClose}
      />
      <ModalBody>
        <p>{message}</p>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button type="em-c-btn--primary" label="OK" onClick={onClose} />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

export default ErrorModal
