import { FC } from 'react'

import { useGetDocumentInterfaceError } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

import Button from '@/standard/Button'
import Table, {
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '@/standard/Tables/Table'

import ReprocessInterfaceErrorModal from '../reprocessInterfaceErrorModal'

const SystemErrorTab: FC = () => {
  const {
    setIsReprocessModalOpen,
    isReprocessModalOpen,
    setCurrentInterfaceError,
  } = useOrderMonitor()

  const { data: documentInterfaceError } = useGetDocumentInterfaceError({
    termID: '1002',
  })

  const handleReprocessInterfaceErrorModal = () => {
    setIsReprocessModalOpen(true)
  }

  return (
    <>
      <div className="flex items-center h-11">
        <h4>System Error Report</h4>
      </div>
      <Table optionalClass="pt-4" type="em-c-table--striped">
        <TableHeader optionalClass="text-white">
          <TableHeaderItem optionalClass="!py-[6px]">Result</TableHeaderItem>
          <TableHeaderItem optionalClass="w-[100px] !py-[6px] text-center">
            Action
          </TableHeaderItem>
        </TableHeader>
        <TableBody>
          {documentInterfaceError?.result.map((item, index) => {
            return (
              <TableRow
                optionalClass="align-middle text-black bg-white !h-[50px]"
                type="em-c-table--condensed"
                key={`${item.tripNo}-${index}`}
              >
                <div>
                  <span>{item.errorDescription}</span>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <Button
                    isIconOnlyButton
                    typeAttribute="button"
                    label="Reprocess"
                    title="Reprocess"
                    iconName="refresh"
                    size="em-c-btn--small"
                    iconSize="em-c-icon--small"
                    onClick={() => {
                      setCurrentInterfaceError(item)
                      handleReprocessInterfaceErrorModal()
                    }}
                  />
                </div>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {isReprocessModalOpen && <ReprocessInterfaceErrorModal />}
    </>
  )
}

export default SystemErrorTab
