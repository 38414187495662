import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface LoadingIndicatorProps {
  /** Select loading indicator style */
  style?: 'icon-spinner-mono' | 'icon-spinner-inverted'
  /** Set animated svg size */
  size?: 'xl' | 'l' | 'm' | 's'
  /** Center the loading indicator */
  isFixed?: boolean
  isAbsolute?: boolean
  optionalClass?: string
  textOnly?: boolean
  label?: string
  isScreen?: boolean
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  style,
  size,
  optionalClass,
  isFixed,
  textOnly,
  label,
  isAbsolute,
  isScreen,
  ...props
}) => {
  if (textOnly) {
    return (
      <div
        className={twMerge(
          'flex items-center justify-center',
          isScreen && 'h-screen'
        )}
        role="alert"
        aria-live="assertive"
        aria-busy="true"
        aria-label="Loading, please wait"
      >
        <p className="text-2xl animate-pulse">{label || 'Loading...'}</p>
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        'em-c-loader',
        optionalClass,
        (isFixed || isAbsolute) && 'relative'
      )}
      role="alert"
      aria-live="assertive"
      aria-busy="true"
      aria-label="Loading, please wait"
      {...props}
    >
      <img
        src={`/images/${style ? style : 'icon-spinner'}.svg`}
        className={twMerge(
          `em-c-loader__icon-${size ? size : 'xl'}`,
          isFixed && 'fixed top-1/2 left-1/2',
          isAbsolute &&
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        )}
        alt="Loading"
      />
    </div>
  )
}

export default LoadingIndicator
