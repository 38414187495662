// const config =
//   window.location.hostname.indexOf("localhost") >= 0
//     ? Object.assign({}, window.localConfig)
//     : Object.assign({}, window.serverConfig);

// export default config;

const config = {
  authConfig: {
    tenantId: import.meta.env.VITE_AUTH_TENANT_ID,
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirectUri: window.location.origin,
    appScope: import.meta.env.VITE_AUTH_APP_SCOPE,
    authority: import.meta.env.VITE_AUTH_AUTHORITY,
  },
  baseApiAddressDev: import.meta.env.VITE_BASE_API_URL,
}

export default config
