import { ChangeEventHandler, ReactNode, useId } from 'react'
import { twMerge } from 'tailwind-merge'

interface ToggleItemProps {
  id?: string
  name?: string
  checked?: boolean
  value: string | number
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  optionalClass?: string
  children: ReactNode
  labelOptionalClass?: string
}

const ToggleItem = ({
  id,
  name,
  checked,
  value,
  onChange,
  disabled,
  optionalClass,
  children,
  labelOptionalClass,
  ...props
}: ToggleItemProps) => {
  const randomId = useId()

  return (
    <>
      <input
        id={id ? id : `toggle-${randomId}`}
        name={name}
        key={id ? id : `toggle-${randomId}`}
        type="radio"
        checked={checked}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={twMerge(
          'em-c-toggle__input em-u-is-vishidden',
          optionalClass
        )}
        {...props}
      />
      <label
        className={twMerge('em-c-toggle__label', labelOptionalClass)}
        htmlFor={id ? id : `toggle-${randomId}`}
      >
        {children}
      </label>
    </>
  )
}

export default ToggleItem
