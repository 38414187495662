import { FC } from 'react'

import TankerMasterAddModal from '@/features/master/tanker/addModal'

import { useTankerMasterStore } from '@/stores'

import TankerMasterDeleteModal from './deleteModal'
import TankerMasterEditModal from './editModal'
import Header from './header'

const TankerMasterComponent: FC = () => {
  const {
    toggleConfirmDeleteModal,
    toggleAddTankerModal,
    toggleEditTankerModal,
  } = useTankerMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleConfirmDeleteModal && <TankerMasterDeleteModal />}
      {toggleAddTankerModal && <TankerMasterAddModal />}
      {toggleEditTankerModal && <TankerMasterEditModal />}
      <Header />
    </div>
  )
}
export default TankerMasterComponent
