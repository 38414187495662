import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { TabItemProps } from './TabItem'

interface TabProps {
  type?: 'em-c-tabs--underline'
  children: React.ReactNode
  optionalClass?: string
  tabListOptionalClass?: string
  tabBodyOptionalClass?: string
}

const Tab = ({
  type,
  children,
  optionalClass,
  tabListOptionalClass,
  tabBodyOptionalClass,
  ...props
}: TabProps) => {
  const [currentTab, setCurrentTab] = useState(1)

  return (
    <div
      className={twMerge(
        'em-c-tabs em-js-tabs',
        type && type,
        optionalClass && optionalClass
      )}
      {...props}
    >
      <ul
        className={twMerge(
          'em-c-tabs__list',
          tabListOptionalClass && tabListOptionalClass
        )}
        role="tablist"
      >
        {React.Children.map(children, (child, i) => (
          <li key={i + 1} className="em-c-tabs__item" role="presentation">
            <a
              href={`#tab-panel-${i + 1}`}
              className={`em-c-tabs__link em-js-tab ${
                currentTab === i + 1 ? 'em-is-active' : ''
              }`}
              onClick={(e) => {
                e.preventDefault()
                setCurrentTab(i + 1)
              }}
              id={`tab-${i + 1}`}
              role="tab"
              aria-selected={currentTab === i + 1 ? 'true' : 'false'}
              aria-controls={`tab-panel-${i + 1}`}
            >
              {React.isValidElement(child) ? child.props.title : child}
            </a>
          </li>
        ))}
      </ul>
      <div
        className={twMerge(
          'em-c-tabs__body',
          type && 'em-c-tabs__body--no-border',
          tabBodyOptionalClass && tabBodyOptionalClass
        )}
      >
        {React.Children.map(children, (child, i) =>
          React.isValidElement<TabItemProps>(child)
            ? React.cloneElement(child, {
                id: i + 1,
                active: currentTab === i + 1,
              })
            : child
        )}
      </div>
    </div>
  )
}

export default Tab
