import { FC } from 'react'

import AuditReportHeader from '@/features/reports/audit/header'

import useAuditReportStore from '@/stores/reports/audit'

const AuditReportFeature: FC = () => {
  const { rows, setRows } = useAuditReportStore()

  const handleCollapseAll = () => {
    setRows(
      rows.map((row) => ({
        ...row,
        type: 'MASTER',
        expanded: false,
      }))
    )
  }

  return (
    <div className="flex flex-col h-full">
      <AuditReportHeader onCollapseAll={handleCollapseAll} />
    </div>
  )
}

export default AuditReportFeature
