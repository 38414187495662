import { FC, ReactNode, useEffect } from 'react'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

import useAlertInModalStore from '../../stores/common/alertInModal'

interface DeleteModalProps {
  children: ReactNode
  onClose: () => void
  onDelete: () => void
  title: string
  confirmText?: string
  modalWindowOptionalClass?: string
  overlayOptionalClass?: string
  isLoading?: boolean
}

const DeleteModal: FC<DeleteModalProps> = ({
  children,
  onClose,
  onDelete,
  title,
  confirmText,
  modalWindowOptionalClass,
  overlayOptionalClass,
  isLoading,
}) => {
  const { setIsAlertInModalOpen } = useAlertInModalStore()

  const handleClose = () => {
    setIsAlertInModalOpen(false)
    onClose()
  }

  useEffect(() => {
    return () => {
      setIsAlertInModalOpen(false)
    }
  }, [])

  return (
    <Modal
      modalWindowOptionalClass={modalWindowOptionalClass}
      overlayOptionalClass={overlayOptionalClass}
    >
      <ModalHeader title={title} onClose={handleClose} isError />
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            type="em-c-btn--primary"
            onClick={onDelete}
            {...(isLoading
              ? {
                  isIconOnlyButton: true,
                  iconName: 'refresh',
                  isLoading: true,
                  disabled: true,
                }
              : { label: confirmText || 'Delete' })}
          />
          <Button label="Cancel" onClick={handleClose} />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal
