import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import BayMasterTable from '@/features/master/bay/tables/parent'

import { ISearchBayRequestParams, useSearchBay } from '@/services/api/bayMaster'

import { useBayMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

export type BayMasterHeaderInputs = Omit<
  ISearchBayRequestParams,
  'termID' | 'Page'
>

interface HeaderProps {
  onManageMultipleBay?: () => void
}

const BayMasterHeader: FC<HeaderProps> = ({ onManageMultipleBay }) => {
  const [Page, setPage] = useState<number>(1)
  const { setToggleAddBayModal } = useBayMasterStore()
  const { bayMaster } = useRoleMatrixStore()
  const { canCreate } = bayMaster

  const { register, handleSubmit, watch } = useForm<BayMasterHeaderInputs>({
    mode: 'onBlur',
    defaultValues: {
      bay: '',
      IsTopLoad: '',
      IsActive: '',
      IsDedicate: '',
    },
  })

  const {
    refetch,
    isFetching,
    isLoading,
    data: bays,
  } = useSearchBay({
    termID: '1002',
    Page,
    ...filterFalsyProperties(watch()),
  })

  const onSubmit: SubmitHandler<BayMasterHeaderInputs> = () => {
    refetch().finally(() => setPage(1))
  }

  const handleAddBay = () => {
    setToggleAddBayModal(true)
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput id="bay" label="Bay" maxLength={20} {...register('bay')} />
        <Select id="IsTopLoad" label="Is Top Load" {...register('IsTopLoad')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select id="IsActive" label="Is Active" {...register('IsActive')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Select id="IsDedicate" label="Is Dedicate" {...register('IsDedicate')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            label="+ Add"
            optionalClass="!p-[1.5em] self-end"
            onClick={handleAddBay}
          />
        )}
        <Button
          optionalClass="!p-[1.5em] self-end ml-auto"
          type="em-c-btn--primary"
          typeAttribute="button"
          label="Manage Multiple Bay"
          onClick={onManageMultipleBay}
        />
      </form>
      <BayMasterTable
        currentPage={Page}
        onChangePage={setPage}
        isLoading={isLoading}
        bays={bays}
      />
    </>
  )
}

export default BayMasterHeader
