import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import PriorityMasterForm from '@/features/master/priority/form'

import { IAddPriority, useAddPriority } from '@/services/api/priorityMaster'

import { usePriorityMasterStore } from '@/stores'

const PriorityAddModal = () => {
  const { setToggleAddPriorityModal } = usePriorityMasterStore()

  const handleClose = () => {
    setToggleAddPriorityModal(false)
  }

  const { mutate, isPending } = useAddPriority(handleClose)

  const methods = useForm<IAddPriority>({
    defaultValues: {
      priorityID: 0,
      priorityCode: 0,
      delayMin: 0,
      dropMax: 0,
      priorityName: '',
      remark: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (body: IAddPriority) => {
    mutate({
      body,
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a priority"
        confirmLabel="Add"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <PriorityMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default PriorityAddModal
