import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteTanker } from '@/services/api/tankerMaster'

import { useTankerMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const TankerMasterDeleteModal: FC = () => {
  const { currentTanker, setCurrentTanker, setToggleConfirmDeleteModal } =
    useTankerMasterStore()
  const handleClose = () => {
    setCurrentTanker(undefined)
    setToggleConfirmDeleteModal(false)
  }

  const { mutate, isPending } = useDeleteTanker(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', tankerID: currentTanker!.tankerID })
  }

  return (
    <DeleteModal
      title="Delete a tanker"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Tanker iButton: {currentTanker?.tankerIButton}
        </BulletedListItem>
        <BulletedListItem>
          Tanker Code: {currentTanker?.tankerCode}
        </BulletedListItem>
        <BulletedListItem>
          License Plate: {currentTanker?.licensePlate}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default TankerMasterDeleteModal
