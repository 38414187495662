import { format, lastDayOfMonth } from 'date-fns'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DatePicker } from '@/components'

import ErrorReportTable from '@/features/reports/mismatch/table'

import {
  SearchErrorReportRequestParams,
  useErrorReport,
  useErrorReportExcel,
} from '@/services/api/errorReport'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties, formatISODate } from '@/utils'

const ErrorReportHeader: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { watch, handleSubmit, register } =
    useForm<SearchErrorReportRequestParams>({
      mode: 'onBlur',
      defaultValues: {
        startdate: format(new Date(), "yyyy-MM-01'T'00:00"),
        enddate: format(lastDayOfMonth(new Date()), "yyyy-MM-dd'T'23:59"),
      },
    })

  const {
    refetch,
    isFetching,
    data: reports,
    isLoading,
  } = useErrorReport({
    ...filterFalsyProperties(watch()),
    ...(watch('startdate')
      ? { startdate: formatISODate(watch('startdate')) }
      : {}),
    ...(watch('enddate') ? { enddate: formatISODate(watch('enddate')) } : {}),
    termId: '1002',
    page: currentPage,
  })

  const { refetch: download } = useErrorReportExcel({
    ...filterFalsyProperties(watch()),
    ...(watch('startdate')
      ? { startdate: formatISODate(watch('startdate')) }
      : {}),
    ...(watch('enddate') ? { enddate: formatISODate(watch('enddate')) } : {}),
    termId: '1002',
    page: currentPage,
  })

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const handleDownload = () => {
    download()
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Load ID"
          id="LoadId"
          maxLength={20}
          {...register('LoadId')}
        />
        <TextInput
          label="Document Number"
          id="DocumentNr"
          maxLength={20}
          {...register('DocumentNr')}
        />
        <TextInput
          label="License Plate"
          id="licensePlate"
          maxLength={50}
          {...register('LicensePlate')}
        />
        <DatePicker
          label="Start Date"
          id="startdate"
          {...register('startdate', {
            setValueAs: formatISODate,
          })}
        />
        <DatePicker
          label="End Date"
          id="enddate"
          {...register('enddate', {
            setValueAs: formatISODate,
          })}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        <Button
          typeAttribute="button"
          optionalClass="!p-[1.5em] self-end"
          label="Download Report"
          onClick={handleDownload}
        />
      </form>
      <ErrorReportTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        reports={reports}
        isLoading={isLoading}
      />
    </>
  )
}

export default ErrorReportHeader
