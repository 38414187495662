import { useQuery } from '@tanstack/react-query'

import { api } from '@/services/apiClient'
import { GenericResponseBody } from '@/services/types'

import { createDownloadLink, formatISODate, handleQueryError } from '@/utils'

export interface SearchGateToGateRequestParams {
  termId: string
  documentNr?: string
  licensePlate?: string
  carrierCode?: string
  startdate?: string
  enddate?: string
  page: number
}

export interface GetGateToGateResponseBody {
  total: number
  result: GetGateToGateResponseData[]
}

export interface GetGateToGateResponseData {
  termID: string
  documentType: string
  documentNr: string
  fullName: string
  licensePlate: string
  carrierCode: string
  drawerCode: string
  createDTM: string
  displayCreateDTM: string
  pickDTM: string
  displayPickDTM: string
  durationWaitForBayAssignment: string
  ackownledgeDTM: string
  displayAckownledgeDTM: string
  durationAckownledgement: string
  bay: string
  callTDM: string
  displayCallTDM: string
  durationWaitForCallToBay: string
  startDTM: string | null
  displayStartDTM: string | null
  durationWaitForStartLoad: string | null
  endDTM: string | null
  displayEndDTM: string | null
  durationLoadTime: string | null
  totalTime: string
}

export function useGateToGateReport(params: SearchGateToGateRequestParams) {
  return useQuery<unknown, unknown, GetGateToGateResponseBody>({
    queryKey: ['gateToGate', params.page],
    queryFn: async () => {
      try {
        const { data } = await api.get<
          GenericResponseBody<GetGateToGateResponseBody>
        >('/GateToGateReport/Report', { params })
        if (data?.message) {
          throw new Error(data.message)
        }
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGateToGateReportExcel(
  params: SearchGateToGateRequestParams
) {
  return useQuery<unknown, unknown, void>({
    queryKey: ['gateToGateExcel'],
    enabled: false,
    queryFn: async () => {
      try {
        const { data } = await api.get<Blob>('/GateToGateReport/Export', {
          responseType: 'blob',
          params,
        })
        createDownloadLink(
          data,
          `gate_to_gate_${formatISODate(new Date())}`,
          'xlsx'
        )
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}
