import { useEffect } from 'react'

import { useAlertInModalStore } from '@/stores'

import Alert from '@/standard/Alert'

import GeneralSetting from './general'

const GeneralSettingsFeature = () => {
  const {
    alertInModalMessage: body,
    isAlertInModalOpen: visible,
    setIsAlertInModalOpen,
  } = useAlertInModalStore()

  useEffect(() => {
    return () => {
      setIsAlertInModalOpen(false)
    }
  }, [])

  return (
    <>
      <Alert
        optionalClass="mt-4"
        type="em-c-alert--error"
        body={body}
        visible={visible}
        onClose={() => setIsAlertInModalOpen(false)}
      />
      <GeneralSetting />
    </>
  )
}

export default GeneralSettingsFeature
