import React, {
  ChangeEventHandler,
  SelectHTMLAttributes,
  forwardRef,
} from 'react'
import { twMerge } from 'tailwind-merge'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  /** Option/validation status, one of: */
  state?: 'em-is-valid' | 'em-has-error' | 'em-is-readonly' | 'em-is-disabled'
  /** The `id` attribute is needed to associate the drop-down list with a label */
  id?: string
  /** Label that sits above the drop-down list */
  label?: string
  /** Defines a name for the drop-down list */
  name?: string
  /** Adds an asterisk (*) to mark the field as required */
  required?: boolean
  onChange?: ChangeEventHandler<HTMLSelectElement>
  /** A note about the option/validation status */
  note?: string
  children: React.ReactNode
  optionalClass?: string
  bodyOptionalClass?: string
  selectOptionalClass?: string
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      state,
      id = 'select',
      label,
      name,
      value,
      required,
      onChange,
      note,
      children,
      optionalClass,
      bodyOptionalClass,
      selectOptionalClass,
      ...props
    },
    ref
  ) => {
    return (
      <div className={twMerge('em-c-field !mb-[0]', state, optionalClass)}>
        {label && (
          <label htmlFor={id} className="em-c-field__label" id="labelSelect">
            {required && (
              <span aria-hidden="true" className="em-c-field__required">
                *
              </span>
            )}
            {label}
          </label>
        )}
        <div className={twMerge('em-c-field__body !mb-[0]', bodyOptionalClass)}>
          <select
            className={twMerge('em-c-select', selectOptionalClass)}
            id={id}
            onChange={onChange}
            value={value}
            disabled={state === 'em-is-disabled'}
            name={name}
            ref={ref}
            {...props}
          >
            {children}
          </select>
        </div>
        {note && (
          <div className="em-c-field__note" id="descSelect">
            {note}
          </div>
        )}
      </div>
    )
  }
)

export default Select
