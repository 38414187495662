import React from 'react'

interface VerticalHeaderSolidNavProps {
  children: React.ReactNode
  optionalClass?: string
}

const VerticalHeaderSolidNav = ({
  children,
  optionalClass,
}: VerticalHeaderSolidNavProps) => {
  return (
    <div className={`em-c-solid-nav ${optionalClass ? optionalClass : ''}`}>
      <ul
        className="em-c-solid-nav__list"
        role="menubar"
        aria-orientation="vertical"
        id="verticalMenu"
      >
        {children}
      </ul>
    </div>
  )
}

export default VerticalHeaderSolidNav
