import { create } from 'zustand'

interface SideBarState {
  isMasterMenuOpen: boolean
  setMasterMenuOpen: () => void
  isReportMenuOpen: boolean
  setReportMenuOpen: () => void
}

const useSideBarStore = create<SideBarState>((set) => ({
  isMasterMenuOpen: false,
  setMasterMenuOpen: () =>
    set((state) => ({ isMasterMenuOpen: !state.isMasterMenuOpen })),
  isReportMenuOpen: false,
  setReportMenuOpen: () =>
    set((state) => ({ isReportMenuOpen: !state.isReportMenuOpen })),
}))

export default useSideBarStore
