import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  IDataForSelectContractProd,
  IRegistrationQueue,
  ISelectedProdDetails,
  ISelectedShipmentList,
} from '@/services/api/qRegistration'

import { useQueueRegistrationStore } from '@/stores'

import ConfirmEditContractModal from './confirmEditContract'
import ConfirmRemoveModal from './confirmRemoveModal'

const SummaryQueueComponent = () => {
  const {
    personnelIButton,
    tankerIButton,
    setStep,
    summaryData,
    setQueueData,
    editContract,
    setEditContract,
    removeOrder,
    setRemoveOrder,
    orderList,
    setOrderList,
    setIsGoToHome,
  } = useQueueRegistrationStore()

  const [allSelectedShipment, setAllSelectedShipment] =
    useState<ISelectedShipmentList[]>()
  const [allSelectedShipmentID, setAllSelectedShipmentID] = useState<
    number[] | []
  >()
  const [allSelectedContract, setAllSelectedContract] =
    useState<IDataForSelectContractProd[]>()
  const [toggleConfirmModal, setToggleConfirmModal] = useState(false)
  const [
    toggleConfirmRemoveShipmentModal,
    setToggleConfirmRemoveShipmentModal,
  ] = useState(false)
  const [
    toggleConfirmRemoveContractModal,
    setToggleConfirmRemoveContractModal,
  ] = useState(false)

  const onRemoveShipment = () => {
    const newAllShipment = allSelectedShipment?.filter(
      (item: ISelectedShipmentList) =>
        item.documentID !== removeOrder?.documentID
    )
    const allID = newAllShipment?.map(
      (item: ISelectedShipmentList) => item.documentID
    )
    setAllSelectedShipment(newAllShipment)
    setAllSelectedShipmentID(allID)

    const newOrderList = orderList.filter(
      (order) => order !== removeOrder?.documentNr
    )
    setOrderList(newOrderList)

    closeConfirmRemoveModal()

    if (newAllShipment?.length === 0 && allSelectedContract?.length === 0) {
      const queueData: IRegistrationQueue = {
        termID: '1002',
        personnelIButton,
        tankerIButton,
        shipmentList: [],
        contractList: [],
      }
      setQueueData(queueData)
      setStep('validateDocument')
    }
  }

  const onRemoveContract = () => {
    const newAllContract = allSelectedContract?.filter(
      (item: IDataForSelectContractProd) =>
        item.documentID !== removeOrder?.documentID
    )
    setAllSelectedContract(newAllContract)

    const newOrderList = orderList.filter(
      (order) => order !== removeOrder?.documentNr
    )
    setOrderList(newOrderList)

    closeConfirmRemoveModal()

    if (newAllContract?.length === 0 && allSelectedShipment?.length === 0) {
      const queueData: IRegistrationQueue = {
        termID: '1002',
        personnelIButton,
        tankerIButton,
        shipmentList: [],
        contractList: [],
      }
      setQueueData(queueData)
      setStep('validateDocument')
    }
  }

  // set all select when page loaded
  useEffect(() => {
    if (summaryData?.selectedShipmentList?.length) {
      const allID = summaryData?.selectedShipmentList?.map(
        (item: ISelectedShipmentList) => item.documentID
      )
      setAllSelectedShipment(summaryData.selectedShipmentList)
      setAllSelectedShipmentID(allID)
    }
    setAllSelectedContract(summaryData?.selectedContractList)
  }, [summaryData])

  const onAddMoreDocument = () => {
    const queueData: IRegistrationQueue = {
      termID: '1002',
      personnelIButton,
      tankerIButton,
      shipmentList: allSelectedShipment,
      contractList: allSelectedContract,
    }
    setQueueData(queueData)
    setStep('validateDocument')
  }
  const onConfirmPhone = () => {
    const queueData: IRegistrationQueue = {
      termID: '1002',
      personnelIButton,
      tankerIButton,
      shipmentList: allSelectedShipment,
      contractList: allSelectedContract,
    }
    setQueueData(queueData)
    setStep('confirmPhoneNumber')
  }
  const onClickEditContract = (item: ISelectedShipmentList) => {
    const queueData: IRegistrationQueue = {
      termID: '1002',
      personnelIButton,
      tankerIButton,
      shipmentList: allSelectedShipment,
      contractList: allSelectedContract,
    }

    setQueueData(queueData)
    setEditContract(item)
    setToggleConfirmModal(true)
  }
  const closeConfirmModal = () => {
    setToggleConfirmModal(false)
  }

  const closeConfirmRemoveModal = () => {
    setToggleConfirmRemoveShipmentModal(false)
    setToggleConfirmRemoveContractModal(false)
    setRemoveOrder(undefined)
  }

  return (
    <div className="flex max-w-screen-xl h-full mx-auto py-10 bg-transparent">
      <div className="flex flex-col gap-4 w-full h-full bg-white rounded-md shadow-xl p-10 z-10">
        <h1 className=" font-bold">Confirm pick up</h1>
        <div className="overflow-auto min-h-0 flex-1 space-y-2">
          {allSelectedShipment?.map((item: ISelectedShipmentList) => {
            return (
              <div
                key={item.documentID}
                className="flex gap-4 p-2 border hover:border-blue-600 hover:bg-blue-200"
              >
                <div className="flex flex-col flex-1 gap-2">
                  <div className="flex justify-between items-center">
                    <h2>{`Shipment: ${item.documentNr}`}</h2>
                    <div className="flex gap-4">
                      {item?.type === 3 && (
                        <button
                          className="p-1 rounded-md border"
                          onClick={() => {
                            onClickEditContract(item)
                          }}
                        >
                          <svg className="em-c-icon em-c-icon--medium text-slate-500">
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="/images/24/em-icons.svg#edit"
                            ></use>
                          </svg>
                        </button>
                      )}
                      <button
                        className="p-1 rounded-md border"
                        onClick={() => {
                          setRemoveOrder(item)
                          setToggleConfirmRemoveShipmentModal(true)
                        }}
                      >
                        <svg className="em-c-icon em-c-icon--medium ">
                          <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xlinkHref="images/16/em-icons.svg#trashcan"
                          ></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <ul className="pl-10">
                    {item.details.map((detail: ISelectedProdDetails) => {
                      return (
                        <li
                          key={detail.drawerProdID}
                          className="flex justify-between items-center"
                        >
                          <h3>{`Product: ${detail.drawerProdName} - ${detail.drawerProdCode}`}</h3>{' '}
                          <h3>{`${detail.compartmentCnt} Compartment`}</h3>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })}
          {allSelectedContract?.map((item: ISelectedShipmentList) => {
            return (
              <div
                key={item.documentID}
                className="flex gap-4 p-2 border hover:border-blue-600 hover:bg-blue-200"
              >
                <div className="flex flex-col flex-1 gap-2">
                  <div className="flex items-center justify-between">
                    <h2>{`Contract: ${item.documentNr}`}</h2>
                    <div className="flex gap-4">
                      <button
                        className="p-1 rounded-md border"
                        onClick={() => {
                          onClickEditContract(item)
                        }}
                      >
                        <svg className="em-c-icon em-c-icon--medium text-slate-500">
                          <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xlinkHref="/images/24/em-icons.svg#edit"
                          ></use>
                        </svg>
                      </button>
                      <button
                        className="p-1 rounded-md border"
                        onClick={() => {
                          setRemoveOrder(item)
                          setToggleConfirmRemoveContractModal(true)
                        }}
                      >
                        <svg className="em-c-icon em-c-icon--medium ">
                          <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xlinkHref="images/16/em-icons.svg#trashcan"
                          ></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <ul className="pl-10">
                    {item.details.map((detail: ISelectedProdDetails) => {
                      return (
                        <li
                          key={detail.drawerProdID}
                          className="flex justify-between items-center"
                        >
                          <h3>{`Product: ${detail.drawerProdName} - ${detail.drawerProdCode}`}</h3>{' '}
                          <h3>{`${detail.compartmentCnt} Compartment`}</h3>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
        <div className="flex gap-4 justify-center items-center">
          <button
            className="em-c-btn !px-12 !py-10 text-2xl"
            onClick={() => {
              setIsGoToHome(true)
            }}
          >
            Go to Home
          </button>
          <button
            className="em-c-btn !px-12 !py-10 text-2xl"
            onClick={() => onAddMoreDocument()}
          >
            <span className="em-c-btn__text !px-12 !py-10 text-2xl">
              Add More
            </span>
          </button>
          <button
            className={twMerge(
              'em-c-btn em-c-btn--primary !px-12 !py-10 text-2xl',
              allSelectedContract?.length || allSelectedShipmentID?.length
                ? 'em-c-btn--primary'
                : 'em-c-btn--disabled'
            )}
            onClick={() => {
              onConfirmPhone()
            }}
            disabled={
              !allSelectedContract?.length && !allSelectedShipmentID?.length
            }
          >
            <span className="em-c-btn__text">Next</span>
          </button>
        </div>
      </div>
      {editContract?.documentID && (
        <ConfirmEditContractModal
          toggleModal={toggleConfirmModal}
          closeModal={closeConfirmModal}
          contractDetail={editContract}
        />
      )}
      {toggleConfirmRemoveShipmentModal && removeOrder?.documentID && (
        <ConfirmRemoveModal
          onClose={closeConfirmRemoveModal}
          onDelete={() => {
            onRemoveShipment()
          }}
        >
          <p>{`Confirmed to REMOVE order: ${removeOrder.documentNr}`}</p>
        </ConfirmRemoveModal>
      )}
      {toggleConfirmRemoveContractModal && removeOrder?.documentID && (
        <ConfirmRemoveModal
          onClose={closeConfirmRemoveModal}
          onDelete={() => {
            onRemoveContract()
          }}
        >
          <p>{`Confirmed to REMOVE order: ${removeOrder.documentNr}`}</p>
        </ConfirmRemoveModal>
      )}
    </div>
  )
}

export default SummaryQueueComponent
