import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useEnableQueue } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

const EnableQueueModal: FC = () => {
  const { setIsEnableQueueModalOpen, currentWaitingQueue } = useOrderMonitor()

  const handleClose = () => {
    setIsEnableQueueModalOpen(false)
  }
  const { mutate: enableQueue } = useEnableQueue(handleClose)

  const handleConfirm = () => {
    enableQueue({
      termID: '1002',
      tankerID: Number(currentWaitingQueue?.tankerID),
    })
  }

  return (
    <DeleteModal
      title="Enable this queue"
      confirmText="Confirm"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>
        Are you sure you want to enable the truck
        <span className="font-bold pl-2">
          {currentWaitingQueue?.licensePlate}
        </span>
        ?
      </p>
    </DeleteModal>
  )
}
export default EnableQueueModal
