import { FC, useEffect } from 'react'

import {
  IAcknowledge,
  acknowledgementService,
} from '@/services/api/acknowledgement'
import { qRegistrationService } from '@/services/api/qRegistration'

import { useQueueAcknowledgementStore } from '@/stores'

import { findDiffObjects } from '@/utils'

import DisplayQueueTable from './queueTable'
import { voiceOver } from './voiceOver'

interface AcknowledgementProps {
  isFullScreen: boolean
}

const AcknowledgementComponent: FC<AcknowledgementProps> = () => {
  const {
    remainingWaitingQueue,
    remainingGotoBayQueue,
    setRemainingWaitingQueue,
    setRemainingGotoBayQueue,
    setDiffWaitingQueue,
    setDiffGotoBayQueue,
  } = useQueueAcknowledgementStore()

  const { data: isEnableQueueRegister } =
    qRegistrationService.useGetEnableQueueRegister({
      termID: '1002',
    })

  const { data, isRefetching } =
    acknowledgementService.useGetQueueAcknowledge('1002')

  const processQueue = (
    oldQueue: IAcknowledge[],
    newQueueData: IAcknowledge[] | undefined
  ) => {
    const newQueue = (newQueueData || []).filter(Boolean)
    const diffQueue = findDiffObjects(oldQueue, newQueue)
    diffQueue.forEach((queue) => {
      voiceOver(
        `Driver name ${queue.driverName
          .split('')
          .join(' ')} please proceed to ${queue.bayList[0]}`
      )
    })
    return [newQueue, diffQueue]
  }

  useEffect(() => {
    if (!data?.code || isRefetching) return

    const [waitingQueue, diffWaitingQueue] = processQueue(
      remainingWaitingQueue as IAcknowledge[],
      [
        ...(data?.waitAcknowledgeListFirst || []),
        ...(data?.waitAcknowledgeListSecond || []),
      ] as IAcknowledge[]
    )
    setRemainingWaitingQueue(waitingQueue)
    setDiffWaitingQueue(diffWaitingQueue)

    const [gotoBayQueue, diffGotoBayQueue] = processQueue(
      remainingGotoBayQueue as IAcknowledge[],
      [...(data?.gotoBayListFirst || []), ...(data?.gotoBayListSecond || [])]
    )
    setRemainingGotoBayQueue(gotoBayQueue)
    setDiffGotoBayQueue(diffGotoBayQueue)
  }, [data, isRefetching])

  return isEnableQueueRegister !== false ? (
    <>
      <div className="grid grid-cols-2 h-full">
        <div className="flex flex-col">
          <div className="text-center font-bold scale-font bg-[#FFD700] border-r-2">
            <span>WAIT</span>
          </div>
          <div className="grid text-xxs lg:text-2xl font-bold bg-black text-white">
            <div className="flex border border-gray-200 px-2 2xl:px-6">
              <div className="w-6/12">NAME</div>
              <div className="w-6/12">RACK NO.</div>
            </div>
          </div>
          <DisplayQueueTable data={data?.waitAcknowledgeListFirst} />
          <DisplayQueueTable isStart data={data?.waitAcknowledgeListSecond} />
        </div>
        <div className="flex flex-col">
          <div className="text-white text-center font-bold scale-font bg-[#017C38] border-l-2">
            <span>READY</span>
          </div>
          <div className="grid text-xxs lg:text-2xl font-bold bg-black text-white">
            <div className="flex border border-gray-200 border-l-2 px-2 2xl:px-6">
              <div className="w-6/12">NAME</div>
              <div className="w-6/12">RACK NO.</div>
            </div>
          </div>
          <DisplayQueueTable isEnd data={data?.gotoBayListFirst} />
          <DisplayQueueTable data={data?.gotoBayListSecond} />
        </div>
      </div>
    </>
  ) : (
    <div className="w-full h-full relative">
      <img
        src="/images/Lehmann.jpg"
        className="w-full h-full absolute z-0 brightness-50 rounded-lg"
      />
      <div className="flex items-center w-full h-full justify-center p-20 z-10 absolute mx-auto">
        <div className="flex flex-col items-center justify-center gap-20 w-3/4 h-3/4 bg-white rounded-lg shadow-2xl p-10">
          <p className="text-4xl xl:text-6xl font-extrabold m-0 text-center">
            Good day, please continue your activities as usual.
          </p>
          <p className="text-lg xl:text-2xl m-0 text-center">
            (Queuing System is out of service at the moment.)
          </p>
        </div>
      </div>
    </div>
  )
}

export default AcknowledgementComponent
