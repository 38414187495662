import { useState } from 'react'
import { useForm } from 'react-hook-form'

import PriorityMasterTable from '@/features/master/priority/table'

import { ISearchPriority, useGetPriority } from '@/services/api/priorityMaster'

import { usePriorityMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

const PriorityMasterHeader = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddPriorityModal } = usePriorityMasterStore()
  const { priorityMaster } = useRoleMatrixStore()
  const { canCreate } = priorityMaster
  const { handleSubmit, watch, register } = useForm<ISearchPriority>({
    defaultValues: {
      PriorityName: '',
    },
    mode: 'onBlur',
  })

  const {
    refetch,
    isFetching,
    isLoading,
    data: priorities,
  } = useGetPriority({
    ...filterFalsyProperties(watch()),
    Page: currentPage,
    termID: '1002',
  })

  const handleAdd = () => {
    setToggleAddPriorityModal(true)
  }

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Priority Name"
          id="PriorityName"
          maxLength={50}
          {...register('PriorityName')}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={handleAdd}
          />
        )}
      </form>
      <PriorityMasterTable
        isLoading={isLoading}
        priorities={priorities}
        currentPage={currentPage}
        onChangePage={setCurrentPage}
      />
    </>
  )
}
export default PriorityMasterHeader
