import { FC } from 'react'

import {
  IProductInBayDetail,
  useGetProductAvailableByBay,
} from '@/services/api/monitor/bay'

import { useBayMonitor } from '@/stores/monitor/bay'

import Modal, { ModalBody, ModalHeader } from '@/standard/Modal'
import Table, {
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from '@/standard/Tables/Table'

import { convertBooleanToYesNo } from '@/utils'

const ShowProductByBayModal: FC = () => {
  const { setIsShowProdModalOpen, currentBay } = useBayMonitor()

  const { data } = useGetProductAvailableByBay({
    termID: '1002',
    bayID: currentBay,
  })

  const handleClose = () => {
    setIsShowProdModalOpen(false)
  }

  return (
    <Modal modalWindowOptionalClass="!w-[50rem]">
      <ModalHeader
        title="All Product"
        onClose={handleClose}
        optionalClass="!mb-[0]"
      />
      <div className="overflow-y-auto">
        <ModalBody optionalClass="!p-[0.6rem]">
          <Table optionalClass="!m-0" type="em-c-table--striped">
            <TableHeader optionalClass="text-white">
              <TableHeaderItem optionalClass="w-[100px]">
                ARM No.
              </TableHeaderItem>
              <TableHeaderItem optionalClass="w-[100px]">
                Product
              </TableHeaderItem>
              <TableHeaderItem optionalClass="w-[100px]">
                IsActive
              </TableHeaderItem>
            </TableHeader>
            <TableBody>
              {data?.result.map((prod: IProductInBayDetail) => {
                return (
                  <TableRow
                    optionalClass="align-middle"
                    key={prod.baseProdCode}
                  >
                    <div>{prod.arm}</div>
                    <div>{`${prod.baseProdCode} - ${prod.baseProdName}`}</div>
                    <div>{convertBooleanToYesNo(prod.isActive)}</div>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </ModalBody>
      </div>
    </Modal>
  )
}
export default ShowProductByBayModal
