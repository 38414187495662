import { format, lastDayOfMonth } from 'date-fns'
import { ChangeEvent, FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DatePicker } from '@/components'

import {
  ISearchShipment,
  useSearchShipment,
} from '@/services/api/shipmentMaster'

import { useRoleMatrixStore, useShipmentMasterStore } from '@/stores'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import {
  extractOnlyNumericCharacters,
  filterFalsyProperties,
  formatISODate,
} from '@/utils'

import ShipmentTable from './table'

const Header: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { shipment } = useRoleMatrixStore()
  const { canCreate } = shipment
  const { setToggleAddShipmentModal } = useShipmentMasterStore()

  const { watch, register, handleSubmit, setValue } = useForm<ISearchShipment>({
    defaultValues: {
      ShipmentNr: '',
      CarrierCode: '',
      DrawerCode: '',
      TankerCode: '',
      SchedDate: format(new Date(), "yyyy-MM-01'T'00:00"),
      ExpDate: format(lastDayOfMonth(new Date()), "yyyy-MM-dd'T'23:59"),
      SoldToCustomerCode: '',
      ShipToCustomerCode: '',
      DrawerProdCode: '',
      IsLoad: 'false',
    },
    mode: 'onBlur',
  })
  const {
    refetch,
    isFetching,
    data: shipments,
    isLoading,
  } = useSearchShipment({
    ...filterFalsyProperties(watch()),
    ...(watch('SchedDate')
      ? { SchedDate: formatISODate(watch('SchedDate')) }
      : {}),
    ...(watch('ExpDate') ? { ExpDate: formatISODate(watch('ExpDate')) } : {}),
    termID: '1002',
    Page: currentPage,
  })

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const handleAdd = () => {
    setToggleAddShipmentModal(true)
  }

  const handleShipmentNrChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('ShipmentNr', extractOnlyNumericCharacters(newValue))
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Shipment Number"
          id="ShipmentNr"
          maxLength={10}
          {...register('ShipmentNr', {
            onChange: handleShipmentNrChange,
          })}
        />
        <TextInput
          label="Carrier Code"
          id="CarrierCode"
          maxLength={20}
          {...register('CarrierCode')}
        />
        <TextInput
          label="Drawer Code"
          id="DrawerCode"
          maxLength={20}
          {...register('DrawerCode')}
        />
        <TextInput
          label="Tanker Code"
          id="TankerCode"
          maxLength={20}
          {...register('TankerCode')}
        />
        <Select label="Is Load" id="IsLoad" {...register('IsLoad')}>
          <option value="">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <DatePicker
          label="Scheduled Date"
          id="SchedDate"
          {...register('SchedDate', {
            setValueAs: formatISODate,
          })}
        />
        <DatePicker
          label="Expiration Date"
          id="ExpDate"
          {...register('ExpDate', {
            setValueAs: formatISODate,
          })}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={handleAdd}
          />
        )}
      </form>
      <ShipmentTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        shipments={shipments}
        isLoading={isLoading}
      />
    </>
  )
}
export default Header
