import { Layout } from '@/components'

import GeneralSettingsFeature from '@/features/settings'

const GeneralSettingsPage = () => {
  return (
    <Layout>
      <GeneralSettingsFeature />
    </Layout>
  )
}

export default GeneralSettingsPage
