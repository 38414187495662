import { FC } from 'react'

import { useDrawerProdGroupMasterStore } from '@/stores'

import DrawerProdGroupAddModal from './addModal'
import DrawerProdGroupDeleteModal from './deleteModal'
import DrawerProdGroupEditModal from './editModal'
import DrawerProdGroupHeader from './header'

const DrawerProdGroupMasterComponent: FC = () => {
  const {
    toggleAddDrawerProdGroupModal,
    toggleEditDrawerProdGroupModal,
    toggleConfirmDeleteModal,
  } = useDrawerProdGroupMasterStore()

  return (
    <div className="flex flex-col h-full gap-4">
      {toggleAddDrawerProdGroupModal && <DrawerProdGroupAddModal />}
      {toggleEditDrawerProdGroupModal && <DrawerProdGroupEditModal />}
      {toggleConfirmDeleteModal && <DrawerProdGroupDeleteModal />}
      <DrawerProdGroupHeader />
    </div>
  )
}
export default DrawerProdGroupMasterComponent
