import { useMutation, useQuery } from '@tanstack/react-query'

import { ICarrierData } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchCarrier {
  termID: string
  Page: number
  CarrierCode?: string
  CarrierName?: string
}

export interface IAddCarrier {
  carrierID: number
  carrierCode: string
  carrierName: string
  remark: string
}

export interface IEditCarrier {
  carrierID: number
  carrierCode: string
  carrierName: string
  remark: string
}

interface IResponseCarrier {
  message: string
  code: number
  result: ICarrierData
}

export function useSearchCarrier(params: ISearchCarrier) {
  return useQuery<unknown, unknown, ICarrierData>({
    queryKey: ['searchCarrier', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseCarrier>(
          `/CarrierMaster/SearchCarrier`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface UseAddCarrier {
  body: IAddCarrier
  params: Pick<ISearchCarrier, 'termID'>
}

export function useAddCarrier(cb?: () => void) {
  return useMutation<IResponseCarrier, unknown, UseAddCarrier>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/CarrierMaster/AddCarrier`, body, {
        params,
      })
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searchCarrier'] })
    },
  })
}

interface UseEditCarrier {
  body: IEditCarrier
  params: Pick<ISearchCarrier, 'termID'>
}

export function useEditCarrier(cb?: () => void) {
  return useMutation<IResponseCarrier, unknown, UseEditCarrier>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(
        `/CarrierMaster/EditCarrierMaster`,
        body,
        {
          params,
        }
      )
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searchCarrier'] })
    },
  })
}

interface UseDeleteCarrierParams extends Pick<ISearchCarrier, 'termID'> {
  carrierID: number
}

export function useDeleteCarrier(cb?: () => void) {
  return useMutation<IResponseCarrier, unknown, UseDeleteCarrierParams>({
    mutationFn: async (params) => {
      const { data } = await api.delete(`/CarrierMaster/DeleteCarrier`, {
        params,
      })
      if (data.message) {
        throw new Error(data.message)
      }
      cb?.()
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searchCarrier'] })
    },
  })
}
