import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  ISearchCustomer,
  useSearchCustomer,
} from '@/services/api/customerMaster'

import { useCustomerMasterStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties } from '@/utils'

import CustomerMasterTable from './table'

const Header: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { setToggleAddCustomerModal } = useCustomerMasterStore()
  const { customerMaster } = useRoleMatrixStore()
  const { canCreate } = customerMaster
  const { handleSubmit, watch, register } = useForm<ISearchCustomer>({
    defaultValues: {
      CustomerCode: '',
      CustomerName: '',
    },
    mode: 'onBlur',
  })

  const { refetch, isFetching, isLoading, data } = useSearchCustomer({
    ...filterFalsyProperties(watch()),
    termID: '1002',
    Page: currentPage,
  })

  const handleAdd = () => {
    setToggleAddCustomerModal(true)
  }

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Customer Code"
          id="CustomerCode"
          maxLength={20}
          {...register('CustomerCode')}
        />
        <TextInput
          label="Customer Name"
          id="CustomerName"
          maxLength={50}
          {...register('CustomerName')}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={handleAdd}
          />
        )}
      </form>
      <CustomerMasterTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        isLoading={isLoading}
        customers={data}
      />
    </>
  )
}

export default Header
