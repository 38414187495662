import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IGetBayResultDetail } from '@/services/api/bayMaster'

import { useBayMasterStore } from '@/stores'

import { formatDateForInput } from '@/utils'

import DedicatedBayForm from '../forms/dedicatedBay'

const EditDedicatedBayModal: FC = () => {
  const { editBayChild, selectedBayChild, setToggleEditBayChildModal } =
    useBayMasterStore()

  const dedicatedStart = selectedBayChild?.dedicatedStart
    ? formatDateForInput(selectedBayChild?.dedicatedStart)
    : ''
  const dedicatedEnd = selectedBayChild?.dedicatedEnd
    ? formatDateForInput(selectedBayChild?.dedicatedEnd)
    : ''

  const methods = useForm<IGetBayResultDetail>({
    mode: 'onBlur',
    values: {
      ...selectedBayChild,
      dedicatedStart,
      dedicatedEnd,
    } as IGetBayResultDetail,
  })

  const handleClose = () => {
    setToggleEditBayChildModal(false)
  }

  const onSubmit = (data: IGetBayResultDetail) => {
    editBayChild(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a dedicated bay"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Edit"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <DedicatedBayForm onSubmit={onSubmit} />
      </FormModal>
    </FormProvider>
  )
}

export default EditDedicatedBayModal
