import { QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { useAlertInModalStore } from '@/stores'

export interface GenericErrorResponse {
  type: string
  title: string
  status: number
  traceId: string
  errors: Record<string, string>
}

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error: Error | AxiosError<GenericErrorResponse>) => {
        const { setAlertInModalMessage, setIsAlertInModalOpen } =
          useAlertInModalStore.getState()
        if (error instanceof AxiosError) {
          setAlertInModalMessage(error.response?.data?.title || error.message)
          setIsAlertInModalOpen(true)
        } else {
          setAlertInModalMessage(error.message)
          setIsAlertInModalOpen(true)
        }
      },
    },
  },
})
