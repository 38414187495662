import Layout from '@/components/Layout'

import RoleMatrixComponent from '@/features/roleMatrix'

const RoleMatrixPage = () => (
  <Layout>
    <RoleMatrixComponent />
  </Layout>
)

export default RoleMatrixPage
