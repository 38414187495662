import { useMutation, useQuery } from '@tanstack/react-query'

import { TRegistrationStep } from '@/stores'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

interface IPersonnelInformation {
  contractForSelectDrawerProd: null
  selectedContractList: null
  selectedShipmentList: null
  step: TRegistrationStep
}

interface IResponseValidateDriver {
  message: string
  code: number
  result: IPersonnelInformation
}

export interface IValidatePersonnel {
  termID: string
  personnelIButton: string
}

export interface IGetPrivacyVersionRequestParams {
  termID: string
}
export interface IGetPrivacyVersionResponseBody {
  message: string
  code: number
  result: string
}
export interface IAgreePrivacy {
  termID: string
  personnelIButton: string
  dataPrivacyVersion: string
}

interface IResponseValidateTanker {
  message: string
  code: number
  result: IPersonnelInformation
}
export interface ISelectedProdDetails {
  drawerProdID: number
  drawerProdCode: string
  drawerProdName: string
  compartmentCnt: number
}
export interface IDataForSelectContractProd {
  documentID: number
  documentNr: string
  type: 0 | 2 | 3
  details: ISelectedProdDetails[]
}
export interface ISelectedShipmentList {
  documentID: number
  documentNr: string
  type: 0 | 2 | 3
  details: ISelectedProdDetails[]
}
export interface ISummaryQRegistrationData {
  dataForSelectProd: IDataForSelectContractProd
  selectedContractList: IDataForSelectContractProd[]
  selectedShipmentList: ISelectedShipmentList[]
  step: TRegistrationStep
}
export interface IResponseValidateDocument {
  message: string
  code: number
  result: ISummaryQRegistrationData
}
export interface IResponseSelectContract {
  message: string
  code: number
  result: ISummaryQRegistrationData
}

export interface IValidateTankerData {
  termID: string
  personnelIButton: string
  tankerIButton: string
}

export interface ICompartmentCnt {
  drawerProdID: number
  compartmentCnt: number
}
export interface IContactList {
  documentID: number
  documentNr: string
  type: 0 | 2 | 3
  details: ISelectedProdDetails[]
}
export interface ISelectedDocument {
  shipmentList: IContactList[] | []
  contractList: IContactList[] | []
}
export interface IValidateDocument {
  termID: string
  personnelIButton: string
  tankerIButton: string
  docNr: string
  details: ISelectedDocument
  isUpdateContract?: boolean
}
export interface ISelectContract {
  termID: string
  shipmentList: IContactList[] | []
  contractList: IContactList[] | []
}
export interface IGetPhoneNo {
  termID: string
  personnelIButton: string
}
export interface IResponseGetPhoneNo {
  message: string
  code: number
  result: string
}
export interface IRegistrationQueue {
  termID: string
  personnelIButton: string
  tankerIButton: string
  shipmentList: IContactList[] | [] | undefined
  contractList: IContactList[] | [] | undefined
  phoneNo?: string
}

export interface IResponseGetEnableQueueRegister {
  message: string
  code: number
  result: {
    data: boolean
  }
}

export const qRegistrationService = {
  useValidateDriver: () => {
    const validateDriver = async (params: IValidatePersonnel) => {
      const { data } = await api.post<IResponseValidateDriver>(
        `/QRegistration/ValidatePersonnelIbutton`,
        null,
        { params }
      )

      return data
    }
    return useMutation({
      mutationFn: validateDriver,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['driverIButton'] })
      },
    })
  },
  useGetDataPrivacyVersion: (params: IGetPrivacyVersionRequestParams) => {
    const GetDataPrivacyVersion = async () => {
      const { data } = await api.get<IGetPrivacyVersionResponseBody>(
        `/QRegistration/GetDataPrivacyVersion`,
        { params }
      )
      return data.result
    }
    return useQuery({
      queryKey: ['dataPrivacy'],
      queryFn: GetDataPrivacyVersion,
    })
  },
  useAgreeDateAndPrivacy: () => {
    const onAgreePrivacy = async (params: IAgreePrivacy) => {
      const { data } = await api.post<IResponseValidateDriver>(
        `/QRegistration/AgreeDataAndPrivacy`,
        null,
        {
          params,
        }
      )
      return data
    }
    return useMutation({
      mutationFn: onAgreePrivacy,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agreePrivacy'] })
      },
    })
  },
  useValidateTanker: () => {
    const validateTanker = async (tankerData: IValidateTankerData) => {
      const { data } = await api.post<IResponseValidateTanker>(
        `/QRegistration/ValidateTankerIButton?termID=1002&personnelIButton=${tankerData.personnelIButton}&tankerIButton=${tankerData.tankerIButton}`
      )

      return data
    }
    return useMutation({
      mutationFn: validateTanker,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['truckIButton'] })
      },
    })
  },
  useValidateDocument: () => {
    const validateDocument = async (document: IValidateDocument) => {
      const { data } = await api.post<IResponseValidateDocument>(
        `/QRegistration/ValidateDocument?termID=1002&personnelIButton=${document.personnelIButton}&tankerIButton=${document.tankerIButton}&docNr=${document.docNr}`,
        document.details
      )
      return data
    }
    return useMutation({
      mutationFn: validateDocument,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['documentData'] })
      },
    })
  },
  useSelectContract: () => {
    const onSelectContract = async (body: ISelectContract) => {
      const { data } = await api.post<IResponseSelectContract>(
        `QRegistration/SelectProd?termID=1002`,
        {
          shipmentList: body.shipmentList,
          contractList: body.contractList,
        }
      )
      return data
    }
    return useMutation({
      mutationFn: onSelectContract,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['selectContracts'] })
      },
    })
  },
  useGetPhoneNo: (isReady: boolean, params: IGetPhoneNo) => {
    const getPhoneNo = async () => {
      const { data } = await api.get<IResponseGetPhoneNo>(
        `/QRegistration/GetPhoneNo`,
        { params }
      )
      return data.result
    }
    return useQuery({
      queryKey: ['driverPhoneNo', params.personnelIButton],
      queryFn: getPhoneNo,
      enabled: isReady && !!params.personnelIButton,
    })
  },
  useCreateQueueRegistration: () => {
    const createQueue = async (body: IRegistrationQueue) => {
      const { data } = await api.post<any>(
        `/QRegistration/RegisterQueue?termID=1002&personnelIButton=${body.personnelIButton}&tankerIButton=${body.tankerIButton}&phoneNo=${body.phoneNo}`,
        { shipmentList: body.shipmentList, contractList: body.contractList }
      )
      return data
    }
    return useMutation({
      mutationFn: createQueue,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['queueRegistration'] })
      },
    })
  },
  useGetEnableQueueRegister: (params: IGetPrivacyVersionRequestParams) => {
    const getEnableQueueRegister = async () => {
      const { data } = await api.get<IResponseGetEnableQueueRegister>(
        `/QRegistration/GetEnableQueueRegister`,
        { params }
      )
      return data.result.data
    }
    return useQuery({
      queryKey: ['configEnableQueueRegister'],
      queryFn: getEnableQueueRegister,
      refetchInterval: 60000,
    })
  },
}
