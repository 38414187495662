import { FC, useState } from 'react'

import { IGetQueueWaitForBayAssignmentResult } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

import ClickAwayListener from '@/standard/ClickAwayListener'

import Button from '../../standard/Button/Button'

interface Props {
  data: IGetQueueWaitForBayAssignmentResult
}

const QueueWaitingActionsDropdown: FC<Props> = ({ data }) => {
  const {
    setIsDeleteQueueModalOpen,
    setIsUpdateSortDTMModalOpen,
    setIsEnableQueueModalOpen,
    setIsDisableQueueModalOpen,
    setIsQueueDetailModalOpen,
    setCurrentWaitingQueue,
  } = useOrderMonitor()

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    if (!data.tankerID) return
    setCurrentWaitingQueue(data)
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleQueueDetailModal = () => {
    setIsQueueDetailModalOpen(true)
  }
  const handleEnableQueueModal = () => {
    setIsEnableQueueModalOpen(true)
  }
  const handleDisableQueueModal = () => {
    setIsDisableQueueModalOpen(true)
  }
  const handleUpdateSortDTMModal = () => {
    setIsUpdateSortDTMModalOpen(true)
  }
  const handleDeleteQueueModal = () => {
    setIsDeleteQueueModalOpen(true)
  }

  return (
    <div className="relative flex flex-col w-[40px] h-6">
      <Button
        isIconOnlyButton
        typeAttribute="button"
        label="View Queue"
        title="View Queue"
        iconName="settings"
        size="em-c-btn--small"
        iconSize="em-c-icon--small"
        onClick={() => {
          handleOpen()
        }}
      />

      <ClickAwayListener onClickAway={() => handleClose()}>
        {isOpen && (
          <div className="w-[150px] bg-white shadow-md rounded-sm absolute top-8 right-0 z-50 space-y-1">
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleClose()
                handleQueueDetailModal()
              }}
            >
              <span>Details</span>
            </button>
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleEnableQueueModal()
                handleClose()
              }}
              disabled={!data.isDisable}
            >
              <span>Enable Queue</span>
            </button>
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleDisableQueueModal()
                handleClose()
              }}
              disabled={data.isDisable}
            >
              <span>Disable Queue</span>
            </button>
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleUpdateSortDTMModal()
                handleClose()
              }}
            >
              <span>Update SortDTM</span>
            </button>
            <button
              className="w-full p-2 hover:bg-gray-200 text-left disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              onClick={() => {
                handleDeleteQueueModal()
                handleClose()
              }}
            >
              <span>Delete Queue</span>
            </button>
          </div>
        )}
      </ClickAwayListener>
    </div>
  )
}

export default QueueWaitingActionsDropdown
