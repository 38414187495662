import { useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import DrawerMasterForm from '@/features/master/drawer/form'

import { useEditDrawer } from '@/services/api/drawerMaster'

import { IDrawerInformation, useDrawerMasterStore } from '@/stores'

const DrawerMasterEditModal = () => {
  const { currentDrawer, setToggleEditDrawerModal, setCurrentDrawer } =
    useDrawerMasterStore()

  const handleClose = () => {
    setToggleEditDrawerModal(false)
    setCurrentDrawer(undefined)
  }

  const { mutate, isPending } = useEditDrawer(handleClose)
  const { register, handleSubmit, formState } = useForm<IDrawerInformation>({
    values: currentDrawer,
    mode: 'onBlur',
  })

  const onSubmit = (data: IDrawerInformation) => {
    mutate({
      body: {
        drawerID: data.drawerID,
        drawerName: data.drawerName,
        drawerCode: data.drawerCode,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormModal
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title="Edit a drawer"
      confirmLabel="Edit"
      isValid={formState.isValid}
      onClose={handleClose}
      isLoading={isPending}
    >
      <DrawerMasterForm isEdit formState={formState} register={register} />
    </FormModal>
  )
}

export default DrawerMasterEditModal
