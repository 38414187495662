import { useEffect, useState } from 'react'
import { useKeyPressEvent } from 'react-use'

function useFullscreenStatus() {
  const [isFullscreen, setIsFullscreen] = useState(
    document.fullscreenElement !== null
  )

  const toggleFullScreen = async () => {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen()
      setIsFullscreen(true)
    } else if (document.fullscreenElement && document.exitFullscreen) {
      await document.exitFullscreen()
      setIsFullscreen(false)
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!(e.target instanceof HTMLInputElement) && e.key === 'f') {
      toggleFullScreen()
    }
  }

  useKeyPressEvent('f', handleKeyDown)

  useEffect(() => {
    addEventListener('fullscreenchange', () => {
      setIsFullscreen(document.fullscreenElement !== null)
    })

    return () => {
      removeEventListener('fullscreenchange', () => {})
    }
  }, [])

  return isFullscreen
}

export default useFullscreenStatus
