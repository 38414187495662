import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import RoleMatrixForm, { IRoleMatrixForm } from '@/features/roleMatrix/form'

import { useAddRole } from '@/services/api/roleMatrix'

import { useRoleModalStore } from '@/stores'

const RoleMatrixAddModal = () => {
  const { setToggleAddModal, screenList } = useRoleModalStore()

  const { handleSubmit, register, formState, ...formMethods } =
    useForm<IRoleMatrixForm>({
      mode: 'onBlur',
      defaultValues: {
        role: '',
      },
    })

  const handleClose = () => {
    setToggleAddModal(false)
  }

  const { mutate, isPending } = useAddRole(handleClose)

  const onSubmit = (data: IRoleMatrixForm) => {
    mutate({
      body: {
        role: data.role,
        screenList: screenList,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      {...formMethods}
    >
      <FormModal
        modalWindowOptionalClass="!w-[75vw]"
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title="Add a role"
        confirmLabel="Add"
        isValid={formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <RoleMatrixForm />
      </FormModal>
    </FormProvider>
  )
}

export default RoleMatrixAddModal
