import { create } from 'zustand'

import { ITankerDetail } from '@/services/api/monitor/bay'
import { IGetQueueAlreadyAssignBayResult } from '@/services/api/monitor/order'

interface IBayMonitor {
  isShowProdModalOpen: boolean
  setIsShowProdModalOpen: (value: boolean) => void
  isReassignModalOpen: boolean
  setIsReassignModalOpen: (value: boolean) => void
  isRemoveAssignedModalOpen: boolean
  setIsRemoveAssignedModalOpen: (value: boolean) => void
  currentBay: number
  setCurrentBay: (value: number) => void
  currentQueueInBay: ITankerDetail | undefined
  setCurrentQueueInBay: (value?: ITankerDetail) => void
  currentQueueInAlreadyAssigned: IGetQueueAlreadyAssignBayResult | undefined
  setCurrentQueueInAlreadyAssigned: (
    value?: IGetQueueAlreadyAssignBayResult
  ) => void
}
export const useBayMonitor = create<IBayMonitor>((set) => ({
  isShowProdModalOpen: false,
  setIsShowProdModalOpen: (value) => set({ isShowProdModalOpen: value }),
  currentBay: 0,
  setCurrentBay: (value) => set({ currentBay: value }),
  isReassignModalOpen: false,
  setIsReassignModalOpen: (value) => set({ isReassignModalOpen: value }),
  isRemoveAssignedModalOpen: false,
  setIsRemoveAssignedModalOpen: (value) =>
    set({ isRemoveAssignedModalOpen: value }),
  currentQueueInBay: undefined,
  setCurrentQueueInBay: (value) => set({ currentQueueInBay: value }),
  currentQueueInAlreadyAssigned: undefined,
  setCurrentQueueInAlreadyAssigned: (value) =>
    set({ currentQueueInAlreadyAssigned: value }),
}))
