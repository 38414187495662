import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import { IAcknowledge } from '@/services/api/acknowledgement'

import { useQueueAcknowledgementStore } from '@/stores'

import { formatDriverName } from '@/utils'

import CascadeBay from './cascadeBay'

interface IDisplayQueueTable {
  data: IAcknowledge[] | undefined | null
  isStart?: boolean
  isEnd?: boolean
}

const DisplayQueueTable: FC<IDisplayQueueTable> = ({
  data,
  isStart,
  isEnd,
}) => {
  const { diffWaitingQueue, diffGotoBayQueue } = useQueueAcknowledgementStore()

  return data?.map((tanker, index) => (
    <div
      key={index}
      className={twMerge(
        'flex items-start justify-between p-2 border border-gray-200 em-c-table--striped',
        isStart && 'border-r-2',
        isEnd && 'border-l-2',
        diffWaitingQueue.some(
          (queue: IAcknowledge) => queue.tankerID === tanker.tankerID
        ) && 'animate-flash ',
        diffGotoBayQueue.some(
          (queue: IAcknowledge) => queue.tankerID === tanker.tankerID
        ) && 'animate-flash ',
        +tanker.overtimeCnt + 1 >= +tanker.maxOvertimeToLoad
          ? 'bg-red-200'
          : +tanker.overtimeCnt + 1 === +tanker.maxOvertimeToLoad - 1
            ? 'bg-yellow-200'
            : 'bg-white'
      )}
    >
      <div className="flex items-center h-full w-full">
        <div className="w-6/12">
          <div className="text-xxs lg:leading-[5vh] lg:text-fluid font-bold">
            {formatDriverName(tanker.driverName)}
          </div>
        </div>
        <div className="w-6/12">
          <div className="text-xxs lg:leading-[5vh] lg:text-fluid font-bold">
            {tanker.bayList.length > 1 ? (
              <CascadeBay bays={tanker.bayList} />
            ) : (
              tanker.bayList[0]
            )}
          </div>
        </div>
      </div>
    </div>
  ))
}
export default DisplayQueueTable
