import { Layout } from '@/components'

import Contract from '@/features/master/contract'

const ContractPage = () => {
  return (
    <Layout>
      <Contract />
    </Layout>
  )
}

export default ContractPage
