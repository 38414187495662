import { create } from 'zustand'

export interface ICustomerInformation {
  customerID: number
  termID: string
  customerCode: string
  customerName: string
  remark: string
  priorityID: number
  priorityCode: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayCreatedDate: Date
  displayModifiedDate: Date
  displayRemark: string
}

export interface ICustomerData {
  result: ICustomerInformation[]
  total: number
}

interface CustomerMasterState {
  currentCustomer: ICustomerInformation | undefined
  setCurrentCustomer: (value: ICustomerInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddCustomerModal: boolean
  setToggleAddCustomerModal: (value: boolean) => void
  toggleEditCustomerModal: boolean
  setToggleEditCustomerModal: (value: boolean) => void
}

export const useCustomerMasterStore = create<CustomerMasterState>((set) => ({
  currentCustomer: undefined,
  setCurrentCustomer: (value) => set({ currentCustomer: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddCustomerModal: false,
  setToggleAddCustomerModal: (value) => set({ toggleAddCustomerModal: value }),
  toggleEditCustomerModal: false,
  setToggleEditCustomerModal: (value) =>
    set({ toggleEditCustomerModal: value }),
}))
