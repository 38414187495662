import { format, lastDayOfMonth } from 'date-fns'
import { FC, MouseEventHandler, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DatePicker } from '@/components'

import {
  SearchAuditReportRequestParams,
  useAuditActionForDropdown,
  useAuditReport,
  useAuditReportExcel,
} from '@/services/api/auditData'

import Button from '@/standard/Button'
import Select from '@/standard/Select'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties, formatISODate } from '@/utils'

import AuditReportTable from './table'

interface AuditReportHeaderProps {
  onCollapseAll?: MouseEventHandler<HTMLButtonElement>
}

const AuditReportHeader: FC<AuditReportHeaderProps> = ({ onCollapseAll }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { watch, handleSubmit, register } =
    useForm<SearchAuditReportRequestParams>({
      mode: 'onBlur',
      defaultValues: {
        startdate: format(new Date(), "yyyy-MM-01'T'00:00"),
        enddate: format(lastDayOfMonth(new Date()), "yyyy-MM-dd'T'23:59"),
      },
    })

  const { refetch, isFetching, isLoading, data } = useAuditReport({
    ...filterFalsyProperties(watch()),
    ...(watch('startdate')
      ? { startdate: formatISODate(watch('startdate')) }
      : {}),
    ...(watch('enddate') ? { enddate: formatISODate(watch('enddate')) } : {}),
    termId: '1002',
    page: currentPage,
  })

  const { data: auditActions } = useAuditActionForDropdown()

  const { refetch: download } = useAuditReportExcel({
    ...filterFalsyProperties(watch()),
    ...(watch('startdate')
      ? { startdate: formatISODate(watch('startdate')) }
      : {}),
    ...(watch('enddate') ? { enddate: formatISODate(watch('enddate')) } : {}),
    termId: '1002',
    page: currentPage,
  })

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const handleDownload = () => {
    download()
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Table Name"
          id="tablename"
          maxLength={50}
          {...register('tablename')}
        />
        <TextInput label="Key" id="key1" maxLength={50} {...register('key1')} />
        <TextInput
          label="Note"
          id="note"
          maxLength={50}
          {...register('note')}
        />
        <DatePicker
          label="Audit Start Date"
          id="startdate"
          {...register('startdate', {
            setValueAs: formatISODate,
          })}
        />
        <DatePicker
          label="Audit End Date"
          id="enddate"
          {...register('enddate', {
            setValueAs: formatISODate,
          })}
        />
        <Select label="Action" id="Action" {...register('actionauditid')}>
          <option value="">All</option>
          {auditActions?.map((action) => (
            <option key={action.auditActionId} value={action.auditActionId}>
              {action.actionName}
            </option>
          ))}
        </Select>
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        <Button
          typeAttribute="button"
          optionalClass="!p-[1.5em] self-end"
          label="Collpase All"
          onClick={onCollapseAll}
        />
        <Button
          typeAttribute="button"
          optionalClass="!p-[1.5em] self-end"
          label="Download Report"
          onClick={handleDownload}
        />
      </form>
      <AuditReportTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        isLoading={isLoading}
        audits={data}
      />
    </>
  )
}

export default AuditReportHeader
