import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import {
  IAddDrawerProdGroup,
  useAddDrawerProdGroup,
} from '@/services/api/drawerProdGroupMaster'

import { useDrawerProdGroupMasterStore } from '@/stores'

import DrawerProdGroupMasterForm from './form'

const DrawerProdGroupAddModal = () => {
  const { setToggleAddDrawerProdGroupModal } = useDrawerProdGroupMasterStore()

  const handleClose = () => {
    setToggleAddDrawerProdGroupModal(false)
  }

  const { mutate, isPending } = useAddDrawerProdGroup(handleClose)

  const methods = useForm<IAddDrawerProdGroup>({
    defaultValues: {
      drawerProdGroupID: 0,
      drawerProdGroupCode: '',
      drawerProdGroupName: '',
      remark: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (body: IAddDrawerProdGroup) => {
    mutate({
      body,
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a drawer product group"
        confirmLabel="Add"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <DrawerProdGroupMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default DrawerProdGroupAddModal
