import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { DatePicker, FormModal } from '@/components'

import { useUpdateSortDTM } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

import { formatDateToLocalDateTime, formatISODate } from '@/utils'

interface ISortDTM {
  sortDTM: string
}

const UpdateSortDTMModal: FC = () => {
  const { setIsUpdateSortDTMModalOpen, currentWaitingQueue } = useOrderMonitor()

  const handleClose = () => {
    setIsUpdateSortDTMModalOpen(false)
  }
  const { mutate: updateQueueSortDTM } = useUpdateSortDTM(handleClose)
  const sortDTM = currentWaitingQueue?.sortDTM?.slice(0, 16) || ''
  const onSubmit = (form: ISortDTM) => {
    updateQueueSortDTM({
      params: {
        termID: '1002',
        tankerID: Number(currentWaitingQueue?.tankerID),
      },
      body: formatISODate(form.sortDTM),
    })
  }

  const methods = useForm<ISortDTM>({
    mode: 'onBlur',
    values: {
      ...currentWaitingQueue,
      sortDTM,
    },
  })

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Update SortDTM"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Update"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <DatePicker
          id="updateSortDTM"
          label="Update SortDTM"
          {...methods.register('sortDTM', {
            required: {
              value: true,
              message: 'sortDTM is required',
            },
            setValueAs: formatDateToLocalDateTime,
          })}
        />
      </FormModal>
    </FormProvider>
  )
}
export default UpdateSortDTMModal
