import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { PriorityAutoComplete } from '@/components'

import { ICustomerInformation } from '@/stores'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

interface ICustomerMasterFormProps {
  isEdit?: boolean
}

const CustomerMasterForm: FC<ICustomerMasterFormProps> = ({ isEdit }) => {
  const { formState, register } = useFormContext<ICustomerInformation>()

  const customerCodeState = isEdit
    ? 'em-is-disabled'
    : formState.errors.customerCode && 'em-has-error'
  const customerNameState = formState.errors.customerName && 'em-has-error'

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <TextInput
        id="customerCode"
        label="Customer Code"
        state={customerCodeState}
        maxLength={20}
        required
        note={formState.errors.customerCode?.message}
        {...register('customerCode', {
          required: {
            value: true,
            message: 'Customer Code is required',
          },
        })}
      />
      <TextInput
        id="customerName"
        label="Customer Name"
        state={customerNameState}
        maxLength={50}
        required
        note={formState.errors.customerName?.message}
        {...register('customerName', {
          required: {
            value: true,
            message: 'Customer Name is required',
          },
        })}
      />
      <PriorityAutoComplete isRequired />
      <TextArea
        id="remark"
        label="Remark"
        textareaOptionalClass="!h-[6rem]"
        maxLength={200}
        {...register('remark')}
      />
    </div>
  )
}

export default CustomerMasterForm
