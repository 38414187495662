import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchDrawerProd {
  DrawerProdCode?: string
  DrawerProdName?: string
  DrawerProdGroupCode?: string
  termID: string
  Page: number
}

export interface IDrawerProdInformation {
  drawerProdID: number
  termID: string
  drawerProdCode: string
  drawerProdName: string
  drawerProdGroupID: number
  drawerProdGroupCode: string
  remark: string
  createdDate: string
  createdBy: string
  modifiedDate: string
  modifiedBy: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
  bomChildList: IBomChildList[]
}

export interface IBomChildList {
  bomid: number
  baseProdID: number
  baseProdCode?: string
}

export interface IGetDrawerProdID {
  drawerProdID: number
  termID: string
  drawerProdCode: string
  drawerProdName: string
  drawerProdGroupID: number
  drawerProdGroupCode: string
  remark: string
  createdDate: string
  createdBy: string
  modifiedDate: string
  modifiedBy: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
  bomChildList: IBomChildList[]
}

export interface IAddDrawerProd {
  drawerProdID: number
  drawerProdCode: string
  drawerProdName: string
  drawerProdGroupID: number
  remark: string
  bomChildList: IBomChildList[]
}

export interface IEditDrawerProd {
  drawerProdID: number
  // termID: string
  drawerProdCode: string
  drawerProdName: string
  drawerProdGroupID: number
  remark: string
  bomChildList: IBomChildList[]
}

interface IResponseDrawerProd {
  message: string
  code: number
  result: IDrawerProdData
}

interface IResponseGetDrawerProdID {
  message: string
  code: number
  result: IGetDrawerProdID
}

export interface IDrawerProdData {
  result: IDrawerProdInformation[]
  total: number
}

export function useSearchDrawerProd(params: ISearchDrawerProd) {
  return useQuery({
    queryKey: ['searchDrawerProd', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseDrawerProd>(
          `/DrawerProdMaster/SearchDrawerProd`,
          {
            params,
          }
        )
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface GetDrawerProdRequestParams {
  termID: string
  drawerProdID: number
}

export function useGetDrawerProdByID(params: GetDrawerProdRequestParams) {
  return useQuery<unknown, unknown, IDrawerProdInformation>({
    queryKey: ['getDrawerProdByID', params.drawerProdID],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseGetDrawerProdID>(
          `DrawerProdMaster/GetDrawerProd`,
          { params }
        )
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface IAddDrawerProdOptions {
  body: IAddDrawerProd
  params: Pick<ISearchDrawerProd, 'termID'>
}

export function useAddDrawerProd(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IAddDrawerProdOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post('/DrawerProdMaster/AddDrawerProd', body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawerProd'] })
    },
  })
}

interface IEditDrawerProdOptions {
  body: IEditDrawerProd
  params: Pick<ISearchDrawerProd, 'termID'>
}

export function useEditDrawerProd(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, IEditDrawerProdOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(
        '/DrawerProdMaster/EditDrawerProd',
        body,
        { params }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawerProd'] })
    },
  })
}

interface IDeleteDrawerProdRequestParams {
  termID: string
  drawerProdID: number
}

export function useDeleteDrawerProd(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    IDeleteDrawerProdRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete(`DrawerProdMaster/DeleteDrawerProd`, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchDrawerProd'] })
    },
  })
}
