import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import BaseProdForm from '@/features/master/baseProd/form'

import { useEditBaseProd } from '@/services/api/baseProdMaster'

import { IBaseProdInformation, useBaseProdMasterStore } from '@/stores'

const BaseProdEditModal: FC = () => {
  const { setToggleEditBaseProdModal, currentBaseProd } =
    useBaseProdMasterStore()

  const handleClose = () => {
    setToggleEditBaseProdModal(false)
  }

  const { mutate, isPending } = useEditBaseProd(handleClose)
  const { formState, handleSubmit, ...formMethods } =
    useForm<IBaseProdInformation>({
      mode: 'onBlur',
      values: currentBaseProd,
    })

  const onSubmit = (data: IBaseProdInformation) => {
    mutate({
      body: {
        baseProdID: data.baseProdID,
        baseProdCode: data.baseProdCode,
        baseProdName: data.baseProdName,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      {...formMethods}
    >
      <FormModal
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title="Edit a base product"
        confirmLabel="Edit"
        isValid={formState.isValid}
        isLoading={isPending}
        onClose={handleClose}
      >
        <BaseProdForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default BaseProdEditModal
