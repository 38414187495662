import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IShipmentData } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchShipment {
  ShipmentNr?: string
  termID: string
  CarrierCode?: string
  DrawerCode?: string
  TankerCode?: string
  SchedDate?: string
  ExpDate?: string
  SoldToCustomerCode?: string
  ShipToCustomerCode?: string
  DrawerProdCode?: string
  Page: number
  IsLoad?: string
}

export interface ISearchShipmentByID {
  termID: string
  shipmentID?: number
}

export interface IQShipmentOrderDetail {
  drawerProdCode?: string
  drawerProdID: number
  drawerProdName?: string
  shipToCustomerCode?: string
  shipToCustomerID: number | null
  shipmentDetailID: number
  soldToCustomerCode?: string
  soldToCustomerID: number | null
}

export interface IGetQShipmentByID {
  carrierID: number
  drawerID: number
  expDate: string
  schedDate: string
  shipmentID: number
  shipmentNr: string
  tankerID: number
  termID: string
  tripPriority: number
  remark: string
  type: number
  details: IQShipmentOrderDetail[]
}

export interface IAddShipment {
  shipmentNr: string
  shipmentID: number
  carrierID: number
  drawerID: number
  tankerID: number
  tripPriority: number
  schedDate: string
  expDate: string
  remark: string
  type: number
  details: IQShipmentOrderDetail[]
}

export interface IEditShipment {
  shipmentNr: string
  shipmentID: number
  carrierID: number
  drawerID: number
  tankerID: number
  tripPriority: number
  schedDate: string
  expDate: string
  remark: string
  type: number
  details: IQShipmentOrderDetail[]
}

interface IResponseQShipment {
  message: string
  code: number
  result: IShipmentData
}

interface IResponseGetQShipmentByID {
  message: string
  code: number
  result: IGetQShipmentByID
}

export function useSearchShipment(params: ISearchShipment) {
  return useQuery<unknown, unknown, IShipmentData>({
    queryKey: ['searchQShipment', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseQShipment>(
          `/QShipment/SearchQShipment`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface AddShipmentOptions {
  body: IAddShipment
  params: Pick<ISearchShipment, 'termID'>
}

export function useAddShipment(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, AddShipmentOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/QShipment/AddShipment`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchQShipment'] })
    },
  })
}

export function useGetShipment(params: ISearchShipmentByID) {
  return useQuery<unknown, unknown, IGetQShipmentByID>({
    queryKey: ['getQShipmentID', params.shipmentID],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseGetQShipmentByID>(
          `/QShipment/GetShipment`,
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface EditShipmentOptions {
  body: IEditShipment
  params: Pick<ISearchShipment, 'termID'>
}

export function useEditShipment(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, EditShipmentOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/QShipment/EditShipment`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchQShipment'] })
    },
  })
}

export function useDeleteShipment(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, ISearchShipmentByID>({
    mutationFn: async (params) => {
      const { data } = await api.delete(`/QShipment/DeleteShipment`, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchQShipment'] })
    },
  })
}
