import React from 'react'

interface BulletedListProps {
  children?: React.ReactNode
  optionalClass?: string
}

const BulletedList = ({
  children,
  optionalClass,
  ...props
}: BulletedListProps) => {
  return (
    <ul
      className={`em-c-bulleted-list ${optionalClass ? optionalClass : ''}`}
      {...props}
    >
      {React.Children.toArray(children).map((child) =>
        React.isValidElement(child) ? React.cloneElement(child) : child
      )}
    </ul>
  )
}

export default BulletedList
