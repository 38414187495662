import { DeleteModal } from '@/components'

import { useDeleteDrawerProdGroup } from '@/services/api/drawerProdGroupMaster'

import { useDrawerProdGroupMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DrawerProdGroupDeleteModal = () => {
  const {
    currentDrawerProdGroup,
    setCurrentDrawerProdGroup,
    setToggleConfirmDeleteModal,
  } = useDrawerProdGroupMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentDrawerProdGroup(undefined)
  }

  const { mutate, isPending } = useDeleteDrawerProdGroup(handleClose)

  const handleConfirm = () => {
    mutate({
      termID: '1002',
      drawerProdGroupID: currentDrawerProdGroup!.drawerProdGroupID,
    })
  }

  return (
    <DeleteModal
      title="Delete a drawer product group"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {currentDrawerProdGroup?.drawerProdGroupCode && (
          <BulletedListItem>
            Drawer Product Group Code:{' '}
            {currentDrawerProdGroup?.drawerProdGroupCode}
          </BulletedListItem>
        )}
        {currentDrawerProdGroup?.drawerProdGroupName && (
          <BulletedListItem>
            Drawer Product Group Name:{' '}
            {currentDrawerProdGroup?.drawerProdGroupName}
          </BulletedListItem>
        )}
        {currentDrawerProdGroup?.remark && (
          <BulletedListItem>
            Remark: {currentDrawerProdGroup?.remark}
          </BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default DrawerProdGroupDeleteModal
