import { useMutation, useQuery } from '@tanstack/react-query'

import { api } from '@/services/apiClient'
import { queryClient } from '@/services/queryClient'
import { GenericResponseBody } from '@/services/types'

import { handleQueryError } from '@/utils'

interface IGetConfigRequestParams {
  termID: string
}

export interface IGetConfigResponseResult {
  enableAutoAck: string
  enableBayAssignment: string
  enableCallToBay: string
  enableQueueRegister: string
  maxOvertimeToAck: number | string
  overtimeIntervalToAckMin: number | string
  maxOvertimeToLoad: number | string
  overtimeIntervalToLoadMin: number | string
  bayAssignmentProcessing: string
}

type IGetConfigResponse = GenericResponseBody<IGetConfigResponseResult>

export function useGetConfig(params: IGetConfigRequestParams) {
  return useQuery<unknown, unknown, IGetConfigResponseResult>({
    queryKey: ['GetConfig'],
    queryFn: async () => {
      try {
        const { data } = await api.get<IGetConfigResponse>(
          '/QGeneralSettings/GetConfig',
          { params }
        )
        return data?.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface IUpdateConfigParams
  extends IGetConfigRequestParams,
    IGetConfigResponseResult {}

export function useUpdateConfig(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    IUpdateConfigParams,
    unknown
  >({
    mutationFn: async (params) => {
      const { data } = await api.post('/QGeneralSettings/UpdateConfig', null, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['GetConfig'] })
    },
  })
}
