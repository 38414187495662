import { FC } from 'react'
import DataGrid from 'react-data-grid'

import { useGetBayProdForActiveFunction } from '@/services/api/bayProdMaster'
import { queryClient } from '@/services/queryClient'

import { IBayProdInformation } from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import Toggle, { ToggleItem } from '@/standard/Toggle'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IBayProdInformation>([
  {
    key: 'bay',
    name: 'Bay',
  },
  {
    key: 'arm',
    name: 'Arm',
  },
  {
    key: 'baseProdCode',
    name: 'Base Product Code',
  },
  {
    key: 'baseProdName',
    name: 'Base Product Name',
  },
  {
    key: 'available',
    name: 'Is Active',
    renderCell: ({ row, onRowChange }) => {
      return (
        <Toggle
          optionalClass="justify-center"
          containerOptionalClass="flex items-center"
        >
          <ToggleItem
            labelOptionalClass="!py-[0.1rem] !px-[1rem] !h-[70%] !flex !items-center"
            name={`isActive-${row.bayProdID}`}
            value="true"
            checked={row.isActive}
            onChange={() => {
              onRowChange({ ...row, isActive: true, displayIsActive: 'Yes' })
            }}
          >
            Yes
          </ToggleItem>
          <ToggleItem
            name={`isInActive-${row.bayProdID}`}
            labelOptionalClass="!py-[1px] !px-[1rem] !h-[70%] !flex !items-center"
            value="false"
            checked={!row.isActive}
            onChange={() => {
              onRowChange({ ...row, isActive: false, displayIsActive: 'No' })
            }}
          >
            No
          </ToggleItem>
        </Toggle>
      )
    },
  },
])

interface ManageMultipleBayProductTableProps {
  currentPage: number
}

const ManageMultipleBayProductTable: FC<ManageMultipleBayProductTableProps> = ({
  currentPage,
}) => {
  const { data } = useGetBayProdForActiveFunction({
    termID: '1002',
    Page: currentPage,
  })

  const handleRowChange = (row: IBayProdInformation[]) => {
    queryClient.setQueriesData(
      { queryKey: ['SearchBayProdForActiveFunction'] },
      (oldData) => {
        if (!oldData) {
          return oldData
        }
        return row
      }
    )
  }

  return data ? (
    <DataGrid
      className="rdg-light"
      columns={columns}
      rows={data}
      rowHeight={45}
      headerRowHeight={30}
      onRowsChange={handleRowChange}
    />
  ) : (
    <LoadingIndicator textOnly />
  )
}

export default ManageMultipleBayProductTable
