import { DeleteModal } from '@/components'

import { useDeleteDrawer } from '@/services/api/drawerMaster'

import { useDrawerMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DrawerMasterDeleteModal = () => {
  const { currentDrawer, setToggleConfirmDeleteModal, setCurrentDrawer } =
    useDrawerMasterStore()

  const handleClose = () => {
    setCurrentDrawer(undefined)
    setToggleConfirmDeleteModal(false)
  }

  const { mutate, isPending } = useDeleteDrawer(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', drawerID: currentDrawer!.drawerID })
  }

  return (
    <DeleteModal
      title="Delete a drawer"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Drawer Code: {currentDrawer?.drawerCode}
        </BulletedListItem>
        <BulletedListItem>
          Drawer Name: {currentDrawer?.drawerName}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default DrawerMasterDeleteModal
