import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { toastParams } from '@/services/api/monitor/toast'
import { api } from '@/services/apiClient'
import { queryClient } from '@/services/queryClient'

/*******************************
 GET - /api/BayMonitor/GetBayMonitorData *
 *******************************/

export interface IGetBayMonitorRequestParams {
  termID: string
}

export interface IGetBayMonitorResponseBody {
  message: string | null
  code: number
  result: IGetBayMonitorResult[]
}

export interface ITankerDetail {
  documentID: number
  documentNr: string
  documentType: string
  qListID: number
  startDTM: string
  tankerCode: string
  tankerID: number
  ackDTM: string
  callDTM: string
  licensePlate: string
}

export interface IGetBayMonitorResult {
  bay: string
  bayID: number
  bayStatus: number
  isDedicate: boolean
  isTopLoad: boolean
  loadTanker: ITankerDetail
  otherTankerList: ITankerDetail[]
}

/*******************************
 GET - /api/BayMonitor/ShowBayProdAvailability *
 *******************************/
export interface IProductInBayDetail {
  bayProdID: number
  arm: number
  baseProdID: number
  baseProdCode: string
  baseProdName: string
  isActive: boolean
}

export interface IGetProdAvailableByBayParams
  extends IGetBayMonitorRequestParams {
  bayID: number
}

export interface IGetProdAvailableByBayResponseBody {
  message: string | null
  code: number
  result: IProductInBayDetail[]
}

/*******************************
 GET - /api/BayMonitor/GetQListDataSummary *
 *******************************/

export interface IQListData {
  tankerID: number
  tankerCode: string
  bayList: string[]
  callDTM: string
  startDTM: string | null
  isLoadOver: boolean
  isCallOver: boolean
}

type IGetQListDataSummaryParams = IGetBayMonitorRequestParams

export interface IGetQListDataSummaryResponseBody {
  message: string | null
  code: number
  result: IQListData[]
}

/*******************************
 GET - /api/BayMonitor/GetCurrentCompartment *
 *******************************/

export interface ICompartmentData {
  drawerProdID: number
  drawerProdCode: string
  drawerProdName: string
  compartmentCnt: number
}

export interface IGetCurrentCompartmentParams
  extends IGetBayMonitorRequestParams {
  qListID?: number
}

export interface IGetCurrentCompartmentResponseBody {
  message: string | null
  code: number
  result: ICompartmentData[]
}

/*******************************
 POST - /api/BayMonitor/ReassignQueue *
 *******************************/

export interface IReassignQueueParams extends IGetBayMonitorRequestParams {
  tankerID: number
}

export interface IReassignQueueDetail {
  drawerProdID: number
  compartmentCnt: number
}

export interface IReassignQueueBody {
  documentID: number
  documentType: string
  detailList: IReassignQueueDetail[]
}

interface IReassignQueueOptions {
  params: IReassignQueueParams
  body: IReassignQueueBody[] | any
}

export interface IReassignQueueResponseBody {
  message: string | null
  code: number
  result: null
}

/*******************************
 POST - /api/BayMonitor/RemoveQueue *
 *******************************/

type IRemoveQueueParams = IReassignQueueParams

/*******************************
 GET - /api/BayMonitor/GetReassignmentData *
 *******************************/

type IGetReassignmentDataParams = IGetBayMonitorRequestParams

export interface IGetReassignmentDataResponseBody {
  message: string | null
  code: number
  result: IGetReassignmentResult[]
}

export interface IGetReassignmentResult {
  tankerID: number
  tankerCode: string
  documentType: string
  documentID: number
  documentNr: string
  oldBayList: string[]
  newBayList: string[]
}

export const useGetBayMonitor = (params: IGetBayMonitorRequestParams) => {
  return useQuery<unknown, unknown, IGetBayMonitorResponseBody>({
    queryKey: ['getBayMonitor'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetBayMonitorResponseBody>('/BayMonitor/GetBayMonitorData', {
          params,
        }),
        toastParams
      )
      toast.clearWaitingQueue()
      return data
    },
  })
}

export const useGetProductAvailableByBay = (
  params: IGetProdAvailableByBayParams
) => {
  return useQuery<unknown, unknown, IGetProdAvailableByBayResponseBody>({
    queryKey: ['getProductByBay', params],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetProdAvailableByBayResponseBody>(
          '/BayMonitor/ShowBayProdAvailability',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()
      return data
    },
  })
}

export const useGetQListDataSummary = (params: IGetQListDataSummaryParams) => {
  return useQuery<unknown, unknown, IGetQListDataSummaryResponseBody>({
    queryKey: ['getQListData'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetQListDataSummaryResponseBody>(
          '/BayMonitor/GetQListDataSummary',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

export const useGetCurrentCompartment = (
  params: IGetCurrentCompartmentParams
) => {
  return useQuery<unknown, unknown, IGetCurrentCompartmentResponseBody>({
    queryKey: ['getCurrentCompartment', params.qListID],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetCurrentCompartmentResponseBody>(
          '/BayMonitor/GetCurrentCompartment',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()

      return data
    },
  })
}

export const useReassignQueue = (cb?: () => void) => {
  return useMutation<
    IReassignQueueResponseBody,
    unknown,
    IReassignQueueOptions
  >({
    mutationFn: async ({ params, body }) => {
      const { data } = await api.post<IReassignQueueResponseBody>(
        '/BayMonitor/ReassignQueue',
        body,
        {
          params,
        }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['getBayMonitor'] })
      cb?.()
    },
  })
}

export const useRemoveQueue = () => {
  return useMutation({
    mutationFn: async (params: IRemoveQueueParams) => {
      const { data } = await api.post(`/BayMonitor/RemoveQueue`, { params })

      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      queryClient.invalidateQueries({ queryKey: ['getBayMonitor'] })
    },
  })
}

// export const useMoveToUnassignBay = (cb?: () => void) => {
//   return useMutation<
//     IMoveToUnassignBayResponseBody,
//     unknown,
//     IMoveToUnassignBayOptions
//   >({
//     mutationFn: async ({ params, body }) => {
//       const { data } = await api.post<IMoveToUnassignBayResponseBody>(
//         '/BayMonitor/MoveToUnassignBay',
//         body,
//         {
//           params,
//         }
//       )
//       return data
//     },
//     onSuccess: (data) => {
//       if (data.message) {
//         throw new Error(data.message)
//       }
//       queryClient.invalidateQueries({ queryKey: ['getBayMonitor'] })
//       cb?.()
//     },
//   })
// }

export const useGetReassignmentData = (params: IGetReassignmentDataParams) => {
  return useQuery<unknown, unknown, IGetReassignmentDataResponseBody>({
    queryKey: ['GetReassignment'],
    queryFn: async () => {
      const { data } = await toast.promise(
        api.get<IGetReassignmentDataResponseBody>(
          '/BayMonitor/GetReassignmentData',
          {
            params,
          }
        ),
        toastParams
      )
      toast.clearWaitingQueue()
      return data
    },
  })
}
