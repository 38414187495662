import { Link } from '@tanstack/react-router'

interface TreeNavigationItemProps {
  label: string
  to?: string
  optionalClass?: string
}

const TreeNavigationItem = ({
  label,
  to = '#',
  optionalClass,
}: TreeNavigationItemProps) => {
  return (
    <li
      className={`em-c-tree__item ${optionalClass ? optionalClass : ''}`}
      role="menuitem"
      onClick={(e) => e.stopPropagation()}
    >
      <Link
        to={to}
        className="em-c-tree__link"
        activeProps={{
          className: 'active-link',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <span className="em-c-tree__text">{label}</span>
      </Link>
    </li>
  )
}

export default TreeNavigationItem
