import { create } from 'zustand'

export interface IBayProdInformation {
  bayProdID?: number
  bayID?: number
  bay?: string
  arm?: number
  baseProdID?: number
  baseProdCode?: string
  baseProdName?: string
  isActive?: boolean
  remark: string
  createdDate: Date
  createdBy: string
  modifiedDate: Date
  modifiedBy: string
  displayRemark: string
  displayCreatedDate: string
  displayModifiedDate: string
  displayIsActive: string
}

export interface IBayProdData {
  result: IBayProdInformation[]
  total: number
}

interface BayProdMasterState {
  currentBayProd: IBayProdInformation | undefined
  setCurrentBayProd: (value: IBayProdInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddBayProdModal: boolean
  setToggleAddBayProdModal: (value: boolean) => void
  toggleEditBayProdModal: boolean
  setToggleEditBayProdModal: (value: boolean) => void
  toggleMultipleEditBayProdModal: boolean
  setToggleMultipleEditBayProdModal: (value: boolean) => void
}

export const useBayProdMasterStore = create<BayProdMasterState>((set) => ({
  currentBayProd: undefined,
  setCurrentBayProd: (value) => set({ currentBayProd: value }),
  toggleConfirmDeleteModal: false,
  setToggleConfirmDeleteModal: (value) =>
    set({ toggleConfirmDeleteModal: value }),
  toggleAddBayProdModal: false,
  setToggleAddBayProdModal: (value) => set({ toggleAddBayProdModal: value }),
  toggleEditBayProdModal: false,
  setToggleEditBayProdModal: (value) => set({ toggleEditBayProdModal: value }),
  toggleMultipleEditBayProdModal: false,
  setToggleMultipleEditBayProdModal: (value) =>
    set({ toggleMultipleEditBayProdModal: value }),
}))
