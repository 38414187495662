import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import {
  IGetQShipmentByID,
  useEditShipment,
  useGetShipment,
} from '@/services/api/shipmentMaster'

import { IShipmentDetail, useShipmentMasterStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'

import { formatDateToLocalDateTime } from '@/utils'

import ShipmentForm from '../forms/shipment'
import ShipmentChildTable from '../tables/child'

const EditShipmentModal: FC = () => {
  const {
    setToggleEditShipmentModal,
    clearState,
    setShipmentChildren,
    setToggleAddShipmentChildModal,
    shipmentChildren,
    currentShipment,
  } = useShipmentMasterStore()

  const { data: shipment, isFetching } = useGetShipment({
    termID: '1002',
    shipmentID: currentShipment?.shipmentID,
  })

  const methods = useForm<IGetQShipmentByID>({
    mode: 'onBlur',
    values: {
      ...shipment,
      schedDate: formatDateToLocalDateTime(shipment?.schedDate),
      expDate: formatDateToLocalDateTime(shipment?.expDate),
    } as IGetQShipmentByID,
  })

  const { mutate, isPending } = useEditShipment(clearState)

  const handleClose = () => {
    setToggleEditShipmentModal(false)
  }

  const handleAddChild = () => {
    setToggleAddShipmentChildModal(true)
  }

  const onSubmit = (data: IGetQShipmentByID) => {
    mutate({
      body: {
        shipmentID: data.shipmentID,
        shipmentNr: data.shipmentNr,
        carrierID: data.carrierID,
        drawerID: data.drawerID,
        tankerID: data.tankerID,
        tripPriority: data.tripPriority,
        schedDate: data.schedDate,
        expDate: data.expDate,
        remark: data.remark,
        type: data.type,
        details: shipmentChildren.map(
          ({
            drawerProdID,
            shipmentDetailID,
            shipToCustomerID,
            soldToCustomerID,
          }) => ({
            drawerProdID,
            shipmentDetailID,
            shipToCustomerID,
            soldToCustomerID,
          })
        ),
      },
      params: {
        termID: '1002',
      },
    })
  }

  useEffect(() => {
    setShipmentChildren((shipment?.details as IShipmentDetail[]) || [])
    return () => {
      setShipmentChildren([])
    }
  }, [shipment?.details])

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a shipment"
        confirmLabel="Edit"
        isValid={methods.formState.isValid && shipmentChildren.length > 0}
        onClose={handleClose}
        modalWindowOptionalClass="!w-[50rem]"
        isLoading={isPending}
      >
        <ShipmentForm isEdit />
        <ButtonGroup optionalClass="mt-4">
          <Button
            disabled={!methods.formState.isValid}
            type="em-c-btn--primary"
            label="+ Add child"
            typeAttribute="button"
            onClick={handleAddChild}
          />
        </ButtonGroup>
        <ShipmentChildTable
          shipmentChildren={shipmentChildren}
          isLoading={isFetching}
        />
      </FormModal>
    </FormProvider>
  )
}

export default EditShipmentModal
