import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { useAddBayProd } from '@/services/api/bayProdMaster'

import { IBayProdInformation, useBayProdMasterStore } from '@/stores'

import { Required } from '@/utils'

import BayProdMasterForm from './form'

const BayProdMasterAddModal: FC = () => {
  const { setToggleAddBayProdModal } = useBayProdMasterStore()

  const handleClose = () => {
    setToggleAddBayProdModal(false)
  }

  const { mutate, isPending } = useAddBayProd(handleClose)
  const { register, handleSubmit, formState, watch, ...formMethods } = useForm<
    Required<IBayProdInformation>
  >({
    mode: 'onBlur',
  })

  const onSubmit = (data: Required<IBayProdInformation>) => {
    mutate({
      body: {
        bayProdID: 0,
        bayID: data.bayID,
        arm: data.arm,
        baseProdID: data.baseProdID,
        isActive: data.isActive,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      watch={watch}
      {...formMethods}
    >
      <FormModal
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title="Add a bay product"
        confirmLabel="Add"
        isValid={formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <BayProdMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default BayProdMasterAddModal
