import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteCarrier } from '@/services/api/carrierMaster'

import { useCarrierMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const CarrierDeleteModal: FC = () => {
  const { currentCarrier, setCurrentCarrier, setToggleConfirmDeleteModal } =
    useCarrierMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentCarrier(undefined)
  }

  const { mutate, isPending } = useDeleteCarrier(handleClose)

  const handleConfirm = () => {
    mutate({ termID: '1002', carrierID: currentCarrier!.carrierID })
  }

  return (
    <DeleteModal
      title="Delete a carrier"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Carrier Code: {currentCarrier?.carrierCode}
        </BulletedListItem>
        <BulletedListItem>
          Carrier Name: {currentCarrier?.carrierName}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default CarrierDeleteModal
