import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface AlertInModalStoreState {
  isAlertInModalOpen: boolean
  setIsAlertInModalOpen: (value: boolean) => void
  alertInModalMessage: string
  setAlertInModalMessage: (value: string) => void
}

const useAlertInModalStore = create<AlertInModalStoreState>()(
  immer((set) => ({
    isAlertInModalOpen: false,
    setIsAlertInModalOpen: (value) => {
      set((state) => {
        if (!value) state.alertInModalMessage = ''
        state.isAlertInModalOpen = value
      })
    },
    alertInModalMessage: '',
    setAlertInModalMessage: (value) => {
      set((state) => {
        state.alertInModalMessage = value
      })
    },
  }))
)

export default useAlertInModalStore
