import { FC } from 'react'

import Layout from '@/components/Layout'

import AuditReportFeature from '@/features/reports/audit'

const AuditReportPage: FC = () => {
  return (
    <Layout>
      <AuditReportFeature />
    </Layout>
  )
}

export default AuditReportPage
