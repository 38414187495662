import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  CarrierAutoComplete,
  DatePicker,
  DrawerAutoComplete,
  TankerAutoComplete,
} from '@/components'

import { IShipmentInformation } from '@/stores'

import Select from '@/standard/Select'
import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

import {
  extractOnlyNumericCharacters,
  formatISODate,
  parseNanToZero,
} from '@/utils'

interface ShipmentFormProps {
  isLoading?: boolean
  isEdit?: boolean
}

const ShipmentForm: FC<ShipmentFormProps> = ({ isLoading, isEdit }) => {
  const { formState, register, setValue, watch } =
    useFormContext<IShipmentInformation>()

  const shipmentNumberState =
    isLoading || isEdit
      ? 'em-is-disabled'
      : formState.errors.shipmentNr && 'em-has-error'
  const tripPriorityState = isLoading
    ? 'em-is-disabled'
    : formState.errors.tripPriority && 'em-has-error'
  const schedDateState = isLoading
    ? 'em-is-disabled'
    : formState.errors.schedDate && 'em-has-error'
  const expDateState = isLoading
    ? 'em-is-disabled'
    : formState.errors.expDate && 'em-has-error'
  const remarkState = isLoading ? 'em-is-disabled' : undefined
  const typeState = isLoading
    ? 'em-is-disabled'
    : formState.errors.type && 'em-has-error'

  const handleShipmentNrChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('shipmentNr', extractOnlyNumericCharacters(newValue))
  }

  const handleTripPriorityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('tripPriority', newValue as unknown as number)
  }

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4 mt-2 lg:mt-4">
        <TextInput
          id="shipmentNumber"
          label="Shipment Number"
          required
          maxLength={10}
          state={shipmentNumberState}
          note={formState.errors.shipmentNr?.message}
          {...register('shipmentNr', {
            required: {
              value: true,
              message: 'Shipment Number is required',
            },
            onChange: handleShipmentNrChange,
          })}
        />
        <DrawerAutoComplete isDisabled={isLoading} isRequired />
        <CarrierAutoComplete isDisabled={isLoading} isRequired />
        <TankerAutoComplete />
        <TextInput
          id="tripPriority"
          label="Trip Priority"
          required
          maxLength={3}
          state={tripPriorityState}
          note={formState.errors.tripPriority?.message}
          {...register('tripPriority', {
            required: {
              value: true,
              message: 'Trip Priority is required',
            },
            onChange: handleTripPriorityChange,
            setValueAs: parseNanToZero,
          })}
        />
        <Select
          required
          label="Type"
          id="type"
          selectOptionalClass="w-full"
          note={formState.errors.type?.message}
          state={typeState}
          {...register('type', {
            required: {
              value: true,
              message: 'Type is required',
            },
            setValueAs: parseNanToZero,
          })}
        >
          <option value="" />
          <option value="2">Preschedule</option>
          <option value="3">Preorder</option>
        </Select>
        <DatePicker
          label="Scheduled Date"
          id="SchedDate"
          state={schedDateState}
          note={formState.errors.schedDate?.message}
          required
          {...register('schedDate', {
            required: {
              value: true,
              message: 'Scheduled Date is required',
            },
            setValueAs: formatISODate,
          })}
        />
        <DatePicker
          label="Expiration Date"
          id="ExpDate"
          required
          state={expDateState}
          note={formState.errors.expDate?.message}
          min={watch('schedDate')}
          {...register('expDate', {
            required: {
              value: true,
              message: 'Expiration Date is required',
            },
            setValueAs: formatISODate,
          })}
        />
      </div>
      <div className="grid grid-cols-1 gap-2 lg:gap-4 mt-2 lg:mt-4">
        <TextArea
          id="remark"
          label="Remark"
          textareaOptionalClass="!h-[6rem]"
          maxLength={200}
          state={remarkState}
          {...register('remark')}
        />
      </div>
    </>
  )
}

export default ShipmentForm
