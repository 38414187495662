import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { IPriorityInformation } from '@/stores'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

import { extractOnlyNumericCharacters } from '@/utils'

interface PriorityMasterFormProps {
  isEdit?: boolean
}

const PriorityMasterForm: FC<PriorityMasterFormProps> = ({ isEdit }) => {
  const { formState, register, setValue } =
    useFormContext<IPriorityInformation>()

  const priorityCodeState = isEdit
    ? 'em-is-disabled'
    : formState.errors.priorityCode && 'em-has-error'
  const priorityNameState = formState.errors.priorityName && 'em-has-error'
  const delayMinState = formState.errors.delayMin && 'em-has-error'
  const dropMaxState = formState.errors.dropMax && 'em-has-error'

  const handlePriorityCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('priorityCode', +extractOnlyNumericCharacters(newValue))
  }

  const handleDelayMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('delayMin', +extractOnlyNumericCharacters(newValue))
  }

  const handleDropMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue('dropMax', +extractOnlyNumericCharacters(newValue))
  }

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <TextInput
        id="priorityCode"
        label="Priority Code"
        type="number"
        state={priorityCodeState}
        maxLength={20}
        required
        note={formState.errors.priorityCode?.message}
        {...register('priorityCode', {
          required: {
            value: true,
            message: 'Priority Code is required',
          },
        })}
        onChange={handlePriorityCodeChange}
      />
      <TextInput
        id="priorityName"
        label="Priority Name"
        state={priorityNameState}
        maxLength={50}
        required
        note={formState.errors.priorityName?.message}
        {...register('priorityName', {
          required: {
            value: true,
            message: 'Priority Name is required',
          },
        })}
      />
      <TextInput
        id="delayMin"
        label="Delay Min"
        type="text"
        state={delayMinState}
        maxLength={3}
        required
        note={formState.errors.delayMin?.message}
        {...register('delayMin', {
          required: {
            value: true,
            message: 'Delay Min is required',
          },
        })}
        onChange={handleDelayMinChange}
      />
      <TextInput
        id="dropMax"
        label="Drop Max"
        type="text"
        state={dropMaxState}
        maxLength={3}
        required
        note={formState.errors.dropMax?.message}
        {...register('dropMax', {
          required: {
            value: true,
            message: 'Drop Max is required',
          },
        })}
        onChange={handleDropMaxChange}
      />
      <TextArea
        id="remark"
        label="Remark"
        textareaOptionalClass="!h-[6rem]"
        maxLength={200}
        {...register('remark')}
      />
    </div>
  )
}

export default PriorityMasterForm
