import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export interface IContractInformation {
  carrierCode: string
  carrierID: number
  createdBy: string
  createdDate: string
  displayCreatedDate: string
  displayValidityEnd: string
  displayModifiedDate: string
  displayValidityStart: string
  validityStart: string
  validityEnd: string
  drawerCode: string
  drawerID: number
  expDate: string
  modifiedBy: string
  modifiedDate: string
  schedDate: string
  contractID: number
  contractNr: string
  contractType: string
  tankerCode: string
  tankerID: number
  soldToCustomerID: number | null
  soldToCustomerCode: string
  shipToCustomerID: number | null
  shipToCustomerCode: string
  termID: string
  tripPriority: number
  remark: string
  details: IContractDetail[]
}

export interface IContractDetail {
  contractDetailID: number
  drawerProdID: string
  drawerProdCode?: string
  itemValStart: string
  itemValEnd: string
}

export interface IContractData {
  result: IContractInformation[]
  total: number
}

interface ContractState {
  currentContract: IContractInformation | undefined
  setCurrentContract: (value: IContractInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddContractModal: boolean
  setToggleAddContractModal: (value: boolean) => void
  toggleEditContractModal: boolean
  setToggleEditContractModal: (value: boolean) => void
  // Detail
  toggleConfirmDeleteDetailModal: boolean
  setToggleConfirmDeleteDetailModal: (value: boolean) => void
  toggleAddContractDetailModal: boolean
  setToggleAddContractDetailModal: (value: boolean) => void
  toggleEditContractDetailModal: boolean
  setToggleEditContractDetailModal: (value: boolean) => void
  contractDetail: IContractDetail[]
  setContractDetail: (value: IContractDetail[]) => void
  addContractDetail: (value: IContractDetail) => void
  editContractDetail: (value: IContractDetail) => void
  deleteContractDetail: () => void
  selectedContractDetail: IContractDetail | undefined
  setSelectedContractDetail: (value: IContractDetail | undefined) => void
  selectedContractDetailIndex: number
  setSelectedContractDetailIndex: (value: number) => void
  clearContractDetail: () => void
}

export const useContractStore = create<ContractState>()(
  immer((set) => ({
    currentContract: undefined,
    setCurrentContract: (value: IContractInformation | undefined) =>
      set((state) => {
        state.currentContract = value
      }),
    toggleConfirmDeleteModal: false,
    setToggleConfirmDeleteModal: (value: boolean) =>
      set((state) => {
        state.toggleConfirmDeleteModal = value
      }),
    toggleAddContractModal: false,
    setToggleAddContractModal: (value: boolean) =>
      set((state) => {
        state.toggleAddContractModal = value
      }),
    toggleEditContractModal: false,
    setToggleEditContractModal: (value: boolean) =>
      set((state) => {
        state.toggleEditContractModal = value
      }),
    // Detail
    toggleConfirmDeleteDetailModal: false,
    setToggleConfirmDeleteDetailModal: (value: boolean) =>
      set((state) => {
        state.toggleConfirmDeleteDetailModal = value
      }),
    toggleAddContractDetailModal: false,
    setToggleAddContractDetailModal: (value: boolean) =>
      set((state) => {
        state.toggleAddContractDetailModal = value
      }),
    toggleEditContractDetailModal: false,
    setToggleEditContractDetailModal: (value: boolean) =>
      set((state) => {
        state.toggleEditContractDetailModal = value
      }),
    contractDetail: [],
    setContractDetail: (value: IContractDetail[]) =>
      set((state) => {
        state.contractDetail = value
      }),
    addContractDetail: (value: IContractDetail) =>
      set((state) => {
        state.contractDetail.push(value)
      }),
    editContractDetail: (value: IContractDetail) =>
      set((state) => {
        state.contractDetail[state.selectedContractDetailIndex] = value
      }),
    deleteContractDetail: () =>
      set((state) => {
        state.contractDetail.splice(state.selectedContractDetailIndex, 1)
      }),
    selectedContractDetail: undefined,
    setSelectedContractDetail: (value: IContractDetail | undefined) =>
      set((state) => {
        state.selectedContractDetail = value
      }),
    selectedContractDetailIndex: 0,
    setSelectedContractDetailIndex: (value: number) =>
      set((state) => {
        state.selectedContractDetailIndex = value
      }),
    clearContractDetail: () =>
      set((state) => {
        state.contractDetail = []
        state.selectedContractDetailIndex = 0
        state.selectedContractDetail = undefined
      }),
  }))
)
