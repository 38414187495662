import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IBayProdData, IBayProdInformation } from '@/stores'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchBayProduct {
  BayID?: number | string
  termID: string
  Arm?: string
  BaseProdCode?: string
  IsActive?: string
  Page: number | string
}

export interface IGetBayProdForActiveFunction {
  termID: string
  BayID?: number | string
  Arm?: string
  BaseProdCode?: string
  IsActive?: string
  Page: number | string
}

export interface IAddBayProd {
  bayProdID: number
  bayID: number
  arm: number
  baseProdID: number
  isActive: boolean
  remark?: string
}

export interface IEditBayProd {
  bayProdID: number
  bayID: number
  arm: number
  baseProdID: number
  isActive: boolean
  remark: string
}

export interface IEditMultipleBayProd {
  bayProdID?: number
  isActive?: boolean
}

interface IResponseBayProd {
  message: string
  code: number
  result: IBayProdData
}

interface IResponseBayProdM {
  message: string
  code: number
  result: IBayProdInformation[]
}

export function useGetBayProd(params: ISearchBayProduct) {
  return useQuery<unknown, unknown, IBayProdData>({
    queryKey: ['SearchBayProd', params.Page],
    queryFn: async () => {
      const { data } = await api.get<IResponseBayProd>(
        `BayProdMaster/SearchBayProd`,
        { params }
      )
      return data?.result
    },
  })
}

interface UseAddBayProdOptions {
  body: IAddBayProd
  params: Pick<ISearchBayProduct, 'termID'>
}

export function useAddBayProd(cb?: () => void) {
  return useMutation<IResponseBayProd, unknown, UseAddBayProdOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post<IResponseBayProd>(
        `BayProdMaster/AddBayProd`,
        body,
        { params }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['SearchBayProd'] })
    },
  })
}

interface UseEditBayProdOptions {
  body: IEditBayProd
  params: Pick<ISearchBayProduct, 'termID'>
}

export function useEditBayProd(cb?: () => void) {
  return useMutation<IResponseBayProd, unknown, UseEditBayProdOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post<IResponseBayProd>(
        `BayProdMaster/EditBayProd`,
        body,
        { params }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['SearchBayProd'] })
    },
  })
}

interface UseDeleteBayProdOptions {
  bayProdID: number
  termID: string
}

export function useDeleteBayProd(cb?: () => void) {
  return useMutation<IResponseBayProd, unknown, UseDeleteBayProdOptions>({
    mutationFn: async (params) => {
      const { data } = await api.delete<IResponseBayProd>(
        `BayProdMaster/DeleteBayProd`,
        { params }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['SearchBayProd'] })
    },
  })
}

export function useGetBayProdForActiveFunction(
  params: IGetBayProdForActiveFunction
) {
  return useQuery<unknown, unknown, IBayProdInformation[]>({
    queryKey: ['SearchBayProdForActiveFunction'],
    queryFn: async () => {
      const { data } = await api.get<IResponseBayProdM>(
        `BayProdMaster/GetBayProdForActiveFunction`,
        { params }
      )
      return data?.result
    },
  })
}

interface UseUpdateBayProdStatusListOptions {
  body: IEditMultipleBayProd[]
  params: Pick<ISearchBayProduct, 'termID'>
}

export function useUpdateBayProdStatusList(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    UseUpdateBayProdStatusListOptions
  >({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post<IResponseBayProd>(
        `BayProdMaster/UpdateBayProdStatusList`,
        body,
        { params }
      )
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['SearchBayProd'] })
    },
  })
}
