import { useMemo } from 'react'

import { calculateEndRange, calculateStartRange } from '@/utils'

interface UsePaginationLabel {
  isLoading?: boolean
  currentPage: number
  rowsPerPage: number
  total: number
}

const usePaginationLabel = (options: UsePaginationLabel) => {
  const { isLoading, currentPage, rowsPerPage, total } = options
  const start = calculateStartRange(currentPage, rowsPerPage)
  const end = calculateEndRange(currentPage, rowsPerPage, total)

  return useMemo(() => {
    if (isLoading) {
      return 'Loading data'
    } else {
      if (total > 0) {
        return `Showing ${start}-${end} of ${total} results`
      } else {
        return 'No data found'
      }
    }
  }, [isLoading, start, end, total])
}

export default usePaginationLabel
