import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DatePicker } from '@/components'

import ContractTable from '@/features/master/contract/tables/contract'

import { ISearchContract, useSearchContract } from '@/services/api/contract'

import { useContractStore, useRoleMatrixStore } from '@/stores'

import Button from '@/standard/Button'
import TextInput from '@/standard/TextInput'

import { filterFalsyProperties, formatISODate } from '@/utils'

const ContractHeader: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const { setToggleAddContractModal } = useContractStore()
  const { contract } = useRoleMatrixStore()
  const { canCreate } = contract

  const { watch, handleSubmit, register } = useForm<ISearchContract>({
    defaultValues: {
      ContractNr: '',
      DrawerCode: '',
      CarrierCode: '',
      ShipToCustomerCode: '',
      SoldToCustomerCode: '',
      ValidityStart: '',
      ValidityEnd: '',
    },
    mode: 'onBlur',
  })

  const {
    isFetching,
    refetch,
    data: contracts,
    isLoading,
  } = useSearchContract({
    ...filterFalsyProperties(watch()),
    ...(watch('ValidityStart')
      ? { ValidityStart: formatISODate(watch('ValidityStart')) }
      : {}),
    ...(watch('ValidityEnd')
      ? { ValidityEnd: formatISODate(watch('ValidityEnd')) }
      : {}),
    Page: currentPage,
    termID: '1002',
  })

  const handleSearch = () => {
    refetch().finally(() => setCurrentPage(1))
  }

  const openModal = () => {
    setToggleAddContractModal(true)
  }

  return (
    <>
      <form
        className="shrink-0 flex gap-1 lg:gap-2 flex-wrap mb-3 lg:mb-6"
        autoComplete="off"
        onSubmit={handleSubmit(handleSearch)}
      >
        <TextInput
          label="Contract Number"
          id="ContractNr"
          maxLength={20}
          {...register('ContractNr')}
        />
        <TextInput
          label="Drawer Code"
          id="DrawerCode"
          maxLength={20}
          {...register('DrawerCode')}
        />
        <TextInput
          label="Carrier Code"
          id="CarrierCode"
          maxLength={20}
          {...register('CarrierCode')}
        />
        <TextInput
          label="Ship To Customer Code"
          id="ShipToCustomerCode"
          maxLength={20}
          {...register('ShipToCustomerCode')}
        />
        <TextInput
          label="Sold To Customer Code"
          id="SoldToCustomerCode"
          maxLength={20}
          {...register('SoldToCustomerCode')}
        />
        <DatePicker
          label="Validity Start"
          id="ValidityStart"
          {...register('ValidityStart', {
            setValueAs: formatISODate,
          })}
        />
        <DatePicker
          label="Validity End"
          id="ValidityEnd"
          {...register('ValidityEnd', {
            setValueAs: formatISODate,
          })}
        />
        <Button
          isIconOnlyButton
          typeAttribute="submit"
          label="Search"
          type="em-c-btn--primary"
          optionalClass="!p-[1.5em] self-end"
          {...(isFetching
            ? { disabled: true, iconName: 'refresh', isLoading: true }
            : { iconName: 'search' })}
        />
        {canCreate && (
          <Button
            typeAttribute="button"
            optionalClass="!p-[1.5em] self-end"
            label="+ Add"
            onClick={openModal}
          />
        )}
      </form>
      <ContractTable
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        contracts={contracts}
        isLoading={isLoading}
      />
    </>
  )
}
export default ContractHeader
