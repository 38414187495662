import { FC, useEffect } from 'react'
import DataGrid from 'react-data-grid'

import { AuditChanges, useAuditReportDetail } from '@/services/api/auditData'

import useAuditReportStore from '@/stores/reports/audit'

import LoadingIndicator from '@/standard/LoadingIndicator'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<AuditChanges>([
  { key: 'columnName', name: 'Column Name' },
  { key: 'oldValue', name: 'Old Value' },
  { key: 'newValue', name: 'New Value' },
])

interface AuditReportDetailTableProps {
  auditHeaderId: number
}

const AuditReportDetailTable: FC<AuditReportDetailTableProps> = ({
  auditHeaderId,
}) => {
  const height = 30
  const { rowHeight, setRowHeight } = useAuditReportStore()
  const { data: changes } = useAuditReportDetail({
    AuditHeaderId: auditHeaderId,
    termId: '1002',
  })

  useEffect(() => {
    if (!rowHeight[auditHeaderId] && changes?.length) {
      setRowHeight({
        auditHeaderId,
        height: height * changes?.length + (changes?.length >= 1 ? height : 0),
      })
    }
  }, [changes?.length])

  return changes ? (
    <DataGrid
      columns={columns}
      rows={changes}
      className="rdg-light max-h-[300px]"
      rowHeight={height}
      headerRowHeight={height}
      style={{
        blockSize:
          height * changes?.length + (changes?.length >= 1 ? height : 0),
        overflowY: 'scroll',
      }}
    />
  ) : (
    <LoadingIndicator isAbsolute size="s" />
  )
}

export default AuditReportDetailTable
