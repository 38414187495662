import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { ICarrierInformation } from '@/stores'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

interface CarrierMasterFormProps {
  isEdit?: boolean
}

const CarrierMasterForm: FC<CarrierMasterFormProps> = ({ isEdit }) => {
  const { formState, register } = useFormContext<ICarrierInformation>()

  const carrierCodeState = isEdit
    ? 'em-is-disabled'
    : formState.errors.carrierCode && 'em-has-error'
  const carrierNameState = formState.errors.carrierName && 'em-has-error'

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <TextInput
        id="carrierCode"
        label="Carrier Code"
        state={carrierCodeState}
        maxLength={20}
        required
        note={formState.errors.carrierCode?.message}
        {...register('carrierCode', {
          required: {
            value: true,
            message: 'Carrier Code is required',
          },
        })}
      />
      <TextInput
        id="carrierName"
        label="Carrier Name"
        state={carrierNameState}
        maxLength={50}
        required
        note={formState.errors.carrierName?.message}
        {...register('carrierName', {
          required: {
            value: true,
            message: 'Carrier Name is required',
          },
        })}
      />
      <TextArea
        id="remark"
        label="Remark"
        textareaOptionalClass="!h-[6rem]"
        maxLength={200}
        {...register('remark')}
      />
    </div>
  )
}

export default CarrierMasterForm
