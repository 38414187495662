import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IAddCustomer, useAddCustomer } from '@/services/api/customerMaster'

import { useCustomerMasterStore } from '@/stores'

import { removeKeyFromObject } from '@/utils'

import CustomerMasterForm from './form'

interface OnSubmit extends IAddCustomer {
  priorityCode: string
}

const CustomerMasterAddModal = () => {
  const { setToggleAddCustomerModal } = useCustomerMasterStore()

  const handleClose = () => {
    setToggleAddCustomerModal(false)
  }

  const { mutate, isPending } = useAddCustomer(handleClose)
  const methods = useForm<OnSubmit>({
    defaultValues: {
      customerID: 0,
      customerCode: '',
      customerName: '',
      remark: '',
      priorityID: 0,
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: OnSubmit) => {
    mutate({
      body: removeKeyFromObject(data, ['priorityCode']),
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a customer"
        confirmLabel="Add"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <CustomerMasterForm />
      </FormModal>
    </FormProvider>
  )
}

export default CustomerMasterAddModal
