import { Layout } from '@/components'

import GateToGateFeature from '@/features/reports/gateToGate'

const GateToGatePage = () => {
  return (
    <Layout>
      <GateToGateFeature />
    </Layout>
  )
}

export default GateToGatePage
