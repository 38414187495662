import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { IDrawerProdGroupInformation } from '@/stores'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

interface DrawerProdGroupMasterFormProps {
  isEdit?: boolean
}

const DrawerProdGroupMasterForm: FC<DrawerProdGroupMasterFormProps> = ({
  isEdit,
}) => {
  const { formState, register } = useFormContext<IDrawerProdGroupInformation>()

  const drawerProdGroupCodeState = isEdit
    ? 'em-is-disabled'
    : formState.errors.drawerProdGroupCode && 'em-has-error'
  const drawerProdGroupNameState =
    formState.errors.drawerProdGroupName && 'em-has-error'

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <TextInput
        id="drawerProdGroupCode"
        label="Drawer Prod Group Code"
        type="number"
        state={drawerProdGroupCodeState}
        maxLength={20}
        required
        note={formState.errors.drawerProdGroupCode?.message}
        {...register('drawerProdGroupCode', {
          required: {
            value: true,
            message: 'Drawer Prod Group Code is required',
          },
        })}
      />
      <TextInput
        id="drawerProdGroupName"
        label="Drawer Prod Group Name"
        state={drawerProdGroupNameState}
        maxLength={50}
        required
        note={formState.errors.drawerProdGroupName?.message}
        {...register('drawerProdGroupName', {
          required: {
            value: true,
            message: 'Drawer Prod Group Name is required',
          },
        })}
      />
      <TextArea
        id="remark"
        label="Remark"
        textareaOptionalClass="!h-[6rem]"
        maxLength={200}
        {...register('remark')}
      />
    </div>
  )
}

export default DrawerProdGroupMasterForm
