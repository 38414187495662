import Icon, { IconTypes } from 'components/Icon'
import React, { FC, MouseEventHandler, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '@/standard/Button'

import { AccordionBodyProps } from './AccordionBody'

export interface AccordionItemProps {
  id: number
  active?: boolean
  title?: string
  children?: React.ReactNode
  /** Optional show icon button */
  showIconButton?: boolean
  iconName?: IconTypes
  onClickButton?: MouseEventHandler<HTMLButtonElement>
}

const AccordionItem: FC<AccordionItemProps> = ({
  id,
  active,
  title,
  children,
  showIconButton,
  iconName,
  onClickButton,
}) => {
  const [isOpen, setIsOpen] = useState(active)
  return (
    <li
      className={`em-c-accordion__item em-js-accordion-item ${
        isOpen ? '' : 'em-is-closed'
      }`}
      id={`accordion-panel-${id + 1}`}
    >
      <div className="em-c-accordion__header" id={`accordionHeader-${id + 1}`}>
        <button
          className="em-c-accordion__title em-js-accordion-trigger"
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-controls={`accordionContent-${id + 1}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon
            size="em-c-icon--small"
            name="chevron-down-filled"
            optionalClass="em-c-icon em-c-accordion__icon"
          />
          <div
            className={twMerge(
              showIconButton && 'flex items-center justify-between w-full'
            )}
          >
            <span className="em-c-accordion__title-text">{title}</span>
            {showIconButton && (
              <Button
                isIconOnlyButton
                typeAttribute="button"
                label="Delete"
                iconName={iconName}
                type="em-c-btn--bare"
                size="em-c-btn--small"
                iconSize="em-c-icon--small"
                onClick={onClickButton}
              />
            )}
          </div>
        </button>
      </div>
      {React.Children.toArray(children).map((child) =>
        React.isValidElement<AccordionBodyProps>(child)
          ? React.cloneElement(child, { id: id })
          : child
      )}
    </li>
  )
}

export default AccordionItem
