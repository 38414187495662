import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteAllQueueAlreadyAssignBay } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

const DeleteAllQueueAlreadyAssignBayModal: FC = () => {
  const { setIsDeleteAllQueueAlreadyAssignBayModalOpen } = useOrderMonitor()

  const handleClose = () => {
    setIsDeleteAllQueueAlreadyAssignBayModalOpen(false)
  }

  const { mutate: deleteAllAlreadyAssignedQueue } =
    useDeleteAllQueueAlreadyAssignBay(handleClose)

  const handleConfirm = () => {
    deleteAllAlreadyAssignedQueue({
      termID: '1002',
    })
  }

  return (
    <DeleteModal
      title="Delete all queue in already assigned ?"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>{`Confirm to DELETE all queue.`}</p>
    </DeleteModal>
  )
}
export default DeleteAllQueueAlreadyAssignBayModal
