import { FC } from 'react'

import Header from '@/features/master/carrier/header'

import { useCarrierMasterStore } from '@/stores'

import CarrierMasterAddModal from './addModal'
import CarrierDeleteModal from './deleteModal'
import CarrierEditModal from './editModal'

const CarrierMasterComponent: FC = () => {
  const {
    toggleAddCarrierModal,
    toggleEditCarrierModal,
    toggleConfirmDeleteModal,
  } = useCarrierMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddCarrierModal && <CarrierMasterAddModal />}
      {toggleEditCarrierModal && <CarrierEditModal />}
      {toggleConfirmDeleteModal && <CarrierDeleteModal />}
      <Header />
    </div>
  )
}
export default CarrierMasterComponent
