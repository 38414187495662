import { twMerge } from 'tailwind-merge'

import Icon, { IconTypes } from '../Icon'

interface AlertProps {
  type?:
    | 'em-c-alert--success'
    | 'em-c-alert--warning'
    | 'em-c-alert--error'
    | 'em-c-alert--a11y'
  /** The body of alert message. It can include guidance, instructions, and even links to take further actions. */
  body: string
  visible: boolean
  onClose?: () => void
  optionalClass?: string
}

const iconNames = {
  'em-c-alert--success': 'circle-check' as IconTypes,
  'em-c-alert--warning': 'warning' as IconTypes,
  'em-c-alert--error': 'circle-exclamation-mark' as IconTypes,
  'em-c-alert--a11y': 'accessibility' as IconTypes,
}
const arialabelNames = {
  'em-c-alert--success': 'Success message',
  'em-c-alert--warning': 'Warging message',
  'em-c-alert--error': 'Error message',
  'em-c-alert--a11y': 'Accessibility message',
}

const Alert = ({
  type,
  body,
  visible = true,
  onClose,
  optionalClass,
  ...props
}: AlertProps) => {
  return (
    <>
      {visible && (
        <div
          className={twMerge('em-c-alert', type, optionalClass)}
          role="alertdialog"
          aria-modal="true"
          aria-label={`${
            type
              ? arialabelNames[type as keyof typeof arialabelNames]
              : 'Alert message'
          }`}
          aria-describedby="alert-msg"
          {...props}
        >
          {type ? (
            <Icon
              name={iconNames[type as keyof typeof iconNames]}
              size="em-c-icon--medium"
              optionalClass="em-c-alert__icon"
            />
          ) : (
            <Icon
              name="info"
              size="em-c-icon--medium"
              optionalClass="em-c-alert__icon"
            />
          )}
          <div
            className="em-c-alert__body em-l-linelength-container"
            id="alert-msg"
          >
            {body}
          </div>
          {onClose && (
            <div className="em-c-alert__actions">
              <button
                className={`em-c-text-btn ${type ? type : ''}`}
                aria-label="Close alert message"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Alert
