import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import { IGetBayResultDetail } from '@/services/api/bayMaster'

import { useBayMasterStore } from '@/stores'

import DedicatedBayForm from '../forms/dedicatedBay'

const AddDedicatedBayModal: FC = () => {
  const { setToggleAddBayChildModal, addBayChild } = useBayMasterStore()

  const methods = useForm<IGetBayResultDetail>({
    mode: 'onBlur',
    defaultValues: {
      dedicatedBayID: 0,
    },
  })

  const handleClose = () => {
    setToggleAddBayChildModal(false)
  }

  const onSubmit = (data: IGetBayResultDetail) => {
    addBayChild(data)
    handleClose()
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Add a dedicated bay"
        onClose={handleClose}
        isValid={methods.formState.isValid}
        confirmLabel="Add"
        modalWindowOptionalClass="!z-[70]"
        overlayOptionalClass="!z-[60]"
      >
        <DedicatedBayForm onSubmit={onSubmit} />
      </FormModal>
    </FormProvider>
  )
}

export default AddDedicatedBayModal
