import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetBayForDropdown } from '@/services/api/dropdown'

import Autocomplete from '@/standard/Autocomplete'

import { parseNanToZero } from '@/utils'

interface BayAutoCompleteField {
  bayID: number | null
  bayCode: string
}

interface BayAutoCompleteProps {
  isRequired?: boolean
  isAbsolute?: boolean
  isDisabled?: boolean
}

const BayAutoComplete: FC<BayAutoCompleteProps> = ({
  isRequired,
  isAbsolute,
  isDisabled,
}) => {
  const { data: bays, isLoading } = useGetBayForDropdown({
    termID: '1002',
  })
  const { formState, setValue, watch, clearErrors, register } =
    useFormContext<BayAutoCompleteField>()

  const bayState =
    isLoading || isDisabled
      ? 'em-is-disabled'
      : formState.errors.bayCode && 'em-has-error'

  return (
    <Autocomplete
      isAbsolute={isAbsolute}
      state={bayState}
      note={formState.errors.bayCode?.message}
      id="bayID"
      required={isRequired}
      itemToString={(item) => item?.label || ''}
      items={bays}
      label="Bay"
      filterKey="label"
      renderedKey="label"
      onClickItem={() => clearErrors('bayCode')}
      maxLength={20}
      value={watch('bayCode')}
      {...register('bayCode', {
        required: isRequired ? 'Bay is required' : false,
        validate: () => (watch('bayID') === 0 ? 'Bay is not valid' : undefined),
      })}
      onChange={(value) => {
        setValue('bayID', value?.label ? parseNanToZero(value?.value) : null)
        setValue('bayCode', value?.label)
      }}
    />
  )
}

export default BayAutoComplete
