import { FC } from 'react'

import { useViewQueueAlreadyAssignBay } from '@/services/api/monitor/order'

import { useBayMonitor, useOrderMonitor } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

const DeleteQueueModal: FC = () => {
  const {
    currentWaitingQueue,
    setIsDeleteQueueModalOpen,
    setIsDeleteQueueByQListModalOpen,
    setIsDeleteQueueByTankerModalOpen,
    setCurrentDeleteQueueByQList,
  } = useOrderMonitor()

  const {
    currentQueueInAlreadyAssigned,
    currentQueueInBay,
    setCurrentQueueInAlreadyAssigned,
  } = useBayMonitor()

  const tankerID =
    Number(currentQueueInAlreadyAssigned?.tankerID) ||
    Number(currentQueueInBay?.tankerID) ||
    Number(currentWaitingQueue?.tankerID)
  const documentID =
    Number(currentQueueInAlreadyAssigned?.documentID) ||
    Number(currentQueueInBay?.documentID) ||
    Number(currentWaitingQueue?.documentID)
  const documentType =
    currentQueueInAlreadyAssigned?.documentType ||
    currentQueueInBay?.documentType ||
    currentWaitingQueue?.documentType ||
    ''

  const { data } = useViewQueueAlreadyAssignBay({
    termID: '1002',
    tankerID,
    documentID,
    documentType,
  })

  const handleClose = () => {
    // setIsUpdateDTMModalOpen(false)
    setCurrentQueueInAlreadyAssigned(undefined)
    setIsDeleteQueueModalOpen(false)
  }

  const handleDeleteQueueByQListModal = () => {
    setIsDeleteQueueByQListModalOpen(true)
  }
  const handleDeleteQueueByTankerModal = () => {
    setIsDeleteQueueByTankerModalOpen(true)
  }

  return (
    <Modal modalWindowOptionalClass="!w-full">
      <ModalHeader
        title="Delete Queue"
        onClose={handleClose}
        optionalClass="!mb-[0]"
      />
      <span className="text-main-darkRed">
        This allows you to delete the queue. You can delete the queue by queue
        or delete all.
      </span>
      <div className="overflow-y-auto">
        <ModalBody>
          <div className="pt-6">
            <h5>List of queue</h5>
            <div className="grid grid-cols-4 gap-2 font-bold border-b-2 border-black px-2 pt-2">
              <div>Queue ID</div>
              <div>Order No.</div>
              <div>Bay</div>
              <div>Action</div>
            </div>

            {data?.result.detailByTankerAndDocList.map((prod) => {
              return (
                <div
                  className="grid grid-cols-4 place-items-baseline gap-2 p-2 bg-slate-200"
                  key={`${prod.documentID}-${prod.qListID}`}
                >
                  <div>{prod.qListID}</div>
                  <div>{`${prod.documentType} - ${prod.documentNr}`}</div>
                  <div>{prod.bay}</div>
                  <div>
                    <Button
                      isIconOnlyButton
                      typeAttribute="button"
                      label="DeleteQueue"
                      title="DeleteQueue"
                      iconName="trashcan"
                      size="em-c-btn--small"
                      iconSize="em-c-icon--small"
                      optionalClass="!p-2"
                      onClick={() => {
                        handleDeleteQueueByQListModal()
                        setCurrentDeleteQueueByQList(prod)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </ModalBody>
        <ModalFooter optionalClass="pt-4">
          <ButtonGroup>
            <Button
              type="em-c-btn--primary"
              label="Delete All"
              onClick={() => {
                handleDeleteQueueByTankerModal()
              }}
            />
            <Button label="Close" onClick={handleClose} />
          </ButtonGroup>
        </ModalFooter>
      </div>
    </Modal>
  )
}
export default DeleteQueueModal
