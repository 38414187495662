import React from 'react'

interface ModalBodyProps {
  children?: React.ReactNode
  optionalClass?: string
}

const ModalBody = ({ children, optionalClass }: ModalBodyProps) => {
  return (
    <div
      className={`em-c-modal__body ${optionalClass ? optionalClass : ''}`}
      id="modalDescription"
    >
      {children}
    </div>
  )
}

export default ModalBody
