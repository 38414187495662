import { FC, useState } from 'react'

import {
  IEditMultipleBayProd,
  useUpdateBayProdStatusList,
} from '@/services/api/bayProdMaster'
import { queryClient } from '@/services/queryClient'

import { IBayProdInformation, useBayProdMasterStore } from '@/stores'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

import ManageMultipleBayProductHeader from './header'
import ManageMultipleBayProductTable from './table'

const ManageMultipleBayProductModal: FC = () => {
  const { setToggleMultipleEditBayProdModal } = useBayProdMasterStore()
  const [currentPage] = useState(1)

  const handleClose = () => {
    setToggleMultipleEditBayProdModal(false)
  }

  const { mutate, isPending } = useUpdateBayProdStatusList(handleClose)

  const handleConfirm = () => {
    const [[, data]] = queryClient.getQueriesData<IBayProdInformation[]>({
      queryKey: ['SearchBayProdForActiveFunction'],
    })
    if (data) {
      const body: IEditMultipleBayProd[] = data.map((item) => ({
        bayProdID: item.bayProdID,
        isActive: item.isActive,
      }))
      mutate({ body, params: { termID: '1002' } })
    }
  }

  return (
    <Modal modalWindowOptionalClass="!w-[75vw]">
      <ModalHeader
        optionalClass="!mb-[0]"
        title="Manage Multiple Bay Products"
        onClose={handleClose}
        isError
      />
      <div className="overflow-y-auto">
        <ModalBody>
          <div className="mb-4">
            <ManageMultipleBayProductHeader currentPage={currentPage} />
          </div>
          <ManageMultipleBayProductTable currentPage={currentPage} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup optionalClass="mt-2">
            <Button
              type="em-c-btn--primary"
              typeAttribute="submit"
              onClick={handleConfirm}
              {...(isPending
                ? {
                    isIconOnlyButton: true,
                    iconName: 'refresh',
                    isLoading: true,
                    disabled: true,
                  }
                : { label: 'Confirm' })}
            />
            <Button
              typeAttribute="button"
              label="Cancel"
              onClick={handleClose}
            />
          </ButtonGroup>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ManageMultipleBayProductModal
