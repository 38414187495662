import Button from 'components/Button'
import React, { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

interface ModalProps {
  /** Defines the size of modal window. */
  size?: 'em-c-modal--large'
  /** If true, adds a button with the 'em-js-modal-trigger` class that triggers the opening of the modal. */
  withTrigger?: boolean
  /** If true, adds footer with action buttons. */
  withFooter?: boolean
  children: React.ReactNode
  optionalClass?: string
  modalWindowOptionalClass?: string
  overlayOptionalClass?: string
}

const Modal = ({
  size,
  withTrigger,
  children,
  optionalClass,
  modalWindowOptionalClass,
  overlayOptionalClass,
}: ModalProps) => {
  useEffect(() => {
    document.body.classList.add('no-scroll')

    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [])

  return (
    <>
      {withTrigger && (
        <Button label="Open modal" optionalClass="em-js-modal-trigger" />
      )}
      <div
        className={twMerge('em-c-modal', optionalClass)}
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section
          className={twMerge(
            'em-c-modal__window',
            size,
            withTrigger && 'em-is-closed',
            modalWindowOptionalClass
          )}
        >
          {children}
        </section>
        <div
          className={twMerge(
            'em-c-modal__overlay',
            withTrigger && 'em-is-closed',
            overlayOptionalClass
          )}
        ></div>
      </div>
    </>
  )
}

export default Modal
