import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteQueueByQList } from '@/services/api/monitor/order'

import { useBayMonitor, useOrderMonitor } from '@/stores'

const DeleteQueueByQListModal: FC = () => {
  const {
    currentDeleteQueueByQList,
    setIsDeleteQueueModalOpen,
    setCurrentDeleteQueueByQList,
    setIsDeleteQueueByQListModalOpen,
    setCurrentWaitingQueue,
  } = useOrderMonitor()
  const { setCurrentQueueInAlreadyAssigned, setCurrentQueueInBay } =
    useBayMonitor()

  const handleCloseWhenDeleteSuccess = () => {
    setIsDeleteQueueByQListModalOpen(false)
    setIsDeleteQueueModalOpen(false)
    setCurrentDeleteQueueByQList(undefined)
    setCurrentWaitingQueue(undefined)
    setCurrentQueueInAlreadyAssigned(undefined)
    setCurrentQueueInBay(undefined)
  }
  const { mutate: deleteQueueByQList } = useDeleteQueueByQList(
    handleCloseWhenDeleteSuccess
  )

  const handleConfirm = () => {
    deleteQueueByQList({
      termID: '1002',
      qListID: Number(currentDeleteQueueByQList?.qListID),
    })
  }

  return (
    <DeleteModal
      modalWindowOptionalClass="!z-[70]"
      overlayOptionalClass="!z-[60]"
      title="Delete this queue"
      confirmText="Confirm"
      onClose={() => {
        setIsDeleteQueueByQListModalOpen(false)
        setCurrentDeleteQueueByQList(undefined)
      }}
      onDelete={handleConfirm}
    >
      <div className=""></div>
      <p>
        Confirm to delete queue:
        <span className="font-bold pl-1">
          {currentDeleteQueueByQList?.qListID}
        </span>
        ?
      </p>
    </DeleteModal>
  )
}
export default DeleteQueueByQListModal
