import { FC } from 'react'

import GateToGateHeader from './header'

const GateToGateFeature: FC = () => {
  return (
    <div className="flex flex-col h-full">
      <GateToGateHeader />
    </div>
  )
}

export default GateToGateFeature
