import Button from 'components/Button'
import Icon, { IconTypes } from 'components/Icon'
import { twMerge } from 'tailwind-merge'

import useAlertInModalStore from '@/stores/common/alertInModal'

import Alert from '@/standard/Alert'

interface ModalHeaderProps {
  icon?: IconTypes
  kicker?: string
  title?: string
  optionalClass?: string
  onClose?: () => void
  isError?: boolean
}

const ModalHeader = ({
  icon,
  kicker,
  title,
  optionalClass,
  onClose,
  isError,
}: ModalHeaderProps) => {
  const {
    alertInModalMessage: body,
    isAlertInModalOpen: visible,
    setIsAlertInModalOpen,
  } = useAlertInModalStore()

  return (
    <div className={twMerge('em-c-modal__header', optionalClass)}>
      <div className="w-full">
        {icon && <Icon name={icon} size="em-c-icon--large" />}
        {kicker && <div className="em-c-modal__kicker">{kicker}</div>}
        <div className="em-c-modal__title" id="modalTitle">
          {title}
        </div>
        {isError && (
          <Alert
            optionalClass="mt-4"
            type="em-c-alert--error"
            body={body}
            visible={visible}
            onClose={() => setIsAlertInModalOpen(false)}
          />
        )}
      </div>
      <Button
        type="em-c-btn--bare"
        isIconOnlyButton
        iconName="x-filled"
        optionalClass="em-c-modal__close-btn"
        title="Close modal"
        onClick={onClose}
        typeAttribute="button"
      />
    </div>
  )
}

export default ModalHeader
