import { FormProvider, useForm } from 'react-hook-form'

import { FormModal } from '@/components'

import PriorityMasterForm from '@/features/master/priority/form'

import { useEditPriority } from '@/services/api/priorityMaster'

import { IPriorityInformation, usePriorityMasterStore } from '@/stores'

const PriorityEditModal = () => {
  const { currentPriority, setToggleEditPriorityModal, setCurrentPriority } =
    usePriorityMasterStore()

  const handleClose = () => {
    setToggleEditPriorityModal(false)
    setCurrentPriority(undefined)
  }

  const { mutate, isPending } = useEditPriority(handleClose)
  const methods = useForm<IPriorityInformation>({
    values: currentPriority,
    mode: 'onBlur',
  })

  const onSubmit = (data: IPriorityInformation) => {
    mutate({
      body: {
        priorityID: data.priorityID,
        priorityCode: data.priorityCode,
        priorityName: data.priorityName,
        delayMin: data.delayMin,
        dropMax: data.dropMax,
        remark: data.remark,
      },
      params: { termID: '1002' },
    })
  }

  return (
    <FormProvider {...methods}>
      <FormModal
        handleSubmit={methods.handleSubmit}
        onSubmit={onSubmit}
        title="Edit a priority"
        confirmLabel="Edit"
        isValid={methods.formState.isValid}
        onClose={handleClose}
        isLoading={isPending}
      >
        <PriorityMasterForm isEdit />
      </FormModal>
    </FormProvider>
  )
}

export default PriorityEditModal
