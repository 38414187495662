import { IconTypes } from './IconTypes'

export interface IconProps {
  /** Size to apply to the icon. */
  size?: 'em-c-icon--small' | 'em-c-icon--medium' | 'em-c-icon--large'
  /** Name of the icon you want to use. */
  name: IconTypes
  optionalClass?: string
}

const iconSize = {
  'em-c-icon--small': '16',
  'em-c-icon--medium': '24',
  'em-c-icon--large': '48',
}

const Icon = ({
  size = 'em-c-icon--medium',
  name,
  optionalClass,
  ...props
}: IconProps) => {
  return (
    <>
      <svg
        className={`em-c-icon ${size} ${optionalClass ? optionalClass : ''}`}
        {...props}
      >
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref={`images/${
            iconSize[size as keyof typeof iconSize]
          }/em-icons.svg#${name}`}
        ></use>
      </svg>
    </>
  )
}

export default Icon
