import {
  ChangeEventHandler,
  InputHTMLAttributes,
  forwardRef,
  useId,
} from 'react'
import { twMerge } from 'tailwind-merge'

import Icon, { IconTypes } from '../Icon'

export type TextInputState =
  | 'em-is-valid'
  | 'em-has-error'
  | 'em-is-readonly'
  | 'em-is-disabled'

interface TextInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /** Field/validation status, one of: */
  state?: TextInputState
  /** Specifies a size on a text input */
  size?: 'em-c-field--small'
  /** Adds an asterisk (*) to mark the field as required */
  required?: boolean
  /** The `id` attribute is needed to associate the input field with a label */
  id?: string
  /** Label that sits above the text input */
  label?: string
  /** Specifies a short hint that describes the expected value of an `<input>` element */
  placeholder?: string
  /** Helps browser interpret what value the text input is displaying. One of: */
  type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url'
  /** Specifies the name of an `<input>` element */
  name?: string
  /** The value that the text input contains */
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  /** A note about the field/validation status */
  note?: string
  optionalClass?: string
  bodyOptionalClass?: string
}

const inputIcon = {
  'em-is-valid': 'circle-check' as IconTypes,
  'em-has-error': 'warning' as IconTypes,
  'em-is-readonly': 'lock' as IconTypes,
  'em-is-disabled': 'ban' as IconTypes,
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      state,
      size,
      required,
      id = 'input',
      label,
      placeholder,
      type = 'text',
      name,
      value,
      onChange,
      note,
      optionalClass,
      bodyOptionalClass,
      ...props
    },
    ref
  ) => {
    const randomId = useId()

    return (
      <div
        className={twMerge('em-c-field !mb-[0]', state, size, optionalClass)}
      >
        {label && (
          <label htmlFor={id} className="em-c-field__label">
            {required && (
              <span aria-hidden="true" className="em-c-field__required">
                *
              </span>
            )}
            {label}
          </label>
        )}
        <div className={twMerge('em-c-field__body !mb-[0]', bodyOptionalClass)}>
          <input
            type={type}
            id={id}
            name={name}
            className="em-c-input em-js-input"
            placeholder={placeholder}
            defaultValue={value}
            disabled={state === 'em-is-disabled'}
            readOnly={state === 'em-is-readonly'}
            onChange={onChange}
            aria-describedby={`textinput-note-${randomId}`}
            aria-required={required}
            required={required}
            ref={ref}
            {...props}
          />
          {state && (
            <Icon
              name={inputIcon[state as keyof typeof inputIcon]}
              size={size && 'em-c-icon--small'}
              optionalClass="em-c-field__icon"
            />
          )}
        </div>
        {note && (
          <div className="em-c-field__note" id={`textinput-note-${randomId}`}>
            {note}
          </div>
        )}
      </div>
    )
  }
)

export default TextInput
