import { create } from 'zustand'

import { IAcknowledge } from '@/services/api/acknowledgement'

interface IAcknowledgementState {
  gotoBay: IAcknowledge[] | []
  waitAcknowledge: IAcknowledge[] | []
  setGotoBayAcknowledge: (value: IAcknowledge[]) => void
  setWaitAcknowledge: (value: IAcknowledge[]) => void
  remainingList: string[] | []
  setRemainingList: (value: string[]) => void
  remainingWaitingQueue: (IAcknowledge | null | undefined)[]
  setRemainingWaitingQueue: (value: (IAcknowledge | null | undefined)[]) => void
  diffWaitingQueue: IAcknowledge[]
  setDiffWaitingQueue: (value: IAcknowledge[]) => void
  remainingGotoBayQueue: (IAcknowledge | null | undefined)[]
  setRemainingGotoBayQueue: (value: (IAcknowledge | null | undefined)[]) => void
  diffGotoBayQueue: IAcknowledge[]
  setDiffGotoBayQueue: (value: IAcknowledge[]) => void
  dupWaitingTankerIds: string[]
  dupGotoBayTankerIds: string[]
  setDupWaitingTankerIds: (value: string[]) => void
  setDupGotoBayTankerIds: (value: string[]) => void
}

export const useQueueAcknowledgementStore = create<IAcknowledgementState>(
  (set) => ({
    gotoBay: [],
    waitAcknowledge: [],
    setGotoBayAcknowledge: (value) => set({ gotoBay: value }),
    setWaitAcknowledge: (value) => set({ waitAcknowledge: value }),
    remainingList: [],
    setRemainingList: (value) => set({ remainingList: value }),
    remainingWaitingQueue: [],
    setRemainingWaitingQueue: (value) => set({ remainingWaitingQueue: value }),
    diffWaitingQueue: [],
    setDiffWaitingQueue: (value) => set({ diffWaitingQueue: value }),
    remainingGotoBayQueue: [],
    setRemainingGotoBayQueue: (value) => set({ remainingGotoBayQueue: value }),
    diffGotoBayQueue: [],
    setDiffGotoBayQueue: (value) => set({ diffGotoBayQueue: value }),
    dupWaitingTankerIds: [],
    dupGotoBayTankerIds: [],
    setDupWaitingTankerIds: (value) => set({ dupWaitingTankerIds: value }),
    setDupGotoBayTankerIds: (value) => set({ dupGotoBayTankerIds: value }),
  })
)
