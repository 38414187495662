import { FC } from 'react'
import { FormState, UseFormRegister } from 'react-hook-form'

import { IDrawerInformation } from '@/stores'

import TextArea from '@/standard/TextArea'
import TextInput from '@/standard/TextInput'

interface DrawerMasterFormProps {
  formState: FormState<IDrawerInformation>
  register: UseFormRegister<IDrawerInformation>
  isEdit?: boolean
}

const DrawerMasterForm: FC<DrawerMasterFormProps> = ({
  formState,
  register,
  isEdit,
}) => {
  const drawerCodeState = isEdit
    ? 'em-is-disabled'
    : formState.errors.drawerCode && 'em-has-error'
  const drawerNameHasError = formState.errors.drawerName && 'em-has-error'

  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <TextInput
        id="drawerCode"
        label="Drawer Code"
        state={drawerCodeState}
        required
        maxLength={20}
        note={formState.errors.drawerCode?.message}
        {...register('drawerCode', {
          required: {
            value: true,
            message: 'Drawer Code is required',
          },
        })}
      />
      <TextInput
        id="drawerName"
        label="Drawer Name"
        state={drawerNameHasError}
        required
        maxLength={50}
        note={formState.errors.drawerName?.message}
        {...register('drawerName', {
          required: {
            value: true,
            message: 'Drawer Name is required',
          },
        })}
      />
      <TextArea
        id="remark"
        label="Remark"
        maxLength={200}
        textareaOptionalClass="!h-[6rem]"
        {...register('remark')}
      />
    </div>
  )
}

export default DrawerMasterForm
