import React from 'react'
import { twMerge } from 'tailwind-merge'

interface TableBodyProps {
  isSelectable?: boolean
  children?: React.ReactNode
  optionalClass?: string
}

const TableBody = ({
  isSelectable,
  children,
  optionalClass,
}: TableBodyProps) => {
  return (
    <tbody className={twMerge('em-c-table__body', optionalClass)}>
      {React.Children.toArray(children).map((child) =>
        React.isValidElement<TableBodyProps>(child)
          ? React.cloneElement(child, { isSelectable: isSelectable })
          : child
      )}
    </tbody>
  )
}

export default TableBody
