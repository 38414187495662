import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC, useEffect } from 'react'
import DataGrid, { RowsChangeData } from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { ExpanderButton } from '@/components'

import AuditReportDetailTable from '@/features/reports/audit/detailTable'

import {
  GetAuditReportResponseBody,
  GetAuditReportResponseData,
} from '@/services/api/auditData'

import useAuditReportStore from '@/stores/reports/audit'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<GetAuditReportResponseData>([
  {
    key: 'expanded',
    name: '',
    minWidth: 30,
    width: 45,
    colSpan(args) {
      return args.type === 'ROW' && args.row.type === 'DETAIL' ? 11 : undefined
    },
    cellClass(row) {
      return row.type === 'DETAIL'
        ? 'px-[64px] py-[16px] bg-neutral-200'
        : 'my-auto mx-auto'
    },
    renderCell({ row, tabIndex, onRowChange }) {
      if (row.type === 'DETAIL') {
        return <AuditReportDetailTable auditHeaderId={row.auditHeaderId} />
      }

      return (
        <ExpanderButton
          expanded={row.expanded}
          tabIndex={tabIndex}
          onClick={() => {
            onRowChange({ ...row, expanded: !row.expanded })
          }}
        />
      )
    },
  },
  { key: 'auditActionName', name: 'Action' },
  { key: 'tableName', name: 'Table' },
  {
    key: 'key1Name',
    name: 'Key 1',
    renderCell: ({ row }) =>
      row.key1 && row.key1Name ? `${row.key1} - ${row.key1Name}` : '',
  },
  {
    key: 'key2Name',
    name: 'Key 2',
    renderCell: ({ row }) =>
      row.key2 && row.key2Name ? `${row.key2} - ${row.key2Name}` : '',
  },
  {
    key: 'key3Name',
    name: 'Key 3',
    renderCell: ({ row }) =>
      row.key3 && row.key3Name ? `${row.key3} - ${row.key3Name}` : '',
  },
  {
    key: 'key4Name',
    name: 'Key 4',
    renderCell: ({ row }) =>
      row.key4 && row.key4Name ? `${row.key4} - ${row.key4Name}` : '',
  },
  {
    key: 'key5Name',
    name: 'Key 5',
    renderCell: ({ row }) =>
      row.key5 && row.key5Name ? `${row.key5} - ${row.key5Name}` : '',
  },
  { key: 'note', name: 'Note' },
  { key: 'displayAuditDate', name: 'Audit Date' },
  { key: 'changeBy', name: 'Changed By' },
])

interface AuditReportTableProps {
  currentPage: number
  onChangePage: (page: number) => void
  audits?: GetAuditReportResponseBody
  isLoading: boolean
}

const AuditReportTable: FC<AuditReportTableProps> = ({
  currentPage,
  onChangePage,
  audits,
  isLoading,
}) => {
  const rowsPerPage = 100
  const { rowHeight, rows, setRows } = useAuditReportStore()

  const onRowsChange = (
    rows: GetAuditReportResponseData[],
    { indexes }: RowsChangeData<GetAuditReportResponseData>
  ) => {
    const row = rows[indexes[0]]
    if (row.type === 'MASTER') {
      if (row.expanded) {
        rows.splice(indexes[0] + 1, 0, {
          ...row,
          type: 'DETAIL',
        })
      } else {
        rows.splice(indexes[0] + 1, 1)
      }
      setRows(rows)
    }
  }

  const handleRowHeight = (row: GetAuditReportResponseData) => {
    if (row.type === 'DETAIL') {
      if (rowHeight[row.auditHeaderId] < 300) {
        return rowHeight[row.auditHeaderId] + 32
      }
      if (rowHeight[row.auditHeaderId] >= 300) {
        return 332
      }
      return 45
    }
    return 45
  }

  const label = usePaginationLabel({
    isLoading,
    currentPage,
    rowsPerPage,
    total: audits?.total || 0,
  })

  useEffect(() => {
    if (audits?.result) {
      setRows(audits?.result)
    }
  }, [audits?.result])

  return audits ? (
    <>
      <DataGrid
        className="h-full rdg-light"
        columns={columns}
        rows={rows}
        onRowsChange={onRowsChange}
        headerRowHeight={30}
        rowHeight={handleRowHeight}
        enableVirtualization={false}
        onCellKeyDown={(_, event) => {
          if (event.isDefaultPrevented()) {
            // skip parent grid keyboard navigation if nested grid handled it
            event.preventGridDefault()
          }
        }}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          audits?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((audits?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default AuditReportTable
