import { FC } from 'react'

import { useCustomerMasterStore } from '@/stores'

import CustomerMasterAddModal from './addModal'
import CustomerMasterDeleteModal from './deleteModal'
import CustomerEditModal from './editModal'
import Header from './header'

const DrawerMasterComponent: FC = () => {
  const {
    toggleAddCustomerModal,
    toggleEditCustomerModal,
    toggleConfirmDeleteModal,
  } = useCustomerMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleAddCustomerModal && <CustomerMasterAddModal />}
      {toggleEditCustomerModal && <CustomerEditModal />}
      {toggleConfirmDeleteModal && <CustomerMasterDeleteModal />}
      <Header />
    </div>
  )
}
export default DrawerMasterComponent
