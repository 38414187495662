import { useMutation, useQuery } from '@tanstack/react-query'

import { GenericResponseBody } from '@/services/types'

import { IContractData, IContractDetail, IContractInformation } from '@/stores'

import { handleQueryError } from '@/utils'

import { api } from '../apiClient'
import { queryClient } from '../queryClient'

export interface ISearchContract {
  ContractNr?: string
  termID: string
  CarrierCode?: string
  DrawerCode?: string
  ValidityStart?: string
  ValidityEnd?: string
  SoldToCustomerCode?: string
  ShipToCustomerCode?: string
  Page: number
}

export interface ISearchContractByID {
  termID: string
  contractID: number
}

export interface IAddContract {
  contractNr: string
  contractID: number
  contractType: string
  carrierID: number
  drawerID: number
  validityEnd: string
  validityStart: string
  shipToCustomerID: number | null
  soldToCustomerID: number | null
  details: IContractDetail[]
}

export interface IEditContract {
  contractNr: string
  contractID: number
  contractType: string
  carrierID: number
  drawerID: number
  validityEnd: string
  validityStart: string
  shipToCustomerID: number | null
  soldToCustomerID: number | null
  details: IContractDetail[]
}

interface IResponseQContract {
  message: string
  code: number
  result: IContractData
}

interface IResponseGetQContractByID {
  message: string
  code: number
  result: IContractInformation
}

export function useSearchContract(params: ISearchContract) {
  return useQuery<unknown, unknown, IContractData>({
    queryKey: ['searchQContract', params.Page],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseQContract>(
          `/QContract/SearchQContract`,
          { params }
        )
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

export function useGetContractByID(params: ISearchContractByID) {
  return useQuery<unknown, unknown, IContractInformation>({
    queryKey: ['getQContractID', params.contractID],
    queryFn: async () => {
      try {
        const { data } = await api.get<IResponseGetQContractByID>(
          `/QContract/GetContract`,
          { params }
        )
        return data.result
      } catch (error) {
        handleQueryError(error)
      }
    },
  })
}

interface UseAddContractOptions {
  body: IAddContract
  params: Pick<ISearchContract, 'termID'>
}

export function useAddContract(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseAddContractOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/QContract/AddContract`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchQContract'] })
    },
  })
}

interface UseEditContractOptions {
  body: IEditContract
  params: Pick<ISearchContract, 'termID'>
}

export function useEditContract(cb?: () => void) {
  return useMutation<GenericResponseBody, unknown, UseEditContractOptions>({
    mutationFn: async ({ body, params }) => {
      const { data } = await api.post(`/QContract/EditContract`, body, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchQContract'] })
    },
  })
}

interface UseDeleteContractRequestParams {
  contractID: number
  termID: string
}

export function useDeleteContract(cb?: () => void) {
  return useMutation<
    GenericResponseBody,
    unknown,
    UseDeleteContractRequestParams
  >({
    mutationFn: async (params) => {
      const { data } = await api.delete(`/QContract/DeleteContract`, {
        params,
      })
      return data
    },
    onSuccess: (data) => {
      if (data.message) {
        throw new Error(data.message)
      }
      if (cb) {
        cb()
      }
      queryClient.invalidateQueries({ queryKey: ['searchQContract'] })
    },
  })
}
