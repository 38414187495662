import React from 'react'

interface HeaderTitleProps {
  title: string
  logotype?: boolean
  link?: string
  kicker?: string
  optionalClass?: string
}

const HeaderTitle = ({
  title,
  logotype,
  link = '#',
  kicker,
  optionalClass,
}: HeaderTitleProps) => {
  return (
    <div
      className={`em-c-header__title-container ${
        optionalClass ? optionalClass : ''
      }`}
    >
      {kicker && <div className="em-c-header__kicker">{kicker}</div>}
      <h2 className="em-c-header__title">
        <a
          href={link}
          rel="home"
          className={
            logotype ? 'em-c-header__title-logo' : 'em-c-header__title-link'
          }
          onClick={(e) => e.preventDefault()}
        >
          {title}
        </a>
      </h2>
    </div>
  )
}

export default HeaderTitle
