import { FC } from 'react'
import DataGrid from 'react-data-grid'

import { TableButtonActions } from '@/components'

import {
  IShipmentDetail,
  useRoleMatrixStore,
  useShipmentMasterStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IShipmentDetail>([
  {
    key: 'drawerProdCode',
    name: 'Drawer Product Code',
  },
  {
    key: 'soldToCustomerCode',
    name: 'Sold To Customer Code',
  },
  {
    key: 'shipToCustomerCode',
    name: 'Ship To Customer Code',
  },
  {
    key: 'actions',
    name: 'Actions',
    renderCell: ({ row, rowIdx }) => {
      const { shipment } = useRoleMatrixStore.getState()
      const {
        setToggleEditShipmentChildModal,
        setSelectedShipmentChild,
        setSelectedShipmentChildIndex,
        setToggleConfirmDeleteChildModal,
      } = useShipmentMasterStore.getState()
      const { canEdit, canDelete } = shipment

      const handleDelete = () => {
        setToggleConfirmDeleteChildModal(true)
        setSelectedShipmentChild(row)
        setSelectedShipmentChildIndex(rowIdx)
      }

      const handleEdit = () => {
        setToggleEditShipmentChildModal(true)
        setSelectedShipmentChild(row)
        setSelectedShipmentChildIndex(rowIdx)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface ShipmentTableProps {
  shipmentChildren?: IShipmentDetail[]
  isLoading?: boolean
}

const ShipmentChildTable: FC<ShipmentTableProps> = ({
  shipmentChildren,
  isLoading,
}) => {
  return !isLoading && shipmentChildren ? (
    <DataGrid
      className="rdg-light h-auto"
      columns={columns}
      rows={shipmentChildren}
      rowHeight={45}
      headerRowHeight={30}
    />
  ) : (
    <LoadingIndicator textOnly />
  )
}

export default ShipmentChildTable
