import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface ErrorModalState {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  message: string
  setMessage: (value: string) => void
}

const useErrorModalStore = create<ErrorModalState>()(
  immer((set) => ({
    isOpen: false,
    setIsOpen: (value) => {
      set((state) => {
        state.isOpen = value
        if (!value) {
          state.message = ''
        }
      })
    },
    message: '',
    setMessage: (value) => {
      set((state) => {
        state.message = value
      })
    },
  }))
)

export default useErrorModalStore
