import { DeleteModal } from '@/components'

import { useShipmentMasterStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DeleteShipmentChildModal = () => {
  const {
    selectedShipmentChild,
    setToggleConfirmDeleteChildModal,
    deleteShipmentChild,
    setSelectedShipmentChild,
    setSelectedShipmentChildIndex,
  } = useShipmentMasterStore()

  const handleClose = () => {
    setToggleConfirmDeleteChildModal(false)
    setSelectedShipmentChild(undefined)
    setSelectedShipmentChildIndex(-1)
  }

  const handleConfirm = () => {
    deleteShipmentChild()
    handleClose()
  }

  return (
    <DeleteModal
      title="Delete a shipment: Order"
      modalWindowOptionalClass="!z-[70]"
      overlayOptionalClass="!z-[60]"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        {selectedShipmentChild?.drawerProdCode && (
          <BulletedListItem>
            Drawer Product Code: {selectedShipmentChild.drawerProdCode}
          </BulletedListItem>
        )}
        {selectedShipmentChild?.soldToCustomerCode && (
          <BulletedListItem>
            Sold To Customer Code: {selectedShipmentChild?.soldToCustomerCode}
          </BulletedListItem>
        )}
        {selectedShipmentChild?.shipToCustomerCode && (
          <BulletedListItem>
            Ship To Customer Code: {selectedShipmentChild.shipToCustomerCode}
          </BulletedListItem>
        )}
      </BulletedList>
    </DeleteModal>
  )
}

export default DeleteShipmentChildModal
