import { ReactNode, useEffect } from 'react'
import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form'

import useAlertInModalStore from '@/stores/common/alertInModal'

import Button from '@/standard/Button'
import ButtonGroup from '@/standard/ButtonGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@/standard/Modal'

interface FormModalProps<T extends FieldValues> {
  children: ReactNode
  onClose: () => void
  title: string
  confirmLabel: string
  handleSubmit: UseFormHandleSubmit<T>
  onSubmit: SubmitHandler<T>
  isValid?: boolean
  modalWindowOptionalClass?: string
  overlayOptionalClass?: string
  isLoading?: boolean
}

function FormModal<T extends FieldValues>({
  title,
  onClose,
  children,
  confirmLabel,
  handleSubmit,
  onSubmit,
  isValid,
  modalWindowOptionalClass,
  overlayOptionalClass,
  isLoading,
}: FormModalProps<T>) {
  const { setIsAlertInModalOpen } = useAlertInModalStore()

  const handleClose = () => {
    setIsAlertInModalOpen(false)
    onClose?.()
  }

  useEffect(() => {
    return () => {
      setIsAlertInModalOpen(false)
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Modal
        modalWindowOptionalClass={modalWindowOptionalClass}
        overlayOptionalClass={overlayOptionalClass}
      >
        <ModalHeader
          optionalClass="!mb-[0]"
          title={title}
          onClose={handleClose}
          isError
        />
        <div className="overflow-y-auto">
          <ModalBody optionalClass="!p-[0.6rem]">{children}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                type="em-c-btn--primary"
                typeAttribute="submit"
                {...(isLoading
                  ? {
                      isIconOnlyButton: true,
                      iconName: 'refresh',
                      isLoading: true,
                      disabled: true,
                    }
                  : {
                      label: confirmLabel,
                      disabled: !isValid,
                    })}
              />
              <Button
                typeAttribute="button"
                label="Cancel"
                onClick={handleClose}
              />
            </ButtonGroup>
          </ModalFooter>
        </div>
      </Modal>
    </form>
  )
}

export default FormModal
