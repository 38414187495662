import { Layout } from '@/components'

import DrawerMaster from '@/features/master/drawer'

const DrawerMasterPage = () => {
  return (
    <Layout>
      <DrawerMaster />
    </Layout>
  )
}

export default DrawerMasterPage
