import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { CarrierAutoComplete, DrawerAutoComplete } from '@/components'

import DatePicker from '@/components/forms/pickers/datePicker'

import { IGetBayResultDetail } from '@/services/api/bayMaster'

import { formatDateToLocalDateTime } from '@/utils'

interface DedicatedBayFormProps {
  onSubmit: (form: IGetBayResultDetail) => void
}

const DedicatedBayForm: FC<DedicatedBayFormProps> = () => {
  const { watch, register, setValue } = useFormContext<IGetBayResultDetail>()

  const handleDedicatedBayChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('dedicatedEnd', '')
    return event
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
      <CarrierAutoComplete
        isRequired={!watch('carrierCode') && !watch('drawerCode')}
      />
      <DrawerAutoComplete
        isRequired={!watch('carrierCode') && !watch('drawerCode')}
      />
      <DatePicker
        id="dedicatedStart"
        label="Dedicated Start"
        type="date"
        required
        {...register('dedicatedStart', {
          onChange: handleDedicatedBayChange,
        })}
      />
      <DatePicker
        id="dedicatedEnd"
        label="Dedicated End"
        type="date"
        min={formatDateToLocalDateTime(watch('dedicatedStart') as string)}
        {...register('dedicatedEnd')}
      />
      <DatePicker
        id="periodStartString"
        label="Period Start"
        type="time"
        max={watch('periodEndString')}
        required
        {...register('periodStartString')}
      />
      <DatePicker
        id="periodEndString"
        label="Period End"
        type="time"
        min={watch('periodStartString')}
        required
        {...register('periodEndString')}
      />
    </div>
  )
}

export default DedicatedBayForm
