import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDeleteDrawerProd } from '@/services/api/drawerProd'

import { useDrawerProductStore } from '@/stores'

import BulletedList, { BulletedListItem } from '@/standard/BulletedList'

const DrawerProductDeleteModal: FC = () => {
  const {
    currentDrawerProd,
    setToggleConfirmDeleteModal,
    setCurrentDrawerProd,
  } = useDrawerProductStore()

  const handleClose = () => {
    setToggleConfirmDeleteModal(false)
    setCurrentDrawerProd(undefined)
  }

  const { mutate, isPending } = useDeleteDrawerProd(handleClose)

  const handleConfirm = () => {
    mutate({ drawerProdID: currentDrawerProd!.drawerProdID, termID: '1002' })
  }

  return (
    <DeleteModal
      title="Delete a drawer product"
      onClose={handleClose}
      onDelete={handleConfirm}
      isLoading={isPending}
    >
      <p>Are you sure you want to delete?</p>
      <BulletedList>
        <BulletedListItem>
          Drawer Product Code: {currentDrawerProd?.drawerProdCode}
        </BulletedListItem>
        <BulletedListItem>
          Drawer Product Name: {currentDrawerProd?.drawerProdName}
        </BulletedListItem>
      </BulletedList>
    </DeleteModal>
  )
}

export default DrawerProductDeleteModal
