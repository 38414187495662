import { FC } from 'react'

import { DrawerProdAutoComplete } from '@/components'

import CustomerAutoComplete from '@/components/forms/autocompletes/customer'

const ShipmentChildForm: FC = () => {
  return (
    <div className="grid grid-cols-1 gap-2 lg:gap-4">
      <DrawerProdAutoComplete isRequired />
      <CustomerAutoComplete
        label="Sold to Customer"
        customFormValue="soldToCustomer"
      />
      <CustomerAutoComplete
        label="Ship to Customer"
        customFormValue="shipToCustomer"
      />
    </div>
  )
}

export default ShipmentChildForm
