import Icon from 'components/Icon'
import { MouseEventHandler, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface TreeNavigationDropdownProps {
  label: string
  expanded?: boolean
  optionalClass?: string
  children: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const TreeNavigationDropdown = ({
  label,
  expanded,
  children,
  optionalClass,
  onClick,
}: TreeNavigationDropdownProps) => {
  return (
    <li
      className={`em-c-tree__item ${optionalClass ? optionalClass : ''}`}
      role="menuitem"
    >
      <button
        className={twMerge(
          'em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger',
          expanded && 'em-is-active',
          'w-full'
        )}
        onClick={onClick}
        aria-label={label}
        aria-expanded={expanded ? 'true' : 'false'}
        aria-current={expanded ? 'true' : 'false'}
      >
        <span className="em-c-tree__text">{label}</span>
        <Icon
          name="chevron-right-filled"
          size="em-c-icon--small"
          optionalClass="em-c-tree__icon"
        />
      </button>
      <ul
        className={`em-c-tree__list em-js-tree-dropdown ${
          expanded ? 'em-is-active' : ''
        }`}
        role="menu"
        aria-hidden={expanded ? 'false' : 'true'}
        aria-current={expanded ? 'true' : 'false'}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </ul>
    </li>
  )
}

export default TreeNavigationDropdown
