import { create } from 'zustand'

import { ScreenList } from '@/services/api/roleMatrix'

interface RoleModalState {
  currentRole: string
  setCurrentRole: (value: string) => void
  toggleDeleteModal: boolean
  setToggleDeleteModal: (value: boolean) => void
  toggleAddModal: boolean
  setToggleAddModal: (value: boolean) => void
  screenList: ScreenList[]
  setScreenList: (value: ScreenList[]) => void
}

const useRoleModalStore = create<RoleModalState>((set) => ({
  currentRole: '',
  setCurrentRole: (value) => set({ currentRole: value }),
  toggleDeleteModal: false,
  setToggleDeleteModal: (value) => set({ toggleDeleteModal: value }),
  toggleAddModal: false,
  setToggleAddModal: (value) => set({ toggleAddModal: value }),
  screenList: [],
  setScreenList: (value) => set({ screenList: value }),
}))

export default useRoleModalStore
