import { FC } from 'react'

import { DeleteModal } from '@/components'

import { useDisableQueue } from '@/services/api/monitor/order'

import { useOrderMonitor } from '@/stores'

const DisableQueueModal: FC = () => {
  const { setIsDisableQueueModalOpen, currentWaitingQueue } = useOrderMonitor()

  const handleClose = () => {
    setIsDisableQueueModalOpen(false)
  }
  const { mutate: disableQueue } = useDisableQueue(handleClose)

  const handleConfirm = () => {
    disableQueue({
      termID: '1002',
      tankerID: Number(currentWaitingQueue?.tankerID),
    })
  }

  return (
    <DeleteModal
      title="Disable this queue"
      confirmText="Confirm"
      onClose={handleClose}
      onDelete={handleConfirm}
    >
      <p>
        Are you sure you want to disable the truck
        <span className="font-bold pl-2">
          {currentWaitingQueue?.licensePlate}
        </span>
        ?
      </p>
    </DeleteModal>
  )
}
export default DisableQueueModal
