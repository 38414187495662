import DataGrid from 'react-data-grid'
import { useFormContext } from 'react-hook-form'

import RoleMatrixToggle from '@/features/roleMatrix/toggle'

import { ScreenList } from '@/services/api/roleMatrix'

import { useRoleModalStore } from '@/stores'

import TextInput from '@/standard/TextInput'

import { makeDataGridColumns, parseStringToBoolean } from '@/utils'

const columns = makeDataGridColumns<ScreenList>([
  {
    key: 'screenName',
    name: 'Screen Name',
  },
  {
    key: 'canRead',
    name: 'Can Read',
    renderCell: ({ row, onRowChange }) => {
      return (
        <RoleMatrixToggle
          screenList={row}
          activeKey="canRead"
          onChange={(event) => {
            onRowChange({
              ...row,
              canRead: parseStringToBoolean(event.currentTarget.value),
            })
          }}
        />
      )
    },
  },
  {
    key: 'canEdit',
    name: 'Can Edit',
    renderCell: ({ row, onRowChange }) => {
      return (
        <RoleMatrixToggle
          screenList={row}
          activeKey="canEdit"
          onChange={(event) => {
            onRowChange({
              ...row,
              canEdit: parseStringToBoolean(event.currentTarget.value),
            })
          }}
        />
      )
    },
  },
  {
    key: 'canCreate',
    name: 'Can Create',
    renderCell: ({ row, onRowChange }) => {
      return (
        <RoleMatrixToggle
          screenList={row}
          activeKey="canCreate"
          onChange={(event) => {
            onRowChange({
              ...row,
              canCreate: parseStringToBoolean(event.currentTarget.value),
            })
          }}
        />
      )
    },
  },
  {
    key: 'canDelete',
    name: 'Can Delete',
    renderCell: ({ row, onRowChange }) => {
      return (
        <RoleMatrixToggle
          screenList={row}
          activeKey="canDelete"
          onChange={(event) => {
            onRowChange({
              ...row,
              canDelete: parseStringToBoolean(event.currentTarget.value),
            })
          }}
        />
      )
    },
  },
])

export interface IRoleMatrixForm {
  role: string
}

const RoleMatrixForm = () => {
  const { screenList, setScreenList } = useRoleModalStore()
  const { formState, register } = useFormContext<IRoleMatrixForm>()

  const roleState = formState.errors.role && 'em-has-error'

  return (
    <>
      <div className="grid grid-cols-1 gap-2 lg:gap-4 mb-4">
        <TextInput
          id="role"
          label="Role Name"
          state={roleState}
          required
          maxLength={20}
          note={formState.errors.role?.message}
          {...register('role', {
            required: {
              value: true,
              message: 'Role is required',
            },
          })}
        />
      </div>
      <DataGrid
        columns={columns}
        rows={screenList}
        onRowsChange={(screenList) => setScreenList(screenList)}
      />
    </>
  )
}

export default RoleMatrixForm
