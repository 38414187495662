import { FC } from 'react'

import DrawerMasterAddModal from '@/features/master/drawer/addModal'
import DrawerMasterEditModal from '@/features/master/drawer/editModal'

import { useDrawerMasterStore } from '@/stores'

import DrawerMasterDeleteModal from './deleteModal'
import Header from './header'

const DrawerMasterComponent: FC = () => {
  const {
    toggleConfirmDeleteModal,
    toggleAddDrawerModal,
    toggleEditDrawerModal,
  } = useDrawerMasterStore()

  return (
    <div className="flex flex-col h-full">
      {toggleConfirmDeleteModal && <DrawerMasterDeleteModal />}
      {toggleAddDrawerModal && <DrawerMasterAddModal />}
      {toggleEditDrawerModal && <DrawerMasterEditModal />}
      <Header />
    </div>
  )
}
export default DrawerMasterComponent
