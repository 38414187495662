import React from 'react'

export interface TabItemProps {
  id?: number
  title?: string
  active?: boolean
  children?: React.ReactNode
  optionalClass?: string
}

const TabItem = ({
  id,
  active,
  children,
  optionalClass,
  ...props
}: TabItemProps) => {
  return (
    <div
      className={`em-c-tabs__panel em-js-tabs-panel ${
        active ? 'em-is-active' : ''
      } ${optionalClass ? optionalClass : ''}`}
      id={`tab-panel-${id}`}
      role="tabpanel"
      aria-labelledby={`tab-${id}`}
      {...props}
    >
      {children}
    </div>
  )
}

export default TabItem
