import usePaginationLabel from '@/hooks/usePaginationLabel'
import { FC } from 'react'
import DataGrid from 'react-data-grid'
import { twMerge } from 'tailwind-merge'

import { TableButtonActions } from '@/components'

import { IPriorityData } from '@/services/api/priorityMaster'

import {
  IPriorityInformation,
  usePriorityMasterStore,
  useRoleMatrixStore,
} from '@/stores'

import LoadingIndicator from '@/standard/LoadingIndicator'
import ToolbarWithPagination from '@/standard/Toolbars/ToolbarWithPagination'

import { makeDataGridColumns } from '@/utils'

const columns = makeDataGridColumns<IPriorityInformation>([
  {
    key: 'priorityCode',
    name: 'Priority Code',
  },
  {
    key: 'priorityName',
    name: 'Priority Name',
  },
  {
    key: 'delayMin',
    name: 'Delay Min',
  },
  {
    key: 'dropMax',
    name: 'Drop Max',
  },
  {
    key: 'displayRemark',
    name: 'Remark',
  },
  {
    key: 'displayCreatedDate',
    name: 'Created Date',
  },
  {
    key: 'createdBy',
    name: 'Created By',
  },
  {
    key: 'displayModifiedDate',
    name: 'Modified Date',
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
  },
  {
    key: 'action',
    name: 'Action',
    frozen: true,
    width: 120,
    renderCell: ({ row }) => {
      const { priorityMaster } = useRoleMatrixStore.getState()
      const {
        setToggleConfirmDeleteModal,
        setCurrentPriority,
        setToggleEditPriorityModal,
      } = usePriorityMasterStore.getState()
      const { canDelete, canEdit } = priorityMaster

      const handleDelete = () => {
        setCurrentPriority(row)
        setToggleConfirmDeleteModal(true)
      }

      const handleEdit = () => {
        setCurrentPriority(row)
        setToggleEditPriorityModal(true)
      }

      return (
        <TableButtonActions
          canDelete={canDelete}
          canEdit={canEdit}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      )
    },
  },
])

interface PriorityMasterTableProps {
  onChangePage: (page: number) => void
  currentPage: number
  priorities?: IPriorityData
  isLoading: boolean
}

const PriorityMasterTable: FC<PriorityMasterTableProps> = ({
  onChangePage,
  currentPage,
  priorities,
  isLoading,
}) => {
  const rowsPerPage = 100

  const label = usePaginationLabel({
    isLoading,
    currentPage,
    rowsPerPage,
    total: priorities?.total || 0,
  })

  return priorities?.result ? (
    <>
      <DataGrid
        columns={columns}
        rows={priorities.result}
        className="h-full rdg-light"
        rowHeight={45}
        headerRowHeight={30}
      />
      <ToolbarWithPagination
        optionalClass={twMerge(
          'text-[0.8rem] !px-[0.8rem]',
          priorities?.total ? '!py-[0.25rem]' : '!py-[0.65rem]'
        )}
        label={label}
        currentPage={currentPage}
        numberOfPages={Math.ceil((priorities?.total || 0) / rowsPerPage)}
        onChangePage={onChangePage}
      />
    </>
  ) : (
    <LoadingIndicator isFixed />
  )
}

export default PriorityMasterTable
