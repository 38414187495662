import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export interface IShipmentInformation {
  carrierCode: string
  carrierID: number
  createdBy: string
  createdDate: string
  displayCreatedDate: string
  displayExpDate: string
  displayModifiedDate: string
  displaySchedDate: string
  drawerCode: string
  drawerID: number
  expDate: string
  modifiedBy: string
  modifiedDate: string
  schedDate: string
  shipmentID: number
  shipmentNr: string
  tankerCode: string
  tankerID: number
  termID: string
  tripPriority: number
  loadDate: string | null
  displayLoadDate: string
  remark: string
  type: number
}

export interface IShipmentDetail {
  shipmentDetailID: number
  drawerProdID: number
  drawerProdCode: string
  shipToCustomerID: number | null
  shipToCustomerCode: string
  soldToCustomerID: number | null
  soldToCustomerCode: string
}

export interface IShipmentData {
  result: IShipmentInformation[]
  total: number
}

interface ShipmentMasterState {
  currentShipment: IShipmentInformation | undefined
  setCurrentShipment: (value: IShipmentInformation | undefined) => void
  toggleConfirmDeleteModal: boolean
  setToggleConfirmDeleteModal: (value: boolean) => void
  toggleAddShipmentModal: boolean
  setToggleAddShipmentModal: (value: boolean) => void
  toggleEditShipmentModal: boolean
  setToggleEditShipmentModal: (value: boolean) => void
  // Children
  toggleConfirmDeleteChildModal: boolean
  setToggleConfirmDeleteChildModal: (value: boolean) => void
  toggleAddShipmentChildModal: boolean
  setToggleAddShipmentChildModal: (value: boolean) => void
  toggleEditShipmentChildModal: boolean
  setToggleEditShipmentChildModal: (value: boolean) => void
  shipmentChildren: IShipmentDetail[]
  setShipmentChildren: (value: IShipmentDetail[]) => void
  addShipmentChild: (value: IShipmentDetail) => void
  editShipmentChild: (value: IShipmentDetail) => void
  deleteShipmentChild: () => void
  selectedShipmentChild: IShipmentDetail | undefined
  setSelectedShipmentChild: (value: IShipmentDetail | undefined) => void
  selectedShipmentChildIndex: number
  setSelectedShipmentChildIndex: (value: number) => void
  clearState: () => void
}

export const useShipmentMasterStore = create<ShipmentMasterState>()(
  immer((set) => ({
    currentShipment: undefined,
    setCurrentShipment: (value) => {
      set((state) => {
        state.currentShipment = value
      })
    },
    toggleConfirmDeleteModal: false,
    setToggleConfirmDeleteModal: (value) => {
      set((state) => {
        state.toggleConfirmDeleteModal = value
      })
    },
    toggleAddShipmentModal: false,
    setToggleAddShipmentModal: (value) => {
      set((state) => {
        state.toggleAddShipmentModal = value
      })
    },
    toggleEditShipmentModal: false,
    setToggleEditShipmentModal: (value) => {
      set((state) => {
        state.toggleEditShipmentModal = value
      })
    },
    shipmentChildren: [],
    setShipmentChildren: (value) => {
      set((state) => {
        state.shipmentChildren = value
      })
    },
    addShipmentChild: (value) => {
      set((state) => {
        state.shipmentChildren.push(value)
      })
    },
    editShipmentChild: (value) => {
      set((state) => {
        state.shipmentChildren[Number(state.selectedShipmentChildIndex)] = value
      })
    },
    deleteShipmentChild: () => {
      set((state) => {
        state.shipmentChildren.splice(state.selectedShipmentChildIndex, 1)
      })
    },
    toggleAddShipmentChildModal: false,
    setToggleAddShipmentChildModal: (value) => {
      set((state) => {
        state.toggleAddShipmentChildModal = value
      })
    },
    toggleConfirmDeleteChildModal: false,
    setToggleConfirmDeleteChildModal: (value) => {
      set((state) => {
        state.toggleConfirmDeleteChildModal = value
      })
    },
    toggleEditShipmentChildModal: false,
    setToggleEditShipmentChildModal: (value) => {
      set((state) => {
        state.toggleEditShipmentChildModal = value
      })
    },
    selectedShipmentChild: undefined,
    setSelectedShipmentChild: (value) => {
      set((state) => {
        state.selectedShipmentChild = value
      })
    },
    selectedShipmentChildIndex: -1,
    setSelectedShipmentChildIndex: (value) => {
      set((state) => {
        state.selectedShipmentChildIndex = value
      })
    },
    clearState: () => {
      set((state) => {
        state.currentShipment = undefined
        state.toggleConfirmDeleteModal = false
        state.toggleAddShipmentModal = false
        state.toggleEditShipmentModal = false
        state.toggleConfirmDeleteChildModal = false
        state.toggleAddShipmentChildModal = false
        state.toggleEditShipmentChildModal = false
        state.shipmentChildren = []
        state.selectedShipmentChild = undefined
        state.selectedShipmentChildIndex = -1
      })
    },
  }))
)
